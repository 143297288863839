
import React, { useState, useEffect } from 'react';
import Rodape from '../../components/Rodape';
import Navegador from '../../components/NavBar';
import Cookies from 'js-cookie';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import './styles.scss';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import logo from '../../assets/imgs/logo_kapplan_energy.png';



const useStyles = makeStyles((theme) => ({
    root: {
        height: '50vh',
    },

    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    papermobile: {
        margin: theme.spacing(1, 1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    avatarmobile: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    entrada: {
        fontSize: 22
    },
}));

export default function Login() {

    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [senha, setSenha] = useState('');
    const [erroSenha, setErroSenha] = useState(false);
    const [textoErroSenha, setTextoErroSenha] = useState('');


    const [width, setWidth] = useState(0);

    const [larguraOriginal, setLarguraOriginal] = useState(0);

    const [limiar, setLimiar] = useState(900);



    function checkDimenssoes() {
        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
        console.log("altura: " + altura);
        console.log("largura: " + largura);

        setLarguraOriginal(largura);
        setWidth(largura * 0.7);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });


    useEffect(() => {



        checkDimenssoes();



    }, []);


    const history = useHistory();

    function validateSenha() {

        if (senha.length > 2) {

            setErroSenha(false);
            setTextoErroSenha('');
            return true;
        } else {

            setErroSenha(true);
            setTextoErroSenha("Revise esse campo");

            return false;
        }
    }





    async function logar() {
        try {

            if (validateSenha()) {


                console.log("logar chamado");
                const credentials = {
                    login: username,
                    senha: senha
                }
                const response = await api.post('/v1/protected/signin', credentials);

                const token = response.data.token;
                const refreshToken = response.data.refreshToken;
                console.log("token na tela de login: " + token);
                console.log("refreshToken na tela de login: " + refreshToken);

                Cookies.set('token', token);
                Cookies.set('refreshToken', refreshToken);

                Cookies.set('id_usuario', response.data.id);


                const regra = response.data.role;
                console.log("regra na tela de login: " + regra);
                Cookies.set('regra', regra);


                // Adds the token to the header
                api.defaults.headers.common.Authorization = `Bearer ${token}`;

                console.log("token: " + Cookies.get('token'));

              
                    history.push({
                        pathname: '/minhaconta',

                    })
                



            }
        } catch (_err) {
            console.log("erro: " + _err);
            try {
                if (_err.response.status === 401) {
                    console.log("erro 401, login errado: ");
                    setErroSenha(true);
                    setTextoErroSenha("Usuário ou Senha Inválidos");
                } else {
                    alert("Erro ao Logar! Verifique sua conexão com a internet! Tente Novamente em alguns instantes!");
                    setErroSenha(false);
                    setTextoErroSenha("");

                }
                return 0;
            } catch (_err) {
                alert("Erro ao Logar! Verifique sua conexão com a internet! Tente Novamente em alguns instantes!");

            }
        }
    };


    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          logar();
        }
      };


    return (
        <div >

            <Grid item xs={12} container
                direction='row'
                alignContent={ larguraOriginal < limiar ? 'center' : 'flex-start' }
                justifyContent={ larguraOriginal < limiar ? 'center' : 'flex-start' }
                component={Paper} elevation={ larguraOriginal < limiar ? 0 : 10 } square
                style={{ margin: larguraOriginal < limiar ? 1 : 50,  marginTop: larguraOriginal < limiar ? 20 : null}}
            >

                <Grid  
                 item xs={12} sm={12} md={7} lg={7} xl={7}
                    container
                    direction='row'

                    alignContent={ larguraOriginal < limiar ? 'center' : 'flex-start' } 
                    justifyContent={ larguraOriginal < limiar ? 'center' : 'flex-start' } 
                >
                    <img
                        src={logo}
                        alt={"capa2"}
                        style={{ maxWidth: larguraOriginal < limiar ? null : 300, maxHeight: larguraOriginal < limiar ? null : 100, margin: larguraOriginal < limiar ? 10 : 100}}
                    />
                </Grid>

                <Grid
                    container
                    alignContent={ larguraOriginal < limiar ? 'center' : 'flex-start' }  
                    justifyContent={ larguraOriginal < limiar ? 'center' : 'flex-start'}
                    item xs={12} sm={12} md={4} lg={4} xl={4}
                    direction='row'
                >

                    <Grid
                        container
                        alignContent= { larguraOriginal < limiar ? 'center' : 'flex-start' }  
                        justifyContent={  larguraOriginal < limiar ? 'center' : 'flex-start' }  
                        item xs={12}  >
                        <div className={  larguraOriginal < limiar ?  classes.papermobile : classes.paper  }>
                            <Avatar className={ larguraOriginal < limiar ? classes.avatarmobile :  classes.avatar  }>
                                <LockOutlinedIcon />
                            </Avatar>
                            <CssBaseline />

                            <TextField
                                id="usuario"
                                variant="standard"
                                margin="normal"
                                name="usuario"
                                fullWidth
                                label="Usuário"
                                required
                                autoComplete="usuario"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                inputProps={{ maxLength: 60, minLength: 15 }}
                                InputLabelProps={{ className: classes.entrada }}
                                style={{ paddingTop: 5, paddingBottom: 10 }}
                                onKeyDown={handleKeyDown}
                            />

                            <TextField
                                error={erroSenha}
                                id="password"
                                helperText={textoErroSenha}
                                variant="standard"
                                name="password"
                                fullWidth
                                label="Senha"
                                required
                                type="password"
                                autoComplete="current-password"
                                value={senha}
                                onChange={e => setSenha(e.target.value)}
                                inputProps={{ maxLength: 15, minLength: 8 }}
                                InputLabelProps={{ className: classes.entrada }}
                                style={{ paddingTop: 5, paddingBottom: 10 }}
                                onKeyDown={handleKeyDown}

                            />

                            <a
                                style={{ fontSize: 18, color: 'black', fontWeight: 'bold', paddingBottom: 20 }}
                                href="/lgpd">
                                Lei Geral de Proteção de Dados</a>

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={logar}


                            >
                                Entrar
                            </Button>
                            <Grid container style={{ paddingTop: 15, paddingBottom: 10 }} >
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Esqueceu sua Senha?
                                    </Link>
                                </Grid>

                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
}
