import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@mui/material/Tooltip';
import CallIcon from "@mui/icons-material/Call"; // Ícone de telefone do Material UI
import { useParams } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Stomp } from '@stomp/stompjs';
import ReactApexChart from "react-apexcharts";

import Close from '@material-ui/icons/Close'
import { Snackbar } from '@material-ui/core';
import { Menu } from '@mui/material';
import Hls from "hls.js";

import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import BarChartIcon from '@mui/icons-material/BarChart';

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Modal from "react-modal";
import CabecalhoInformacoes from '../components/CabecalhoInformacoes/index'
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import './styles.css';


import {
    MaterialReactTable
} from 'material-react-table';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import BuildIcon from '@mui/icons-material/Build';
import Switch from '@mui/material/Switch';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/Power';
import ModuloPGMComponent from '../components/ModuloPGMComponent/index'
import ModuloSonoroComponent from '../components/ModuloSonoroComponent/index'
import CornetaComponent from '../components/CornetaComponent/index'
import CentralComponent from '../components/CentralComponent/index'



import api from '../../../../../services/api';


import NavegadorGestao from "../../../../../components/NavBarGestao";


import switch_off from '../../../../../assets/imgs/switch-off.png';
import switch_on from '../../../../../assets/imgs/switch-on.png';


import mini_icone_carro from '../../../../../assets/imgs/mini_icone_carro.png';

import mini_icone_moto from '../../../../../assets/imgs/mini_icone_moto.png';

import icone_origem from '../../../../../assets/imgs/icone_origem.png';
import icone_destino from '../../../../../assets/imgs/icone_destino.png';
import icone_parada from '../../../../../assets/imgs/icone_parada.png';

import icone_call from '../../../../../assets/imgs/icone_call.png';


import cabo_conectado from '../../../../../assets/imgs/cabo_conectado.png';
import cabo_desconectado from '../../../../../assets/imgs/cabo_desconectado.png';
import cabo_internet from '../../../../../assets/imgs/cabo_internet.png';
import cabo_sem_internet from '../../../../../assets/imgs/cabo_sem_internet.png';

import wifi_conectado from '../../../../../assets/imgs/cabo_conectado.png';

import modem_conectado from '../../../../../assets/imgs/modem_conectado.png';
import modem_desconectado from '../../../../../assets/imgs/modem_desconectado.png';
import modem_internet from '../../../../../assets/imgs/cabo_internet.png';
import modem_sem_internet from '../../../../../assets/imgs/cabo_sem_internet.png';

import icone_pgm from '../../../../../assets/imgs/icone_pgm.png';
import icone_corneta from '../../../../../assets/imgs/corneta.png';

import icone_play from '../../../../../assets/imgs/play.png';


import icone_medidor from '../../../../../assets/imgs/icone_medidor.png';

import icone_subestacao from '../../../../../assets/imgs/icone_subestacao.png';
import icone_casa from '../../../../../assets/imgs/icone_casa.png';
import icone_loja from '../../../../../assets/imgs/icone_loja.png';
import icone_central from '../../../../../assets/imgs/icone_central.png';

import icone_instalacoes from '../../../../../assets/imgs/icone_instalacoes.png';
import icone_equipamentos from '../../../../../assets/imgs/icone_equipamentos.png';

import icone_armar_stay from '../../../../../assets/imgs/armar_stay.png';
import icone_armar_away from '../../../../../assets/imgs/armar_away.png';
import icone_desarmar from '../../../../../assets/imgs/desarmar.png';
import globalConfig from '../../../../../configs/globalConfigs';

import CamerasComponent from '../components/CamerasComponent/index'




import {
    GoogleMap,
    Marker,
    LoadScript,
    OverlayView,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
    DistanceMatrixService,
    MaxZoomService, Circle, Polygon, Polyline
} from "@react-google-maps/api";

import "./MapPage.css";
import MapaDoLocal from './mapaLocal.js';
import alarmeAudio from '../../../../../assets/mp3/alarme.mp3';




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'white',
    },
    toolbar: {

    },
    toolbarIcon: {

    },
    appBar: {

    },
    appBarShift: {

    },
    menuButton: {

    },
    menuButtonHidden: {

    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {

    },
    drawerPaperClose: {

    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        display: 'flex',


    },
    container: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'green',


    },
    paper: {

    },
    fixedHeight: {

    },
}));


const libraries = ["places"];


// Gerenciador de Áudio (Singleton)
const audioManager = {
    audio: new Audio(alarmeAudio),
    isPlaying: false,
    play() {
        if (!this.isPlaying) {
            this.audio.loop = true;
            this.audio.volume = 1.0;
            this.audio
                .play()
                .then(() => {
                    this.isPlaying = true;
                })
                .catch(error => console.error("Erro ao reproduzir o áudio:", error));
        }
    },
    pause() {
        if (this.isPlaying) {
            this.audio.pause();
            this.audio.currentTime = 0;
            this.isPlaying = false;
        }
    }
};

const customStyles = {
    content: {
        top: "0", // Alinha ao topo
        left: "0", // Alinha à esquerda
        right: "auto",
        bottom: "0", // Ocupa toda a altura
        margin: "0", // Remove margens
        transform: "none", // Nenhuma transformação necessária
        width: "30%", // Ocupa 30% da largura disponível
        height: "100%", // Ocupa 100% da altura da tela
        zIndex: 9999, // Sobrepõe todas as telas
        backgroundColor: "white", // Fundo branco para o modal
        borderRadius: "0 8px 8px 0", // Bordas arredondadas apenas no lado direito
        boxShadow: "4px 0 10px rgba(0, 0, 0, 0.2)", // Sombra para dar destaque ao modal
    },
    overlay: {
        zIndex: 9998, // Sobrepõe o fundo
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro translúcido
    },
};

const customStylesMobile = {
    content: {
        top: "0", // Alinha ao topo
        left: "0", // Alinha à esquerda
        right: "auto",
        bottom: "0", // Ocupa toda a altura
        margin: "0", // Remove margens
        transform: "none", // Nenhuma transformação necessária
        width: "100%", // Ocupa 30% da largura disponível
        height: "100%", // Ocupa 100% da altura da tela
        zIndex: 9999, // Sobrepõe todas as telas
        backgroundColor: "white", // Fundo branco para o modal
        borderRadius: "0 8px 8px 0", // Bordas arredondadas apenas no lado direito
        boxShadow: "4px 0 10px rgba(0, 0, 0, 0.2)", // Sombra para dar destaque ao modal
    },
    overlay: {
        zIndex: 9998, // Sobrepõe o fundo
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro translúcido
    },
};


Modal.setAppElement("#root");


export default function MonitoramentoDashboard() {

    const { idInst,pesquisa } = useParams();

    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);
    const instalacaoSelecionadaRef = useRef(instalacaoSelecionada); // Inicialize a ref com o valor inicial do estado


    const handleFiltrar = (filtro) => {
        setSearchTerm(filtro);
    }

    
    useEffect(() => {
        if(idInst){
            const instalacao = { id_instalacao: idInst };
            handleInstalacaoSelecionada(instalacao);  // Passando o objeto para a função
            handleFiltrar(pesquisa);      
        }
    }, []);

    




    const handleInstalacaoSelecionada = useCallback(async (instalacao) => {
        try {
            const token = Cookies.get("token");
            const headers = {
                Authorization: "Bearer " + token,
            };

            const response = await api.get(
                `/v1/protected/cliente/instalacoes/listarporid/${instalacao.id_instalacao}`,
                { headers }
            );
            setInstalacaoSelecionada(response.data);
        } catch (_err) {
            console.log("Erro ao listar informações: " + _err);
        }
    }, []);



    useEffect(() => {
        if (instalacaoSelecionada !== null) {
            const interval = setInterval(() => {
                handleInstalacaoSelecionada(instalacaoSelecionada);
            }, 5000);

            return () => clearInterval(interval); // Limpa o intervalo quando o componente desmonta
        }
    }, [instalacaoSelecionada, handleInstalacaoSelecionada]);


    const audioRef = useRef(new Audio(alarmeAudio)); // Inicializar o ref com o áudio importado

    const [pgmsArmadas, setPgmsArmadas] = useState(0);
    const [pgmsDesarmadas, setPgmsDesarmadas] = useState(0);
    const [pgmsOfflines, setPgmsOfflines] = useState(0);
    const [pgmsAlarmadas, setPgmsAlarmadas] = useState(0);

    const [openMosaico, setOpenMosaico] = useState(false);
    const [cameras, setCameras] = useState([]);
    const [urlStream, setUrlStream] = useState(null);




    const [larguraOriginal, setLarguraOriginal] = useState(0);

    const [limiar, setLimiar] = useState(900);



    function checkDimenssoes() {
        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;

        setLarguraOriginal(largura);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });


    useEffect(() => {



        checkDimenssoes();



    }, []);

    const handleOpenMosaico = (url, equipamentos) => {
        setOpenMosaico(true);
        setCameras(equipamentos);
        setUrlStream(url);
    };

    const closeMosaico = () => {
        setOpenMosaico(false);
        setCameras([]);
        setUrlStream("");
    };

 
    const [openGraficoDisponibilidade, setOpenGraficoDisponibilidade] = useState(false);
    const [dadosDisponibilidade, setDadosDisponibilidade] = useState(null);

    const [dadosGrafico, setDadosGrafico] = useState({
        series: [],
        options: {
            chart: {
                height: "100%",
                type: "line",
                width: "100%",
            },
            colors: ["#0288d1", "#FF5733", "#33FF57", "#5733FF", "#FFC300"], // Definição de cores diferentes para cada equipamento
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 3,
                curve: "smooth",
            },
            xaxis: {
                categories: [], // Será preenchido dinamicamente com os dias do mês
                title: {
                    text: "Dias do Mês",
                },
            },
            yaxis: {
                title: {
                    text: "Registro de Disponibilidade",
                },
            },
            markers: {
                size: 5,
                strokeColors: "#fff",
                strokeWidth: 2,
            },
            fill: {
                opacity: 1,
            },
        },
    });

    const handleOpenGraficoDisponibilidade = () => {
        listarDadosDisponibilidade();
    };

    const closeGraficoDisponibilidade = () => {
        setOpenGraficoDisponibilidade(false);
        setDadosDisponibilidade(null);
     };

    
     async function listarDadosDisponibilidade() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const dataAtual = new Date();
            const mes = dataAtual.getMonth() + 1; // getMonth() retorna de 0 a 11, então somamos 1
            const ano = dataAtual.getFullYear();

            await api.get("/v1/protected/registrodisponibilidade/" + instalacaoSelecionada.id_instalacao + "/" + mes + "/" + ano, {
                headers: headers
            }).then(function (response) {
                


                atualizarGrafico(response.data);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            alert("Erro ao listar dados de disponibilidade!");
            console.log("Erro ao listar informações: " + _err)

        }

    }

   
    
    function atualizarGrafico(dados) {
        if (!dados || dados.length === 0) return;
    
        // Pegar todos os dias distintos presentes nos dados
        const dias = [...new Set(dados.map(d => d.dia))].sort((a, b) => a - b);
    
        // Agrupar dados por equipamento
        const equipamentos = {};
    
        dados.forEach((item) => {
            const id = item.id_equipamento;
    
            if (!equipamentos[id]) {
                equipamentos[id] = {
                    name: item.nome_mapa,
                    data: new Array(dias.length).fill(0), // Inicializa com zeros, não null
                };
            }
    
            // Encontrar o índice correto do dia no array ordenado
            const indexDia = dias.indexOf(item.dia);
            if (indexDia !== -1) {
                equipamentos[id].data[indexDia] = item.total_registros;
            }
        });
    

        
        setDadosGrafico({
            series: Object.values(equipamentos),
            options: {
                ...dadosGrafico.options,
                xaxis: {
                    ...dadosGrafico.options.xaxis,
                    categories: dias, // Define os dias no eixo X
                },
            },
        });
    
        setOpenGraficoDisponibilidade(true);
    }





    const tempoInativo = 1;

    const [mapPosition, setMapPosition] = useState(null);
    const mapPositionRef = useRef(null);

    // Atualize a referência sempre que o estado mudar
    useEffect(() => {
        instalacaoSelecionadaRef.current = instalacaoSelecionada;
        if (instalacaoSelecionada) {
            const newMapPosition = {
                lat: parseFloat(instalacaoSelecionada.latitude),
                lng: parseFloat(instalacaoSelecionada.longitude),
            };

            // Verifica se o valor atual é diferente do armazenado na ref
            if (
                !mapPositionRef.current ||
                mapPositionRef.current.lat !== newMapPosition.lat ||
                mapPositionRef.current.lng !== newMapPosition.lng
            ) {
                setMapPosition(newMapPosition);
                mapPositionRef.current = newMapPosition; // Atualiza a ref com a nova posição
            }
        }
    }, [instalacaoSelecionada]);







    const [showTable, setShowTable] = useState(true);

    const [definingPerimeter, setDefiningPerimeter] = useState(false); // Indica se está definindo perímetros
    const [perimeterPoints, setPerimeterPoints] = useState([]); // Armazena os pontos do perímetro
    const [currentPerimeter, setCurrentPerimeter] = useState(1); // Controla qual perímetro está sendo definido (P1 a P4)





    const [endereco, setEndereco] = useState();

    const [instalacoes, setInstalacoes] = useState([]);



    const [configuracoes, setConfiguracoes] = useState();
    const [loading, setLoading] = useState();
    const [loadingInstalacoes, setLoadingInstalacoes] = useState();

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/configuracoes", {
                headers: headers
            }).then(function (response) {
                setConfiguracoes(response.data);



                const logradouro = response.data.unidade_base.logradouro;
                const numero = response.data.unidade_base.numero;
                const bairro = response.data.unidade_base.bairro;
                const complemento = response.data.unidade_base.complemento;
                const cidade = response.data.unidade_base.cidade;
                const estado = response.data.unidade_base.estado;
                const cep = response.data.unidade_base.cep;

                const end = `${logradouro}, ${numero}, ${bairro}, ${cidade}, ${estado} - ${cep}`;
                setEndereco(end);
                console.log("Endereço: " + end);
                setLoading(false);


            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }






    useEffect(() => {


        listarInformacoes();


    }, []);








    function processarIcone(tipo) {
        if (tipo === 0) {
            return icone_pgm;
        } else if (tipo === 10) {
            return icone_medidor;
        } else if (tipo === 70) {
            return icone_central;
        } else if (tipo === 30) {
            return icone_pgm;
        } else if (tipo === 120) {
            return icone_corneta;
        }
    }



    function processarIconeInstalacao(tipo) {
        if (tipo === 0) {
            return icone_casa;
        } else if (tipo === 1) {
            return icone_loja;
        } else if (tipo == 2) {
            return icone_subestacao;
        }
    }






    const LocalComponent = ({ id_instalacao, lat, lng, onClick, tipo_icone, nome_mapa, status, instalacao }) => {
        const [showMenu, setShowMenu] = useState(false);



        const handleClickGerenciar = () => {
            const url = `/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/${id_instalacao}/0`;
            window.open(url, '_blank');
            setShowMenu(!showMenu);
        };





        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ padding: 10 }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    align="flex-start"
                    item xs={12}
                    style={{}}
                >
                    <img
                        alt={"icone"}
                        style={{
                            textAlign: 'center',
                            width: '40px',
                            height: '40px'
                        }}
                        src={processarIconeInstalacao(tipo_icone)}

                    />

                    <spam style={{ textDecoration: "underline" }} > {nome_mapa + " - " + instalacao.identificador_integrador}</spam>
                    <IconButton color="primary" onClick={handleClickGerenciar}>
                        <SettingsIcon />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };









    const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());


    const [isConnected, setIsConnected] = useState(false);

    const [numEquipamentos, setNumEquipamentos] = useState(0);

    useEffect(() => {
        // Obter o token do cookie
        const token = Cookies.get('token');


        // Criar a conexão WebSocket
        const socket = new WebSocket(`wss://gruporosinetos.com/api/monitoramentowebsocket?token=${token}`);

        // Passando o WebSocket para o Stomp
        const client = Stomp.over(socket);

        if (!isConnected) {
            // Definir o comportamento para quando a conexão for aberta
            client.connect({}, () => {
                setIsConnected(true);
                console.log('Conectado ao WebSocket');

                // Inscrever no tópico /topic/dados
                client.subscribe('/topic/dados', (message) => {
                    // Aqui você pode tratar a mensagem recebida
                    //console.log('Dados recebidos:', message.body);
                    console.log("atualizou");
                    let num_equipamentos = 0;
                    try {
                        const dados = JSON.parse(message.body); // Parse do JSON recebido

                        var num_equipamentos_armados = 0;
                        var num_equipamentos_alarmados = 0;
                        var num_equipamentos_desarmados = 0;
                        var num_equipamentos_offlines = 0;
                        const now = new Date();

                        // Percorre cada instalação
                        for (let i = 0; i < dados.length; i++) {
                            const novaInstalacao = dados[i];

                            if (novaInstalacao.equipamentos !== null) {


                                for (let p = 0; p < novaInstalacao.equipamentos.length; p++) {

                                    const novoEquipamento = novaInstalacao.equipamentos[p];
                                    // Verifica se o ID do equipamento ou status do alarme mudaram
                                    if (novoEquipamento.tipo_equipamento_area_tecnica === 0
                                    ) {

                                        const lastResponse = new Date(novoEquipamento.modulo_pgm.data_hora_ultima_resposta);
                                        const differenceInMinutes = (now - lastResponse) / 1000 / 60; // Convertendo de milissegundos para minutos

                                        const alarme = novoEquipamento.alarme;
                                        const armada = novoEquipamento.modulo_pgm.armada;

                                        if (alarme) {
                                            //statusText = "ALARME";
                                            num_equipamentos_alarmados++;
                                        } else if (differenceInMinutes < tempoInativo) {
                                            if (armada) {
                                                // statusText = "ONLINE - ARMADA";
                                                num_equipamentos_armados++;
                                            }
                                            else {
                                                //  statusText = "ONLINE - DESARMADA";
                                                num_equipamentos_desarmados++;
                                            }
                                        } else {
                                            // statusText = "OFFLINE";
                                            num_equipamentos_offlines++;
                                        }



                                    }

                                }

                                //fazer ossets
                                setPgmsArmadas(num_equipamentos_armados);
                                setPgmsDesarmadas(num_equipamentos_desarmados);
                                setPgmsOfflines(num_equipamentos_offlines);
                                setPgmsAlarmadas(num_equipamentos_alarmados);
                            }
                        }


                        const currentTime = Date.now();


                        // Função para verificar alterações profundas nas instalações e equipamentos
                        const dadosSaoDiferentes = (novosDados, dadosAtuais) => {




                            if (novosDados.length !== dadosAtuais.length) return true;

                            // Percorre cada instalação
                            for (let i = 0; i < novosDados.length; i++) {
                                const novaInstalacao = novosDados[i];
                                const instalacaoAtual = dadosAtuais[i];




                                if (instalacaoSelecionadaRef.current) {
                                    if (novaInstalacao.id_instalacao === instalacaoSelecionadaRef.current.id_instalacao) {
                                        //alert("Atualiuzar instalacao selecionada");
                                        setInstalacaoSelecionada({
                                            ...instalacaoSelecionadaRef.current,
                                            equipamentos: novaInstalacao.equipamentos // Substitui o array de equipamentos
                                        });
                                    }
                                }

                                // Verifica se o ID ou outra propriedade relevante da instalação mudou
                                if (novaInstalacao.id_instalacao !== instalacaoAtual.id_instalacao ||
                                    novaInstalacao.nome_mapa !== instalacaoAtual.nome_mapa) {

                                    if (instalacaoSelecionadaRef.current) {
                                        if (novaInstalacao.id_instalacao === instalacaoSelecionadaRef.current.id_instalacao) {
                                            //alert("Atualiuzar instalacao selecionada");
                                            setInstalacaoSelecionada({
                                                ...instalacaoSelecionadaRef.current,
                                                equipamentos: novaInstalacao.equipamentos // Substitui o array de equipamentos
                                            });
                                        }
                                    }


                                    return true;
                                }

                                // Verifica se o número de equipamentos mudou
                                if (novaInstalacao.equipamentos.length !== instalacaoAtual.equipamentos.length) return true;
                                num_equipamentos += novaInstalacao.equipamentos.length;
                                // Percorre cada equipamento da instalação

                                if (novaInstalacao.equipamentos !== null) {

                                    for (let j = 0; j < novaInstalacao.equipamentos.length; j++) {

                                        const novoEquipamento = novaInstalacao.equipamentos[j];
                                        const equipamentoAtual = instalacaoAtual.equipamentos[j];


                                        if (novoEquipamento.id_equipamento !== equipamentoAtual.id_equipamento ||
                                            novoEquipamento.alarme !== equipamentoAtual.alarme) {

                                            if (instalacaoSelecionadaRef.current) {
                                                if (novaInstalacao.id_instalacao === instalacaoSelecionadaRef.current.id_instalacao) {
                                                    //alert("Atualiuzar instalacao selecionada");
                                                    setInstalacaoSelecionada({
                                                        ...instalacaoSelecionadaRef.current,
                                                        equipamentos: novaInstalacao.equipamentos // Substitui o array de equipamentos
                                                    });
                                                }
                                            }

                                            return true;
                                        }

                                        // Se o equipamento é do tipo modulo_pgm, verifica os status dos relés
                                        if (novoEquipamento.tipo_equipamento_area_tecnica === 0) {
                                            // Verifica status_rele1 até status_rele4
                                            for (let k = 1; k <= 4; k++) {
                                                const releKey = `status_rele${k}`;
                                                if (novoEquipamento.modulo_pgm[releKey] !== equipamentoAtual.modulo_pgm[releKey]) {

                                                    if (instalacaoSelecionadaRef.current) {
                                                        if (novaInstalacao.id_instalacao === instalacaoSelecionadaRef.current.id_instalacao) {
                                                            //alert("Atualiuzar instalacao selecionada");
                                                            setInstalacaoSelecionada({
                                                                ...instalacaoSelecionadaRef.current,
                                                                equipamentos: novaInstalacao.equipamentos // Substitui o array de equipamentos
                                                            });
                                                        }
                                                    }

                                                    return true;
                                                }
                                            }
                                        } else if (novoEquipamento.tipo_equipamento_area_tecnica === 30) {
                                            // Verifica status_rele1 até status_rele4
                                            for (let k = 1; k <= 5; k++) {
                                                const releKey = `status_rele${k}`;
                                                if (novoEquipamento.modulo_sonoro[releKey] !== equipamentoAtual.modulo_sonoro[releKey]) {

                                                    if (instalacaoSelecionadaRef.current) {
                                                        if (novaInstalacao.id_instalacao === instalacaoSelecionadaRef.current.id_instalacao) {
                                                            //alert("Atualiuzar instalacao selecionada");
                                                            setInstalacaoSelecionada({
                                                                ...instalacaoSelecionadaRef.current,
                                                                equipamentos: novaInstalacao.equipamentos // Substitui o array de equipamentos
                                                            });
                                                        }
                                                    }

                                                    return true;
                                                }
                                            }
                                        }
                                    }



                                }
                            }
                            setNumEquipamentos(num_equipamentos);
                            // Se nenhuma diferença foi encontrada
                            return false;
                        };

                        setInstalacoes((instalacoesAtuais) => {
                            const shouldUpdate =
                                dadosSaoDiferentes(dados, instalacoesAtuais) ||
                                (currentTime - lastUpdateTime > 30000); // Verifica se passaram 30 segundos

                            if (shouldUpdate) {
                                setLastUpdateTime(currentTime); // Atualiza o tempo da última atualização
                                return dados; // Atualiza o estado com os novos dados
                            }
                            return instalacoesAtuais; // Mantém o estado inalterado se não houver mudanças
                        });

                    } catch (error) {
                        console.error('Erro ao fazer parse dos dados:', error);
                    }
                    setLoadingInstalacoes(false);


                });

                // Configurar envio a cada 5 segundos
                const intervalId = setInterval(() => {
                    if (client.connected) {
                        client.send("/app/requestData", {}, JSON.stringify({}));
                    }
                }, 2000); // 5000ms = 5 segundos


            }, (error) => {
                console.error('Erro ao conectar ao WebSocket:', error);
            });
        }

        // Limpeza da conexão quando o componente for desmontado
        return () => {
            if (client.connected) {
                client.disconnect(() => {
                    console.log('Desconectado do WebSocket');
                });
            }
        };
    }, []); // O array vazio garante que isso será executado uma única vez


    const [searchTerm, setSearchTerm] = useState("");


    function returnDescricaoEndereco(objeto) {

        return objeto.logradouro + ", " + objeto.numero + ", bairro: " + objeto.bairro + " Complemento: " + objeto.complemento +
            "\n" + objeto.cidade + " UF: " + objeto.estado + " CEP: " + objeto.cep

    }



    const filteredInstalacoes = instalacoes
        ? instalacoes.filter((instalacao) => {
            const matchesNomeMapa =
                instalacao.nome_mapa &&
                instalacao.nome_mapa.toLowerCase().includes(searchTerm.toLowerCase());

            const matchesIdentificador =
                searchTerm &&
                instalacao.identificador_integrador &&
                String(instalacao.identificador_integrador).trim().includes(String(searchTerm).trim());


            const matchesEquipamentos = instalacao.equipamentos?.some(


                (equipamento) =>
                    equipamento &&
                    equipamento.tipo_equipamento_area_tecnica === 0 &&
                    equipamento.modulo_pgm &&
                    equipamento.modulo_pgm.id_modulo_pgm > 0 &&
                    equipamento.modulo_pgm.codigo.toLowerCase().includes(searchTerm.toLowerCase())
            );



            const matchesOnline = instalacao.equipamentos?.some((equipamento) => {
                if (
                    equipamento &&
                    equipamento.tipo_equipamento_area_tecnica === 0 &&
                    equipamento.modulo_pgm &&
                    equipamento.modulo_pgm.id_modulo_pgm > 0
                ) {
                    const now = new Date();
                    const lastResponse = new Date(equipamento.modulo_pgm.data_hora_ultima_resposta);
                    const differenceInMinutes = (now - lastResponse) / 1000 / 60; // Convertendo de milissegundos para minutos

                    const alarme = equipamento.alarme;
                    const armada = equipamento.modulo_pgm.armada;

                    let statusConexao;

                    if (alarme) {
                        statusConexao = "ONLINE";
                    } else if (differenceInMinutes < 1) {
                        if (armada) {
                            statusConexao = "ONLINE";
                        }
                        else {
                            statusConexao = "ONLINE";
                        }
                    } else {
                        statusConexao = "OFFLINE";
                    }


                    return statusConexao.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            });


            const matchesStatus = instalacao.equipamentos?.some((equipamento) => {
                if (
                    equipamento &&
                    equipamento.tipo_equipamento_area_tecnica === 0 &&
                    equipamento.modulo_pgm &&
                    equipamento.modulo_pgm.id_modulo_pgm > 0
                ) {
                    const status = equipamento.modulo_pgm.armada ? "protegido" : "inseguro";
                    return status.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            });

            const matchesAlarme = instalacao.equipamentos?.some((equipamento) => {
                if (
                    equipamento &&
                    equipamento.tipo_equipamento_area_tecnica === 0 &&
                    equipamento.modulo_pgm &&
                    equipamento.modulo_pgm.id_modulo_pgm > 0
                ) {
                    const alarme = equipamento.alarme ? "alarme" : "nodispar";
                    return alarme.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return false;
            });



            // Exibe a instalação se algum critério for atendido 
            return matchesIdentificador || matchesNomeMapa || matchesEquipamentos || matchesStatus || matchesAlarme || matchesOnline;
        })
        : [];


    const [openLogTable, setOpenLogTable] = useState(false);




    const options = {
        mapTypeId: 'satellite',
        streetViewControl: false, // Desativa o Street View
        fullscreenControl: false, // Desativa o controle de tela cheia
        mapTypeControl: false, // Desativa a opção de alterar o tipo de mapa
        zoomControl: true, // Ativa somente o controle de zoom
        maxZoom: 21,
        styles: [
            {
                featureType: "poi", // Oculta pontos de interesse
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "road", // Simplicidade nas rodovias
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "transit", // Remove transporte público
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "administrative.land_parcel", // Remove labels de terrenos
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "administrative.neighborhood", // Remove labels de bairros
                stylers: [{ visibility: "off" }],
            },
        ],
    };



    const containerStyle = {
        width: '100%',
        height: '100%'
    };



    const [map, setMap] = useState();
    const mapRef = useRef(null);

    const [lat, setLat] = useState();
    const [lng, setLng] = useState();

    const [zoom, setZoom] = useState(15);


    /*
        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item xs={12}
                            style={{ padding: 10 }}
                            spacing={1}
                        >
    
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                item
                                xs={12}
                                item xs={12} sm={6} md={6} lg={3} xl={3}
                                justifyContent={"center"}
                                style={{ backgroundColor: '#720309', padding: 5, margin: 5 }}
                            >
                                <img
                                    alt={"icone"}
                                    style={{
                                        textAlign: 'center',
                                        width: '70px', // Define a largura da imagem
                                        height: '70px' // Define a altura da imagem
                                    }}
                                    src={icone_instalacoes}
                                />
    
                                 <div style={{ textAlign: "center" }}>
                                     <span
                                        style={{
                                            fontSize: 36,
                                            color: 'white',
                                            fontWeight: 'bold',
                                            display: "block", // Para alinhar em bloco
                                            marginBottom: "1px" // Controla a distância vertical
                                        }}
                                    >
                                        {instalacoes.length}
                                    </span>
    
                                     <span
                                        style={{
                                            fontSize: 20,
                                            color: 'white',
                                            fontWeight: 'bold',
                                            paddingLeft: "10px" // Recuo horizontal para direita
                                        }}
                                    >
                                        instalações
                                    </span>
                                </div>
                            </Grid>
    
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                item
                                item xs={12} sm={6} md={6} lg={3} xl={3}
                                justifyContent={"center"}
                                style={{ backgroundColor: '#ffa424', padding: 5, margin: 5 }}
                            >
                                <img
                                    alt={"icone"}
                                    style={{
                                        textAlign: 'center',
                                        width: '70px', // Define a largura da imagem
                                        height: '70px' // Define a altura da imagem
                                    }}
                                    src={icone_equipamentos}
                                />
    
                              
                                <div style={{ textAlign: "center" }}>
                                     <span
                                        style={{
                                            fontSize: 36,
                                            color: 'white',
                                            fontWeight: 'bold',
                                            display: "block", // Para alinhar em bloco
                                            marginBottom: "1px" // Controla a distância vertical
                                        }}
                                    >
                                        {numEquipamentos}
                                    </span>
    
                                     <span
                                        style={{
                                            fontSize: 20,
                                            color: 'white',
                                            fontWeight: 'bold',
                                            paddingLeft: "10px" // Recuo horizontal para direita
                                        }}
                                    >
                                        equipamentos
                                    </span>
                                </div>
                            </Grid>
    
    
                        </Grid>
    */


    return (
        <div  >
            <NavegadorGestao
                AreaTecnica={true}
                Monitoramento={true}
                MonitoramentoDashboard={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            {loading ?
                <Skeleton animation={"wave"} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    item xs={12}
                >

                    <CabecalhoInformacoes
                        pgmsArmadas={pgmsArmadas}
                        pgmsDesarmadas={pgmsDesarmadas}
                        pgmsOfflines={pgmsOfflines}
                        pgmsAlarmadas={pgmsAlarmadas}
                        filtrar={handleFiltrar}

                    />

                    {/* Campo de pesquisa de instalacao*/}
                    <input
                        type="text"
                        placeholder="Pesquisar..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: "10px", padding: "5px", width: "100%" }}
                    />

                    <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                        item xs={12}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12} sm={6} md={6} lg={4} xl={4}
                            style={{}}
                        >


                            {!loadingInstalacoes && instalacoes && filteredInstalacoes.map((instalacao) => {

                                return (
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ border: "1px solid black", padding: 10, margin: 5 }}
                                        onClick={() => setInstalacaoSelecionada(instalacao)} // Atualiza o estado ao clicar

                                    >
                                        <LocalComponent
                                            key={instalacao.id_instalacao}
                                            id_instalacao={instalacao.id_instalacao}
                                            tipo_icone={instalacao.icone_mapa}
                                            nome_mapa={instalacao.nome_mapa}
                                            instalacao={instalacao}
                                        />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item
                                            xs={12}
                                            style={{
                                                border: "1px solid black",
                                                padding: 10,
                                                margin: 5,
                                                position: "relative",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "-10px",
                                                    left: "10px",
                                                    backgroundColor: "white",
                                                    padding: "0 5px",
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                Equipamentos
                                            </div>
                                            {instalacao.equipamentos.map((equipamento) => {

                                                return (
                                                    equipamento && equipamento.tipo_equipamento_area_tecnica === 0 && equipamento.modulo_pgm.id_modulo_pgm > 0 ? (

                                                        <ModuloPGMComponent
                                                            key={equipamento.id_equipamento}
                                                            tipo={equipamento.tipo_equipamento_area_tecnica}
                                                            equipamento={equipamento}
                                                            id_instalacao={instalacao.id_instalacao}
                                                            flag={0}
                                                            instalacao={instalacao}
                                                        />
                                                    ) :
                                                        equipamento && equipamento.tipo_equipamento_area_tecnica === 70 ?
                                                            (
                                                                <CentralComponent
                                                                    key={equipamento.id_equipamento}
                                                                    tipo={equipamento.tipo_equipamento_area_tecnica}
                                                                    equipamento={equipamento}
                                                                    id_instalacao={instalacao.id_instalacao}
                                                                    flag={0}
                                                                    id_sigma={instalacao.identificador_integrador}
                                                                />
                                                            )
                                                            :
                                                            equipamento && equipamento.tipo_equipamento_area_tecnica === 30 ?
                                                                (
                                                                    <ModuloSonoroComponent
                                                                        key={equipamento.id_equipamento}
                                                                        tipo={equipamento.tipo_equipamento_area_tecnica}
                                                                        equipamento={equipamento}
                                                                        id_instalacao={instalacao.id_instalacao}
                                                                        flag={0}
                                                                        id_sigma={instalacao.identificador_integrador}

                                                                    />


                                                                ) : null


                                                );
                                            })}

                                        </Grid>
                                    </Grid>
                                );
                            })}



                        </Grid>


                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item xs={7}
                            style={{
                                border: "1px solid black",
                                padding: 10,
                                margin: 5,
                                position: "relative",
                            }}
                        >

                        </Grid>

                    </Grid>
                </Grid>
            }








            {
                instalacaoSelecionada && (
                    <div className={larguraOriginal < limiar ? "fullPageMobileContainer" : "fullPageContainer"}>
                        <Grid
                            container
                            justifyContent="center"
                            align="center"
                            item xs={12}


                        >

                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                item xs={1}
                            >
                                <button
                                    onClick={() => setInstalacaoSelecionada(null)} // Função de fechar
                                    style={{
                                        backgroundColor: '#FF4500', // Cor laranja-avermelhada estilosa (TOP!)
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '8px',
                                        padding: '5px 5px',
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra leve
                                        textTransform: 'uppercase',
                                    }}


                                >
                                    X
                                </button>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={11}
                                style={{}}
                            >

                                <Typography style={{ fontSize: 16 }} variant="h6" color="inherit" noWrap  >
                                    {instalacaoSelecionada.tipo_instalacao === 0 ? (instalacaoSelecionada.nome + " " + instalacaoSelecionada.sobrenome) : instalacaoSelecionada.nome_fantasia} -
                                    {instalacaoSelecionada.tipo_instalacao === 0 ? instalacaoSelecionada.cpf : instalacaoSelecionada.cnpj} -
                                    {instalacaoSelecionada.identificador_integrador}
                                </Typography>

                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}

                            >
                                <Grid
                                    container
                                    item xs={6}
                                    style={{}}
                                    direction="column"
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={10}
                                    >
                                        <span style={{ paddingTop: 10, paddingBottom: 1, fontSize: 16 }}>{returnDescricaoEndereco(instalacaoSelecionada)}</span>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        align="flex-end"
                                        item xs={2}
                                    >
                                        <button
                                       onClick={() => handleOpenGraficoDisponibilidade()} // Função de fechar
                                            style={{
                                                backgroundColor: 'blue',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '8px',
                                                padding: '4px 10px',
                                                fontSize: '12px',
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                                textTransform: 'uppercase',
                                                display: 'flex',  // Garante que o ícone e o texto fiquem lado a lado
                                                alignItems: 'center',  // Alinha verticalmente
                                                gap: '5px'  // Espaço entre o ícone e o texto
                                            }}
                                        >
                                            <BarChartIcon style={{ fontSize: '32px' }} />
                                            Disponibilidade
                                        </button>

                                    </Grid>
                                </Grid>


                                <Grid
                                    container
                                    justifyContent="center"
                                    align="center"
                                    item xs={6}
                                    style={{ minHeight: '300px', border: '1px solid black' }}


                                > {
                                        instalacaoSelecionada && (
                                            <MapaDoLocal instalacao={instalacaoSelecionada} />
                                        )
                                    }
                                </Grid>
                            </Grid>


                       

                            {instalacaoSelecionada.equipamentos
                                .sort((a, b) => a.id_equipamento - b.id_equipamento)
                                .map((equipamento) => {

                                    return (
                                        equipamento && equipamento.tipo_equipamento_area_tecnica === 0 && equipamento.modulo_pgm.id_modulo_pgm > 0 ? (
                                            <ModuloPGMComponent
                                                key={equipamento.id_equipamento}
                                                tipo={equipamento.tipo_equipamento_area_tecnica}
                                                equipamento={equipamento}
                                                id_instalacao={instalacaoSelecionada.id_instalacao}
                                                flag={1}
                                            />
                                        ) :
                                            equipamento && equipamento.tipo_equipamento_area_tecnica === 70 ?
                                                (
                                                    <CentralComponent
                                                        key={equipamento.id_equipamento}
                                                        tipo={equipamento.tipo_equipamento_area_tecnica}
                                                        equipamento={equipamento}
                                                        id_instalacao={instalacaoSelecionada.id_instalacao}
                                                        id_sigma={instalacaoSelecionada.identificador_integrador}

                                                        flag={1}
                                                    />
                                                )
                                                :
                                                equipamento && equipamento.tipo_equipamento_area_tecnica === 30 ?
                                                    (
                                                        <ModuloSonoroComponent
                                                            key={equipamento.id_equipamento}
                                                            tipo={equipamento.tipo_equipamento_area_tecnica}
                                                            equipamento={equipamento}
                                                            id_instalacao={instalacaoSelecionada.id_instalacao}
                                                            id_sigma={instalacaoSelecionada.identificador_integrador}
                                                            flag={1}
                                                        />
                                                    ) :
                                                    equipamento && equipamento.tipo_equipamento_area_tecnica === 120 ?
                                                        (
                                                            <CornetaComponent
                                                                key={equipamento.id_equipamento}
                                                                tipo={equipamento.tipo_equipamento_area_tecnica}
                                                                equipamento={equipamento}
                                                                id_instalacao={instalacaoSelecionada.id_instalacao}
                                                                flag={1}
                                                                id_sigma={instalacaoSelecionada.identificador_integrador}

                                                            />
                                                        ) : null


                                    );
                                })}
{/*
{Array.isArray(instalacaoSelecionada?.equipamentos) && (
    <CamerasComponent
        flag={1}
        cameras={instalacaoSelecionada.equipamentos
            .filter(equipamento => equipamento && equipamento.ip && equipamento.tipo_equipamento_area_tecnica === 80)}
    />
)}
    */}
                            <button
                                onClick={() => handleOpenMosaico(instalacaoSelecionada.url_video_stream, instalacaoSelecionada.equipamentos)} // Função de fechar
                                style={{
                                    backgroundColor: 'green', // Cor laranja-avermelhada estilosa (TOP!)
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '8px',
                                    padding: '2px 8px',
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra leve
                                    textTransform: 'uppercase',
                                }}
                            >
                                Mosaico
                            </button>


                        </Grid>
                    </div>

                )
            }







            {/* Modal */}
            {openMosaico && (
                <div
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro com opacidade
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1100",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            width: "95%",
                            height: "95%",
                            borderRadius: "8px",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Sombra para destaque
                            display: "flex",
                            flexDirection: "column",
                            padding: "10px",
                            position: "relative",
                        }}
                    >
                        {/* Botão de fechar */}
                        <button
                            onClick={(e) => closeMosaico()}
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                backgroundColor: "#FF4500",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                padding: "8px",
                                cursor: "pointer",
                                fontWeight: "bold",
                            }}
                        >
                            Fechar
                        </button>

                        

                        {/* Conteúdo do Modal */}
                        <h6 style={{ marginTop: 0 }}>Mosaico</h6>

                        {/* Espaço reservado para a tabela */}
                        <div
                            style={{
                                flex: 1,
                                overflowY: "auto", // Adiciona rolagem se a tabela for grande
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                padding: "10px",
                            }}
                        >

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ padding: 1 }}
                            >
                                {cameras.map((equipamento, index) => {
                                    if (equipamento.tipo_equipamento_area_tecnica === 80) {
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                key={index}
                                                style={{
                                                    border: "1px solid red",
                                                    padding: "1px",
                                                    margin: "1px",
                                                    borderRadius: "8px",
                                                    height: "300px", // Define uma altura fixa (ou ajuste conforme necessário)
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    overflow: "hidden" // Evita margens extras
                                                }}
                                            >

                                                <iframe
                                                    src={`${urlStream}/${equipamento.rota_camera}`}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        border: "none",
                                                        objectFit: "cover"
                                                    }}
                                                    allow="autoplay"
                                                ></iframe>

                                            </Grid>
                                        );
                                    }
                                    return null;
                                })}
                            </Grid>

                        </div>
                    </div>
                </div>
            )}



             {/* Modal */}
             {openGraficoDisponibilidade && (
                <div
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro com opacidade
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1100",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            width: "95%",
                            height: "95%",
                            borderRadius: "8px",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Sombra para destaque
                            display: "flex",
                            flexDirection: "column",
                            padding: "10px",
                            position: "relative",
                        }}
                    >
                        {/* Botão de fechar */}
                        <button
                            onClick={(e) => closeGraficoDisponibilidade()}
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                backgroundColor: "#FF4500",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                padding: "8px",
                                cursor: "pointer",
                                fontWeight: "bold",
                            }}
                        >
                            Fechar
                        </button>

                        {/* Conteúdo do Modal */}
                        <h6 style={{ marginTop: 0 }}>Disponibilidade</h6>

                        {/* Espaço reservado para a tabela */}
                        <div
                            style={{
                                flex: 1,
                                overflowY: "auto", // Adiciona rolagem se a tabela for grande
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                padding: "10px",
                            }}
                        >

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ padding: 1 }}
                            >
                                  <Grid item xs={12} container
                                style={{ width: '100%', margin: 1 }}
                                direction={'row'} >

                                <Grid item xs={10}
                                    style={{
                                        padding: '5px',

                                        borderRadius: '8px',
                                        textAlign: 'center',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        marginTop: '5px',
                                        marginRight: '12px',
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <div style={{ width: '100%', height: '100%' }}>
                                        <ReactApexChart
                                            options={dadosGrafico.options}
                                            series={dadosGrafico.series}
                                            type="bar"
                                        />
                                    </div>
                                </Grid>
                                
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </div>
            )}



        </div >
    );
}





