import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@mui/material/Tooltip';
import CallIcon from "@mui/icons-material/Call"; // Ícone de telefone do Material UI
import { Card, CardContent } from "@mui/material";

import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccountTreeIcon from "@mui/icons-material/AccountTree"; // Ícone para Partições
import SensorsIcon from "@mui/icons-material/Sensors"; // Ícone para Zonas

import { Stomp } from '@stomp/stompjs';

import Close from '@material-ui/icons/Close'
import { Snackbar } from '@material-ui/core';
import { Menu } from '@mui/material';
import Hls from "hls.js";

import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Modal from "react-modal";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';



import {
    MaterialReactTable
} from 'material-react-table';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import BuildIcon from '@mui/icons-material/Build';
import Switch from '@mui/material/Switch';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/Power';



import api from '../../../../../../services/api';


import NavegadorGestao from "../../../../../../components/NavBarGestao";


import switch_off from '../../../../../../assets/imgs/switch-off.png';
import switch_on from '../../../../../../assets/imgs/switch-on.png';


import mini_icone_carro from '../../../../../../assets/imgs/mini_icone_carro.png';

import mini_icone_moto from '../../../../../../assets/imgs/mini_icone_moto.png';

import icone_origem from '../../../../../../assets/imgs/icone_origem.png';
import icone_destino from '../../../../../../assets/imgs/icone_destino.png';
import icone_parada from '../../../../../../assets/imgs/icone_parada.png';

import icone_call from '../../../../../../assets/imgs/icone_call.png';


import sem_tensao from '../../../../../../assets/imgs/sem_tensao.png';
import tensao_ac from '../../../../../../assets/imgs/tensao_ac.png';


import cabo_conectado from '../../../../../../assets/imgs/cabo_conectado.png';
import cabo_desconectado from '../../../../../../assets/imgs/cabo_desconectado.png';
import cabo_internet from '../../../../../../assets/imgs/cabo_internet.png';
import cabo_sem_internet from '../../../../../../assets/imgs/cabo_sem_internet.png';

import wifi_conectado from '../../../../../../assets/imgs/wifi_conectado.png';
import wifi_desconectado from '../../../../../../assets/imgs/wifi_desconectado.png';
import wifi_internet from '../../../../../../assets/imgs/cabo_internet.png';
import wifi_sem_internet from '../../../../../../assets/imgs/cabo_sem_internet.png';

import modem_conectado from '../../../../../../assets/imgs/modem_conectado.png';
import modem_desconectado from '../../../../../../assets/imgs/modem_desconectado.png';
import modem_internet from '../../../../../../assets/imgs/cabo_internet.png';
import modem_sem_internet from '../../../../../../assets/imgs/cabo_sem_internet.png';

import icone_pgm from '../../../../../../assets/imgs/icone_pgm.png';
import icone_corneta from '../../../../../../assets/imgs/corneta.png';

import icone_play from '../../../../../../assets/imgs/play.png';


import icone_medidor from '../../../../../../assets/imgs/icone_medidor.png';

import icone_subestacao from '../../../../../../assets/imgs/icone_subestacao.png';
import icone_casa from '../../../../../../assets/imgs/icone_casa.png';
import icone_loja from '../../../../../../assets/imgs/icone_loja.png';
import icone_central from '../../../../../../assets/imgs/icone_central.png';

import icone_instalacoes from '../../../../../../assets/imgs/icone_instalacoes.png';
import icone_equipamentos from '../../../../../../assets/imgs/icone_equipamentos.png';

import icone_armar_stay from '../../../../../../assets/imgs/armar_stay.png';
import icone_armar_away from '../../../../../../assets/imgs/armar_away.png';
import icone_desarmar from '../../../../../../assets/imgs/desarmar.png';
import globalConfig from '../../../../../../configs/globalConfigs';


import ConfiguracoesReproducao from '../../../../../../pages/painelAdmin/AreaGestao/ModuloAdministracao/Equipamentos/ModuloSonoro/configuracoesReproducao.js';



import {
    GoogleMap,
    Marker,
    LoadScript,
    OverlayView,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
    DistanceMatrixService,
    MaxZoomService, Circle, Polygon, Polyline
} from "@react-google-maps/api";

import "./MapPage.css";
import alarmeAudio from '../../../../../../assets/mp3/alarme.mp3';

const customStyles = {
    content: {
        top: "0", // Alinha ao topo
        left: "0", // Alinha à esquerda
        right: "auto",
        bottom: "0", // Ocupa toda a altura
        margin: "0", // Remove margens
        transform: "none", // Nenhuma transformação necessária
        width: "30%", // Ocupa 30% da largura disponível
        height: "100%", // Ocupa 100% da altura da tela
        zIndex: 9999, // Sobrepõe todas as telas
        backgroundColor: "white", // Fundo branco para o modal
        borderRadius: "0 8px 8px 0", // Bordas arredondadas apenas no lado direito
        boxShadow: "4px 0 10px rgba(0, 0, 0, 0.2)", // Sombra para dar destaque ao modal
    },
    overlay: {
        zIndex: 9998, // Sobrepõe o fundo
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro translúcido
    },
};

const customStylesMobile = {
    content: {
        top: "0", // Alinha ao topo
        left: "0", // Alinha à esquerda
        right: "auto",
        bottom: "0", // Ocupa toda a altura
        margin: "0", // Remove margens
        transform: "none", // Nenhuma transformação necessária
        width: "100%", // Ocupa 30% da largura disponível
        height: "100%", // Ocupa 100% da altura da tela
        zIndex: 9999, // Sobrepõe todas as telas
        backgroundColor: "white", // Fundo branco para o modal
        borderRadius: "0 8px 8px 0", // Bordas arredondadas apenas no lado direito
        boxShadow: "4px 0 10px rgba(0, 0, 0, 0.2)", // Sombra para dar destaque ao modal
    },
    overlay: {
        zIndex: 9998, // Sobrepõe o fundo
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro translúcido
    },
};



export default function ModuloSonoroComponent(props) {


    const { lat, lng, tipo, onClick, status, equipamento, perimetro, id_instalacao, flag, id_sigma } = props;
    const tempoInativo = 1;



    const [larguraOriginal, setLarguraOriginal] = useState(0);

    const [limiar, setLimiar] = useState(900);
    const [openLogTable, setOpenLogTable] = useState(false);



    function checkDimenssoes() {
        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;

        setLarguraOriginal(largura);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });

    const Zone = ({ zoneData }) => {

        const {
            id,
            nome,
            zoneStatus,
            armeStatus,
            zoneAlarme,
        } = zoneData;

        return (
            <Card sx={{ margin: 2, padding: 2, backgroundColor: "#f5f5f5" }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Zona {id}: {nome}
                    </Typography>
                    <Box sx={{ whiteSpace: "pre-line" }}>
                        <Typography><b>Online?</b> {zoneStatus}  </Typography>
                        <Typography><b>Armada?</b> {armeStatus}  </Typography>
                        <Typography><b>Em Alarme?</b> {zoneAlarme}</Typography>
                    </Box>
                </CardContent>
            </Card>
        );
    };


    useEffect(() => {



        checkDimenssoes();



    }, []);


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');



    const [urlChamada, setUrlChamada] = useState("");

    const [isOpen, setIsOpen] = useState(false);

    const openModal = (ramal) => {
        setUrlChamada("https://gruporosinetos.com/call/softphone.html?wp_serveraddress=gruporosinetos.com:6050&wp_username=webrtc&wp_password=1234&wp_callto=" + ramal + "&wp_autoaction=1");
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setUrlChamada("");
    };

    const iframeRef = useRef(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [retries, setRetries] = useState(0);

    const MAX_RETRIES = 7; // Número máximo de tentativas de recarregar o iframe



    const [openReproducao, setOpenReproducao] = useState(false);

    const handleCloseReproducao = () => {
        setOpenReproducao(false);
    }

    const checkIframeContent = () => {
        setTimeout(() => {
            try {
                const iframeWindow = iframeRef.current?.contentWindow;

                if (!iframeWindow) {
                    console.warn("Não foi possível acessar o conteúdo do iframe.");
                    return;
                }

                const logs = iframeWindow.document.body.innerText;


                // Verifica mensagens indicativas de carregamento completo
                //alert(logs);
                const isLoaded =
                    logs.includes("menu") || logs.includes("Menu") ||
                    logs.includes("GetBrowserVersion") ||
                    logs.includes("About:");

                if (isLoaded) {
                    setHasLoaded(true);
                    console.log("Página carregada com sucesso no iframe!");
                } else if (retries < MAX_RETRIES) {
                    console.warn(`Tentativa ${retries + 1}: Página não carregou completamente, recarregando iframe...`);
                    setRetries(retries + 1);
                    iframeRef.current.src += ""; // Recarrega o iframe
                } else {
                    console.error("Número máximo de tentativas atingido. Não foi possível carregar o iframe.");
                }
            } catch (err) {
                console.error("Erro ao verificar conteúdo do iframe:", err);
            }
        }, 2000); // Delay de 1 segundo para aguardar o carregamento
    };

    useEffect(() => {
        if (!isOpen) {
            setHasLoaded(false); // Reseta o estado ao fechar
            setRetries(0); // Reseta o número de tentativas
        }
    }, [isOpen]);



    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };


    const [logsPgm, setLogsPgm] = useState([]);
    const [limit, setLimit] = useState(200);


    function processarIcone(tipo) {
        if (tipo === 0) {
            return icone_pgm;
        } else if (tipo === 10) {
            return icone_medidor;
        } else if (tipo === 70) {
            return icone_central;
        } else if (tipo === 30) {
            return icone_pgm;
        } else if (tipo === 120) {
            return icone_corneta;
        }
    }

    const tipoRequisicaoMap = {
        0: "Acionar Ambos Reles",
        1: "Acionar Rele 1",
        2: "Acionar Rele 2",
        3: "Desligar Ambos Reles",
        4: "Desligar Rele 1",
        5: "Desligar Rele 2",
        6: "Reproduzir Audio Personalizado 1",
        7: "Reproduzir Audio Personalizado 2",
        8: "Reproduzir Audio Personalizado 3",
        9: "Reproduzir Audio Personalizado 4",
        10: "Reproduzir Audio Personalizado 5",
        20: "Desativar Automação Geral",
        21: "Ativar Automação Geral",
        22: "Reiniciar PGM",
        23: "Desativar Automação dos Reles",
        24: "Ativar Automação dos Reles",
        25: "Desativar Automação da Corneta",
        26: "Ativar Automação da Corneta",
        27: "Armar Central em Stay",
        28: "Armar Central em Away",
        29: "Desativar Central",
        50: "Central Armada",
        51: "Central Desarmada",
        52: "Central em Alarme"
    };

    const respondidoModuloPgmMap = {
        0: "Não Respondido",
        1: "Respondido"
    };

    const processarDadosLogs = (dados) => {
        return dados.map((item) => ({
            ...item, // Mantém os outros atributos
            data_hora_requisicao: moment(item.data_hora_requisicao).isValid()
                ? moment(item.data_hora_requisicao).format('HH:mm:ss DD/MM/YYYY')
                : item.data_hora_requisicao, // Formata a data ou mantém o valor original
            data_hora_resposta: moment(item.data_hora_resposta).isValid()
                ? moment(item.data_hora_resposta).format('HH:mm:ss DD/MM/YYYY')
                : item.data_hora_resposta, // Formata a data ou mantém o valor original
            tipo_requisicao: tipoRequisicaoMap[item.tipo_requisicao] || "Tipo Desconhecido", // Mapeia o tipo
            origem: item.origem === 0 ? "Local" : "Remoto", // Mapeia a origem
            respondido_modulo_sonoro: respondidoModuloPgmMap[item.respondido_modulo_sonoro] || "Status Desconhecido", // Mapeia o status de respondido
        }));
    };


    async function handleClickLog() {
        setLogsPgm([]);


        console.log("handleClickLog")
        try {
            const cadastro = {};
            const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
            const url = `/v1/protected/modulopgm/tarefas/listartodasparaareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_sonoro.id_modulo_sonoro + "/" + limit;
            const response = await api.get(url, { headers });

            if (response.data) {
                const dadosProcessados = processarDadosLogs(response.data);

                setLogsPgm(dadosProcessados);
                setOpenLogTable(true);
            }

        } catch (error) {
            console.error("Erro ao enviar o comando:", error);
        }
    }


    const colunasLogsPgm = useMemo(
        () => [
            {
                accessorKey: 'id_tarefa',
                header: 'ID',
                size: 30,
            },
            {
                accessorKey: 'data_hora_requisicao',
                header: 'Data Requisição',
                size: 200,
            },
            {
                accessorKey: 'data_hora_resposta',
                header: 'Data Resposta',
                size: 200,
            },
            {
                accessorKey: 'tipo_requisicao',
                header: 'Tipo',
                size: 200,
            },
            {
                accessorKey: 'origem',
                header: 'Origem',
                size: 200,
            },
            {
                accessorKey: 'respondido_modulo_sonoro',
                header: 'Respondido',
                size: 200,
            },
        ],
        [],
    );



    // const link =
    //"https://gruporosinetos.com/call/softphone.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=2024111&wp_autoaction=1";

    const link =
        "https://gruporosinetos.com/call/softphone.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=2024111&wp_autoaction=1";


    const openLinkInNewTab = () => {
        window.open(link, "_blank"); // Abre em uma nova guia
    };

    async function handleClickLog() {
        setLogsPgm([]);


        console.log("handleClickPgm")
        try {
            const cadastro = {};
            const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
            const url = `/v1/protected/modulosonoro/tarefas/listartodasparaareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_sonoro.id_modulo_sonoro + "/" + limit;
            const response = await api.get(url, { headers });

            if (response.data) {
                const dadosProcessados = processarDadosLogs(response.data);

                setLogsPgm(dadosProcessados);
                console.log("log recebido, abrir modal");
                setOpenLogTable(true);
            }

        } catch (error) {
            console.error("Erro ao enviar o comando:", error);
        }
    }

    async function handleClickPgm(rele, comando) {

        console.log("handleClickPgm")
        try {
            const cadastro = {};
            const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
            const url = `/v1/protected/modulosonoro/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_sonoro.id_modulo_sonoro + "/" + comando + "/" + rele + "/0/0/0/0/0/0/0";
            const response = await api.get(url, { headers });

            if (response.data) {
                setSnackbarMessage('Comando Enviado!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);


            }



        } catch (error) {
            setSnackbarMessage('Erro ao enviar comando');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            console.error("Erro ao enviar o comando:", error);
        }
    }

    const processarIndexAtivarRele = (index) => {
        if (index === 1) {
            //rele 1
            return 1;
        } else if (index === 2) {
            //rele 2
            return 2;
        } else if (index === 3) {
            //rele 3
            return 3;
        } else if (index === 4) {
            //rele 4
            return 4;
        } else if (index === 5) {
            //rele 5
            return 5;
        }
    }

    const processarIndexDesativarRele = (index) => {
        if (index === 1) {
            //rele 1
            return 1;
        } else if (index === 2) {
            //rele 2
            return 2;
        } else if (index === 3) {
            //rele 3
            return 3;
        } else if (index === 4) {
            //rele 4
            return 4;
        } else if (index === 5) {
            //rele 5
            return 5;
        }
    }

    // Calcular a diferença de tempo
    const now = new Date();
    const lastResponse = new Date(equipamento.modulo_sonoro.data_hora_ultima_resposta);
    const differenceInMinutes = (now - lastResponse) / 1000 / 60; // Convertendo de milissegundos para minutos

    // Verificar se o alarme deve ser ativado
    const alarme = equipamento.alarme;

    // Definir o estado do equipamento
    let statusText;
    let statusColor;
    let isBlinking = false;

    const armada = equipamento.modulo_sonoro.armada;

    if (alarme) {
        statusText = "ALARME";
        statusColor = "red";
        isBlinking = true; // Habilitar o piscar se estiver em alarme
    } else if (differenceInMinutes < tempoInativo) {
        if (armada) {
            statusText = "ONLINE - ARMADA";
            statusColor = "green";
        }
        else {
            statusText = "ONLINE - DESARMADA";
            statusColor = "orange";
        }
    } else {
        statusText = "OFFLINE";
        statusColor = "red";
        isBlinking = true; // Habilitar o piscar se estiver offline
    }




    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            align="center"
            item xs={flag === 0 ? 3 : 12}
            style={{ border: flag === 1 ? '1px solid black' : null, padding: 1, margin: flag === 1 ? 3 : 0 }}

        >
            {
                flag === 1 && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        align="center"
                        item xs={12}
                        style={{ padding: 1 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={11}
                            style={{ padding: 0 }}
                        >
                            <span style={{ fontWeight: 'bold', fontSize: 12 }}>TITANIWM MODULO UNIFIER</span>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={1}
                            style={{ padding: 0 }}
                        >
                            <button
                                onClick={() => handleClickLog()} // Função de fechar
                                style={{
                                    backgroundColor: 'green', // Cor laranja-avermelhada estilosa (TOP!)
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '8px',
                                    padding: '2px 8px',
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra leve
                                    textTransform: 'uppercase',
                                }}
                            >
                                LOG
                            </button>
                        </Grid>

                    </Grid>
                )
            }
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={flag === 0 ? 12 : 2}
                style={{
                    boxShadow: alarme && flag === 0 ? `0 0 30px 150px rgba(255, 0, 0, 0.5)` : "none", // Sombra afastada e translúcida
                }}>
                <Grid item xs={12}>
                    <img
                        alt={"icone"}
                        style={{
                            textAlign: 'center',
                            width: '40px',  // Define a largura da imagem
                            height: '40px'  // Define a altura da imagem
                        }}
                        src={processarIcone(tipo)}

                    />
                </Grid>

                <Grid item xs={12}>
                    <span style={{ color: 'black', fontSize: '14px', fontWeight: 'bold' }}>{equipamento.modulo_sonoro.codigo} </span>
                </Grid>


                <Grid item xs={12}
                >
                    <span style={{
                        color: statusColor,
                        fontSize: '14px',
                        fontWeight: 'bold',
                        animation: isBlinking && flag === 0 ? 'blink 2s step-start infinite' : 'none'
                    }}>
                        {flag === 0 ? statusText : ''}
                    </span>

                </Grid>



            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                align="flex-start"
                item xs={flag === 0 ? 1 : 6}
            >
                {flag === 1 && (
                    <div style={{
                        color: 'black', fontSize: '14px', fontWeight: 'bold',
                        display: 'flex', alignItems: 'center', gap: '8px', marginTop: '10px'
                    }}>
                        {[1, 2, 3, 4, 5].map((index) => {
                            const releNome = equipamento?.modulo_sonoro[`nome_rele${index}`];
                            const status = equipamento?.modulo_sonoro[`status_rele${index}`];
                            const iconPath = status === "on" ? switch_on : switch_off;

                            return (
                                <div key={index} style={{
                                    border: '1px solid black',
                                    borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                                }}>
                                    <span>{releNome}</span>
                                    {status === "on" ? (
                                        <IconButton color="error" onClick={() => { handleClickPgm(processarIndexDesativarRele(index), 11) }}>
                                            <img src={iconPath} alt={`${status} icon`} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                        </IconButton>
                                    ) : (
                                        <IconButton color="primary" onClick={() => { handleClickPgm(processarIndexAtivarRele(index), 10) }}>
                                            <img src={iconPath} alt={`${status} icon`} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                        </IconButton>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                align="flex-start"
                item xs={2}
            >
                {flag === 1 && (

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        align="center"
                        item xs={12}
                    >


                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            align="flex-start"
                            style={{ marginRight: '10px' }}
                            item xs={6}
                        >
                            {
                                equipamento.modulo_sonoro.conectado_wifi.includes("wifi_conectado") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Wifi conectado</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                            }}
                                            src={wifi_conectado}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }

{
                                equipamento.modulo_sonoro.conectado_wifi.includes("wifi_desconectado") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Wifi desconectado</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                                animation: "piscar 0.5s infinite"
                                            }}
                                            src={wifi_desconectado}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }

{
                                equipamento.modulo_sonoro.conectado_wifi.includes("wifi_conectado") &&
                                equipamento.modulo_sonoro.conectado_wifi.split(';').map(s => s.trim()).includes("internet_wifi") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Internet via Wifi</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                            }}
                                            src={wifi_internet}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }

                            {
                                equipamento.modulo_sonoro.conectado_wifi.includes("wifi_conectado") &&
                                equipamento.modulo_sonoro.conectado_wifi.includes("sem_internet_wifi") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Sem Internet via Wifi</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                                animation: "piscar 0.5s infinite"
                                            }}
                                            src={wifi_sem_internet}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }
                        </Grid>


                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"

                            item xs={6}
                        >



                            {
                                equipamento.modulo_sonoro.conectado_eth.includes("cabo_conectado") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Cabo conectado</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                            }}
                                            src={cabo_conectado}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }

                            {
                                equipamento.modulo_sonoro.conectado_eth.includes("cabo_desconectado") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Cabo desconectado</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                                animation: "piscar 0.5s infinite"
                                            }}
                                            src={cabo_desconectado}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }


                            {
                                equipamento.modulo_sonoro.conectado_eth.includes("cabo_conectado") &&
                                equipamento.modulo_sonoro.conectado_eth.split(';').map(s => s.trim()).includes("internet_cabo") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Internet via cabo</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                            }}
                                            src={cabo_internet}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }

                            {
                                equipamento.modulo_sonoro.conectado_eth.includes("cabo_conectado") &&
                                equipamento.modulo_sonoro.conectado_eth.includes("sem_internet_cabo") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Sem Internet via cabo</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                                animation: "piscar 0.5s infinite"
                                            }}
                                            src={cabo_sem_internet}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            align="flex-start"
                            style={{ marginRight: '10px' }}
                            item xs={6}
                        >
                            {
                                equipamento.modulo_sonoro.conectado_gsm.includes("modem_conectado") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Modem conectado</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                            }}
                                            src={modem_conectado}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }

                            {
                                equipamento.modulo_sonoro.conectado_gsm.includes("modem_desconectado") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Modem desconectado</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                                animation: "piscar 0.5s infinite"
                                            }}
                                            src={modem_desconectado}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }


                            {
                                equipamento.modulo_sonoro.conectado_gsm.includes("modem_conectado") &&
                                equipamento.modulo_sonoro.conectado_gsm.split(';').map(s => s.trim()).includes("internet_modem") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Internet via modem</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                            }}
                                            src={modem_internet}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }

                            {
                                equipamento.modulo_sonoro.conectado_gsm.includes("modem_conectado") &&
                                equipamento.modulo_sonoro.conectado_gsm.includes("sem_internet_modem") &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Sem Internet via modem</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                                animation: "piscar 0.5s infinite"

                                            }}
                                            src={modem_sem_internet}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }

                            {
                                equipamento.modulo_sonoro.tensao <= 0 &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Sem tensão AC</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem
                                                animation: "piscar 0.5s infinite"

                                            }}
                                            src={sem_tensao}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }

                            {
                                equipamento.modulo_sonoro.tensao >= 1 &&
                                (<Grid item xs={6}>
                                    <Tooltip
                                        title={<span>Tensão AC detectada</span>}
                                        placement="top"
                                        arrow
                                        style={{ backgroundColor: 'white' }}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <img
                                            alt="Ícone"
                                            style={{
                                                width: "30px", // Define a largura da imagem
                                                height: "30px", // Define a altura da imagem

                                            }}
                                            src={tensao_ac}
                                        />
                                    </Tooltip>

                                </Grid>)

                            }
                        </Grid>
                    </Grid>


                )}
            </Grid>

            {flag === 1 && (
                <Grid item xs={12}
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    style={{ padding: 0 }}


                >


                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        align="flex-end"
                        item xs={6}
                    >
                        <Tooltip
                            title={
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        minHeight: "300px",
                                        minWidth: "400px",
                                        maxHeight: "300px",
                                        maxWidth: "400px",
                                        border: "1px solid black",
                                        color: "black",
                                        overflow: "auto", // Habilita scroll quando necessário
                                    }}
                                >
                                    <Grid container direction="row" item xs={12} alignItems="center" justifyContent="flex-start">
                                        <Typography style={{ padding: 1 }}>
                                            <Box sx={{ whiteSpace: "pre-line" }}>
                                                <span>
                                                    Atualizado em{" "}
                                                    {moment(equipamento.modulo_sonoro.data_hora_status_zona, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY [às] HH:mm:ss")} (
                                                    {moment(equipamento.modulo_sonoro.data_hora_status_zona, "YYYY-MM-DD HH:mm:ss").fromNow()})
                                                </span>
                                                {equipamento.modulo_sonoro.status_zonas
                                                    .split(";")
                                                    .filter(Boolean) // Remove strings vazias do final
                                                    .map((linha) => {
                                                        const [id, nome, zoneStatus, armeStatus, zoneAlarme] = linha.split(",");
                                                        return (
                                                            <Zone
                                                                key={id}
                                                                zoneData={{
                                                                    id,
                                                                    nome,
                                                                    zoneStatus: zoneStatus,
                                                                    armeStatus: armeStatus,
                                                                    zoneAlarme: zoneAlarme,
                                                                }}
                                                            />
                                                        );
                                                    })}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </div>
                            }
                            placement="top"
                            arrow
                            style={{ backgroundColor: 'white' }}
                            sx={{ backgroundColor: 'white' }}
                        >
                            <SensorsIcon style={{ fontSize: "30px" }} /> Zonas
                        </Tooltip>
                    </Grid>


                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        align="flex-start"
                        item xs={5}
                    >
                        <Tooltip
                            title={
                                <div
                                    style={{ backgroundColor: 'white', minHeight: '300px', minWidth: '400px', border: '1px solid black', color: 'black' }}
                                >

                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    >
                                        <Typography style={{ padding: 1 }}>
                                            <Box sx={{ whiteSpace: 'pre-line' }}>
                                                <span>
                                                    Atualizado em{" "}
                                                    {moment(equipamento.modulo_sonoro.data_hora_status_particao, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY [às] HH:mm:ss")} (
                                                    {moment(equipamento.modulo_sonoro.data_hora_status_particao, "YYYY-MM-DD HH:mm:ss").fromNow()})
                                                </span>
                                                <span>
                                                    {equipamento.modulo_sonoro.status_central}
                                                </span>

                                            </Box>
                                        </Typography>
                                    </Grid>
                                </div>
                            }
                            placement="top"
                            arrow
                            style={{ backgroundColor: 'white' }}
                            sx={{ backgroundColor: 'white' }}
                        >
                            <AccountTreeIcon style={{ fontSize: "30px" }} /> Partições
                        </Tooltip>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        align="flex-end"
                        item xs={1}
                    >
                    </Grid>


                </Grid>
            )}

            {
                flag === 1 && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        align="center"
                        item xs={12}
                        style={{ padding: 1 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={6}
                            style={{ padding: 1 }}
                        >

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                item
                                xs={12}
                                style={{ padding: "10px" }} // Adicionando um espaçamento externo para melhor layout
                            >
                                <Button
                                    variant="contained"
                                    startIcon={<CallIcon />} // Ícone no início do botão
                                    onClick={() => openModal(equipamento.ramal)}
                                    sx={{
                                        backgroundColor: "#4CAF50", // Verde elegante
                                        color: "white",
                                        fontWeight: "bold",
                                        textTransform: "none",
                                        padding: "12px 24px", // Aumentando o tamanho do botão
                                        borderRadius: "20px", // Bordas bem arredondadas
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Sombra suave
                                        transition: "0.3s ease-in-out",
                                        "&:hover": {
                                            backgroundColor: "#388E3C", // Tom mais escuro no hover
                                            boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)", // Efeito de hover na sombra
                                        },
                                    }}
                                >

                                    Chamar - Ramal ({equipamento.ramal}) - ({equipamento.localizacao})
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                )
            }


            {
                flag === 1 && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        item xs={12}
                        style={{ padding: 5 }}
                    >
                        <button
                            onClick={() => setOpenReproducao(true)}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                backgroundColor: "#64B5F6", // Azul claro
                                color: "white",
                                fontWeight: "bold",
                                textTransform: "none",
                                padding: "6px 10px",
                                borderRadius: "8px",
                                border: "none",
                                cursor: "pointer",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                transition: "0.3s ease-in-out",
                            }}
                            onMouseOver={(e) => (e.target.style.backgroundColor = "#42A5F5")} // Efeito hover
                            onMouseOut={(e) => (e.target.style.backgroundColor = "#64B5F6")}
                        >
                            <img src={icone_corneta} alt="Corneta" style={{ width: 24, height: 24 }} />
                            Painel de Áudio
                        </button>
                    </Grid>

                )
            }

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    style: {
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        marginTop: '60px', // Ajuste a altura conforme necessário
                        fontSize: '16px',
                        fontWeight: 'bold',
                        border: '1px solid black',
                        borderRadius: '4px',
                        padding: '10px',
                        zIndex: 555,
                        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',

                    }
                }}
            />



            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    style={larguraOriginal < limiar ? customStylesMobile : customStyles}
                    contentLabel="Janela Modal"
                >
                    <button onClick={closeModal} style={{ float: "center" }}>
                        Fechar
                    </button>
                    <iframe
                        ref={iframeRef}
                        onLoad={checkIframeContent} // Evento chamado ao carregar o iframe

                        src={urlChamada}// {"https://gruporosinetos.com/call/softphone.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=2024111&wp_autoaction=1"}
                        //src="https://gruporosinetos.com/call/softphone.html"

                        //src="https://gruporosinetos.com/call/click2call.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=2024111&wp_autoaction=1"



                        style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                        }}
                        title="Softphone"
                    ></iframe>
                </Modal>
            </div>



            <Dialog open={openReproducao} onClose={handleCloseReproducao}
                PaperProps={{ style: { minWidth: '80%', minHeight: '80%' } }}
            >
                <DialogTitle>Painel de Aúdio</DialogTitle>
                <DialogContent>
                    <ConfiguracoesReproducao
                        equipamento={equipamento} />
                </DialogContent>
            </Dialog>



            {/* Modal */}
            {openLogTable && (
                <div
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro com opacidade
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "199999",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            width: "80%",
                            height: "80%",
                            borderRadius: "8px",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Sombra para destaque
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                            position: "relative",

                        }}
                    >
                        {/* Botão de fechar */}
                        <button
                            onClick={() => setOpenLogTable(false)}
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                backgroundColor: "#FF4500",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                padding: "8px",
                                cursor: "pointer",
                                fontWeight: "bold",
                            }}
                        >
                            Fechar
                        </button>

                        {/* Conteúdo do Modal */}
                        <h6 style={{ marginTop: 0 }}>Historico de Eventos</h6>

                        {/* Espaço reservado para a tabela */}
                        <div
                            style={{
                                flex: 1,
                                overflowY: "auto", // Adiciona rolagem se a tabela for grande
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                padding: "10px",
                            }}
                        >

                            <div>
                                <div style={{ backgroundColor: 'white', margin: 5 }}>
                                    {logsPgm && (
                                        <MaterialReactTable
                                            columns={colunasLogsPgm}
                                            data={logsPgm}
                                            enableBottomToolbar={true}
                                            enableGlobalFilterModes
                                            enablePagination={true}
                                            enableRowActions={false}
                                            paginationDisplayMode={'pages'}
                                            getRowId={(row) => row.id_tarefa}
                                            enableGrouping
                                            enableExpandAll={false} //hide expand all double arrow in column header
                                            enableExpanding={true}
                                            filterFromLeafRows={true} //apply filtering to all rows instead of just parent rows
                                            paginateExpandedRows={false}
                                            initialState={{
                                                pagination: { pageSize: 200, pageIndex: 0 }, // Correção da sintaxe aqui
                                                showGlobalFilter: true,
                                            }}
                                            muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                            muiPaginationProps={{
                                                rowsPerPageOptions: [200, 300, 500],
                                                variant: 'outlined',
                                            }}
                                            enableFullScreenToggle={true}


                                        />

                                    )}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}


        </Grid>
    );


}

