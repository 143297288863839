import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import Skeleton from '@material-ui/lab/Skeleton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { format, addDays, isValid, parse } from 'date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import Chip from '@mui/material/Chip';

import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import { useParams } from "react-router-dom";

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function AtualizarProcedimentoExecucaoRondaVirtual() {


    const [value, setValue] = React.useState(0);
    const descriptions = [
        "",
        "Tentativa de Realizar o Procedimento, não realizado, pois não é adequado",
        "Procedimento Realizado, mas não é necessário",
        "Procedimento Realizado, mas não é eficaz para essa solicitação",
        "Procedimento realizado, é necessário ,mas deve ser melhorado",
        "Procedimento realizado, é essencial para resolver a solicitação"
    ];
    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }

    const [problemas, setProblemas] = useState([]);
    const [problemasSelecionados, setProblemasSelecionados] = useState([]);


    const handleAdicionarProblema = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosProblemas = valoresSelecionados.filter(
            (opcao) => !problemasSelecionados.some((selecionado) => selecionado.id_problema === opcao.id_problema)
        );

        setProblemasSelecionados([...problemasSelecionados, ...novosProblemas]);
    };

    const handleRemoverProblema = (event, problemaRemovido) => {
        // alert("Handle remover dep chamado")
        const novosProblemas = problemasSelecionados.filter(
            (problema) => problema.id_problema !== problemaRemovido.id_problema
        );
        setProblemasSelecionados(novosProblemas);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };



    const [botaoClicado, setBotaoClicado] = useState(false);


    const [rondaVirtual, setRondaVirtual] = useState(null);

    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };

    const { idProcedimentoExecucacao } = useParams();
    const { idRondaVirtual } = useParams();
    const { flag } = useParams();


    const [unidadeBase, setUnidadebase] = useState(null);
    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);

    const classes = useStyles();
    const history = useHistory();

    const [observacoes, setObservacoes] = useState('');
    const [resultado, setResultado] = useState(1);


    const [descricaoImagem1, setDescricaoImagem1] = useState('');
    const [descricaoImagem2, setDescricaoImagem2] = useState('');
    const [descricaoImagem3, setDescricaoImagem3] = useState('');
    const [descricaoImagem4, setDescricaoImagem4] = useState('');


    const [descricaoVideo1, setDescricaoVideo1] = useState('');
    const [descricaoVideo2, setDescricaoVideo2] = useState('');
    const [descricaoVideo3, setDescricaoVideo3] = useState('');


    const [descricaoArquivo1, setDescricaoArquivo1] = useState('');
    const [descricaoArquivo2, setDescricaoArquivo2] = useState('');
    const [descricaoArquivo3, setDescricaoArquivo3] = useState('');




    const handleChangeStart = (event, newValue) => {
        console.log("nota: " + newValue);
        if (!newValue)
            newValue = nota;
        setNota(newValue); // Deselect if clicking on the selected star again
        setDescricaoNota(descriptions[newValue]);
    };

    const [descricaoNota, setDescricaoNota] = useState(descriptions[5]);

    const [nota, setNota] = useState(5);
    const [comentarioNota, setComentarioNota] = useState("");

    const [obrigatorio, setObrigatorio] = useState(0);

    const handleChangeResultado = (event) => {
        setResultado(event.target.value);
    };

    const [imagemSelecionada1, setImagemSelecionada1] = useState(null);
    const [imagemSelecionada2, setImagemSelecionada2] = useState(null);
    const [imagemSelecionada3, setImagemSelecionada3] = useState(null);
    const [imagemSelecionada4, setImagemSelecionada4] = useState(null);


    const onDrop1 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada1(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);

    const onDrop2 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada2(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);

    const onDrop3 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada3(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);

    const onDrop4 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada4(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);



    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: onDrop1,

        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: onDrop2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        onDrop: onDrop3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
        onDrop: onDrop4,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });



    const [videoSelecionado1, setVideoSelecionado1] = useState(null);
    const [videoSelecionado2, setVideoSelecionado2] = useState(null);
    const [videoSelecionado3, setVideoSelecionado3] = useState(null);


    // Função para lidar com o "paste"
    const handlePaste = useCallback(
        (event) => {
            const clipboardItems = event.clipboardData.items;

            for (const item of clipboardItems) {
                if (item.type.startsWith("image/")) {
                    const file = item.getAsFile();
                    if (file) {
                        // Converte para PNG usando Canvas (caso necessário)
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            const img = new Image();
                            img.src = e.target.result;
                            img.onload = () => {
                                const canvas = document.createElement("canvas");
                                const ctx = canvas.getContext("2d");
                                canvas.width = img.width;
                                canvas.height = img.height;

                                // Desenha a imagem no Canvas
                                ctx.drawImage(img, 0, 0);

                                // Converte o Canvas para PNG
                                canvas.toBlob(
                                    (blob) => {
                                        const convertedFile = new File(
                                            [blob],
                                            `imagem-${Date.now()}.png`,
                                            { type: "image/png" }
                                        );

                                        // Define a próxima imagem disponível
                                        if (imagemSelecionada1 === null) {
                                            setImagemSelecionada1(convertedFile);
                                        } else if (imagemSelecionada2 === null) {
                                            setImagemSelecionada2(convertedFile);
                                        } else if (imagemSelecionada3 === null) {
                                            setImagemSelecionada3(convertedFile);
                                        } else if (imagemSelecionada4 === null) {
                                            setImagemSelecionada4(convertedFile);
                                        } else {
                                            alert("Todas as imagens já foram preenchidas!");
                                        }
                                    },
                                    "image/png",
                                    1
                                );
                            };
                        };
                        reader.readAsDataURL(file);
                    }
                }
            }
        },
        [imagemSelecionada1, imagemSelecionada2, imagemSelecionada3, imagemSelecionada4] // Adicionar dependências
    );

    // Listener para evento "paste"
    useEffect(() => {
        window.addEventListener("paste", handlePaste);
        return () => {
            window.removeEventListener("paste", handlePaste);
        };
    }, [handlePaste]);


    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const recordedChunksRef = useRef([]);

    const startRecording = async () => {
        try {
            // Captura a tela
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true, // Inclui o áudio da tela, se disponível
            });

            // Captura o microfone
            const audioStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });

            // Combina os streams de tela e microfone
            const combinedStream = new MediaStream([
                ...screenStream.getTracks(),
                ...audioStream.getTracks(),
            ]);

            // Configura o MediaRecorder
            const mediaRecorder = new MediaRecorder(combinedStream, {
                mimeType: "video/webm",
            });

            // Configura os eventos de gravação
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    recordedChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                // Cria um blob com os dados gravados
                const blob = new Blob(recordedChunksRef.current, {
                    type: "video/webm",
                });
                recordedChunksRef.current = [];

                // Cria um arquivo a partir do blob
                const file = new File([blob], `video-${Date.now()}.webm`, {
                    type: "video/webm",
                });

                // Salva o arquivo em videoSelecionado1
                
                // Salva o arquivo em videoSelecionado1
                if (!videoSelecionado1)
                    setVideoSelecionado1(file);
                else if (!videoSelecionado2) {
                    setVideoSelecionado2(file);
                } else if(!videoSelecionado3){
                    setVideoSelecionado3(file);
                }

                // Limpa os streams
                screenStream.getTracks().forEach((track) => track.stop());
                audioStream.getTracks().forEach((track) => track.stop());
            };

            // Inicia a gravação
            mediaRecorder.start();
            mediaRecorderRef.current = mediaRecorder;
            setIsRecording(true);
        } catch (error) {
            console.error("Erro ao iniciar a gravação:", error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const onDropVideo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropVideo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropVideo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado3(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootVideoProps1, getInputProps: getInputVideoProps1 } = useDropzone({
        onDrop: onDropVideo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps2, getInputProps: getInputVideoProps2 } = useDropzone({
        onDrop: onDropVideo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps3, getInputProps: getInputVideoProps3 } = useDropzone({
        onDrop: onDropVideo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });



    const [arquivoSelecionado1, setArquivoSelecionado1] = useState(null);
    const [arquivoSelecionado2, setArquivoSelecionado2] = useState(null);
    const [arquivoSelecionado3, setArquivoSelecionado3] = useState(null);


    const onDropArquivo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropArquivo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropArquivo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado3(acceptedFiles[0]);
    }, []);



    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps2, getInputProps: getInputArquivoProps2 } = useDropzone({
        onDrop: onDropArquivo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps3, getInputProps: getInputArquivoProps3 } = useDropzone({
        onDrop: onDropArquivo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });





    async function funcaoSalvar() {


        try {

            console.log("cadastrar chamado");

            
            if(problemasSelecionados.length === 0){
                const resposta = window.confirm("Você não marcou nenhum problema detectado, deseja continuar?\n\nOK - Sem problemas detectados \n\Cancelar - Há problemas, vou seleciona-lós!");
                if (resposta) {
                   
                } else {
                    setBotaoClicado(false);
                    return;
                }
            }
    
 
            const formData = new FormData();

            // Adicionar os dados do procedimento
            formData.append('id_procedimento_execucao', idProcedimentoExecucacao);
            formData.append('resultado', resultado);
            formData.append('usuario_executor.id_usuario', Cookies.get('id_usuario'));
            formData.append('observacoes', observacoes);
            formData.append("nota", nota);

            if (procedimentoExecucao.questao_resposta) {
                for (const questao of procedimentoExecucao.questao_resposta) {
                    if (questao.tipo === 0) {
                        if (
                            !questao.resposta_livre || // Nulo ou indefinido
                            questao.resposta_livre.trim() === "" || // Vazio
                            questao.resposta_livre.trim().length < 5 // Comprimento menor que 5
                        ) {
                            alert(`Responda a questão: ${questao.nome}`);
                            setBotaoClicado(false);
                            return;
                        }
                    }
                }
            }

            if (comentarioNota && comentarioNota.trim() !== "" && comentarioNota.length > 3) {
                formData.append("comentario_nota", comentarioNota);
            } else {
                alert("Ajude a melhorar o sistema, forneça um comentário sobre esse procedimento no campo Avaliação do Procedimento!");
                setBotaoClicado(false);
                return;
            }



            if (imagemSelecionada1 || imagemSelecionada2 || imagemSelecionada3 || imagemSelecionada4
                || videoSelecionado1 || videoSelecionado2 || videoSelecionado3 ||
                arquivoSelecionado1 || arquivoSelecionado2 || arquivoSelecionado3
            ) {

            } else {
                alert("O procedimento exige comprovação, anexe no minimo uma midia comprovatoria sendo imagem, video ou arquivo pdf");
                setBotaoClicado(false);
                return;
            }





            const resposta = window.confirm("Suas informações estão corretas? Uma vez salvo, o procedimento será finalizado e não pode ser mais atualizado, em caso de duvidas, revise suas informações!");
            if (resposta) {

            } else {
                setBotaoClicado(false);
                return;
            }




            formData.append('descricao_imagem1', descricaoImagem1);
            formData.append('descricao_imagem2', descricaoImagem2);
            formData.append('descricao_imagem3', descricaoImagem3);
            formData.append('descricao_imagem4', descricaoImagem4);


            formData.append('descricao_video1', descricaoVideo1);
            formData.append('descricao_video2', descricaoVideo2);
            formData.append('descricao_video3', descricaoVideo3);

            formData.append('descricao_arquivo1', descricaoArquivo1);
            formData.append('descricao_arquivo2', descricaoArquivo2);
            formData.append('descricao_arquivo3', descricaoArquivo3);



            // Adicionar as imagens ao FormData
            formData.append('imagens', imagemSelecionada1);
            formData.append('imagens', imagemSelecionada2);
            formData.append('imagens', imagemSelecionada3);
            formData.append('imagens', imagemSelecionada4);

            formData.append('videos', videoSelecionado1);
            formData.append('videos', videoSelecionado2);
            formData.append('videos', videoSelecionado3);

            formData.append('arquivos', arquivoSelecionado1);
            formData.append('arquivos', arquivoSelecionado2);
            formData.append('arquivos', arquivoSelecionado3);


          
            const jsonString = JSON.stringify(procedimentoExecucao.questao_resposta);
            formData.append('questoessalvar', new Blob([jsonString], { type: 'application/json' }));



            const jsonStringProblemas = JSON.stringify(problemasSelecionados);
            formData.append('problemasanexar', new Blob([jsonStringProblemas], { type: 'application/json' }));


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token"),
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data; charset=UTF-8'
            }

            const response = await api.put('/v1/protected/rondavirtual/finalizarprocedimentoexecucao/' + idProcedimentoExecucacao + "/" + idRondaVirtual, formData,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Procedimento Atualizado");

                history.push({
                    pathname: "/gestao/operacional/rondavirtual/gerenciar/" + idRondaVirtual,
                })

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }

    const [loading, setLoading] = useState(true);

    const [procedimentoExecucao, setProcedimentoExecucao] = useState();

    const [arquivos, setArquivos] = useState(null);



    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/rondavirtual/listarprocedimentoexecucaoporid/" + idProcedimentoExecucacao, {
                headers: headers
            }).then(function (response) {

                setProcedimentoExecucao(response.data.procedimento);

                if (parseInt(flag, 10) === 1) {
                    setObservacoes(response.data.procedimento.observacoes);
                    setComentarioNota(response.data.procedimento.comentario_nota);
                    setResultado(response.data.procedimento.resultado);
                    setNota(response.data.procedimento.nota);
                    setDescricaoNota(descriptions[response.data.procedimento.nota]);
                    setArquivos(response.data);
                    setProblemasSelecionados(response.data.procedimento.problemas);

                }

                console.log("id do procedimento: " + response.data.procedimento.procedimento.id_procedimento);

                api.get("/v1/protected/rondavirtual/listarporid/" + idRondaVirtual, {
                    headers: headers
                }).then(function (response_os) {
                    setRondaVirtual(response_os.data);
                    setInstalacaoSelecionada(response_os.data.instalacao);

                    api.get("/v1/protected/problemas/listartodos" , {
                        headers: headers
                    }).then(function (response_pr) {
                        setProblemas(response_pr.data);
                    
                        setLoading(false);
                    });
 
                    
                });

            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();
    }, []);


    function returnDescricaoInstalacaoResumida(instalacao) {
        var texto = " SIGMA: " + instalacao.identificador_integrador + " - " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);
        return texto;
    }

    function returnDescricaoProblema(problema) {
        var texto =  problema.nome;
        return texto;
    }



    


    return (
        <div>
            <NavegadorGestao
                ListarOrdemSevicos={'underline'}
                corGestaoServicosMarcado={'orange'}
                Gestao={'underline'}
                corGestaoMarcado={'orange'}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Grid container spacing={2}
                    alignItems="center"
                    justifyContent="center"
                >

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        align="flex-start"
                        item xs={12}
                        style={{ width: '100%', margin: 2, paddingTop: 15 }}
                    >
                        <a href={"/gestao/operacional/rondavirtual/gerenciar/" + idRondaVirtual} style={{ fontSize: 16, color: 'black', marginRight: '5px' }}>{"< Ronda Virtual nº " + idRondaVirtual + returnDescricaoInstalacaoResumida(instalacaoSelecionada)}</a>
                    </Grid>

                    <Grid
                        item xs={12}
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent={"center"}
                        style={{ marginRight: 10, marginLeft: 10, marginTop: 0 }}
                    >


                        <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                            <AppBar position="static" >

                                <Tabs
                                    value={value}
                                    onChange={handleChangeTab}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"


                                >
                                    <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                    <Tab label="Imagens" style={{ backgroundColor: 'green' }} />
                                    <Tab label="Vídeos" style={{ backgroundColor: 'green' }} />
                                    <Tab label="Documentos" style={{ backgroundColor: 'green' }} />

                                </Tabs>
                            </AppBar>

                        </Box>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        item xs={6}
                    >
                        <Typography align="center" variant="h5">
                            {procedimentoExecucao.procedimento.nome}
                        </Typography>
                    </Grid>

                    {
                        parseInt(flag, 10) === 0 && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={6}
                            >
                                <Button style={{ marginTop: 5 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                    disabled={botaoClicado}
                                > Salvar  </Button>
                            </Grid>
                        )
                    }



                    <Grid
                        style={{ paddingTop: 30 }}
                        container
                        direction="row"
                        item xs={12}
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >


                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}

                        >

                            <Grid
                                value={value} index={0}
                                style={{ padding: 10, marginRight: 10, marginLeft: 10, marginTop: 0 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="resultado-selector-label">Resultado da Execução do Procedimento</InputLabel>
                                            <Select
                                                labelId="resultado-selector-label"
                                                id="resultado-selector"
                                                value={resultado}
                                                onChange={handleChangeResultado}
                                                label="Resultado"
                                            >
                                                <MenuItem value={0}>Falha</MenuItem>
                                                <MenuItem value={1}>Sucesso</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>


                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={2}
                                    style={{
                                        border: "1px solid black", // Borda preta ao redor
                                        borderRadius: "8px", // Borda arredondada (opcional)
                                        padding: "16px", // Espaçamento interno
                                        marginTop: "16px", // Margem superior
                                    }}
                                >
                                    {/* Título */}
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            align="center"
                                            style={{ fontWeight: "bold", marginBottom: "16px" }}
                                        >
                                            Questionário
                                        </Typography>
                                    </Grid>

                                    {/* Questões (em linha) */}
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >
                                        {procedimentoExecucao.questao_resposta.map((questao, index) => (
                                            <Grid
                                                item
                                                xs={3} // Ajuste o tamanho conforme necessário
                                                key={index}
                                                container
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{
                                                    border: "1px solid black", // Borda preta para cada questão
                                                    borderRadius: "8px", // Borda arredondada (opcional)
                                                    padding: "16px", // Espaçamento interno
                                                    margin: "8px", // Espaçamento externo entre questões
                                                }}
                                            >
                                                {/* Nome da Questão */}
                                                <Typography
                                                    variant="subtitle1"
                                                    align="center"
                                                    style={{ fontWeight: "bold" }}
                                                >
                                                    Nome:
                                                </Typography>
                                                <Typography variant="body2" align="center">
                                                    {questao.nome}
                                                </Typography>

                                                {/* Descrição da Questão */}
                                                <Typography
                                                    variant="subtitle1"
                                                    align="center"
                                                    style={{ fontWeight: "bold", marginTop: "8px" }}
                                                >
                                                    Descrição:
                                                </Typography>
                                                <Typography variant="body2" align="center">
                                                    {questao.descricao}
                                                </Typography>

                                                {/* Campo de Resposta ou Radio */}
                                                <Grid item style={{ marginTop: "8px", width: "100%" }}>
                                                    {questao.tipo === 0 ? (
                                                        <TextField
                                                            fullWidth
                                                            label="Resposta"
                                                            variant="outlined"
                                                            style={{ textAlign: "center" }}
                                                            minRows={3}
                                                            value={questao.resposta_livre || ""}
                                                            onChange={(e) => {
                                                                questao.resposta_livre = e.target.value;
                                                                procedimentoExecucao.questao_resposta[index] = { ...questao };
                                                                setProcedimentoExecucao({ ...procedimentoExecucao }); // Garante re-renderização
                                                            }}
                                                        />
                                                    ) : (
                                                        <FormControl component="fieldset" style={{ width: "100%" }}>
                                                            <FormLabel component="legend" style={{ textAlign: "center" }}>
                                                                Resposta
                                                            </FormLabel>
                                                            <RadioGroup row
                                                                onChange={(e) => {
                                                                    questao.escolha_fixa = parseInt(e.target.value, 10);
                                                                    procedimentoExecucao.questao_resposta[index] = { ...questao };
                                                                    setProcedimentoExecucao({ ...procedimentoExecucao }); // Garante re-renderização
                                                                }}
                                                                value={questao.escolha_fixa}
                                                                style={{ justifyContent: "center" }}>
                                                                <FormControlLabel
                                                                    value={0}
                                                                    control={<Radio />}
                                                                    label="NÃO"
                                                                />
                                                                <FormControlLabel
                                                                    value={1}
                                                                    control={<Radio />}
                                                                    label="SIM"
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>


                                <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ marginTop: 5 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    align="center"
                                    item xs={11}
                                >

                                    <Autocomplete
                                        id="select-problemas"
                                        options={problemas}
                                        disableCloseOnSelect
                                        multiple
                                        getOptionLabel={(problema) => returnDescricaoProblema(problema).replace(/<br \/>/g, '\n')}
                                        value={problemasSelecionados}
                                        onChange={handleAdicionarProblema}
                                        filterOptions={(options, { inputValue }) => {
                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                            return options.filter((problema) => {
                                                const cotacaoText = returnDescricaoProblema(problema).toLowerCase();
                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label=" Problemas" multiline minRows={1} />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((problema, index) => (
                                                <Chip
                                                    key={index}
                                                    label={problema.nome  }
                                                    style={{
                                                        borderRadius: 15, // Borda arredondada
                                                        backgroundColor: '#e3f2fd', // Cor de fundo azul claro
                                                        margin: '4px', // Espaçamento entre as tags
                                                    }}
                                                    {...getTagProps({ index })}
                                                    onDelete={(event) => handleRemoverProblema(event, problema)}

                                                />
                                            ))
                                        }
                                    />
                                </Grid>
                                <WarningAmberIcon style={{ color: 'orange', fontSize: 40 }} />
                                Informe aqui os problemas detectados na instalação durante a execução do procedimento
                            </Grid>


                                <Grid item xs={12} style={{ padding: 40 }}>
                                    <TextField
                                        variant="standard"
                                        name="observacoes"
                                        label="Observações"
                                        required
                                        id="observacoes"
                                        value={observacoes}
                                        onChange={(e) => setObservacoes(e.target.value)}
                                        fullWidth
                                        multiline
                                        minRows={1}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ padding: 2 }}
                                    container
                                    direction={'row'}
                                    alignItems='center'
                                    justifyContent='center'
                                >

                                    <Grid item xs={12} style={{ padding: 2 }}
                                        container
                                        direction={'row'}
                                        alignItems='center'
                                        justifyContent='center'
                                    >
                                        <Box component="fieldset" mb={3} borderColor="transparent">
                                            <Rating
                                                name="simple-controlled"
                                                value={nota}
                                                onChange={handleChangeStart}
                                                precision={1}
                                                max={5}
                                                emptyIcon={<StarBorderIcon />}

                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: 2 }}
                                        container
                                        direction={'row'}
                                        alignItems='center'
                                        justifyContent='center'
                                    >
                                        <Typography variant="body1" gutterBottom>
                                            {descricaoNota}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{ padding: 2 }}
                                        container
                                        direction={'row'}
                                        alignItems='center'
                                        justifyContent='center'
                                    >
                                        <TextField
                                            variant="standard"
                                            name="comentarioNota"
                                            label="Avaliação do Procedimento"
                                            id="comentarioNota"
                                            value={comentarioNota}
                                            onChange={(e) => setComentarioNota(e.target.value)}
                                            fullWidth
                                            multiline
                                            minRows={1}
                                        />
                                    </Grid>
                                </Grid>







                            </Grid>


                            <Grid
                                value={value} index={1}
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >



                                {parseInt(flag, 10) === 0 ? (
                                    <Grid item xs={12} style={{ padding: 40 }}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item xs={3}>
                                            <Card>
                                                <CardContent>
                                                    {imagemSelecionada1 ? (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <img
                                                                src={URL.createObjectURL(imagemSelecionada1)}
                                                                alt="Imagem Selecionada 1"
                                                                style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                            />
                                                            <button
                                                                style={{
                                                                    backgroundColor: '#ff4d4f',
                                                                    color: '#fff',
                                                                    border: 'none',
                                                                    padding: '10px 15px',
                                                                    borderRadius: '5px',
                                                                    cursor: 'pointer',
                                                                    marginTop: '10px',
                                                                }}
                                                                onClick={() => setImagemSelecionada1(null)}
                                                            >
                                                                Remover Imagem
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div {...getRootProps1()} style={dropzoneStyles}>
                                                            <input {...getInputProps1()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoImagem1"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoImagem1"
                                                            value={descricaoImagem1}
                                                            onChange={(e) => setDescricaoImagem1(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Card>
                                                <CardContent>
                                                    {imagemSelecionada2 ? (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <img
                                                                src={URL.createObjectURL(imagemSelecionada2)}
                                                                alt="Imagem Selecionada 1"
                                                                style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                            />
                                                            <button
                                                                style={{
                                                                    backgroundColor: '#ff4d4f',
                                                                    color: '#fff',
                                                                    border: 'none',
                                                                    padding: '10px 15px',
                                                                    borderRadius: '5px',
                                                                    cursor: 'pointer',
                                                                    marginTop: '10px',
                                                                }}
                                                                onClick={() => setImagemSelecionada2(null)}
                                                            >
                                                                Remover Imagem
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div {...getRootProps2()} style={dropzoneStyles}>
                                                            <input {...getInputProps2()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoImagem2"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoImagem2"
                                                            value={descricaoImagem2}
                                                            onChange={(e) => setDescricaoImagem2(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Card>
                                                <CardContent>
                                                    {imagemSelecionada3 ? (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <img
                                                                src={URL.createObjectURL(imagemSelecionada3)}
                                                                alt="Imagem Selecionada 1"
                                                                style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                            />
                                                            <button
                                                                style={{
                                                                    backgroundColor: '#ff4d4f',
                                                                    color: '#fff',
                                                                    border: 'none',
                                                                    padding: '10px 15px',
                                                                    borderRadius: '5px',
                                                                    cursor: 'pointer',
                                                                    marginTop: '10px',
                                                                }}
                                                                onClick={() => setImagemSelecionada3(null)}
                                                            >
                                                                Remover Imagem
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div {...getRootProps3()} style={dropzoneStyles}>
                                                            <input {...getInputProps3()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoImagem3"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoImagem3"
                                                            value={descricaoImagem3}
                                                            onChange={(e) => setDescricaoImagem3(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>


                                        <Grid item xs={3}>
                                            <Card>
                                                <CardContent>
                                                    {imagemSelecionada4 ? (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <img
                                                                src={URL.createObjectURL(imagemSelecionada4)}
                                                                alt="Imagem Selecionada 1"
                                                                style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                            />
                                                            <button
                                                                style={{
                                                                    backgroundColor: '#ff4d4f',
                                                                    color: '#fff',
                                                                    border: 'none',
                                                                    padding: '10px 15px',
                                                                    borderRadius: '5px',
                                                                    cursor: 'pointer',
                                                                    marginTop: '10px',
                                                                }}
                                                                onClick={() => setImagemSelecionada4(null)}
                                                            >
                                                                Remover Imagem
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div {...getRootProps4()} style={dropzoneStyles}>
                                                            <input {...getInputProps4()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <Grid item xs={12} style={{ padding: 40 }}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoImagem4"
                                                            label="Descrição"
                                                            required
                                                            id="descricaoImagem4"
                                                            value={descricaoImagem4}
                                                            onChange={(e) => setDescricaoImagem4(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                    </Grid>
                                )
                                    :
                                    (

                                        <Grid item xs={12}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >

                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                    {
                                                        arquivos.imagem1 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_imagem1}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.imagem1 &&
                                                        <img
                                                            src={`data:image/jpeg;base64,${arquivos.imagem1}`} alt="Imagem 1" />
                                                    }
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}
                                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                                >
                                                    {
                                                        arquivos.imagem2 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_imagem2}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.imagem2 &&
                                                        <img
                                                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                            src={`data:image/jpeg;base64,${arquivos.imagem2}`} alt="Imagem 2" />
                                                    }
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}
                                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                                >
                                                    {
                                                        arquivos.imagem3 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_imagem3}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.imagem3 &&
                                                        <img
                                                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                            src={`data:image/jpeg;base64,${arquivos.imagem3}`} alt="Imagem 3" />
                                                    }
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}
                                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                                >
                                                    {
                                                        arquivos.imagem4 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_imagem4}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                >
                                                    {arquivos.imagem4 &&
                                                        <img
                                                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                            src={`data:image/jpeg;base64,${arquivos.imagem4}`} alt="Imagem 4" />
                                                    }
                                                </Grid>

                                            </Grid>


                                        </Grid>)
                                }
                            </Grid>



                            <Grid
                                value={value} index={2}
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >



                                <Grid item xs={12} style={{ padding: 40 }}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >

                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {videoSelecionado1 ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <video
                                                            controls
                                                            src={URL.createObjectURL(videoSelecionado1)}
                                                            alt="Vídeo Selecionado 1"
                                                            style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                        />
                                                        <button
                                                            style={{
                                                                backgroundColor: '#ff4d4f',
                                                                color: '#fff',
                                                                border: 'none',
                                                                padding: '10px 15px',
                                                                borderRadius: '5px',
                                                                cursor: 'pointer',
                                                                marginTop: '10px',
                                                            }}
                                                            onClick={() => setVideoSelecionado1(null)}
                                                        >
                                                            Remover
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div>
                                                    <div {...getRootVideoProps1()} style={dropzoneStyles}>
                                                        <input {...getInputVideoProps1()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um vídeo aqui ou clique para adicionar
                                                        </Typography>

                                                    </div>
                                                    <div> {isRecording ? (
                                                        <button onClick={stopRecording}>Parar Gravação</button>
                                                    ) : (
                                                        <button onClick={startRecording}>Iniciar Gravação</button>
                                                    )}</div>
                                                </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoVideo1"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoVideo1"
                                                        value={descricaoVideo1}
                                                        onChange={(e) => setDescricaoVideo1(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {videoSelecionado2 ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <video
                                                            controls
                                                            src={URL.createObjectURL(videoSelecionado2)}
                                                            alt="Vídeo Selecionado 2"
                                                            style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                        />
                                                        <button
                                                            style={{
                                                                backgroundColor: '#ff4d4f',
                                                                color: '#fff',
                                                                border: 'none',
                                                                padding: '10px 15px',
                                                                borderRadius: '5px',
                                                                cursor: 'pointer',
                                                                marginTop: '10px',
                                                            }}
                                                            onClick={() => setVideoSelecionado2(null)}
                                                        >
                                                            Remover
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div {...getRootVideoProps2()} style={dropzoneStyles}>
                                                    <input {...getInputVideoProps2()} />
                                                    <Typography align="center" variant="h5">
                                                        Arraste e solte um vídeo aqui ou clique para adicionar
                                                    </Typography>
                                                    {isRecording ? (
                                                        <button onClick={stopRecording}>Parar Gravação</button>
                                                    ) : (
                                                        <button onClick={startRecording}>Iniciar Gravação</button>
                                                    )}
                                                </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoVideo2"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoVideo2"
                                                        value={descricaoVideo2}
                                                        onChange={(e) => setDescricaoVideo2(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {videoSelecionado3 ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <video
                                                            controls
                                                            src={URL.createObjectURL(videoSelecionado3)}
                                                            alt="Vídeo Selecionado 3"
                                                            style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                        />
                                                        <button
                                                            style={{
                                                                backgroundColor: '#ff4d4f',
                                                                color: '#fff',
                                                                border: 'none',
                                                                padding: '10px 15px',
                                                                borderRadius: '5px',
                                                                cursor: 'pointer',
                                                                marginTop: '10px',
                                                            }}
                                                            onClick={() => setVideoSelecionado3(null)}
                                                        >
                                                            Remover
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div {...getRootVideoProps3()} style={dropzoneStyles}>
                                                    <input {...getInputVideoProps3()} />
                                                    <Typography align="center" variant="h5">
                                                        Arraste e solte um vídeo aqui ou clique para adicionar
                                                    </Typography>
                                                    {isRecording ? (
                                                        <button onClick={stopRecording}>Parar Gravação</button>
                                                    ) : (
                                                        <button onClick={startRecording}>Iniciar Gravação</button>
                                                    )}
                                                </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoVideo3"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoVideo3"
                                                        value={descricaoVideo3}
                                                        onChange={(e) => setDescricaoVideo3(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                value={value} index={3}
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >




                                <Grid item xs={12} style={{ padding: 40 }}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {arquivoSelecionado1 ? (

                                                    <div style={{ textAlign: 'center' }}>
                                                        <div
                                                            href={URL.createObjectURL(arquivoSelecionado1)}
                                                            onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                                            onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado1))}
                                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}
                                                        >
                                                            <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <button
                                                                style={{
                                                                    backgroundColor: '#ff4d4f',
                                                                    color: '#fff',
                                                                    border: 'none',
                                                                    padding: '10px 15px',
                                                                    borderRadius: '5px',
                                                                    cursor: 'pointer',
                                                                    marginTop: '10px',
                                                                }}
                                                                onClick={() => setArquivoSelecionado1(null)}
                                                            >
                                                                Remover
                                                            </button>
                                                        </div>
                                                    </div>


                                                ) : (
                                                    <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                                        <input {...getInputArquivoProps1()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um arquivo aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoArquivo1"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoArquivo1"
                                                        value={descricaoArquivo1}
                                                        onChange={(e) => setDescricaoArquivo1(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {arquivoSelecionado2 ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div
                                                            href={URL.createObjectURL(arquivoSelecionado2)}
                                                            onClick={() => window.open(URL.createObjectURL(arquivoSelecionado2), '_blank')}
                                                            onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado2))}
                                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}
                                                        >
                                                            <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <button
                                                                style={{
                                                                    backgroundColor: '#ff4d4f',
                                                                    color: '#fff',
                                                                    border: 'none',
                                                                    padding: '10px 15px',
                                                                    borderRadius: '5px',
                                                                    cursor: 'pointer',
                                                                    marginTop: '10px',
                                                                }}
                                                                onClick={() => setArquivoSelecionado2(null)}
                                                            >
                                                                Remover
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div {...getRootArquivoProps2()} style={dropzoneStyles}>
                                                        <input {...getInputArquivoProps2()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um arquivo aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoArquivo2"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoArquivo2"
                                                        value={descricaoArquivo2}
                                                        onChange={(e) => setDescricaoArquivo2(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {arquivoSelecionado3 ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div
                                                            href={URL.createObjectURL(arquivoSelecionado3)}
                                                            onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                                            onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado3))}
                                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}
                                                        >
                                                            <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <button
                                                                style={{
                                                                    backgroundColor: '#ff4d4f',
                                                                    color: '#fff',
                                                                    border: 'none',
                                                                    padding: '10px 15px',
                                                                    borderRadius: '5px',
                                                                    cursor: 'pointer',
                                                                    marginTop: '10px',
                                                                }}
                                                                onClick={() => setArquivoSelecionado3(null)}
                                                            >
                                                                Remover
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div {...getRootArquivoProps3()} style={dropzoneStyles}>
                                                        <input {...getInputArquivoProps3()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um arquivo aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoArquivo3"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoArquivo3"
                                                        value={descricaoArquivo3}
                                                        onChange={(e) => setDescricaoArquivo3(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </SwipeableViews>
                    </Grid>
                </Grid>
            }

        </div >
    );
}


