import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@mui/material/Tooltip';
import CallIcon from "@mui/icons-material/Call"; // Ícone de telefone do Material UI

import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Stomp } from '@stomp/stompjs';

import Close from '@material-ui/icons/Close'
import { Snackbar } from '@material-ui/core';
import { Menu } from '@mui/material';
import Hls from "hls.js";

import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Modal from "react-modal";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
 


import {
    MaterialReactTable
} from 'material-react-table';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import BuildIcon from '@mui/icons-material/Build';
import Switch from '@mui/material/Switch';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/Power';



import api from '../../../../../../services/api';


import NavegadorGestao from "../../../../../../components/NavBarGestao";


import switch_off from '../../../../../../assets/imgs/switch-off.png';
import switch_on from '../../../../../../assets/imgs/switch-on.png';


import mini_icone_carro from '../../../../../../assets/imgs/mini_icone_carro.png';

import mini_icone_moto from '../../../../../../assets/imgs/mini_icone_moto.png';

import icone_origem from '../../../../../../assets/imgs/icone_origem.png';
import icone_destino from '../../../../../../assets/imgs/icone_destino.png';
import icone_parada from '../../../../../../assets/imgs/icone_parada.png';

import icone_call from '../../../../../../assets/imgs/icone_call.png';


import cabo_conectado from '../../../../../../assets/imgs/cabo_conectado.png';
import cabo_desconectado from '../../../../../../assets/imgs/cabo_desconectado.png';
import cabo_internet from '../../../../../../assets/imgs/cabo_internet.png';
import cabo_sem_internet from '../../../../../../assets/imgs/cabo_sem_internet.png';

import wifi_conectado from '../../../../../../assets/imgs/cabo_conectado.png';

import modem_conectado from '../../../../../../assets/imgs/modem_conectado.png';
import modem_desconectado from '../../../../../../assets/imgs/modem_desconectado.png';
import modem_internet from '../../../../../../assets/imgs/cabo_internet.png';
import modem_sem_internet from '../../../../../../assets/imgs/cabo_sem_internet.png';

import icone_pgm from '../../../../../../assets/imgs/icone_pgm.png';
import icone_corneta from '../../../../../../assets/imgs/corneta.png';

import icone_play from '../../../../../../assets/imgs/play.png';


import icone_medidor from '../../../../../../assets/imgs/icone_medidor.png';

import icone_subestacao from '../../../../../../assets/imgs/icone_subestacao.png';
import icone_casa from '../../../../../../assets/imgs/icone_casa.png';
import icone_loja from '../../../../../../assets/imgs/icone_loja.png';
import icone_central from '../../../../../../assets/imgs/icone_central.png';

import icone_instalacoes from '../../../../../../assets/imgs/icone_instalacoes.png';
import icone_equipamentos from '../../../../../../assets/imgs/icone_equipamentos.png';

import icone_armar_stay from '../../../../../../assets/imgs/armar_stay.png';
import icone_armar_away from '../../../../../../assets/imgs/armar_away.png';
import icone_desarmar from '../../../../../../assets/imgs/desarmar.png';
import globalConfig from '../../../../../../configs/globalConfigs';





import {
    GoogleMap,
    Marker,
    LoadScript,
    OverlayView,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
    DistanceMatrixService,
    MaxZoomService, Circle, Polygon, Polyline
} from "@react-google-maps/api";

import "./MapPage.css";
import alarmeAudio from '../../../../../../assets/mp3/alarme.mp3';

const customStyles = {
    content: {
        top: "0", // Alinha ao topo
        left: "0", // Alinha à esquerda
        right: "auto",
        bottom: "0", // Ocupa toda a altura
        margin: "0", // Remove margens
        transform: "none", // Nenhuma transformação necessária
        width: "30%", // Ocupa 30% da largura disponível
        height: "100%", // Ocupa 100% da altura da tela
        zIndex: 9999, // Sobrepõe todas as telas
        backgroundColor: "white", // Fundo branco para o modal
        borderRadius: "0 8px 8px 0", // Bordas arredondadas apenas no lado direito
        boxShadow: "4px 0 10px rgba(0, 0, 0, 0.2)", // Sombra para dar destaque ao modal
    },
    overlay: {
        zIndex: 9998, // Sobrepõe o fundo
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro translúcido
    },
};

const customStylesMobile = {
    content: {
        top: "0", // Alinha ao topo
        left: "0", // Alinha à esquerda
        right: "auto",
        bottom: "0", // Ocupa toda a altura
        margin: "0", // Remove margens
        transform: "none", // Nenhuma transformação necessária
        width: "100%", // Ocupa 30% da largura disponível
        height: "100%", // Ocupa 100% da altura da tela
        zIndex: 9999, // Sobrepõe todas as telas
        backgroundColor: "white", // Fundo branco para o modal
        borderRadius: "0 8px 8px 0", // Bordas arredondadas apenas no lado direito
        boxShadow: "4px 0 10px rgba(0, 0, 0, 0.2)", // Sombra para dar destaque ao modal
    },
    overlay: {
        zIndex: 9998, // Sobrepõe o fundo
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro translúcido
    },
};



export default function CornetaComponent(props) {


    const { lat, lng, tipo, onClick, status, equipamento, perimetro, id_instalacao, flag, id_sigma  } = props;
    const tempoInativo = 1;

    

    const [larguraOriginal, setLarguraOriginal] = useState(0);

    const [limiar, setLimiar] = useState(900);
    const [openLogTable, setOpenLogTable] = useState(false);



    function checkDimenssoes() {
        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;

        setLarguraOriginal(largura);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });


    useEffect(() => {



        checkDimenssoes();



    }, []);


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');


    const [urlChamada, setUrlChamada] = useState("");

    const [isOpen, setIsOpen] = useState(false);

    const openModal = (ramal) => {
        setUrlChamada("https://gruporosinetos.com/call/softphone.html?wp_serveraddress=gruporosinetos.com:6050&wp_username=webrtc&wp_password=1234&wp_callto=" + ramal + "&wp_autoaction=1");
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setUrlChamada("");
    };

    const iframeRef = useRef(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [retries, setRetries] = useState(0);

    const MAX_RETRIES = 7; // Número máximo de tentativas de recarregar o iframe




    const checkIframeContent = () => {
        setTimeout(() => {
            try {
                const iframeWindow = iframeRef.current?.contentWindow;

                if (!iframeWindow) {
                    console.warn("Não foi possível acessar o conteúdo do iframe.");
                    return;
                }

                const logs = iframeWindow.document.body.innerText;


                // Verifica mensagens indicativas de carregamento completo
                //alert(logs);
                const isLoaded =
                    logs.includes("menu") || logs.includes("Menu") ||
                    logs.includes("GetBrowserVersion") ||
                    logs.includes("About:");

                if (isLoaded) {
                    setHasLoaded(true);
                    console.log("Página carregada com sucesso no iframe!");
                } else if (retries < MAX_RETRIES) {
                    console.warn(`Tentativa ${retries + 1}: Página não carregou completamente, recarregando iframe...`);
                    setRetries(retries + 1);
                    iframeRef.current.src += ""; // Recarrega o iframe
                } else {
                    console.error("Número máximo de tentativas atingido. Não foi possível carregar o iframe.");
                }
            } catch (err) {
                console.error("Erro ao verificar conteúdo do iframe:", err);
            }
        }, 2000); // Delay de 1 segundo para aguardar o carregamento
    };

    useEffect(() => {
        if (!isOpen) {
            setHasLoaded(false); // Reseta o estado ao fechar
            setRetries(0); // Reseta o número de tentativas
        }
    }, [isOpen]);


 
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    
    const [logsPgm, setLogsPgm] = useState([]);
    const [limit, setLimit] = useState(200);


    function processarIcone(tipo) {
        if (tipo === 0) {
            return icone_pgm;
        } else if (tipo === 10) {
            return icone_medidor;
        } else if (tipo === 70) {
            return icone_central;
        } else if (tipo === 30) {
            return icone_pgm;
        } else if (tipo === 120) {
            return icone_corneta;
        }
    }

    

   

    async function handleClickCorneta(comando) {

        console.log("handleClickPgm")
        try {
            const cadastro = {};
            const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
            const url = `/v1/protected/modulopgm/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/" + comando;
            const response = await api.get(url, { headers });

            if (response.data) {
                setSnackbarMessage('Comando Enviado!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);

               
            }



        } catch (error) {
            setSnackbarMessage('Erro ao enviar comando');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            console.error("Erro ao enviar o comando:", error);
        }
    }


       

        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={flag === 0 ? 3 : 12}
                style={{ border: flag === 1 ? '1px solid black' : null, padding: 1, margin: flag === 1 ? 3 : 0 }}


            >
                {
                    flag === 1 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                            style={{ padding: 1 }}
                        >
                            <span style={{ fontWeight: 'bold', fontSize: 12 }}>CORNETA KHOMP</span>
                        </Grid>
                    )
                }
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    align="flex-start"

                    item xs={flag === 0 ? 12 : 2}
                >
                    <Grid item xs={12}

                    >
                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '40px',  // Define a largura da imagem
                                height: '40px'  // Define a altura da imagem
                            }}
                            src={processarIcone(tipo)}
                        />
                    </Grid>



                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    align="flex-start"
                    item xs={flag === 0 ? 1 : 8}
                >
                    {flag === 1 && (
                        <div style={{
                            color: 'black',
                            display: 'flex', alignItems: 'center', gap: '1px', marginTop: '1px'
                        }}>

                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCorneta(6) }}  >
                                    <img src={icone_play} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Audio 1</span>
                                </IconButton>
                            </div>

                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCorneta(7) }}  >
                                    <img src={icone_play} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Audio 2</span>
                                </IconButton>
                            </div>


                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCorneta(8) }}  >
                                    <img src={icone_play} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Audio 3</span>
                                </IconButton>
                            </div>

                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCorneta(9) }}  >
                                    <img src={icone_play} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Audio 4</span>
                                </IconButton>
                            </div>






                        </div>
                    )}
                </Grid>

                {
                    flag === 1 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                            style={{ padding: 1 }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={6}
                                style={{ padding: 1 }}
                            >

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    item
                                    xs={12}
                                    style={{ padding: "10px" }} // Adicionando um espaçamento externo para melhor layout
                                >
                                    <Button
                                        variant="contained"
                                        startIcon={<CallIcon />} // Ícone no início do botão
                                        onClick={() => openModal(equipamento.ramal)}
                                        sx={{
                                            backgroundColor: "#4CAF50", // Verde elegante
                                            color: "white",
                                            fontWeight: "bold",
                                            textTransform: "none",
                                            padding: "12px 24px", // Aumentando o tamanho do botão
                                            borderRadius: "20px", // Bordas bem arredondadas
                                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Sombra suave
                                            transition: "0.3s ease-in-out",
                                            "&:hover": {
                                                backgroundColor: "#388E3C", // Tom mais escuro no hover
                                                boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)", // Efeito de hover na sombra
                                            },
                                        }}
                                    >
                                        Chamar - Ramal ({equipamento.ramal}) - ({equipamento.localizacao})
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    )
                }


<Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    style: {
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        marginTop: '60px', // Ajuste a altura conforme necessário
                        fontSize: '16px',
                        fontWeight: 'bold',
                        border: '1px solid black',
                        borderRadius: '4px',
                        padding: '10px',
                        zIndex: 555,
                        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',

                    }
                }}
            />


<div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    style={larguraOriginal < limiar ? customStylesMobile : customStyles}
                    contentLabel="Janela Modal"
                >
                    <button onClick={closeModal} style={{ float: "center" }}>
                        Fechar
                    </button>
                    <iframe
                        ref={iframeRef}
                        onLoad={checkIframeContent} // Evento chamado ao carregar o iframe

                        src={urlChamada}// {"https://gruporosinetos.com/call/softphone.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=2024111&wp_autoaction=1"}
                        //src="https://gruporosinetos.com/call/softphone.html"

                        //src="https://gruporosinetos.com/call/click2call.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=2024111&wp_autoaction=1"



                        style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                        }}
                        title="Softphone"
                    ></iframe>
                </Modal>
            </div>

   {/* Modal */}
            {openLogTable && (
                <div
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro com opacidade
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "199999",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            width: "80%",
                            height: "80%",
                            borderRadius: "8px",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Sombra para destaque
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                            position: "relative",
                           
                        }}
                    >
                        {/* Botão de fechar */}
                        <button
                            onClick={() => setOpenLogTable(false)}
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                backgroundColor: "#FF4500",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                padding: "8px",
                                cursor: "pointer",
                                fontWeight: "bold",
                            }}
                        >
                            Fechar
                        </button>

                        {/* Conteúdo do Modal */}
                        <h6 style={{ marginTop: 0 }}>Historico de Eventos</h6>

                        {/* Espaço reservado para a tabela */}
                        <div
                            style={{
                                flex: 1,
                                overflowY: "auto", // Adiciona rolagem se a tabela for grande
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                padding: "10px",
                            }}
                        >

                            <div>
                                <div style={{ backgroundColor: 'white', margin: 5 }}>
                                    {logsPgm && (
                                        <MaterialReactTable
                                            columns={colunasLogsPgm}
                                            data={logsPgm}
                                            enableBottomToolbar={true}
                                            enableGlobalFilterModes
                                            enablePagination={true}
                                            enableRowActions={false}
                                            paginationDisplayMode={'pages'}
                                            getRowId={(row) => row.id_tarefa}
                                            enableGrouping
                                            enableExpandAll={false} //hide expand all double arrow in column header
                                            enableExpanding={true}
                                            filterFromLeafRows={true} //apply filtering to all rows instead of just parent rows
                                            paginateExpandedRows={false}
                                            initialState={{
                                                pagination: { pageSize: 200, pageIndex: 0 }, // Correção da sintaxe aqui
                                                showGlobalFilter: true,
                                            }}
                                            muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                            muiPaginationProps={{
                                                rowsPerPageOptions: [200, 300, 500],
                                                variant: 'outlined',
                                            }}
                                            enableFullScreenToggle={true}


                                        />

                                    )}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}


            </Grid>
        );


}

