// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-timeline {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 2rem; /* espaço entre os pontos */
    padding: 1rem 0;
  }
  
  .custom-timeline .rs-timeline-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .custom-timeline .rs-timeline-item::after {
    content: '';
    position: absolute;
    top: 25px;
    left: 100%;
    height: 2px;
    width: 2rem;
    background-color: #ccc;
  }
  
  .custom-timeline .rs-timeline-item:last-child::after {
    content: none;
  }`, "",{"version":3,"sources":["webpack://./src/pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeServicos/Timeline.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,SAAS,EAAE,2BAA2B;IACtC,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,WAAW;IACX,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE,aAAa;EACf","sourcesContent":[".custom-timeline {\r\n    display: flex;\r\n    flex-direction: row;\r\n    overflow-x: auto;\r\n    gap: 2rem; /* espaço entre os pontos */\r\n    padding: 1rem 0;\r\n  }\r\n  \r\n  .custom-timeline .rs-timeline-item {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    position: relative;\r\n  }\r\n  \r\n  .custom-timeline .rs-timeline-item::after {\r\n    content: '';\r\n    position: absolute;\r\n    top: 25px;\r\n    left: 100%;\r\n    height: 2px;\r\n    width: 2rem;\r\n    background-color: #ccc;\r\n  }\r\n  \r\n  .custom-timeline .rs-timeline-item:last-child::after {\r\n    content: none;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
