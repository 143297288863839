import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@mui/material';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Ícone para "finalizar"
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import TextField from '@material-ui/core/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import Cookies from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { styled } from '@material-ui/styles';
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckIcon from '@mui/icons-material/Check';
import { useDropzone } from 'react-dropzone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';

import api from '../../../../../services/api';
import SNARonda from '../../../../../pages/painelAdmin/AreaGestao/ModuloOperacional/RondaVirtual/snaRonda';

import MenuRecursosAdministrativos from '../components/menu';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { AddToQueueOutlined, LaptopWindows } from '@material-ui/icons';

import GerenciarTratamentoEmCampo from './gerenciarTratamentoEmCampo';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'; // Ícone apropriado
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';



const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'whit',
        borderColor: 'red',
    },
}));


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },

    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'whit',
        borderColor: 'blue',
    },
}));




const dropzoneStyles = {
    border: '4px dashed #000000',
    borderRadius: '4px',
    padding: '2px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '2px 0',
};



export default function CabecalhoInformacoes({ ordemServico, instalacaoSelecionada, solicitacaoSelecionada, projetoSelecionado, propostaSelecionada, solicitante, analista, rondaVirtual }) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };


    function returnDescricaoInstalacao(instalacao) {
        var texto = "SIGMA: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }



    function returnDescricaoProjeto(projeto) {
        var texto = projeto.id_projeto + " - " + projeto.descricao_projeto + "\n";//+ returnDescricaoInstalacao(projeto.instalacao);
        return texto;
    }


    function returnDescricaoProposta(proposta) {
        var texto = proposta.id_proposta + " - " + proposta.descricao_proposta;
        return texto;
    }


    function returnDescricaoProjetoPeD(projeto) {
        var texto = projeto.id_projeto + " - " + projeto.descricao_projeto;
        return texto;
    }

    function returnDescricaoSolicitante(usuario) {
        try {
            var texto = "ID_Usuario: " + usuario.id_usuario + "\n Nome: " + usuario.nome_amigavel;
            return texto;
        } catch (_err) {
            console.log("erro ao retornar descrição do solicitante: " + _err);
            return "Dado invalido";
        }

    }


    function returnDescricaoSolicitacao(solicitacao) {
        var texto = "Solicitação: " + solicitacao.solicitacao + "\n Descrição: " + solicitacao.descricao;
        return texto;
    }


    function returnDescricaoCategoria(categoria) {
        var texto = '';
        if (categoria === 0) {
            texto = "Projeto 🗂️";
        }
        else if (categoria === 1) {
            texto = "Pesquisa & Desenvolvimento 💻";
        }
        else if (categoria === 2) {
            texto = "Serviços e Manutenção 🛠️";
        }
        else if (categoria === 1) {
            texto = "Renovar Estoque 🛒";
        }
        return texto;
    }

    const [openSnaRondaVirtual, setOpenSnaRondaVirtual] = useState(false);

    function handleOpenSnaRondaVirtual() {

        setOpenSnaRondaVirtual(true);

    }


    const handleCloseSnaRondaVirtual = () => {

        setOpenSnaRondaVirtual(false);
    };



    return (
        <div>
            <Grid  
                alignItems="center"
                justifyContent="center"
            >

                <Grid
                    item xs={12}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent={"center"}
                    style={{ marginRight: 10, marginLeft: 10 }}
                >


                    <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                        <AppBar position="static" >

                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"


                            >
                                <Tab label="Dados" style={{ backgroundColor: '#1C1C1C' }} />
                                <Tab label="Imagens" style={{ backgroundColor: 'green' }} />
                                <Tab label="Vídeos" style={{ backgroundColor: 'green' }} />
                                <Tab label="Documentos" style={{ backgroundColor: 'green' }} />

                            </Tabs>
                        </AppBar>

                    </Box>
                </Grid>

                <SwipeableViews
                    index={value}
                    onChangeIndex={handleChangeIndex}
                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                >


                    <Grid
                        style={{ paddingTop: 30 }}
                        container
                        direction="row"
                        item xs={12}
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                        value={value} index={0}
                    >



                        {projetoSelecionado &&
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={6}
                                style={{ marginTop: 20 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                >

                                    <TextField
                                        variant="standard"
                                        name="projeto"
                                        label="Projeto"
                                        id="projeto"
                                        value={returnDescricaoProjeto(projetoSelecionado)}
                                        fullWidth
                                        multiline
                                        minRows={1}
                                    />
                                </Grid>
                            </Grid>
                        }


                        {propostaSelecionada &&
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={6}
                                style={{ marginTop: 20 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                >

                                    <TextField
                                        variant="standard"
                                        name="proposta"
                                        label="Proposta"
                                        id="proposta"
                                        value={returnDescricaoProposta(propostaSelecionada)}
                                        fullWidth
                                        multiline
                                        minRows={1}
                                    />
                                </Grid>
                            </Grid>
                        }


                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                            style={{ marginTop: 5 }}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                align="center"
                                item xs={6}
                            >

                                <TextField
                                    variant="standard"
                                    name="solicitacao"
                                    label="Solicitação"
                                    id="solicitacao"
                                    value={returnDescricaoSolicitacao(solicitacaoSelecionada)}
                                    fullWidth
                                    multiline
                                    minRows={1}
                                />
                            </Grid>
                        </Grid>

                        {solicitante &&

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12} sm={12} md={12} lg={4} xl={4}
                                style={{ marginTop: 5 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                >

                                    <Autocomplete
                                        id="select-ordem-solicitante"
                                        options={[]}
                                        disableCloseOnSelect
                                        getOptionLabel={(sol) => returnDescricaoSolicitante(sol).replace(/<br \/>/g, '\n')}
                                        value={solicitante}
                                        filterOptions={(options, { inputValue }) => {
                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                            return options.filter((solicitante) => {
                                                const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label=" Usuário Solicitador " multiline minRows={1} />
                                        )}
                                        renderOption={(option) => (
                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                        )}

                                    />
                                </Grid>
                            </Grid>
                        }


                        {solicitantesSelecionados && (

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"

                                item xs={12} sm={12} md={12} lg={4} xl={4}
                                style={{ margin: 5 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                >

                                    <Autocomplete
                                        id="select-procedimentos"
                                        options={[solicitantesSelecionados]}
                                        disableCloseOnSelect
                                        multiple
                                        getOptionLabel={(solicitante) => returnDescricaoSolicitante(solicitante).replace(/<br \/>/g, '\n')}
                                        value={solicitantesSelecionados}
                                        getOptionSelected={(option, value) => true}

                                        filterOptions={(options, { inputValue }) => {
                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                            return options.filter((solicitante) => {
                                                const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label=" Solicitantes " multiline minRows={1} />
                                        )}
                                        renderOption={(option) => (
                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                        )}

                                    />
                                </Grid>
                            </Grid>
                        )
                        }


                        {analista &&

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"

                                item xs={12} sm={12} md={12} lg={4} xl={4}
                                style={{ marginTop: 5 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                >

                                    <Autocomplete
                                        id="select-ordem-analista"
                                        options={[]}
                                        disableCloseOnSelect
                                        getOptionLabel={(sol) => returnDescricaoSolicitante(sol).replace(/<br \/>/g, '\n')}
                                        value={analista}
                                        filterOptions={(options, { inputValue }) => {
                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                            return options.filter((solicitante) => {
                                                const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label=" Usuário Analista " multiline minRows={1} />
                                        )}
                                        renderOption={(option) => (
                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                        )}

                                    />
                                </Grid>
                            </Grid>
                        }





                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                name="descricao"
                                label="Descrição"
                                id="descricao"
                                value={ordemServico.descricao_solicitacao}
                                // onChange={(e) => setDescricao(e.target.value)}
                                fullWidth
                                multiline
                                minRows={1}
                            />
                        </Grid>




                    </Grid>

                </SwipeableViews>

            </Grid>

            <Dialog open={openSnaRondaVirtual} onClose={handleCloseSnaRondaVirtual} PaperProps={{ style: { maxWidth: '90%', minWidth: '90%' } }}>
                <DialogContent>
                    <SNARonda
                        rondaVirtual={rondaVirtual}
                    />
                </DialogContent>
            </Dialog>

        </div >
    );
}


