// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
  height: 100vh;
}

.map-marker {
  margin-top: -30px;
}

.address {
  box-sizing: border-box;
  width: 240px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  position: absolute;
  left: 50%;
  margin-left: -120px;
  margin-top: 12px;
}

.addressField {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 100%;
  height: 18px;
  margin-bottom: 8px;
  padding: 0 12px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
}

@keyframes blink {
  50% {
      opacity: 0;
  }
}
.piscar {
  animation: blink 5s step-start infinite;
}


`, "",{"version":3,"sources":["webpack://./src/pages/painelAdmin/AreaTecnica/Monitoramento/components/CornetaComponent/MapPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,0CAA0C;EAC1C,kBAAkB;EAClB,wCAAwC;EACxC,eAAe;EACf,kBAAkB;EAClB,SAAS;EACT,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,wCAAwC;EACxC,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE;MACI,UAAU;EACd;AACF;AACA;EACE,uCAAuC;AACzC","sourcesContent":[".map {\r\n  height: 100vh;\r\n}\r\n\r\n.map-marker {\r\n  margin-top: -30px;\r\n}\r\n\r\n.address {\r\n  box-sizing: border-box;\r\n  width: 240px;\r\n  padding: 12px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  background-color: rgba(255, 255, 255, 0.8);\r\n  border-radius: 4px;\r\n  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);\r\n  font-size: 14px;\r\n  position: absolute;\r\n  left: 50%;\r\n  margin-left: -120px;\r\n  margin-top: 12px;\r\n}\r\n\r\n.addressField {\r\n  box-sizing: border-box;\r\n  border: 1px solid transparent;\r\n  width: 100%;\r\n  height: 18px;\r\n  margin-bottom: 8px;\r\n  padding: 0 12px;\r\n  border-radius: 4px;\r\n  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);\r\n  font-size: 14px;\r\n  outline: none;\r\n  text-overflow: ellipses;\r\n}\r\n\r\n@keyframes blink {\r\n  50% {\r\n      opacity: 0;\r\n  }\r\n}\r\n.piscar {\r\n  animation: blink 5s step-start infinite;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
