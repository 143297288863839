import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom";
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';



import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import { useParams } from "react-router-dom";



export default function CadastrarProcedimentoAvulso() {


    const [botaoClicado, setBotaoClicado] = useState(false);

    const [usuarios, setUsuarios] = useState([]);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
    const [tipoExecutor, setTipoExecutor] = useState(0);


    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };


    const { idOrdemServico } = useParams();
    const { tipoProcedimento } = useParams();
    const { telaRetorno } = useParams();

    const history = useHistory();


    async function funcaoSalvar() {

        let id_usuario_criador = Cookies.get("id_usuario");
        let id_usuario_executor = -1;
        try {

            console.log("cadastrar chamado");

            if (!procedimentosSelecionado) {
                setBotaoClicado(false);
                alert("Selecione o Procedimento!");
                return;
            }

            if (parseInt(tipoExecutor, 10) === 0) {
                id_usuario_executor = id_usuario_criador;
            } else {
                if (!usuarioSelecionado) {
                    setBotaoClicado(false);
                    alert("Selecione o Usuário que irá executar este procedimento!");
                    return;
                }else{
                    id_usuario_executor = usuarioSelecionado.id_usuario;
                }
            }

            if(id_usuario_executor <= 0){
                setBotaoClicado(false);
                alert("Erro interno!");
                return;
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/ordemservico/inserirprocedimento/' + idOrdemServico + "/" + procedimentosSelecionado.id_procedimento + "/" + id_usuario_criador + "/" + id_usuario_executor,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Procedimento Inserido");
                if (telaRetorno == 0) {
                    history.push({
                        pathname: "/gestao/administracao/ordensdeservicos/editar/" + idOrdemServico,
                    })
                } else if (telaRetorno == 1) {
                    history.push({
                        pathname: "/gestao/administracao/ordensdeservicos/gerenciartratamentoemcampo/" + idOrdemServico,
                    })
                }

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoClicado(false);
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }






    const [loading, setLoading] = useState(true);
    const [procedimentos, setProcedimentos] = useState([]);

    const [procedimentosSelecionado, setProcedimentoSelecionado] = useState(null);




    async function listarProcedimentos() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/procedimentos/listarporetapa/" + tipoProcedimento, {
                headers: headers
            }).then(function (response) {
                setProcedimentos(response.data)

                api.get("/v1/protected/user/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setUsuarios(response.data)
                    setLoading(false);


                });

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Procedimentos: " + _err)

        }

    }

    useEffect(() => {




        listarProcedimentos();


    }, []);


    function returnDescricaoProcedimento(procedimento) {
        var texto = "Nome: " + procedimento.nome + "\n Descrição: " + procedimento.descricao_geral +
         (procedimento.obrigatorio === 1 ? "\nObrigatório" : "\nOpcional") + 
         "\nEtapa: " + (procedimento.etapa === 1 ? 'Tratamento Remoto' : procedimento.etapa === 2 ? 'Ánalise Prévia' : 'Tratamento em Campo')

        return texto;
    }


    function handleSetProcedimento(procedimento) {
        setProcedimentoSelecionado(procedimento);
    }


    function returnDescricaoUsuario(usuario) {
        var texto = "ID_Usuario: " + usuario.id_usuario + "\nNome: " + usuario.nome_amigavel + "\nRegiões de Atuação: " + usuario.regiao_cobertura;

        var dados_colaborador = "";

        if (usuario.tipo_associacao === 0) {
            //colaborador
            dados_colaborador += ("\nDepartamentos: " + usuario.colaborador.departamentos.map(departamento => departamento.nome).join(", "));
            dados_colaborador += ("\nCargos: " + usuario.colaborador.cargos.map(cargo => cargo.nome).join(", "));
            dados_colaborador += ("\nFunções: " + usuario.colaborador.funcoes.map(funcao => funcao.nome).join(", "));


        }

        texto += ("\n" + dados_colaborador);


        return texto;
    }


    function handleUsuario(user) {
        setUsuarioSelecionado(user);
    }



    return (
        <div>
            <NavegadorGestao
                Gestao={'underline'}
                idOrdemServico={'orange'}
                corFundo={'white'} travado={true} corTexto={'black'}
            />


            <Container style={{ paddingTop: 5 }} >
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >


                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={23}
                            >
                                <Button style={{ margin: 5 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                    disabled={botaoClicado}
                                > Salvar  </Button>
                            </Grid>


                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ marginTop: 5 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    align="center"
                                    item xs={6}
                                >

                                    <Autocomplete
                                        id="select-procedimentos"
                                        options={procedimentos}
                                        disableCloseOnSelect
                                        getOptionLabel={(procedimento) => returnDescricaoProcedimento(procedimento).replace(/<br \/>/g, '\n')}
                                        value={procedimentosSelecionado}
                                        onChange={(e, v) => handleSetProcedimento(v)}
                                        filterOptions={(options, { inputValue }) => {
                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                            return options.filter((procedimento) => {
                                                const cotacaoText = returnDescricaoProcedimento(procedimento).toLowerCase();
                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label=" Procedimentos " multiline rows={3} />
                                        )}
                                        renderOption={(option) => (
                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoProcedimento(option) }} />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}
                                container
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="Tipo-selector-label">Executor</InputLabel>
                                        <Select
                                            labelId="Tipo-selector-label"
                                            id="Tipo-selector"
                                            value={tipoExecutor}
                                            onChange={(e) => setTipoExecutor(e.target.value)}
                                            label="Executor"
                                        >
                                            <MenuItem value={0}>Sou o executor</MenuItem>
                                            <MenuItem value={10}>Usuário do Sistema</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {
                                parseInt(tipoExecutor,10) !== 0 &&
                                    (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 10 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5}
                                            >

                                                <Autocomplete
                                                    id="select-procedimento-usuario-designado"
                                                    options={usuarios}

                                                    getOptionLabel={(usuario) => returnDescricaoUsuario(usuario).replace(/<br \/>/g, '\n')}
                                                    value={usuarioSelecionado}
                                                    onChange={(e, v) => handleUsuario(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((solicitante) => {
                                                            const cotacaoText = returnDescricaoUsuario(solicitante).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Usuário Executor " multiline minRows={1} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoUsuario(option) }} />
                                                    )}

                                                />
                                            </Grid>
                                        </Grid>

                                    )
                            }




                        </Grid>


                    </React.Fragment>
                }

            </Container>

        </div >
    );
}


