import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@mui/material/Tooltip';
import CallIcon from "@mui/icons-material/Call"; // Ícone de telefone do Material UI

import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';
import SensorsIcon from "@mui/icons-material/Sensors"; // Ícone para Zonas
import AccountTreeIcon from "@mui/icons-material/AccountTree"; // Ícone para Partições
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Stomp } from '@stomp/stompjs';

import Close from '@material-ui/icons/Close'
import { Snackbar } from '@material-ui/core';
import { Menu } from '@mui/material';
import Hls from "hls.js";

import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import { Card, CardContent } from "@mui/material";

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Modal from "react-modal";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';



import {
    MaterialReactTable
} from 'material-react-table';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import BuildIcon from '@mui/icons-material/Build';
import Switch from '@mui/material/Switch';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/Power';



import api from '../../../../../../services/api';


import NavegadorGestao from "../../../../../../components/NavBarGestao";


import switch_off from '../../../../../../assets/imgs/switch-off.png';
import switch_on from '../../../../../../assets/imgs/switch-on.png';


import mini_icone_carro from '../../../../../../assets/imgs/mini_icone_carro.png';

import mini_icone_moto from '../../../../../../assets/imgs/mini_icone_moto.png';

import icone_origem from '../../../../../../assets/imgs/icone_origem.png';
import icone_destino from '../../../../../../assets/imgs/icone_destino.png';
import icone_parada from '../../../../../../assets/imgs/icone_parada.png';

import icone_call from '../../../../../../assets/imgs/icone_call.png';


import cabo_conectado from '../../../../../../assets/imgs/cabo_conectado.png';
import cabo_desconectado from '../../../../../../assets/imgs/cabo_desconectado.png';
import cabo_internet from '../../../../../../assets/imgs/cabo_internet.png';
import cabo_sem_internet from '../../../../../../assets/imgs/cabo_sem_internet.png';

import wifi_conectado from '../../../../../../assets/imgs/cabo_conectado.png';

import modem_conectado from '../../../../../../assets/imgs/modem_conectado.png';
import modem_desconectado from '../../../../../../assets/imgs/modem_desconectado.png';
import modem_internet from '../../../../../../assets/imgs/cabo_internet.png';
import modem_sem_internet from '../../../../../../assets/imgs/cabo_sem_internet.png';

import icone_pgm from '../../../../../../assets/imgs/icone_pgm.png';
import icone_corneta from '../../../../../../assets/imgs/corneta.png';

import icone_play from '../../../../../../assets/imgs/play.png';


import icone_camera from '../../../../../../assets/imgs/icone_camera.png';



import icone_medidor from '../../../../../../assets/imgs/icone_medidor.png';

import icone_subestacao from '../../../../../../assets/imgs/icone_subestacao.png';
import icone_casa from '../../../../../../assets/imgs/icone_casa.png';
import icone_loja from '../../../../../../assets/imgs/icone_loja.png';
import icone_central from '../../../../../../assets/imgs/icone_central.png';

import icone_instalacoes from '../../../../../../assets/imgs/icone_instalacoes.png';
import icone_equipamentos from '../../../../../../assets/imgs/icone_equipamentos.png';

import icone_armar_stay from '../../../../../../assets/imgs/armar_stay.png';
import icone_armar_away from '../../../../../../assets/imgs/armar_away.png';
import icone_desarmar from '../../../../../../assets/imgs/desarmar.png';
import globalConfig from '../../../../../../configs/globalConfigs';


import './App.css'; // ou o caminho correto para o seu arquivo CSS



import {
    GoogleMap,
    Marker,
    LoadScript,
    OverlayView,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
    DistanceMatrixService,
    MaxZoomService, Circle, Polygon, Polyline
} from "@react-google-maps/api";


import alarmeAudio from '../../../../../../assets/mp3/alarme.mp3';




export default function CamerasComponent({ flag, cameras }) {

   
 


    const [imageSrc, setImageSrc] = useState('');  // Aqui vai o src da imagem carregada
    // Função para carregar a imagem com o token JWT
    const fetchImage = async (url) => {
        const token = Cookies.get('token');
        const imageUrl = url.replaceAll("/home/wwgrup/disponibilidade", "https://gruporosinetos.com/disponibilidade/midia");

        try {
            // Fazendo a requisição com 'axios'
            const response = await api.get(imageUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob', // Garantir que a resposta seja tratada como 'blob'
            });

            // Verifique a resposta
            console.log(response);

            // A resposta agora contém o 'blob' corretamente
            const blob = response.data; // Aqui, o 'data' do axios já é o 'blob'
            const objectURL = URL.createObjectURL(blob); // Criar URL do blob
            setImageSrc(objectURL); // Atualiza o 'src' da imagem
        } catch (error) {
            console.error('Erro ao carregar a imagem', error);
            return null;
        }
    };


    return (
        <div>
        <Grid
            container
            direction="row"
            justifyContent="center"
            align="center"
            style={{ border: flag === 1 ? '1px solid black' : null, padding: 1, margin: flag === 1 ? 12 : 0 }}

        >
             <Grid
                item
                xs={12} 
                container
                direction="row"
                justifyContent="center"
                align="center"
                >
                      <Grid
                          item
                           xs={11}
                           justifyContent="flex-end"
                align="flex-end"
                           >
                <span style={{ fontSize: 16, fontWeight:'bold' , padding: 10 }}>
                {cameras.length + " câmeras"}
                </span>
                </Grid>
               
            </Grid>
            {Array.isArray(cameras) &&
    cameras
        .filter(equipamento => typeof equipamento === "object" && equipamento !== null && equipamento.ip)
        .sort((a, b) => (a.ip ?? "").localeCompare(b.ip ?? "", undefined, { numeric: true }))
        .map((equipamento, index) => {
                if (equipamento.tipo_equipamento_area_tecnica === 80) {
                    return (
                        <Tooltip
                            title={
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        minHeight: '300px',
                                        minWidth: '400px',
                                        border: '1px solid black',
                                        color: 'black',
                                    }}
                                >

                                    <img
                                        alt="icone"
                                        style={{
                                            textAlign: 'center',
                                        }}
                                        src={imageSrc || ''}  // Inicia com src vazio
                                    />

                                    {equipamento.registro_disponibilidade &&

                                        (
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                xs={12}
                                                style={{
                                                    padding: "2px",
                                                    margin: "2px",
                                                }}
                                            >
                                                  <Grid
                                                    item
                                                    xs={12} >
                                                    <span style={{ fontSize: 14 }}>
                                                        Identificação:{" "}
                                                        {equipamento.nome_mapa}
                                                    </span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12} >
                                                    <span style={{ fontSize: 14 }}>
                                                        Registrado em{" "}
                                                        {moment(equipamento.registro_disponibilidade.data_hora_registro, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY [às] HH:mm:ss")}
                                                    </span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12} >
                                                    <span style={{ fontSize: 14 }}>
                                                        ID:{" "}
                                                        {equipamento.id_equipamento}
                                                    </span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12} >
                                                    <span style={{ fontSize: 14 }}>
                                                        IP:{" "}
                                                        {equipamento.registro_disponibilidade.ip}
                                                    </span>
                                                </Grid>
                                               
                                            </Grid>
                                        )
                                    }







                                </div>
                            }
                            placement="top"
                            arrow
                            onOpen={() => {
                                const imageUrl = equipamento?.registro_disponibilidade?.caminho_imagem;
                            
                                if (imageUrl && typeof imageUrl === 'string' && imageUrl.trim() !== '') {
                                    fetchImage(imageUrl);
                                } else {
                                    console.warn('Caminho da imagem inválido:', imageUrl);
                                }
                            }}
                        >
                            <Grid
                                item
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                style={{
                                    border: "1px solid red",
                                    padding: "1px",
                                    margin: "1px",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    overflow: "hidden", // Evita margens extras
                                }}
                            >
                                <img
                                    alt="icone"
                                    style={{
                                        textAlign: 'center',
                                        width: '30px', // Define a largura da imagem
                                        height: '30px', // Define a altura da imagem
                                    }}
                                    src={icone_camera}
                                />
                                <span style={{fontSize:12}}>{equipamento.nome_mapa}</span>
                                <br></br>
                                <span style={{ fontSize: 12 }}>
                                                        IP:{" "}
                                                        {equipamento.registro_disponibilidade.ip}
                                                    </span>
                            </Grid>
                        </Tooltip>
                    );
                }
                return null;
            })}

        </Grid>

   

        </div>
    );


}

