import React from 'react'
import './styles/bulma.scss';
import './styles/global.scss';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './pages/home';
import Status from './pages/Status';
import Localizacao from './pages/localizacao';
import LGPD from './pages/LGPD';
import About from './pages/About';
import Blog from './pages/Blog';
import VizualizarNoticia from './pages/painelAdmin/AreaTecnica/Noticias/vizualizarNoticia';
import Contato from './pages/Contato';
import Produtos from './pages/Produtos';
import Solucoes from './pages/Solucoes';
import AutenticarDoc from './pages/AutenticarDoc';
import Pagamento from './pages/Pagamento';
import PageNotFound from './pages/PageNotFound';


import Login from './pages/Login';





//painel do administrador
import ContaAdmin from './pages/painelAdmin/MinhaConta';

//painel da Area Central de Gestao
import AreaGestao from './pages/painelAdmin/AreaGestao/painel';




//painel dos Recursos Administrativos
import RecursosAdministrativos from './pages/painelAdmin/AreaGestao/ModuloAdministracao/painel';

import Unidades from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Unidades'
import CadastroUnidade from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Unidades/cadastroUnidade';
import AlterarUnidade from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Unidades/alterarUnidade';



import Clientes from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Clientes'
import CadastroCliente from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Clientes/cadastroCliente';
import GerenciarCiente from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Clientes/GerenciarCliente/index';
import GruposClientes from './pages/painelAdmin/AreaGestao/ModuloAdministracao/GruposClientes'
import GruposControle from './pages/painelAdmin/AreaGestao/ModuloAdministracao/GruposControle'
import CadastrarGrupoControle from './pages/painelAdmin/AreaGestao/ModuloAdministracao/GruposControle/cadastrar'


import CadastroClienteContato from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Clientes/GerenciarCliente/cadastrarContato';
import CadastroClienteEditarContato from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Clientes/GerenciarCliente/editarContato';

import CadastroConta from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Clientes/GerenciarCliente/Conta/cadastroConta';
import GerenciarInstalacao from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Clientes/GerenciarCliente/Conta/gerenciarConta';
import CadastroInstalacaoContato from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Clientes/GerenciarCliente/Conta/cadastroContato';
import CadastroInstalacaoEditarContato from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Clientes/GerenciarCliente/Conta/editarContato';

import AlterarCliente from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Clientes/alterarCliente';

import Fornecedores from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Fornecedores'
import CadastroFornecedor from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Fornecedores/cadastroFornecedor';
import AlterarFornecedor from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Fornecedores/alterarFornecedor'
import GerenciarFornecedor from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Fornecedores/GerenciarFornecedor/index';
import CadastroFornecedorContato from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Fornecedores/GerenciarFornecedor/cadastrarContato';
import CadastroFornecedorEditarContato from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Fornecedores/GerenciarFornecedor/editarContato';

import CadastroFornecedorContaBancaria from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Fornecedores/GerenciarFornecedor/cadastrarContaBancaria';
import CadastroFornecedorEditarContaBancaria from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Fornecedores/GerenciarFornecedor/editarContaBancaria';



import Usuarios from './pages/painelAdmin/AreaAdministracao//Usuarios'
import CadastroUsuario from './pages/painelAdmin/AreaAdministracao//Usuarios/cadastrarUsuario';
import AlterarUsuario from './pages/painelAdmin/AreaAdministracao//Usuarios/alterarUsuario';
import GerenciarUsuario from './pages/painelAdmin/AreaAdministracao//Usuarios/gerenciar';


import Configuracoes from './pages/painelAdmin/AreaAdministracao/Configuracoes'



import OrdensDeCompras from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeCompras';
import CadastrarOrdemDeCompra from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeCompras/cadastrar';
import EditarOrdemDeCompra from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeCompras/editar';
import CadastrarNovoItemPreCotacao from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeCompras/editarNovoItem';

import CadastrarPreCotacao from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeCompras/cadastrarCotacao';
import EditarPreCotacao from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeCompras/editarCotacao';
import EditarCompra from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeCompras/editarCompra';


import AtualizarProcedimentoExecucaoOrdemCompra from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeCompras/atualizarProcedimentoExecucao';

import EditarListaRequerimentos from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeCompras/editarListaRequerimentos';

import OrdensDeServicos from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeServicos';
import CadastrarOrdemDeServico from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeServicos/cadastrar';
import EditarOrdemDeServico from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeServicos/editar';
import AtualizarProcedimentoExecucao from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeServicos/atualizarProcedimentoExecucao';
import VizualizarProcedimentoExecucao from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeServicos/vizualizarProcedimentoExecucao';




import CadastrarProcedimentoAvulso from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeServicos/cadastrarProcedimentoAvulso';


import GerenciarTratamentoEmCampo from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeServicos/gerenciarTratamentoEmCampo';
import VizualizarProcedimentoExecucaoTratamentoEmCampo from './pages/painelAdmin/AreaGestao/ModuloAdministracao/OrdensDeServicos/vizualizarProcedimentoExecucaoTratamentoEmCampo';


import PeD from './pages/painelAdmin/AreaGestao/ModuloAdministracao/PeD';
import CadastrarPeD from './pages/painelAdmin/AreaGestao/ModuloAdministracao/PeD/cadastrar';
import EditarPeD from './pages/painelAdmin/AreaGestao/ModuloAdministracao/PeD/editar';



import Projetos from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Projetos';
import CadastrarProjeto from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Projetos/cadastrar';
import EditarProjeto from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Projetos/editar';

import CadastrarProposta from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Projetos/Propostas/cadastrarProposta';
import EditarProposta from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Projetos/Propostas/editarProposta';



import CadastrarEquipamento from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Equipamentos/cadastrar';
import EditarEquipamento from './pages/painelAdmin/AreaGestao/ModuloAdministracao/Equipamentos/editar';





//painel dos Recursos RecursosOperacionais
import RecursosOperacionais from './pages/painelAdmin/AreaGestao/ModuloOperacional/painel';



//Modulo Almoxarifado
import ModuloAlmoxarifado from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/painel';


import EntradaEstoque from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/OperacaoEntradaEstoque/index';
import CadastroEntradaEstoque from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/OperacaoEntradaEstoque/cadastrar';
import GerenciarEntradaEstoque from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/OperacaoEntradaEstoque/gerenciar';


import Estoque from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/Estoque';
import GerenciarItemEstoque from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/Estoque/gerenciarItem';


import Armazenamento from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/Armazenamento';
import CadastrarArmazenamento from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/Armazenamento/cadastrar';




import Insumos from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/Insumos';
import CadastroInsumo from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/Insumos/cadastrar';
import EditarInsumo from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/Insumos/editar';

import Cotacoes from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/Cotacoes';
import CadastroCotacao from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/Cotacoes/cadastrar';
import EditarCotacao from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloAlmoxarifado/Cotacoes/editar';

import Equipes from './pages/painelAdmin/AreaGestao/ModuloOperacional/Equipes/index'
import CadastrarEquipe from './pages/painelAdmin/AreaGestao/ModuloOperacional/Equipes/cadastrar'


import Agendamentos from './pages/painelAdmin/AreaGestao/ModuloOperacional/Agendamentos/index';
import CadastrarAgendamento from './pages/painelAdmin/AreaGestao/ModuloOperacional/Agendamentos/cadastrar';


//Modulo Frota
import ModuloFrota from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloFrota/painel';

import CadastrarVeiculo from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloFrota/Veiculos/cadastrar';
import Veiculos from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloFrota/Veiculos/index';


import CadastrarRota from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloFrota/Rotas/cadastrarRota';
import Rotas from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloFrota/Rotas/index';
import VizualizarRota from './pages/painelAdmin/AreaGestao/ModuloOperacional/ModuloFrota/Rotas/vizualizarRota';



import Notificacoes from './pages/painelAdmin/AreaGestao/ModuloOperacional/Notificacoes/index'
import CadastrarNotificacao from './pages/painelAdmin/AreaGestao/ModuloOperacional/Notificacoes/cadastrar'

import RondaVirtual from './pages/painelAdmin/AreaGestao/ModuloOperacional/RondaVirtual/index'
import CadastrarRondaVirtual from './pages/painelAdmin/AreaGestao/ModuloOperacional/RondaVirtual/cadastrar'
import GerenciarRondaVirtual from './pages/painelAdmin/AreaGestao/ModuloOperacional/RondaVirtual/gerenciar'
import AtualizarProcedimentoExecucaoRondaVirtual from './pages/painelAdmin/AreaGestao/ModuloOperacional/RondaVirtual/atualizarProcedimentoExecucao';


import TipoOcorrencias from './pages/painelAdmin/AreaGestao/ModuloOperacional/Notificacoes/TipoOcorrencias/index'
import CadastrarTipoOcorrencia from './pages/painelAdmin/AreaGestao/ModuloOperacional/Notificacoes/TipoOcorrencias/cadastrar'



import Procedimentos from './pages/painelAdmin/AreaGestao/ModuloOperacional/Procedimentos/index'
import CadastrarProcedimento from './pages/painelAdmin/AreaGestao/ModuloOperacional/Procedimentos/cadastrar'

import VizualizarProcedimento from './pages/painelAdmin/AreaGestao/ModuloOperacional/Procedimentos/vizualizar'

import Solicitacoes from './pages/painelAdmin/AreaGestao/ModuloOperacional/Solicitacoes/index'
import CadastrarSolicitacao from './pages/painelAdmin/AreaGestao/ModuloOperacional/Solicitacoes/cadastrar'
import EditarSolicitacao from './pages/painelAdmin/AreaGestao/ModuloOperacional/Solicitacoes/editar'



//financas


import RecursosFinanceiros from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/painel';

import MetodosPagamento from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/MetodosPagamento/index';
import CadastrarMetodoPagamento from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/MetodosPagamento/cadastrar';

import InstituicaoBancaria from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/InstituicaoBancaria/index';
import CadastrarInstituicaoBancaria from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/InstituicaoBancaria/cadastrar';

import Lancamento from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/Lancamento/index';
import CadastrarLancamento from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/Lancamento/cadastrar';
import GerenciarLancamento from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/Lancamento/GerenciarLancamento/index';


import CondicoesPagamento from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/CondicoesPagamento/index';
import CadastrarCondicaoPagamento from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/CondicoesPagamento/cadastrar';

import Contas from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/Contas/index';
import CadastrarConta from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/Contas/cadastrar';

import GrupoContas from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/GrupoContas/index';
import CadastrarGrupoContas from './pages/painelAdmin/AreaGestao/ModuloFinanceiro/GrupoContas/cadastrar';



//painel da Area Tecnica Central
import MonitoramentoMapa from './pages/painelAdmin/AreaTecnica/Monitoramento/Mapa';
import MonitoramentoDashboard from './pages/painelAdmin/AreaTecnica/Monitoramento/Dashboard';
import MonitoramentoMosaico from './pages/painelAdmin/AreaTecnica/Monitoramento/Mosaico';

import Equipamentos from './pages/painelAdmin/AreaTecnica/Equipamentos';



import Logs from './pages/painelAdmin/AreaTecnica/Logs';

import Cartoes from './pages/painelAdmin/AreaTecnica/Cartoes';
import CadastroCartao from './pages/painelAdmin/AreaTecnica/Cartoes/cadastrarCartao';



import Receptores from './pages/painelAdmin/AreaTecnica/Receptores';
import CadastroReceptor from './pages/painelAdmin/AreaTecnica/Receptores/cadastroReceptor';
import AlterarReceptor from './pages/painelAdmin/AreaTecnica/Receptores/alterarReceptor';
import GerenciarReceptor from './pages/painelAdmin/AreaTecnica/Receptores/gerenciarReceptor';
import CadastroZona from './pages/painelAdmin/AreaTecnica/Receptores/cadastroZona';
import ConfigurarZona from './pages/painelAdmin/AreaTecnica/Receptores/configurarZona';


import Transmissores from './pages/painelAdmin/AreaTecnica/Transmissores';
import CadastroTransmissor from './pages/painelAdmin/AreaTecnica/Transmissores/cadastroTransmissor';
import AlterarTransmissor from './pages/painelAdmin/AreaTecnica/Transmissores/alterarTransmissor';
import GerenciarTransmissor from './pages/painelAdmin/AreaTecnica/Transmissores/gerenciarTransmissor';

import VisaoGeralTransmissores from './pages/painelAdmin/AreaTecnica/Transmissores/visaoGeralTransmissores';



import ControladoresAcesso from './pages/painelAdmin/AreaTecnica/ControladoresAcesso';
import CadastroControladorAcesso from './pages/painelAdmin/AreaTecnica/ControladoresAcesso/cadastroControladorAcesso';
import AlterarControladorAcesso from './pages/painelAdmin/AreaTecnica/ControladoresAcesso/alterarControladorAcesso';
import ConfigurarControladorAcesso from './pages/painelAdmin/AreaTecnica/ControladoresAcesso/configurarControladorAcesso';
import Tokens from './pages/painelAdmin/AreaTecnica/ControladoresAcesso/tokens';


//modulos sonoros
import ModulosSonoros from './pages/painelAdmin/AreaTecnica/ModulosSonoros';
import CadastrarModuloSonoro from './pages/painelAdmin/AreaTecnica/ModulosSonoros/cadastrarModuloSonoro';
import AlterarModuloSonoro from './pages/painelAdmin/AreaTecnica/ModulosSonoros/alterarModuloSonoro';
import GerenciarModuloSonoro from './pages/painelAdmin/AreaTecnica/ModulosSonoros/gerenciarModuloSonoro';




import MinhasNoticias from './pages/painelAdmin/AreaTecnica/Noticias/index';
import CadastroNoticia from './pages/painelAdmin/AreaTecnica/Noticias/cadastroNoticia';
import AlterarNoticia from './pages/painelAdmin/AreaTecnica/Noticias/alterarNoticia';



//painel dos Recursos Humanos
import RecursosHumanos from './pages/painelAdmin/AreaGestao/ModuloRecursosHumanos/painel';


//Colaboradores
import ListarColaboradores from './pages/painelAdmin/AreaGestao/ModuloRecursosHumanos/Colaboradores/index'
import CadastrarColaborador from './pages/painelAdmin/AreaGestao/ModuloRecursosHumanos/Colaboradores/cadastrarColaborador'


import CadastrarCargo from './pages/painelAdmin/AreaGestao/ModuloRecursosHumanos/Cargos/cadastrarCargo';
import ListarCargos from './pages/painelAdmin/AreaGestao/ModuloRecursosHumanos/Cargos/index';

import ListarDepartamentos from './pages/painelAdmin/AreaGestao/ModuloRecursosHumanos/Departamentos/index';
import CadastrarDepartamento from './pages/painelAdmin/AreaGestao/ModuloRecursosHumanos/Departamentos/cadastrarDepartamento';


import ListarFuncoes from './pages/painelAdmin/AreaGestao/ModuloRecursosHumanos/Funcoes/index';
import CadastrarFuncao from './pages/painelAdmin/AreaGestao/ModuloRecursosHumanos/Funcoes/cadastrarFuncao';




//painel do fornecedor
import ContaFornecedor from './pages/painelFornecedor/MinhaConta';
import MeusClientes from './pages/painelFornecedor/Clientes'
import CadastroClienteFornecedor from './pages/painelFornecedor/Clientes/cadastroCliente';
import ConfiguracoesFornecedor from './pages/painelFornecedor/Configuracoes';

import VisaoGeralFornecedor from './pages/painelFornecedor/VisaoGeral';




import CartoesFornecedor from './pages/painelFornecedor/Cartoes';





//painel do cliente
import ContaCliente from './pages/painelCliente/MinhaConta';
import MonitoramentoCliente from './pages/painelCliente/Monitoramento/monitoramento'



//painel da Area de Administracao do Sistema
import AreaAdministracao from './pages/painelAdmin/AreaAdministracao/painel';






import Cookies from 'js-cookie';
import api from './services/api';


function isAuthenticated() {
  var token = Cookies.get('token');
  var refreshToken = Cookies.get('refreshToken');

  console.log(typeof token)
  if (token === null || token === undefined || token === "undefined") {
    // This means that there's no JWT and no user is logged in.
    api.defaults.headers.common.Authorization = null;
    console.log("token invalido na funcao isAuthenticated ");

    return false;
  } else {
    // This means that there's a JWT so someone must be logged in.
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    console.log("token valido: " + token);
    console.log("refreshtoken valido: " + refreshToken);
    return true;

  }
};

 

function checkRules(rule){
  if(
  rule.includes("SUPER_ADMIN") ||
      rule.includes("ADMIN_FINAN") ||
      rule.includes("ADMIN_TEC") ||
      rule.includes("TEC") ||
      rule.includes("MARKETING") ||
      rule.includes("ADMIN_OS") ||
      rule.includes("ADMIN_RH") ||
      rule.includes("ADMIN_TEC") ||
      rule.includes("TEC_OS")
  ) return true;
  return false;
}


const renderConta = () => {
  let rule = Cookies.get('regra');
  console.log("regra na funcao render: " + rule);

  if (isAuthenticated()) {
    if (checkRules(rule)) {
      return <ContaAdmin />
    } else {
      return <Login />
    }

  }
  else {
    console.login("token esta invalido!");
    return <Login />
  }
}

const ContaRoute = ({ ...rest }) => {
  console.log("na funcao ContaRoute");

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      renderConta()
    )} />
  );
};




const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  console.log("na funcao PublicRoute");

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest} render={props => (
      isAuthenticated() && restricted ?
        <Redirect to="/minhaconta" />
        : <Component {...props} />
    )} />
  );
};



const PrivateOnlySuperAdminAutorizationRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateOnlySuperAdminAutorizationRoute");
  let regra = Cookies.get('regra')
  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() && (regra.includes("SUPER_ADMIN")
    )  ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};

const PrivateAllAdminAutorizationRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateAllAdminAutorizationRoute");
  let regra = Cookies.get('regra');
  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
        (regra.includes("ADMIN")
        )
      ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};

const PrivateSuperAdminAndTecnicoAutorizationRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateSuperAdminAndTecnicoAutorizationRoute");
  let regra = Cookies.get('regra');

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
        (regra.includes("ADMIN") 
        )
      ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};




const PrivateOnlySuperAdminAndMonitorAutorizationRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateOnlySuperAdminAndAutorizationRoute");
  let regra = Cookies.get('regra')
  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() && ( regra.includes("SUPER_ADMIN") ||  regra.includes("MONITOR")  ) ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};



const PrivateFornecedorAutorizationRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateFornecedorAutorizationRoute");
  let regra = Cookies.get('regra');

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() && (regra === "ROLE_FORNECEDOR")) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};

const PrivateClienteAutorizationRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateClienteAutorizationRoute");
  let regra = Cookies.get('regra');

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() && (regra === "ROLE_CLIENTE")) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};



const PrivateAllUserAutorizationRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateAllUserAutorizationRoute");
   let regra = Cookies.get('regra');
  
  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
      checkRules(regra)
      ) ?
        <Component {...props} />
        : <Redirect to="/login" />
    )} />
  );
};


 

const PrivateSuperAdminAndAdminFinanRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateSuperAdminAndAdminFinanRoute");
  let regra = Cookies.get('regra');

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
        (regra.includes("SUPER_ADMIN") ||
          regra.includes("ADMIN_FINAN") 
        )
      ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};



const PrivateAdminUsersRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateAdminRHRoute");
  let regra = Cookies.get('regra');

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
        (regra.includes("SUPER_ADMIN") ||
          regra.includes("ADMIN_USERS") 
        )
      ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};


const PrivateAdminRHRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateAdminRHRoute");
  let regra = Cookies.get('regra');

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
        (regra.includes("SUPER_ADMIN") ||
          regra.includes("ADMIN_RH") 
        )
      ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};


const PrivateAdminOCRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateAdminOCRoute");
  let regra = Cookies.get('regra');

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
        (regra.includes("SUPER_ADMIN") ||
          regra.includes("ADMIN_OC") 
        )
      ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};

const PrivateAdminOSRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateAdminOSRoute");
  let regra = Cookies.get('regra');

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
        (regra.includes("SUPER_ADMIN") ||
          regra.includes("ADMIN_OS") 
        )
      ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};



const PrivateAdminTecRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivateAdminTecRoute");
  let regra = Cookies.get('regra');

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
        (regra.includes("SUPER_ADMIN") ||
          regra.includes("ADMIN_TEC") 
        )
      ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};




const PrivateAdminAndTecOSRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivatTecRoute");
  let regra = Cookies.get('regra')
  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
        (regra.includes("SUPER_ADMIN") || regra.includes("ADMIN_OS") ||
          regra.includes("TEC_OS")  
        )
      ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};


const PrivateTecRoute = ({ component: Component, ...rest }) => {
  console.log("na funcao PrivatTecRoute");
  let regra = Cookies.get('regra')
  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      (isAuthenticated() &&
        (regra.includes("SUPER_ADMIN") ||
          regra.includes("ADMIN_TEC")  || regra.includes("TEC")
        )
      ) ?
        <Component {...props} />
        : <Redirect to="/minhaconta" />
    )} />
  );
};

function App() {


  return (

    <BrowserRouter>

      <Switch>
        <PublicRoute restricted={false} component={Home} path="/" exact />
        <PublicRoute restricted={false} component={PageNotFound} path="/page-not-found" exact />

        <PublicRoute restricted={false} component={Localizacao} path="/localizacao" exact />
        <PublicRoute restricted={false} component={About} path="/sobre" exact />
        <PublicRoute restricted={false} component={Blog} path="/noticias/:categoria" exact />
        <PublicRoute restricted={false} component={Blog} path="/noticias/" exact />
        <PublicRoute restricted={false} component={AutenticarDoc} path="/autenticar" exact />
        <PublicRoute restricted={false} component={Pagamento} path="/pagamento" exact />

        <PublicRoute restricted={false} component={Contato} path="/contato" exact />
        <PublicRoute restricted={false} component={LGPD} path="/lgpd" exact />
        <PublicRoute restricted={false} component={Status} path="/status" exact />
        <PublicRoute restricted={false} component={Produtos} path="/Produtos" exact />
        <PublicRoute restricted={false} component={Solucoes} path="/solucoes" exact />

        <PublicRoute restricted={false} component={VizualizarNoticia} path="/noticias/:dia/:mes/:ano/:titulo/:idNoticia" exact />

        <PublicRoute restricted={true} component={Login} path="/login" exact />
        <PrivateAllUserAutorizationRoute component={ContaRoute} path="/minhaconta" exact />

        
        <PrivateOnlySuperAdminAutorizationRoute component={Logs} path="/logs/" exact />

        <PrivateOnlySuperAdminAutorizationRoute component={Cartoes} path="/cartoes/" exact />
        <PrivateOnlySuperAdminAutorizationRoute component={CadastroCartao} path="/cadastrarcartao/" exact />

        <PrivateOnlySuperAdminAutorizationRoute component={AreaGestao} path="/gestao" exact />


        <PrivateOnlySuperAdminAutorizationRoute component={MonitoramentoMapa} path="/tecnica/monitoramento/mapa" exact />
        <PrivateOnlySuperAdminAutorizationRoute component={Equipamentos} path="/tecnica/equipamentos" exact />
        <PrivateOnlySuperAdminAndMonitorAutorizationRoute component={MonitoramentoDashboard} path="/tecnica/monitoramento/dashboard/:idInst?/:pesquisa?" exact />
        <PrivateOnlySuperAdminAutorizationRoute component={MonitoramentoMosaico} path="/tecnica/monitoramento/mosaico" exact />


 
        <PrivateAdminUsersRoute component={Usuarios} path="/administracao/usuarios" exact />
        <PrivateAdminUsersRoute component={CadastroUsuario} path="/administracao/cadastrarusuario" exact />
        <PrivateAdminUsersRoute component={AlterarUsuario} path="/administracao/alterarusuario/:idUsuario" exact />
        <PrivateAdminUsersRoute component={GerenciarUsuario} path="/administracao/gerenciarusuario/:idUsuario" exact />

        <PrivateOnlySuperAdminAutorizationRoute component={Configuracoes} path="/administracao/configuracoes" exact />



        <PrivateAdminRHRoute component={RecursosHumanos} path="/gestao/rh/" exact />

        <PrivateAdminRHRoute component={ListarColaboradores} path="/gestao/rh/colaboradores/listar" exact />
        <PrivateAdminRHRoute component={CadastrarColaborador} path="/gestao/rh/colaboradores/cadastrar" exact />

        <PrivateAdminRHRoute component={ListarCargos} path="/gestao/rh/cargos/listar" exact />
        <PrivateAdminRHRoute component={CadastrarCargo} path="/gestao/rh/cargos/cadastrar" exact />
        <PrivateAdminRHRoute component={ListarDepartamentos} path="/gestao/rh/departamentos/listar" exact />
        <PrivateAdminRHRoute component={CadastrarDepartamento} path="/gestao/rh/departamentos/cadastrar" exact />
        <PrivateAdminRHRoute component={ListarFuncoes} path="/gestao/rh/funcoes/listar" exact />
        <PrivateAdminRHRoute component={CadastrarFuncao} path="/gestao/rh/funcoes/cadastrar" exact />






        <PrivateOnlySuperAdminAutorizationRoute component={RecursosAdministrativos} path="/gestao/administracao/" exact />

        <PrivateAdminTecRoute component={Unidades} path="/gestao/administracao/unidades/listar" exact />
        <PrivateAdminTecRoute component={CadastroUnidade} path="/gestao/administracao/unidades/cadastrarunidade/" exact />
        <PrivateAdminTecRoute component={AlterarUnidade} path="/gestao/administracao/unidades/alterarunidade/:idUnidade" exact />




        <PrivateAdminTecRoute component={Clientes} path="/gestao/administracao/clientes/listar" exact />
        <PrivateAdminTecRoute component={CadastroCliente} path="/gestao/administracao/clientes/cadastrarcliente/" exact />
        <PrivateAdminTecRoute component={AlterarCliente} path="/gestao/administracao/clientes/alterarcliente/:idCliente" exact />
        <PrivateAdminTecRoute component={GruposClientes} path="/gestao/administracao/clientes/gruposclientes/listar" exact />
        <PrivateAdminTecRoute component={GruposControle} path="/gestao/administracao/clientes/gruposcontrole/listar" exact />
        <PrivateAdminTecRoute component={CadastrarGrupoControle} path="/gestao/administracao/clientes/gruposcontrole/cadastrar" exact />




        <PrivateAdminTecRoute component={ModulosSonoros} path="/tecnica/equipamentos/modulossonoros" exact />
        <PrivateAdminTecRoute component={CadastrarModuloSonoro} path="/tecnica/equipamentos/modulossonoros/cadastrar" exact />
        <PrivateAdminTecRoute component={AlterarModuloSonoro} path="/tecnica/equipamentos/modulossonoros/alterar/:idModuloSonoro" exact />
        <PrivateAdminTecRoute component={GerenciarModuloSonoro} path="/tecnica/equipamentos/modulossonoros/gerenciar/:idModuloSonoro" exact />

        
        
        
        <PrivateAdminTecRoute component={CadastroClienteContato} path="/gestao/administracao/clientes/gerenciarcliente/:idCliente/cadastrarcontato" exact />
        <PrivateAdminTecRoute component={CadastroClienteEditarContato} path="/gestao/administracao/clientes/gerenciarcliente/:idCliente/editarcontato/:idContato" exact />

        
        <PrivateAdminTecRoute component={GerenciarCiente} path="/gestao/administracao/clientes/gerenciarcliente/:idCliente" exact />
        <PrivateAdminTecRoute component={CadastroConta} path="/gestao/administracao/clientes/gerenciarcliente/adicionarconta/:idCliente" exact />

        <PrivateAdminTecRoute component={GerenciarInstalacao} path="/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/:idInstalacao/:idCliente" exact />
        <PrivateAdminTecRoute component={CadastroInstalacaoContato} path="/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/:idInstalacao/:idCliente/cadastrarcontato" exact />
        <PrivateAdminTecRoute component={CadastroInstalacaoEditarContato} path="/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/:idInstalacao/:idCliente/editarcontato/:idContato" exact />




        <PrivateAdminOCRoute component={OrdensDeCompras} path="/gestao/administracao/ordensdecompras/listar" exact />
        <PrivateAdminOCRoute component={CadastrarOrdemDeCompra} path="/gestao/administracao/ordensdecompras/cadastrarordemcompra" exact />
        <PrivateAdminOCRoute component={EditarOrdemDeCompra} path="/gestao/administracao/ordensdecompras/editar/:idOrdemCompra" exact />

        <PrivateAdminOCRoute component={CadastrarPreCotacao} path="/gestao/administracao/ordensdecompras/editar/:idOrdemCompra/cadastrarcotacao" exact />
        <PrivateAdminOCRoute component={EditarPreCotacao} path="/gestao/administracao/ordensdecompras/editar/:idOrdemCompra/editarcotacao/:idPreCotacao" exact />
        <PrivateAdminOCRoute component={EditarCompra} path="/gestao/administracao/ordensdecompras/editar/:idOrdemCompra/editarcompra/:idPreCotacao" exact />

        <PrivateAdminOCRoute component={CadastrarNovoItemPreCotacao} path="/gestao/administracao/ordensdecompras/editar/:idOrdemCompra/editarcotacao/:idPreCotacao/inseriritemnacotacao" exact />

        <PrivateAdminOCRoute component={AtualizarProcedimentoExecucaoOrdemCompra} path="/gestao/administracao/ordensdecompras/:idOrdemCompra/atualizarprocedimentoexecucao/:idProcedimentoExecucao" exact />

        <PrivateAdminOCRoute component={EditarListaRequerimentos} path="/gestao/administracao/ordensdecompras/:idOrdemCompra/editarlistarequerimentos" exact />


        <PrivateAdminAndTecOSRoute component={OrdensDeServicos} path="/gestao/administracao/ordensdeservicos/listar" exact />
        <PrivateAdminAndTecOSRoute component={CadastrarOrdemDeServico} path="/gestao/administracao/ordensdeservicos/cadastrarordemservico" exact />
        <PrivateAdminAndTecOSRoute component={EditarOrdemDeServico} path="/gestao/administracao/ordensdeservicos/editar/:idOrdemServico" exact />
        <PrivateAdminAndTecOSRoute component={AtualizarProcedimentoExecucao} path="/gestao/administracao/ordensdeservicos/atualizarprocedimentoexecucao/:idProcedimentoExecucacao/ordemservico/:idOrdemServico/:telaRetorno/:idEntradaEstoque" exact />
        <PrivateAdminAndTecOSRoute component={VizualizarProcedimentoExecucao} path="/gestao/administracao/ordensdeservicos/vizualizarprocedimentoexecucao/:idProcedimentoExecucacao/ordemservico/:idOrdemServico" exact />


        <PrivateAdminAndTecOSRoute component={CadastrarProcedimentoAvulso} path="/gestao/administracao/ordensdeservicos/cadastrarprocedimentoavulso/:idOrdemServico/:tipoProcedimento/:telaRetorno" exact />

        <PrivateAdminAndTecOSRoute component={GerenciarTratamentoEmCampo} path="/gestao/administracao/ordensdeservicos/gerenciartratamentoemcampo/:idOrdemServico" exact />
        <PrivateAdminAndTecOSRoute component={VizualizarProcedimentoExecucaoTratamentoEmCampo} path="/gestao/administracao/ordensdeservicos/vizualizarprocedimentoexecucaotratamentoemcampo/:idProcedimentoExecucacao/ordemservico/:idOrdemServico" exact />

        <PrivateAdminTecRoute component={PeD} path="/gestao/administracao/ped/listar" exact />
        <PrivateAdminTecRoute component={CadastrarPeD} path="/gestao/administracao/ped/cadastrarprojeto" exact />
        <PrivateAdminTecRoute component={EditarPeD} path="/gestao/administracao/ped/editarprojeto/:idProjeto" exact />



        <PrivateAdminTecRoute component={Projetos} path="/gestao/administracao/projetos/listar" exact />
        <PrivateAdminTecRoute component={CadastrarProjeto} path="/gestao/administracao/projetos/cadastrarprojeto/:flagBack/:idCliente/:idInstalacao" exact />
        <PrivateAdminTecRoute component={EditarProjeto} path="/gestao/administracao/projetos/editaroprojeto/:idProjeto/:telaRetorno/:idInstalacao/:idCliente" exact />

        <PrivateAdminTecRoute component={CadastrarProposta} path="/gestao/administracao/projetos/editarprojeto/:idProjeto/proposta/cadastrarproposta" exact />
        <PrivateAdminTecRoute component={EditarProposta} path="/gestao/administracao/projetos/editarprojeto/:idProjeto/propostas/editarproposta/:idProposta/:telaRetorno/:idInstalacao/:idCliente" exact />

        <PrivateAdminTecRoute component={CadastrarEquipamento} path="/gestao/administracao/equipamentos/cadastrarequipamento/:flagBack/:idCliente/:idInstalacao" exact />
        <PrivateAdminTecRoute component={EditarEquipamento} path="/gestao/administracao/equipamentos/editarequipamento/:idEquipamento/:flagBack/:idInstalacao/:idCliente" exact />



        <PrivateAdminTecRoute component={RecursosOperacionais} path="/gestao/operacional/" exact />


        <PrivateAdminTecRoute component={ModuloAlmoxarifado} path="/gestao/operacional/almoxarifado" exact />


        <PrivateAdminTecRoute component={EntradaEstoque} path="/gestao/operacional/almoxarifado/entradaestoque/listar" exact />
        <PrivateAdminTecRoute component={CadastroEntradaEstoque} path="/gestao/operacional/almoxarifado/entradaestoque/cadastrarentradaestoque" exact />
        <PrivateAdminTecRoute component={GerenciarEntradaEstoque} path="/gestao/operacional/almoxarifado/entradaestoque/gerenciarentradaestoque/:idEntradaEstoque" exact />


        <PrivateAdminTecRoute component={Armazenamento} path="/gestao/operacional/almoxarifado/armazenamento/listar" exact />
        <PrivateAdminTecRoute component={CadastrarArmazenamento} path="/gestao/operacional/almoxarifado/armazenamento/cadastrararmazenamento" exact />


        <PrivateAdminTecRoute component={Estoque} path="/gestao/operacional/almoxarifado/estoque/listar" exact />
        <PrivateAdminTecRoute component={GerenciarItemEstoque} path="/gestao/operacional/almoxarifado/estoque/gerenciaritemestoque/:idItemEstoque" exact />

        


        <PrivateAdminTecRoute component={Fornecedores} path="/gestao/operacional/almoxarifado/fornecedores/listar/:tipoMenuRender" exact />
        <PrivateAdminTecRoute component={CadastroFornecedor} path="/gestao/operacional/almoxarifado/cadastrarfornecedor/:tipoMenuRender" exact />
        <PrivateAdminTecRoute component={AlterarFornecedor} path="/gestao/operacional/almoxarifado/alterarfornecedor/:tipoMenuRender/:idFornecedor" exact />

        <PrivateAdminTecRoute component={GerenciarFornecedor} path="/gestao/operacional/almoxarifado/fornecedores/gerenciarfornecedor/:idFornecedor" exact />

        <PrivateAdminTecRoute component={CadastroFornecedorContato} path="/gestao/operacional/almoxarifado/fornecedores/gerenciarfornecedor/:idFornecedor/cadastrarcontato" exact />
        <PrivateAdminTecRoute component={CadastroFornecedorEditarContato} path="/gestao/operacional/almoxarifado/fornecedores/gerenciarfornecedor/:idFornecedor/editarcontato/:idContato" exact />

        <PrivateAdminTecRoute component={CadastroFornecedorContaBancaria} path="/gestao/operacional/almoxarifado/fornecedores/gerenciarfornecedor/:idFornecedor/cadastrarcontabancaria" exact />
        <PrivateAdminTecRoute component={CadastroFornecedorEditarContaBancaria} path="/gestao/operacional/almoxarifado/fornecedores/gerenciarfornecedor/:idFornecedor/editarcontabancaria/:idContaBancaria" exact />


        <PrivateAdminTecRoute component={Insumos} path="/gestao/operacional/almoxarifado/insumos/listar" exact />
        <PrivateAdminTecRoute component={CadastroInsumo} path="/gestao/operacional/almoxarifado/insumos/cadastrarinsumo/:idInsumo/:idFornecedor" exact />
        <PrivateAdminTecRoute component={EditarInsumo} path="/gestao/operacional/almoxarifado/insumos/editarinsumo/:idInsumo" exact />

        <PrivateAdminTecRoute component={Cotacoes} path="/gestao/operacional/almoxarifado/cotacoes/listar/:idInsumo" exact />
        <PrivateAdminTecRoute component={CadastroCotacao} path="/gestao/operacional/almoxarifado/cotacoes/cadastrarcotacao/:idInsumo" exact />
        <PrivateAdminTecRoute component={EditarCotacao} path="/gestao/operacional/almoxarifado/cotacoes/editarcotacao/:idCotacao" exact />


        <PrivateAdminTecRoute component={Equipes} path="/gestao/operacional/equipes/listar" exact />
        <PrivateAdminTecRoute component={CadastrarEquipe} path="/gestao/operacional/equipes/cadastrar" exact />

        <PrivateAdminAndTecOSRoute component={Procedimentos} path="/gestao/operacional/procedimentos/listar" exact />
        <PrivateAdminAndTecOSRoute component={CadastrarProcedimento} path="/gestao/operacional/procedimentos/cadastrar" exact />
        <PrivateAdminAndTecOSRoute component={VizualizarProcedimento} path="/gestao/operacional/procedimentos/vizualizar/:idProcedimento" exact />

        <PrivateTecRoute component={Notificacoes} path="/gestao/operacional/notificacoes/listar" exact />
        <PrivateTecRoute component={CadastrarNotificacao} path="/gestao/operacional/notificacoes/cadastrar" exact />
    
        <PrivateOnlySuperAdminAutorizationRoute component={TipoOcorrencias} path="/gestao/operacional/notificacoes/tiposocorrencias/listar" exact />
        <PrivateOnlySuperAdminAutorizationRoute component={CadastrarTipoOcorrencia} path="/gestao/operacional/notificacoes/tiposocorrencias/cadastrar" exact />
    

        <PrivateAdminAndTecOSRoute component={Agendamentos} path="/gestao/operacional/agendamentos/listar" exact />
        <PrivateAdminAndTecOSRoute component={CadastrarAgendamento} path="/gestao/operacional/agendamentos/cadastrar/:tipoCadastro/:idOrdemServico/:idProcedimentoExecucao" exact />


        <PrivateTecRoute component={RondaVirtual} path="/gestao/operacional/rondavirtual/listar" exact />
        <PrivateTecRoute component={CadastrarRondaVirtual} path="/gestao/operacional/rondavirtual/cadastrar" exact />
        <PrivateTecRoute component={GerenciarRondaVirtual} path="/gestao/operacional/rondavirtual/gerenciar/:idRondaVirtual" exact />
        <PrivateTecRoute component={AtualizarProcedimentoExecucaoRondaVirtual} path="/gestao/operacional/rondavirtual/atualizarprocedimentoexecucao/:idProcedimentoExecucacao/rondavirtual/:idRondaVirtual/:flag" exact />



        <PrivateOnlySuperAdminAutorizationRoute component={Solicitacoes} path="/gestao/operacional/solicitacoes/listar" exact />
        <PrivateOnlySuperAdminAutorizationRoute component={CadastrarSolicitacao} path="/gestao/operacional/solicitacoes/cadastrar" exact />
        <PrivateOnlySuperAdminAutorizationRoute component={EditarSolicitacao} path="/gestao/operacional/solicitacoes/editar/:idSolicitacao" exact />




        <PrivateOnlySuperAdminAutorizationRoute component={ModuloFrota} path="/gestao/operacional/frota" exact />
        <PrivateOnlySuperAdminAutorizationRoute component={Veiculos} path="/gestao/operacional/frota/veiculos/listar" exact />
        <PrivateOnlySuperAdminAutorizationRoute component={CadastrarVeiculo} path="/gestao/operacional/frota/veiculos/cadastrarveiculo" exact />

        <PrivateOnlySuperAdminAutorizationRoute component={Rotas} path="/gestao/operacional/frota/rotas/listar" exact />
        <PrivateOnlySuperAdminAutorizationRoute component={CadastrarRota} path="/gestao/operacional/frota/rotas/cadastrarrota/:tipoCadastro/:idOrdemServico/:idProcedimentoExecucao" exact />
        <PrivateOnlySuperAdminAutorizationRoute component={VizualizarRota} path="/gestao/operacional/frota/rotas/vizualizarrota/:idRota" exact />



        <PrivateAllAdminAutorizationRoute component={MinhasNoticias} path="/minhasnoticias/" exact />
        <PrivateAllAdminAutorizationRoute component={CadastroNoticia} path="/cadastrarnoticia/" exact />
        <PrivateAllAdminAutorizationRoute component={AlterarNoticia} path="/alterarnoticia/:idNoticia" exact />


        <PrivateAllAdminAutorizationRoute component={AreaAdministracao} path="/administracao/" exact />




        <PrivateSuperAdminAndAdminFinanRoute component={RecursosFinanceiros} path="/gestao/financas/" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={MetodosPagamento} path="/gestao/financas/metodospagamento/listar" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={CadastrarMetodoPagamento} path="/gestao/financas/metodospagamento/cadastrar" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={CondicoesPagamento} path="/gestao/financas/condicoespagamento/listar" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={CadastrarCondicaoPagamento} path="/gestao/financas/condicoespagamento/cadastrar" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={GrupoContas} path="/gestao/financas/grupocontas/listar" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={CadastrarGrupoContas} path="/gestao/financas/grupocontas/cadastrar" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={Contas} path="/gestao/financas/contas/listar" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={CadastrarConta} path="/gestao/financas/contas/cadastrar" exact />

        <PrivateSuperAdminAndAdminFinanRoute component={InstituicaoBancaria} path="/gestao/financas/instituicaobancaria/listar" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={CadastrarInstituicaoBancaria} path="/gestao/financas/instituicaobancaria/cadastrar" exact />
       

        <PrivateSuperAdminAndAdminFinanRoute component={Lancamento} path="/gestao/financas/lancamento/listar" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={CadastrarLancamento} path="/gestao/financas/lancamento/cadastrar" exact />
        <PrivateSuperAdminAndAdminFinanRoute component={GerenciarLancamento} path="/gestao/financas/lancamento/gerenciar/:idLancamento" exact />


      </Switch>

    </BrowserRouter>

  );
}

export default App;
