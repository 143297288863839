import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@mui/material';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Ícone para "finalizar"
import IconButton from '@mui/material/IconButton';
import { Radio, RadioGroup } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import TextField from '@material-ui/core/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import Cookies from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { styled } from '@material-ui/styles';
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckIcon from '@mui/icons-material/Check';
import { useDropzone } from 'react-dropzone';
import CancelIcon from '@mui/icons-material/Cancel';

import api from '../../../../../services/api';
import MenuRecursosAdministrativos from '../components/menu';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { AddToQueueOutlined, LaptopWindows } from '@material-ui/icons';


const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'whit',
        borderColor: 'red',
    },
}));


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },

    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'whit',
        borderColor: 'blue',
    },
}));




const dropzoneStyles = {
    border: '4px dashed #000000',
    borderRadius: '4px',
    padding: '2px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '2px 0',
};



export default function GerenciarRondaVirtual() {


    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [rondaVirtual, setRondaVirtual] = useState();
    const { idRondaVirtual } = useParams();

    const [inSalvamento, setInSalvamento] = useState(false);
    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);


    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/rondavirtual/listarporid/" + idRondaVirtual, {
                headers: headers
            }).then(function (response) {
                setRondaVirtual(response.data);
                setInstalacaoSelecionada(response.data.instalacao);
                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();


    }, []);




    function novoProcedimento(etapaProcedimento) {

        if (parseInt(rondaVirtual.usuario_executor.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {

            window.location.href = "/gestao/operacional/rondavirtual/cadastrarprocedimentoavulso/" + idRondaVirtual;

        } else {
            alert("Somente o usuário executor da Ronda Virtual pode executar esta ação!");
            return;
        }

    }




    const handleClickAtualizarProcedimento = (procedimento) => {

        if (parseInt(rondaVirtual.usuario_executor.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {

            window.location.href = "/gestao/operacional/rondavirtual/atualizarprocedimentoexecucao/" + procedimento.id_procedimento_execucao + "/rondavirtual/" + rondaVirtual.id_ronda_virtual + "/0";

        } else {
            alert("Somente o usuário executor da Ronda Virtual pode executar esta ação!");
            return;
        }

    };


    const handleClickVizualizarProcedimento = (procedimento) => {
        // window.open(`/gestao/operacional/rondavirtual/vizualizarprocedimentoexecucao/${id}`, '_blank');
        window.location.href = "/gestao/operacional/rondavirtual/atualizarprocedimentoexecucao/" + procedimento.id_procedimento_execucao + "/rondavirtual/" + rondaVirtual.id_ronda_virtual + "/1";

    };



    async function handleClickIniciarProcedimento(id) {

        if (parseInt(rondaVirtual.usuario_executor.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {

            const resposta = window.confirm("Sinalizar inicio da execução do procedimento?");

            if (resposta) {
                console.log(`Procedimento com ID ${id} iniciado.`);
                try {

                    const cadastro = {
                        usuario_criador: { id_usuario: Cookies.get("id_usuario") },
                    }
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }
                    const response = await api.put('/v1/protected/rondavirtual/atualizarprocedimentoexecucao/iniciarprocedimento/' + id + "/" + idRondaVirtual, cadastro,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Procedimento foi sinalizado como iniciado!");
                        listarInformacoes();
                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }


                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }

            } else {
                return;
            }
        } else {
            alert("Somente o usuário executor da Ronda Virtual pode executar esta ação!");
            return;
        }
    };

    const [mensagemCancelamento, setMensagemCancelamento] = useState("");
    const [openCancelarProcedimento, setOpenCancelarProcedimento] = useState(false);
    const [idProcedimentoCancelar, setIdProcedimentoCancelar] = useState(false);

    const handleCloseCancelarProcedimento = () => {
        setMensagemCancelamento("");
        setIdProcedimentoCancelar(0);
        setOpenCancelarProcedimento(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };




    function handleClickOpenCancelarProcedimento(id) {

        if (parseInt(rondaVirtual.usuario_executor.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {
            setMensagemCancelamento("");
            setIdProcedimentoCancelar(id);
            setOpenCancelarProcedimento(true);

        } else {
            alert("Somente o usuário executor da Ronda Virtual pode executar esta ação!");
            return;
        }
    };

    async function handleClickCancelarProcedimento() {
        if (parseInt(rondaVirtual.usuario_executor.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {
            const resposta = window.confirm("Confirmar cancelamento do procedimento?");

            if (resposta) {
                console.log(`Procedimento com ID ${idProcedimentoCancelar} cancelado.`);
                try {

                    const cadastro = {
                        mensagem_cancelamento: mensagemCancelamento,
                    }
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }
                    const response = await api.put('/v1/protected/rondavirtual/cancelarprocedimentorondavirtual/' + idProcedimentoCancelar + "/" + idRondaVirtual, cadastro,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Procedimento foi cancelado!");
                        handleCloseCancelarProcedimento();
                        listarInformacoes();
                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }


                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }

            } else {
                return;
            }

        } else {
            alert("Somente o usuário executor da Ronda Virtual pode executar esta ação!");
            return;
        }
    };


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {

        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    function returnDescricaoInstalacao(instalacao) {
        var texto = "SIGMA: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }



    const [openTratamentoRemoto, setOpenTratamentoRemoto] = useState(false);
    const [observacoesEncerramentoRonda, setObservacoesEncerramentoRonda] = useState("");

    const [criarOrdemServico, setCriarOrdemServico] = useState(false);
    const [disableOpcaoOrdemServico, setDisableOpcaoOrdemServico] = useState(false);

    const verificarProcedimentosObrigatorios = () => {
        return rondaVirtual.procedimentos.some((procedimentoExecucao) => {
            return procedimentoExecucao.procedimento.obrigatorio === 1 && procedimentoExecucao.status === 0;
        });
    };

    async function handleOpenRondaVirtual() {


        const result = verificarProcedimentosObrigatorios();
        if (result) {
            alert("Existem Procedimentos Obrigatórios Pendentes, atualize-os!")
        } else {
            //verificar se existem problemas
            const hasProblemas = rondaVirtual.procedimentos.some(procedimento => procedimento.problemas.length >= 1);

            if (hasProblemas) {
                setCriarOrdemServico(true);
                setDisableOpcaoOrdemServico(true);
            } else {
                setDisableOpcaoOrdemServico(false);
                setCriarOrdemServico(false);
            }
            setOpenTratamentoRemoto(true);

        }
    }


    const handleCloseTratamentoRemoto = () => {
        setObservacoesEncerramentoRonda("");
        setOpenTratamentoRemoto(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo

    };




    async function handleFinalizarRondaVirtual() {

        if (parseInt(rondaVirtual.usuario_executor.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {
            setInSalvamento(true);

            try {

                console.log("cadastrar chamado");
                const cadastro = {
                    observacoes_encerramento_ronda: observacoesEncerramentoRonda,
                }

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.put('/v1/protected/rondavirtual/finalizarrondavirtual/' + idRondaVirtual, cadastro,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    alert("A Ronda Virtual foi atualizada!");
                    setInSalvamento(false);
                    // setOpen(false);
                    window.location.reload(true);
                }

            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                setInSalvamento(false);
                alert("Erro de Conexão, tente novamente mais tarde");

            }
        } else {
            alert("Somente o usuário executor da Ronda Virtual pode executar esta ação!");
            setInSalvamento(false);
            return;
        }



    }






    async function handleBalaoOk() {
        //verificar se a ronda virtual ainda esta pendente
        try {


            console.log("Vizualizar tarefa chamado");
            const id_usuario = Cookies.get('id_usuario');


            var url = "v1/protected/rondavirtual/verificarpendente/" + parseInt(rondaVirtual.id_ronda_virtual, 10);
            //console.log("Url: " + url);
            await api.get(url).then(function (response) {
                // alert(response.data);
                if (response.data === "ronda_nula") {
                    alert("Ronda não mais listada no sistema!");
                    listarInformacoes();
                } else if (response.data === "disponivel") {
                    //assumir
                    const token = Cookies.get('token');
                    const headers = {
                        'Authorization': 'Bearer ' + token
                    }
                    const cadastro = {
                        usuario_executor: { id_usuario: Cookies.get("id_usuario") },
                    }
                    api.put("/v1/protected/rondavirtual/assumirrondavirtual/" + rondaVirtual.id_ronda_virtual + "/" + Cookies.get("id_usuario"), cadastro, {
                        headers: headers
                    }).then(function (response) {
                        if (parseInt(response.data.usuario_executor.id_usuario) === parseInt(Cookies.get("id_usuario"), 10)) {
                            alert("Ronda Virtual assumida por você");
                            listarInformacoes();
                        } else {


                            alert("Erro, consulte o administrador");
                            listarInformacoes();
                        }
                    });

                } else {

                    alert("Usuário " + response.data + " já assumiu a execução dessa ronda virtual");
                    listarInformacoes();
                }
            });


        } catch (_err) {
            console.log("Erro ao vizualizar acao, err: " + _err);

        }
    };



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };


    function returnDescricaoUsuario(usuario) {
        var texto = usuario.nome_amigavel;


        return texto;
    }


    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                RondaVirtual={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} >
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <Grid
                        item xs={12}
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent={"center"}
                    >


                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            align="flex-start"
                            item xs={12}
                            style={{ width: '100%', margin: 0, padding: 0 }}
                        >

                            <a href="/gestao/operacional/rondavirtual/listar" style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"< Rondas Virtuais | "}</a>
                            <a href={"/gestao/operacional/rondavirtual/gerenciar/" + idRondaVirtual} style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"Ronda Virtual Nº " + idRondaVirtual}</a>

                        </Grid>


                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                            style={{ margin: 10 }}
                        >

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={3}
                                style={{
                                    border: "1px solid black",
                                    borderRadius: "8px", // Cantos arredondados (opcional)
                                    padding: "5px", // Espaçamento interno
                                    backgroundColor: "#e0f7fa", // Cor de fundo clara (opção 1)
                                    margin: 10
                                }}
                            >
                                <span>{"Localidade: " + returnDescricaoInstalacao(instalacaoSelecionada)}</span>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={3}
                                style={{
                                    border: "1px solid black",
                                    borderRadius: "8px", // Cantos arredondados (opcional)
                                    padding: "5px", // Espaçamento interno
                                    backgroundColor: "#e0f7fa", // Cor de fundo clara (opção 1)
                                    margin: 10
                                }}
                            >
                                {rondaVirtual.usuario_executor ?
                                    (<span>{"Executor: " + returnDescricaoUsuario(rondaVirtual.usuario_executor)}</span>)
                                    :
                                    (
                                        <div> <span>{"Sem usuário executor"}</span><br></br> <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleBalaoOk}
                                            sx={{ marginRight: "8px" }}
                                        >
                                            Assumir
                                        </Button></div>

                                    )
                                }

                            </Grid>
                            <Grid
                                container
                                direction="row"
                                item xs={3}
                                alignItems="center"
                                justifyContent="center"
                                style={{
                                    border: "1px solid black",
                                    borderRadius: "8px", // Cantos arredondados (opcional)
                                    padding: "5px", // Espaçamento interno
                                    backgroundColor: "#e0f7fa", // Cor de fundo clara (opção 1)
                                    margin: 10
                                }}
                            >

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item xs={12}
                                    style={{
                                        padding: 1,
                                    }}
                                >
                                    {rondaVirtual.status_ronda_virtual === 0 ?
                                        ("Status: Ronda Virtual em andamento") :
                                        ("Status: Ronda Virtual finalizada!\nDuração: " + rondaVirtual.duracao + " minutos")
                                    }

                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item xs={12}
                                > {rondaVirtual.status_ronda_virtual === 1 &&
                                    <span>{"Observações do executor: " + rondaVirtual.observacoes_encerramento_ronda}</span>
                                    }
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                            >


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={2}
                                    item xs={12}
                                >




                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        item xs={12}
                                        style={{ margin: 20, borderRadius: '10px', border: '1px solid #000000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}
                                    >


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            item xs={12}
                                            style={{ padding: 5 }}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                item xs={6}
                                                style={{ padding: 5 }}
                                            >

                                                <Typography variant="h5">Procedimentos Ronda Virtual</Typography>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                item xs={6}
                                            >
                                                {rondaVirtual.status_ronda_virtual === 0 &&
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            margin: 5,
                                                            backgroundColor: '#4caf50', // Cor de fundo personalizada (verde)
                                                            color: '#fff',              // Cor do texto personalizada (branco)
                                                        }}
                                                        startIcon={<CheckCircleIcon />} // Ícone de finalizar
                                                        onClick={handleOpenRondaVirtual}
                                                    > Finalizar Ronda Virtual  </Button>
                                                }

                                            </Grid>


                                        </Grid>







                                        {rondaVirtual.status_ronda_virtual === 10 ?
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                item xs={12}
                                            >

                                                <Tooltip title="Adicione novos procedimentos que irá executar ou destinar a outros usuários">
                                                    <Button
                                                        style={{ margin: 5 }}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(e) => novoProcedimento(1)}
                                                        startIcon={<AddIcon />} // Ícone de "+" (você também pode usar um emoji, como "➕")
                                                    >
                                                        Procedimento
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                            :
                                            <div></div>

                                        }

                                        <Grid container spacing={2}
                                            item xs={12}
                                            style={{ padding: 5 }}
                                            rowSpacing={2}>
                                            {rondaVirtual.procedimentos.map((procedimento_analise, index) => (
                                                <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                                                    {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}
                                                    <Card style={{ borderRadius: '10px', border: '1px solid #000000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}>
                                                        <CardContent>
                                                            <Link
                                                                target="_blank"
                                                                to={`/gestao/operacional/procedimentos/vizualizar/${procedimento_analise.procedimento.id_procedimento}`}
                                                            >
                                                                <Typography variant="h6">{procedimento_analise.procedimento.nome}</Typography>
                                                            </Link>
                                                            <br />
                                                            <Typography variant="h8">{"Status: " + ((procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao === null) ? "Não Iniciado" :
                                                                (procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao) ? "Iniciado" :
                                                                    (procedimento_analise.status === 1) ? "Concluído" :
                                                                        (procedimento_analise.status === 9) ? "Cancelado" : "Indefinido"

                                                            )}</Typography>
                                                            <br />
                                                            {
                                                                procedimento_analise.status === 1 ?
                                                                    <Typography variant="h8">{(procedimento_analise.resultado === 0 ? "Resultado: Falha" : "Resultado: Sucesso")}</Typography>
                                                                    :
                                                                    <Typography variant="h8"></Typography>

                                                            }
                                                            <br />
                                                            {
                                                                procedimento_analise.status === 1 ?
                                                                    <Typography variant="h8">
                                                                        {`Executado por ${procedimento_analise.usuario_executor.nome_amigavel}`} <br />
                                                                        {`às ${moment(procedimento_analise.data_hora_fim_execucao, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}
                                                                    </Typography> :
                                                                    <Typography variant="h8"></Typography>

                                                            }
                                                            {
                                                                procedimento_analise.status === 9 ?
                                                                    <Typography variant="h8">
                                                                        {`Cancelado por ${rondaVirtual.usuario_executor.nome_amigavel}`} <br />
                                                                        {`às ${moment(procedimento_analise.data_hora_cancelamento, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}<br />
                                                                        {`Motivo: ${procedimento_analise.mensagem_cancelamento}`}

                                                                    </Typography> :
                                                                    <Typography variant="h8"></Typography>

                                                            }
                                                            <br />
                                                            {
                                                                procedimento_analise.status === 1 ?
                                                                    <Typography variant="h8">
                                                                        {`Duração: ${procedimento_analise.duracao} minutos`} <br />
                                                                    </Typography> :
                                                                    <Typography variant="h8"></Typography>

                                                            }
                                                            <br />
                                                            {
                                                                procedimento_analise.status === 1 ?
                                                                    <Typography variant="h8">
                                                                        {"Resumo: " + procedimento_analise.observacoes} <br />
                                                                    </Typography> :
                                                                    <Typography variant="h8"></Typography>

                                                            }
                                                            <br />
                                                            <Typography variant="h8">{(procedimento_analise.procedimento.obrigatorio === 1 ? "Procedimento Obrigatório" : "Procedimento Opcional")}</Typography>
                                                            <br />
                                                            {
                                                                (procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao === null) ?


                                                                    <Grid container
                                                                        item xs={12}
                                                                    >
                                                                        <Grid container
                                                                            item xs={6}
                                                                            justifyContent="flex-start"
                                                                            alignItems="center"
                                                                        >
                                                                            <ColorButtonGreen variant="contained" color="primary"
                                                                                onClick={(event) => handleClickIniciarProcedimento(procedimento_analise.id_procedimento_execucao)}
                                                                            >
                                                                                Iniciar
                                                                            </ColorButtonGreen>
                                                                        </Grid>
                                                                        <Grid container
                                                                            justifyContent="flex-end"
                                                                            alignItems="center"
                                                                            item xs={6}
                                                                        >
                                                                            <Tooltip title="Cancelar procedimento">
                                                                                <IconButton
                                                                                    color="error"
                                                                                    onClick={(event) => handleClickOpenCancelarProcedimento(procedimento_analise.id_procedimento_execucao)}
                                                                                >
                                                                                    <CancelIcon />
                                                                                </IconButton>
                                                                            </Tooltip>


                                                                        </Grid>
                                                                    </Grid>
                                                                    :
                                                                    (procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao !== null) ?
                                                                        <Button style={{ fontSize: 12, border: '2px solid #4caf50' }}
                                                                            variant="outlined"
                                                                            startIcon={<RefreshIcon />}
                                                                            onClick={(event) => handleClickAtualizarProcedimento(procedimento_analise)}
                                                                        >  Atualizar
                                                                        </Button>
                                                                        :
                                                                        (procedimento_analise.status === 9) ?
                                                                            <span>Procedimento Cancelado</span>
                                                                            : <div></div>
                                                            }
                                                            {
                                                                procedimento_analise.status === 1 &&
                                                                <ColorButtonGreen variant="contained" color="primary"
                                                                    onClick={(event) => handleClickVizualizarProcedimento(procedimento_analise)}
                                                                >
                                                                    Vizualizar
                                                                </ColorButtonGreen>

                                                            }

                                                        </CardContent>
                                                    </Card>

                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }


            </div>




            <Dialog open={openTratamentoRemoto} onClose={handleCloseTratamentoRemoto} PaperProps={{ style: { maxWidth: '600px', minWidth: '300px' } }}>
                <DialogTitle>Encerrar Ronda Virtual</DialogTitle>
                <DialogContent>
                    <Grid
                        direction={"row"}
                        item xs={12}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={1}
                    >

                        <Grid
                            item xs={12}
                            style={{ margin: 20, padding: 20 }}
                        >
                            <TextField
                                label="Descrição"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={observacoesEncerramentoRonda}
                                onChange={(e) => setObservacoesEncerramentoRonda(e.target.value)}
                            />
                        </Grid>

                        <Grid
                            item xs={12}
                            style={{ margin: 2, padding: 2 }}
                            alignItems={'center'}
                            justifyContent={'center'}
                            direction={"row"}
                            container
                        >
                            <Tooltip title="Quando há no minimo 1 problema identificado, uma Ordem de Serviço é criada automaticamente!">

                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={criarOrdemServico}
                                       // onChange={() => setCriarOrdemServico(!criarOrdemServico)}
                                        //disabled={disableOpcaoOrdemServico}
                                    />
                                }
                                label="Criar Ordem de Serviço?"
                            />
                            </Tooltip>

                        </Grid>


                        <Grid
                            item xs={12}
                        >


                            <Button
                                disabled={inSalvamento}
                                style={{ margin: 20, padding: 20 }} variant="contained" color="primary" onClick={handleFinalizarRondaVirtual}>
                                Finalizar
                            </Button>
                        </Grid>



                    </Grid>

                </DialogContent>
            </Dialog>


            <Dialog open={openCancelarProcedimento} onClose={handleCloseCancelarProcedimento} PaperProps={{ style: { maxWidth: '600px', minWidth: '300px' } }}>
                <DialogTitle>Cancelar Procedimento</DialogTitle>
                <DialogContent>
                    <Grid
                        direction={"row"}
                        item xs={12}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={3}
                    >

                        <Grid
                            item xs={12}
                            style={{ margin: 20, padding: 20 }}
                        >

                        </Grid>

                        <Grid
                            item xs={12}
                        >
                            <TextField
                                variant="standard"
                                name="justificativa"
                                label="Justificativa"
                                required
                                id="justificativa"
                                value={mensagemCancelamento}
                                onChange={(e) => setMensagemCancelamento(e.target.value)}
                                fullWidth
                                multiline
                                minRows={2}
                            />

                            <Button style={{ margin: 20, padding: 20 }} variant="contained" color="primary" onClick={(e) => handleClickCancelarProcedimento()}>
                                Finalizar
                            </Button>
                        </Grid>



                    </Grid>

                </DialogContent>
            </Dialog>



        </div >
    );
}


