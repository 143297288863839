import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import ModuloPGM from './moduloPgm.js';
import ModuloSonoro from './ModuloSonoro/moduloSonoro.js';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { Snackbar } from '@material-ui/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';
import { Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#434343',
        fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
        fontSize: 14,
        backgroundColor: '#eeeeee',
        marginTop: theme.spacing(10),
    },
    container: {
        maxWidth: 400,
        padding: theme.spacing(4),
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
        backgroundColor: '#ffffff',
        borderRadius: 10,
        textAlign: 'center',
    },
    header: {
        color: '#0ee6b1',
        fontSize: 35,
        marginBottom: theme.spacing(2),
    },
    subHeader: {
        color: '#336859',
        fontSize: 30,
        marginBottom: theme.spacing(4),
    },
    fieldGroup: {
        marginBottom: theme.spacing(2),
    },
    button: {
        backgroundColor: '#00E1AA',
        color: '#ffffff',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: theme.spacing(1, 4),
        '&:hover': {
            backgroundColor: '#00c196',
        },
    },
}));


const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};


export default function EditarEquipamento() {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        Cookies.set("abaPaginaConta", newValue);
        setValue(newValue);

    }

    const [ip, setIp] = useState(null);
    const [porta, setPorta] = useState(null);


    const [insumos, setInsumos] = useState([]);
    const [insumoSelecionado, setInsumoSelecionado] = useState(null);


    const [modulosPgms, setModulosPgms] = useState(null);
    const [modulosSonoros, setModulosSonoros] = useState(null);


    const handleInsumo = (insumo) => {
        setInsumoSelecionado(insumo);
    }


    const [moduloPgmSelecionado, setModuloPgmSelecionado] = useState(null);
    const [moduloSonoroSelecionado, setModuloSonoroSelecionado] = useState(null);


    function returnDescricaoInsumo(insumo_) {

        try {
            let insumo = insumo_.insumo;
            let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao;

            if (insumo.variacoes && insumo.variacoes.length > 0) {
                textoComQuebraDeLinha += insumo.variacoes

                    .map((variacao, index) => (
                        `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                    )).join('');
            }

            var texto =

                //"<br />Marca/Modelo: " + insumo.marca + "/" + insumo.modelo +
                // "<br />Código Fornecedor: " + insumo.codigo_fornecedor +
                //"<br />Tipo: " + insumo.tipo +
                //"<br />Categoria: " + insumo.categoria +
                textoComQuebraDeLinha + "<br />Estoque: " + (Array.isArray(insumo_.itens_estoque) ? insumo_.itens_estoque.length : 0) + " unidades";
            return texto;
        } catch (_err) {

            try {
                let insumo = insumo_;
                let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao;

                if (insumo.variacoes && insumo.variacoes.length > 0) {
                    textoComQuebraDeLinha += insumo.variacoes

                        .map((variacao, index) => (
                            `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                        )).join('');
                }

                var texto =

                    //"<br />Marca/Modelo: " + insumo.marca + "/" + insumo.modelo +
                    // "<br />Código Fornecedor: " + insumo.codigo_fornecedor +
                    //"<br />Tipo: " + insumo.tipo +
                    //"<br />Categoria: " + insumo.categoria +
                    textoComQuebraDeLinha + "<br />Estoque: " + (Array.isArray(insumo.itens_estoque) ? insumo.itens_estoque.length : 0) + " unidades";
                return texto;
            } catch (_err) {
                return "INDEFINIDO";
            }
        }
    }






    function returnDescricaoStatus(status_item) {
        var texto = "";

        if (status_item === 0) {
            texto = "Disponível";
        }
        else if (status_item === 1) {
            texto = "Alugado";
        }
        else if (status_item === 2) {
            texto = "Vendido";
        }
        else if (status_item === 3) {
            texto = "Emprestado";
        }

        return texto
    }



    function returnDescricaoItemEstoque(item) {

        return "ID: " + item.id_item_estoque + "<br />" +
            "Status: " + returnDescricaoStatus(item.status_item) + "<br />" +
            "Fornecedor: " + (item.item.cotacao.fornecedor.tipo_fornecedor === 0 ? (item.item.cotacao.fornecedor.nome + " " + item.item.cotacao.fornecedor.sobrenome) : item.item.cotacao.fornecedor.nome_fantasia) + "<br />";


    }







    const { flagBack } = useParams();
    const { idCliente } = useParams();
    const { idInstalacao } = useParams();
    const { idEquipamento } = useParams();



    //const { idEquipamento } = useParams();



    const [rotaCamera, setRotaCamera] = useState(null);


    const [instalacao, setInstalacao] = useState(null);
    const [botaoClicado, setBotaoClicado] = useState(false);


    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [nomeMapa, setNomeMapa] = useState("");
    const [iconeMapa, setIconeMapa] = useState(0);

    const [ramal, setRamal] = useState("");
    const [localizacao, setLocalizacao] = useState("");


    const handleIconeMapa = (event) => {
        setIconeMapa(event.target.value);
    };


    function returnDescricaoModuloPgm(modulo) {
        var texto = "ID: " + modulo.id_modulo_pgm + "\n Código: " + modulo.codigo;
        return texto;
    }


    function returnDescricaoModuloSonoro(modulo) {
        var texto = "ID: " + modulo.id_modulo_sonoro + "\n Código: " + modulo.codigo;
        return texto;
    }



    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };



    const history = useHistory();



    async function funcaoSalvar() {


        try {

            const cadastro = {
                latitude: latitude,
                longitude: longitude,
                nome_mapa: nomeMapa,
                ip: ip,
                porta: porta,
                rota_camera: rotaCamera,
                localizacao: localizacao,
                ramal: ramal,

            }

            if ((insumoSelecionado && insumoSelecionado.insumo) || insumoSelecionado) {

                if (insumoSelecionado && insumoSelecionado.insumo) {
                    cadastro.id_insumo = insumoSelecionado.insumo.id_insumo;
                    cadastro.tipo_equipamento_area_tecnica = insumoSelecionado.insumo.tipo_equipamento_area_tecnica;
                } else if (insumoSelecionado) {
                    cadastro.id_insumo = insumoSelecionado.id_insumo;
                    cadastro.tipo_equipamento_area_tecnica = insumoSelecionado.tipo_equipamento_area_tecnica;

                }
            } else {
                alert("Selecione o Insumo!");
                return;
            }




            //modulo pgm
            if (moduloPgmSelecionado?.id_modulo_pgm) {
                cadastro.modulo_pgm = { id_modulo_pgm: moduloPgmSelecionado.id_modulo_pgm };
            }

            //modulo pgm
            if (moduloSonoroSelecionado?.id_modulo_sonoro) {
                cadastro.modulo_sonoro = { id_modulo_sonoro: moduloSonoroSelecionado.id_modulo_sonoro };
            }

            //ramal
            if (insumoSelecionado && insumoSelecionado.insumo && parseInt(insumoSelecionado.insumo.tipo_equipamento_area_tecnica, 10) === 120) {
                cadastro.ramal = ramal;
            }
            //ramal
            if (insumoSelecionado && parseInt(insumoSelecionado.tipo_equipamento_area_tecnica, 10) === 120) {
                cadastro.ramal = ramal;
            }


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/equipamentos/atualizar/' + idEquipamento, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {

                alert("Equipamento Atualizado");
                if (parseInt(flagBack) === 1) {
                    history.push({

                        pathname: "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + idInstalacao + "/" + idCliente,
                    })
                }

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                //setBotaoSalvarClickado(false);

            }

        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }


    const [modulosPgmsAssociados, setModulosPgmsAssociados] = useState([]);
    const [modulosSonorosAssociados, setModulosSonorosAssociados] = useState([]);

    const [clienteSelecionado, setClienteSelecionado] = useState();

    const [clientes, setClientes] = useState();

    const [loading, setLoading] = useState(true);


    const [itemEstoqueSelecionado, setItemEstoqueSelecionado] = useState(null);

    const [equipamento, setEquipamento] = useState(null);


    const handleCliente = (event, cliente) => {

        setClienteSelecionado(cliente);
    };



    function returnDescricaoCliente(cliente) {
        console.log("Descricao de cliente chamada!");
        var texto = "ID: " + cliente.id_cliente + " " +
            (cliente.tipo_cliente === 0 ? ("CPF: " + cliente.cpf) : ("CNPJ: " + cliente.cnpj)) + " " +
            (cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) : cliente.nome_fantasia);

        return texto;
    }


    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }



    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            let id_cliente = parseInt(idCliente);
            let id_instalacao = parseInt(idInstalacao);
            let flag = parseInt(flagBack);


            if (flag === 0) {
                await api.get("v1/protected/clientes/listarTodos", {
                    headers: headers
                }).then(function (response) {
                    setClientes(response.data)


                });


            } else if (flag === 1) {
                console.log("Flag é " + flag);

                setClientes([]);

                await api.get("/v1/protected/retornardadoscliente/" + id_cliente, {
                    headers: headers
                }).then(function (response) {
                    console.log("Dados de cliente retornado!");
                    setClienteSelecionado(response.data);

                    //listar instalacao
                    api.get("/v1/protected/cliente/instalacoes/listarporid/" + idInstalacao, {
                        headers: headers
                    }).then(function (response) {
                        setInstalacao(response.data);
                        console.log("Instalacao retornada!");

                        //listar o equipamento
                        api.get("v1/protected/equipamentos/listarporid/" + idEquipamento, {
                            headers: headers
                        }).then(function (response) {
                            console.log("Item estoque retornado!");

                            setEquipamento(response.data);

                            setLatitude(response.data.latitude);
                            setLongitude(response.data.longitude);
                            setNomeMapa(response.data.nome_mapa);
                            setLocalizacao(response.data.localizacao);

                            setRamal(response.data.ramal);

                            setIp(response.data.ip);
                            setPorta(response.data.porta);
                            setRotaCamera(response.data.rota_camera);

                            setInsumoSelecionado(response.data.insumo)
                            setItemEstoqueSelecionado(response.data.item_estoque);


                            setModuloPgmSelecionado(response.data.modulo_pgm);

                            setModuloSonoroSelecionado(response.data.modulo_sonoro);

                        });

                    });
                });


            }

            api.get("v1/protected/almoxarifado/estoque/listarporinsumodisponiveis/" + idInstalacao, {
                headers: headers
            }).then(function (response) {
                setInsumos(response.data)

            });


            //pgms
            try {
                const response = await api.get("v1/protected/modulopgm/listardisponiveis", {
                    headers: headers
                });
                setModulosPgms(response.data);

                //modulos sonoros
                try {
                    const response = await api.get("v1/protected/modulosonoro/listardisponiveis", {
                        headers: headers
                    });
                    setModulosSonoros(response.data);
                    try {
                        const response = await api.get("v1/protected/modulopgm/listardisponiveisporinstalacao/" + idInstalacao, {
                            headers: headers
                        });
                        setModulosPgmsAssociados(response.data);
                        try {
                            const response = await api.get("v1/protected/modulosonoro/listardisponiveisporinstalacao/" + idInstalacao, {
                                headers: headers
                            });
                            setModulosSonorosAssociados(response.data);

                            setLoading(false);
                        } catch (error) {
                            console.error('Erro ao buscar módulos sonoros:', error);
                        }

                    } catch (error) {
                        console.error('Erro ao buscar módulos PGM:', error);
                    }
                } catch (error) {
                    console.error('Erro ao buscar módulos sonoros:', error);
                }

            } catch (error) {
                console.error('Erro ao buscar módulos PGM:', error);
            }


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Clientes: " + _err)
        }
    }

    useEffect(() => {

        listarInformacoes();


    }, []);



    const handleChange = (event, newValue) => {
        Cookies.set("abaPaginaConta", newValue);
        setValue(newValue);


    }


    const handleChangeIndex = (index) => {
        Cookies.set("abaPaginaConta", newValue);
        setValue(index);


    };

    function handleSetInstalacao(instalacao) {
        setInstalacao(instalacao);
    }


    const classes = useStyles();



    const handleOpenMap = (latitude, longitude) => {
        // Verifica se os valores de latitude e longitude são válidos
        if (latitude && longitude) {
            const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            window.open(googleMapsUrl, '_blank');
        } else {
            alert('Coordenadas inválidas!');
        }
    };


    return (

        <div>
            <NavegadorGestao
                BaseDados={true}
                Projetos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} />


            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container >

                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >



                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            style={{ width: '100%', margin: 0, padding: 0 }}
                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                            {
                                                equipamento.tipo_equipamento_area_tecnica === 0 ? (
                                                    <Tab label="Configurar PGM" style={{ backgroundColor: '#1C1C1C' }} />
                                                ) :

                                                    equipamento.tipo_equipamento_area_tecnica === 30 ? (
                                                        <Tab label="Configurar Modulo Sonoro" style={{ backgroundColor: '#1C1C1C' }} />
                                                    ) :
                                                        (
                                                            <Tab label="Configurar" style={{ backgroundColor: '#1C1C1C' }} />
                                                        )
                                            }

                                        </Tabs>

                                    </AppBar>

                                </Box>
                            </Grid>





                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                                style={{ width: '100%', alignItems: 'center', backgroundColor: 'white' }}
                            >


                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ margin: 0, padding: 0, backgroundColor: 'white', width: '100%' }}
                                >

                                    <Grid
                                        value={value}
                                        index={0}
                                        style={{ paddingTop: 0, margin: 0 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            item xs={6}
                                        >
                                            <Button style={{ margin: 5 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={salvar}
                                                disabled={botaoClicado}
                                            > Atualizar Equipamento  </Button>
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5}
                                            >

                                                <Autocomplete
                                                    id="select-ordem-servico"
                                                    options={flagBack === 0 ? clientes : []}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(cliente) => returnDescricaoCliente(cliente).replace(/<br \/>/g, '\n')}
                                                    value={clienteSelecionado}
                                                    disabled={flagBack === 0 ? false : true}
                                                    onChange={handleCliente}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cliente) => {
                                                            const cotacaoText = returnDescricaoCliente(cliente).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Cliente " multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoCliente(option) }} />
                                                    )}

                                                />
                                            </Grid>
                                        </Grid>




                                        {clienteSelecionado &&
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ marginTop: 20 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={5}
                                                >

                                                    <Autocomplete
                                                        id="select-instalacao"
                                                        options={flagBack === 0 ? clienteSelecionado.instalacoes : []}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(instalacao) => returnDescricaoInstalacao(instalacao).replace(/<br \/>/g, '\n')}
                                                        value={instalacao}
                                                        disabled={flagBack === 0 ? false : true}
                                                        onChange={(e, v) => handleSetInstalacao(v)}
                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((instalacao) => {
                                                                const cotacaoText = returnDescricaoInstalacao(instalacao).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Instalação " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoInstalacao(option) }} />
                                                        )}

                                                    />
                                                </Grid>

                                                <button
                                                    style={{
                                                        backgroundColor: '#003366', // Azul escuro
                                                        color: '#FFFFFF', // Texto branco
                                                        textAlign: 'center',
                                                        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)', // Sombra
                                                        padding: '10px', // Espaçamento interno
                                                        border: 'none',
                                                        borderRadius: '5px', // Borda arredondada opcional
                                                        cursor: 'pointer', // Alterar cursor ao passar o mouse
                                                    }}
                                                    onClick={(e) => handleOpenMap(instalacao.latitude, instalacao.longitude)}
                                                >
                                                    Mapa
                                                </button>
                                            </Grid>
                                        }



                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={8}
                                        >

                                            <Autocomplete
                                                id="select-insumo"
                                                options={insumos}
                                                getOptionLabel={(insumo) => returnDescricaoInsumo(insumo).replace(/<br \/>/g, '\n')}
                                                value={insumoSelecionado}
                                                getOptionSelected={(option, value) => true}
                                                onChange={(e, v) => handleInsumo(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((insumo) => {
                                                        const insumoText = returnDescricaoInsumo(insumo).toLowerCase();
                                                        return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Insumo " multiline minRows={2} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoInsumo(option) }} />
                                                )}
                                            />
                                        </Grid>

                                        {insumoSelecionado &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-item-estoque"
                                                    options={(insumoSelecionado && flagBack === 0) ? insumoSelecionado.itens_estoque : []}
                                                    getOptionLabel={(itemEstoque) => returnDescricaoItemEstoque(itemEstoque).replace(/<br \/>/g, '\n')}
                                                    value={itemEstoqueSelecionado}
                                                    disabled={flagBack === 0 ? false : true}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => setItemEstoqueSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoItemEstoque(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Item do Estoque " multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoItemEstoque(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }


                                        {insumoSelecionado && itemEstoqueSelecionado && (itemEstoqueSelecionado.item.insumo.tipo_equipamento_area_tecnica === 0) &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-modulo_pgm"
                                                    options={[]}
                                                    getOptionLabel={(modulo) => returnDescricaoModuloPgm(modulo).replace(/<br \/>/g, '\n')}
                                                    value={moduloPgmSelecionado}
                                                    disabled={flagBack === 0 ? false : true}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => setModuloPgmSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoModuloPgm(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Módulo PGM " multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoModuloPgm(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }



                                        {insumoSelecionado && itemEstoqueSelecionado && (itemEstoqueSelecionado.item.insumo.tipo_equipamento_area_tecnica === 30) &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-modulo_sonoro"
                                                    options={[]}
                                                    disabled={flagBack === 0 ? false : true}
                                                    getOptionLabel={(modulo) => returnDescricaoModuloSonoro(modulo).replace(/<br \/>/g, '\n')}
                                                    value={moduloSonoroSelecionado}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => setModuloSonoroSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoModuloSonoro(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Módulo Sonoro " multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoModuloSonoro(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }


                                        {insumoSelecionado && (insumoSelecionado.tipo_equipamento_area_tecnica !== 0 && insumoSelecionado.tipo_equipamento_area_tecnica !== 30) &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-modulo_pgm"
                                                    options={modulosPgmsAssociados}
                                                    getOptionLabel={(modulo) => returnDescricaoModuloPgm(modulo).replace(/<br \/>/g, '\n')}
                                                    value={moduloPgmSelecionado}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => setModuloPgmSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoModuloPgm(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Módulo PGM associado" multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoModuloPgm(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }



                                        {insumoSelecionado &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-modulo_sonoro"
                                                    options={modulosSonorosAssociados}
                                                    getOptionLabel={(modulo) => returnDescricaoModuloSonoro(modulo).replace(/<br \/>/g, '\n')}
                                                    value={moduloSonoroSelecionado}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => setModuloSonoroSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoModuloSonoro(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Módulo Unifier associado" multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoModuloSonoro(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }

                                        {insumoSelecionado &&

                                            ((insumoSelecionado.insumo && parseInt(insumoSelecionado.insumo.tipo_equipamento_area_tecnica, 10) === 120)
                                                ||
                                                (insumoSelecionado && parseInt(insumoSelecionado.tipo_equipamento_area_tecnica, 10) === 120)
                                            ) ||
                                            ((insumoSelecionado.insumo && parseInt(insumoSelecionado.insumo.tipo_equipamento_area_tecnica, 10) === 30)
                                            ||
                                            (insumoSelecionado && parseInt(insumoSelecionado.tipo_equipamento_area_tecnica, 10) === 30)
                                        )
                                            &&

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={8}
                                            >
                                                <Grid item xs={12}>
                                                    <TextField

                                                        id="ramal"
                                                        variant="standard"
                                                        name="ramal"
                                                        fullWidth
                                                        label="Ramal"
                                                        value={ramal}
                                                        onChange={e => setRamal(e.target.value)}
                                                    />
                                                </Grid>

                                            </Grid>

                                        }



                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography style={{ margin: 15 }} >
                                                    <span style={{ fontSize: 22, textDecoration: 'underline' }}>Dados de Mapa: </span>
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} spacing={2}
                                            >
                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="nome_mapa"
                                                        variant="standard"
                                                        name="nome_mapa"
                                                        fullWidth
                                                        label="Nome Mapa"
                                                        value={nomeMapa}
                                                        onChange={e => setNomeMapa(e.target.value)}
                                                    />
                                                </Grid>

                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} spacing={2}
                                            >
                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="localizacao"
                                                        variant="standard"
                                                        name="localizacao"
                                                        fullWidth
                                                        label="Localização"
                                                        value={localizacao}
                                                        onChange={e => setLocalizacao(e.target.value)}
                                                    />
                                                </Grid>

                                            </Grid>


                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} spacing={2}
                                            >
                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="latitude"
                                                        variant="standard"
                                                        name="latitude"
                                                        fullWidth
                                                        label="Latitude"
                                                        value={latitude}
                                                        onChange={e => setLatitude(e.target.value)}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="longitude"
                                                        variant="standard"
                                                        name="longitude"
                                                        fullWidth
                                                        label="Longitude"
                                                        value={longitude}
                                                        onChange={e => setLongitude(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>


                                        </Grid>


                                    </Grid>

                                    <Grid
                                        value={value}
                                        index={1}
                                        style={{ margin: 1, padding: 1, width: '100%' }}

                                    >

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12} spacing={2}
                                        >
                                            <Grid item xs={4}>
                                                <TextField

                                                    id="ip"
                                                    variant="standard"
                                                    name="ip"
                                                    fullWidth
                                                    label="IP"
                                                    value={ip}
                                                    onChange={e => setIp(e.target.value)}
                                                />
                                            </Grid>

                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12} spacing={2}
                                        >
                                            <Grid item xs={4}>
                                                <TextField

                                                    id="porta"
                                                    variant="standard"
                                                    name="porta"
                                                    fullWidth
                                                    label="Porta"
                                                    value={porta}
                                                    onChange={e => setPorta(e.target.value)}
                                                />
                                            </Grid>

                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12} spacing={2}
                                        >
                                            <Grid item xs={4}>
                                                <TextField

                                                    id="rotaCamera"
                                                    variant="standard"
                                                    name="rotaCamera"
                                                    fullWidth
                                                    label="Rota Câmera"
                                                    value={rotaCamera}
                                                    onChange={e => setRotaCamera(e.target.value)}
                                                />
                                            </Grid>

                                        </Grid>

                                        {insumoSelecionado && (insumoSelecionado.tipo_equipamento_area_tecnica === 0) &&
                                            <ModuloPGM
                                                equipamento={equipamento}
                                            />
                                        }


                                        {insumoSelecionado && (insumoSelecionado.tipo_equipamento_area_tecnica === 30) &&
                                            <ModuloSonoro
                                                equipamento={equipamento}
                                            />
                                        }


                                    </Grid>


                                </SwipeableViews>


                            </Grid>

                        </Grid>


                    </React.Fragment>


                </Container >
            }


            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    style: {
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        marginTop: '60px', // Ajuste a altura conforme necessário
                        fontSize: '16px',
                        fontWeight: 'bold',
                        border: '1px solid black',
                        borderRadius: '4px',
                        padding: '10px',
                        zIndex: 555,
                        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',

                    }
                }}
            />


        </div >
    );
}



