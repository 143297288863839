import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment';
import RefreshIcon from '@mui/icons-material/Refresh';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Chip from '@mui/material/Chip';
import { Navigation, Pagination } from "swiper/modules"; // Certifique-se de importar os módulos
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { Snackbar } from '@material-ui/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { styled } from '@material-ui/styles';

import api from '../../../../../services/api';

import { Paper, makeStyles } from '@material-ui/core';

import Slider from "react-slick";




const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'whit',
        borderColor: 'blue',
    },
}));


const settings = {
    dots: true, // Adiciona indicadores (bolinhas)
    infinite: true, // Carrossel infinito
    speed: 500, // Velocidade da transição
    slidesToShow: 4, // Quantidade de slides visíveis ao mesmo tempo
    slidesToScroll: 1, // Quantidade de slides para rolar por vez
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};


export default function SNARonda(props) {

    const rondaVirtual = props.rondaVirtual;
    const [problemasSelecionados, setProblemasSelecionados] = useState([]);


    const [todasImagens, setTodasImagens] = useState([]);


    useEffect(() => {
        if (rondaVirtual && rondaVirtual.procedimentos) {
            const problemas = rondaVirtual.procedimentos
                .flatMap(procedimento => procedimento.problemas); // Pega todos os problemas

            setProblemasSelecionados(problemas);
        }

        const imagensColetadas = rondaVirtual.procedimentos.flatMap(procedimento => {
            const imagens = [];

            if (procedimento.caminho_imagem1) {
                imagens.push({
                    caminho: procedimento.caminho_imagem1.replace(
                        "/home/wwgrup/rondavirtual/",
                        "https://gruporosinetos.com/rvmidias/"
                    ),

                    descricao: procedimento.descricao_imagem1 || "Sem descrição"
                });
            }

            if (procedimento.caminho_imagem2) {
                imagens.push({
                    caminho: procedimento.caminho_imagem2.replace(
                        "/home/wwgrup/rondavirtual/",
                        "https://gruporosinetos.com/rvmidias/"
                    ),
                    descricao: procedimento.descricao_imagem2 || "Sem descrição"
                });
            }

            if (procedimento.caminho_imagem3) {
                imagens.push({
                    caminho: procedimento.caminho_imagem3.replace(
                        "/home/wwgrup/rondavirtual/",
                        "https://gruporosinetos.com/rvmidias/"
                    ),
                    descricao: procedimento.descricao_imagem3 || "Sem descrição"
                });
            }

            if (procedimento.caminho_imagem4) {
                imagens.push({
                    caminho: procedimento.caminho_imagem4.replace(
                        "/home/wwgrup/rondavirtual/",
                        "https://gruporosinetos.com/rvmidias/"
                    ),
                    descricao: procedimento.descricao_imagem4 || "Sem descrição"
                });
            }


            return imagens;
        });
        console.log(imagensColetadas);

        setTodasImagens(imagensColetadas);

    }, [rondaVirtual]);


    function returnDescricaoProblema(problema) {
        var texto = problema.nome;
        return texto;
    }

    function returnDescricaoInstalacaoResumida(instalacao) {
        var texto = " SIGMA: " + instalacao.identificador_integrador + " - " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);
        return texto;
    }


    const handleClickVizualizarProcedimento = (procedimento) => {
        const url = `/gestao/operacional/rondavirtual/atualizarprocedimentoexecucao/${procedimento.id_procedimento_execucao}/rondavirtual/${rondaVirtual.id_ronda_virtual}/1`;
        window.open(url, '_blank'); // Abre em nova guia
    };

    return (
        <div>
            {
                rondaVirtual && (


                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <a
                                href={`/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/${rondaVirtual.instalacao.id_instalacao}/0`}
                                style={{ color: 'green', textDecoration: 'none' }} // Remove o sublinhado
                                target="_blank"

                            >
                                <SettingsIcon fontSize="small" /> {/* Ícone de engrenagem */}
                            </a>
                            <span>{returnDescricaoInstalacaoResumida(rondaVirtual.instalacao)}</span> {/* Exibe o texto da conta */}

                        </div>
                        <br></br>
                        <span
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {"Executor: " + rondaVirtual.usuario_executor.nome_amigavel}
                        </span>
                        <br></br>
                        <span style={{ fontSize: 14, color: 'black', fontWeight: 'bold' }}>
                            {"Data e Hora da conclusão: " + moment(rondaVirtual.data_hora_finalizacao, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}
                        </span>
                        <br></br>
                        <span
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {"Duração: " + rondaVirtual.duracao + " minutos"}
                        </span>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                            style={{ marginTop: 10, marginBottom: 10 }}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                align="center"
                                item xs={11}
                            >

                                <Autocomplete
                                    id="select-problemas"
                                    options={[]}
                                    disableCloseOnSelect
                                    multiple
                                    getOptionLabel={(problema) => returnDescricaoProblema(problema).replace(/<br \/>/g, '\n')}
                                    value={problemasSelecionados}
                                    filterOptions={(options, { inputValue }) => {
                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                        return options.filter((problema) => {
                                            const cotacaoText = returnDescricaoProblema(problema).toLowerCase();
                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label=" Problemas" multiline minRows={1} />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((problema, index) => (
                                            <Chip
                                                key={index}
                                                label={problema.nome}
                                                style={{
                                                    borderRadius: 15, // Borda arredondada
                                                    backgroundColor: '#e3f2fd', // Cor de fundo azul claro
                                                    margin: '4px', // Espaçamento entre as tags
                                                }}
                                                {...getTagProps({ index })}


                                            />
                                        ))
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}
                            item xs={12}
                            style={{ padding: 5 }}
                            rowSpacing={2}>

                            {rondaVirtual.procedimentos.map((procedimento_analise, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                    {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}
                                    <Card style={{ borderRadius: '10px', border: '1px solid #000000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}>
                                        <CardContent>
                                            <Link
                                                target="_blank"
                                                to={`/gestao/operacional/procedimentos/vizualizar/${procedimento_analise.procedimento.id_procedimento}`}
                                            >
                                                <Typography variant="h6">{procedimento_analise.procedimento.nome}</Typography>
                                            </Link>
                                            <br />
                                            <Typography variant="h8">{"Status: " + ((procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao === null) ? "Não Iniciado" :
                                                (procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao) ? "Iniciado" :
                                                    (procedimento_analise.status === 1) ? "Concluído" :
                                                        (procedimento_analise.status === 9) ? "Cancelado" : "Indefinido"

                                            )}</Typography>
                                            <br />
                                            {
                                                procedimento_analise.status === 1 ?
                                                    <Typography variant="h8">{(procedimento_analise.resultado === 0 ? "Resultado: Falha" : "Resultado: Sucesso")}</Typography>
                                                    :
                                                    <Typography variant="h8"></Typography>

                                            }
                                            <br />
                                            {
                                                procedimento_analise.status === 1 ?
                                                    <Typography variant="h8">
                                                        {`Executado por ${procedimento_analise.usuario_executor.nome_amigavel}`} <br />
                                                        {`às ${moment(procedimento_analise.data_hora_fim_execucao, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}
                                                    </Typography> :
                                                    <Typography variant="h8"></Typography>

                                            }
                                            {
                                                procedimento_analise.status === 9 ?
                                                    <Typography variant="h8">
                                                        {`Cancelado por ${rondaVirtual.usuario_executor.nome_amigavel}`} <br />
                                                        {`às ${moment(procedimento_analise.data_hora_cancelamento, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}<br />
                                                        {`Motivo: ${procedimento_analise.mensagem_cancelamento}`}

                                                    </Typography> :
                                                    <Typography variant="h8"></Typography>

                                            }
                                            <br />
                                            {
                                                procedimento_analise.status === 1 ?
                                                    <Typography variant="h8">
                                                        {`Duração: ${procedimento_analise.duracao} minutos`} <br />
                                                    </Typography> :
                                                    <Typography variant="h8"></Typography>

                                            }
                                            <br />
                                            {
                                                procedimento_analise.status === 1 ?
                                                    <Typography variant="h8">
                                                        {"Resumo: " + procedimento_analise.observacoes} <br />
                                                    </Typography> :
                                                    <Typography variant="h8"></Typography>

                                            }
                                            <br />
                                            <Typography variant="h8">{(procedimento_analise.procedimento.obrigatorio === 1 ? "Procedimento Obrigatório" : "Procedimento Opcional")}</Typography>
                                            <br />
                                            {
                                                (procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao === null) ?


                                                    <Grid container
                                                        item xs={12}
                                                    >
                                                        <Grid container
                                                            item xs={6}
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                        >
                                                            <ColorButtonGreen variant="contained" color="primary"
                                                            >
                                                                Iniciar
                                                            </ColorButtonGreen>
                                                        </Grid>
                                                        <Grid container
                                                            justifyContent="flex-end"
                                                            alignItems="center"
                                                            item xs={6}
                                                        >
                                                            <Tooltip title="Cancelar procedimento">
                                                                <IconButton
                                                                    color="error"
                                                                >
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            </Tooltip>


                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    (procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao !== null) ?
                                                        <Button style={{ fontSize: 12, border: '2px solid #4caf50' }}
                                                            variant="outlined"
                                                            startIcon={<RefreshIcon />}
                                                        >  Atualizar
                                                        </Button>
                                                        :
                                                        (procedimento_analise.status === 9) ?
                                                            <span>Procedimento Cancelado</span>
                                                            : <div></div>
                                            }
                                            {
                                                procedimento_analise.status === 1 &&
                                                <ColorButtonGreen variant="contained" color="primary"
                                                    onClick={(event) => handleClickVizualizarProcedimento(procedimento_analise)}

                                                >
                                                    Vizualizar
                                                </ColorButtonGreen>

                                            }

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ marginTop: 10, marginBottom: 10 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={11}
                                                >

                                                    <Autocomplete
                                                        id="select-problemas"
                                                        options={[]}
                                                        disableCloseOnSelect
                                                        multiple
                                                        getOptionLabel={(problema) => returnDescricaoProblema(problema).replace(/<br \/>/g, '\n')}
                                                        value={procedimento_analise.problemas}
                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((problema) => {
                                                                const cotacaoText = returnDescricaoProblema(problema).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Problemas" multiline minRows={1}
                                                                sx={{
                                                                    fontSize: '16px', // Tamanho da fonte do label
                                                                    color: 'black', // Cor do label
                                                                    '& .MuiInputBase-input': { // Afeta o texto digitado
                                                                        fontSize: '16px',
                                                                        color: 'black',
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((problema, index) => (
                                                                <Chip
                                                                    key={index}
                                                                    label={problema.nome}
                                                                    style={{
                                                                        borderRadius: 15, // Borda arredondada
                                                                        backgroundColor: 'black', // Cor de fundo azul claro
                                                                        margin: '4px', // Espaçamento entre as tags
                                                                        color: 'white',
                                                                    }}
                                                                    {...getTagProps({ index })}


                                                                />
                                                            ))
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>



                                        </CardContent>
                                    </Card>

                                </Grid>
                            ))}


                        </Grid>
                        <Grid container spacing={2}
                            item xs={12}
                            style={{ padding: 5 }}
                            rowSpacing={2}>


                            {todasImagens.length > 0 && (
                                <div style={{ textAlign: "center", width: "100%" }}>
                                    <Swiper
                                        spaceBetween={10}
                                        slidesPerView={"auto"}
                                        navigation={true} // Ativando navegação
                                        pagination={{ clickable: true }}
                                        modules={[Navigation, Pagination]} // Adicionando os módulos necessários
                                        style={{ width: "100%", maxWidth: "400px" }}
                                    >
                                        {todasImagens.map((imagem, index) => (
                                            <SwiperSlide key={index} style={{ textAlign: "center" }}>
                                                <img
                                                    src={imagem.caminho}
                                                    alt={imagem.descricao}
                                                    style={{ width: "100%", height: "auto", borderRadius: "5px" }}
                                                />
                                                <p>{imagem.descricao}</p>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            )}
                        </Grid>
                    </div>

                )
            }

        </div>
    )
}


