import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@mui/material/Tooltip';
import CallIcon from "@mui/icons-material/Call"; // Ícone de telefone do Material UI

import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Stomp } from '@stomp/stompjs';

import Close from '@material-ui/icons/Close'
import { Snackbar } from '@material-ui/core';
import { Menu } from '@mui/material';
import Hls from "hls.js";

import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Modal from "react-modal";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
 


import {
    MaterialReactTable
} from 'material-react-table';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import BuildIcon from '@mui/icons-material/Build';
import Switch from '@mui/material/Switch';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/Power';



import api from '../../../../../../services/api';


import NavegadorGestao from "../../../../../../components/NavBarGestao";


import switch_off from '../../../../../../assets/imgs/switch-off.png';
import switch_on from '../../../../../../assets/imgs/switch-on.png';


import mini_icone_carro from '../../../../../../assets/imgs/mini_icone_carro.png';

import mini_icone_moto from '../../../../../../assets/imgs/mini_icone_moto.png';

import icone_origem from '../../../../../../assets/imgs/icone_origem.png';
import icone_destino from '../../../../../../assets/imgs/icone_destino.png';
import icone_parada from '../../../../../../assets/imgs/icone_parada.png';

import icone_call from '../../../../../../assets/imgs/icone_call.png';


import cabo_conectado from '../../../../../../assets/imgs/cabo_conectado.png';
import cabo_desconectado from '../../../../../../assets/imgs/cabo_desconectado.png';
import cabo_internet from '../../../../../../assets/imgs/cabo_internet.png';
import cabo_sem_internet from '../../../../../../assets/imgs/cabo_sem_internet.png';

import wifi_conectado from '../../../../../../assets/imgs/cabo_conectado.png';

import modem_conectado from '../../../../../../assets/imgs/modem_conectado.png';
import modem_desconectado from '../../../../../../assets/imgs/modem_desconectado.png';
import modem_internet from '../../../../../../assets/imgs/cabo_internet.png';
import modem_sem_internet from '../../../../../../assets/imgs/cabo_sem_internet.png';

import icone_pgm from '../../../../../../assets/imgs/icone_pgm.png';
import icone_corneta from '../../../../../../assets/imgs/corneta.png';

import icone_play from '../../../../../../assets/imgs/play.png';


import icone_medidor from '../../../../../../assets/imgs/icone_medidor.png';

import icone_subestacao from '../../../../../../assets/imgs/icone_subestacao.png';
import icone_casa from '../../../../../../assets/imgs/icone_casa.png';
import icone_loja from '../../../../../../assets/imgs/icone_loja.png';
import icone_central from '../../../../../../assets/imgs/icone_central.png';

import icone_instalacoes from '../../../../../../assets/imgs/icone_instalacoes.png';
import icone_equipamentos from '../../../../../../assets/imgs/icone_equipamentos.png';

import icone_armar_stay from '../../../../../../assets/imgs/armar_stay.png';
import icone_armar_away from '../../../../../../assets/imgs/armar_away.png';
import icone_desarmar from '../../../../../../assets/imgs/desarmar.png';
import globalConfig from '../../../../../../configs/globalConfigs';
import icone_reiniciar from '../../../../../../assets/imgs/icone_reiniciar.png';





import {
    GoogleMap,
    Marker,
    LoadScript,
    OverlayView,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
    DistanceMatrixService,
    MaxZoomService, Circle, Polygon, Polyline
} from "@react-google-maps/api";

import "./MapPage.css";
import alarmeAudio from '../../../../../../assets/mp3/alarme.mp3';



export default function CentralComponent(props) {


    const { lat, lng, tipo, onClick, status, equipamento, perimetro, id_instalacao, flag } = props;
    const tempoInativo = 1;


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [openLogTable, setOpenLogTable] = useState(false);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const [logsPgm, setLogsPgm] = useState([]);
    const [limit, setLimit] = useState(200);


    function processarIcone(tipo) {
        if (tipo === 0) {
            return icone_pgm;
        } else if (tipo === 10) {
            return icone_medidor;
        } else if (tipo === 70) {
            return icone_central;
        } else if (tipo === 30) {
            return icone_pgm;
        } else if (tipo === 120) {
            return icone_corneta;
        }
    }


  
    async function handleClickCentral(comando) {

        console.log("handleClickPgm")
        try {
            const cadastro = {};
            const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
            let url = "";
            if (equipamento.modulo_pgm && parseInt(equipamento.modulo_pgm.id_modulo_pgm) > 0) {
                url = `/v1/protected/modulopgm/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/" + comando;
            } else if (equipamento.modulo_sonoro && parseInt(equipamento.modulo_sonoro.id_modulo_sonoro) > 0) {
                url = `/v1/protected/modulosonoro/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_sonoro.id_modulo_sonoro + "/" + comando + "/0/0/0/0/0/0/0/0";
            } else {
                alert("Nenhum Módulo TITANIWM PGM v1.0 ou Módulo TITANIWM Unifer associados a Central");
            }
            const response = await api.get(url, { headers });

            if (response.data) {
                setSnackbarMessage('Comando Enviado!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);

               
            }



        } catch (error) {
            setSnackbarMessage('Erro ao enviar comando');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            console.error("Erro ao enviar o comando:", error);
        }
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            align="center"
            item xs={flag === 0 ? 3 : 12}
            style={{ border: flag === 1 ? '1px solid black' : null, padding: 1, margin: flag === 1 ? 3 : 0 }}


        >
            {
                flag === 1 && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        align="center"
                        item xs={12}
                        style={{ padding: 1 }}
                    >
                        <span style={{ fontWeight: 'bold', fontSize: 12 }}>CENTRAL DE ALARME</span>
                    </Grid>
                )
            }
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"

                item xs={flag === 0 ? 12 : 2}
            >
                <Grid item xs={12}

                >
                    <img
                        alt={"icone"}
                        style={{
                            textAlign: 'center',
                            width: '40px',  // Define a largura da imagem
                            height: '40px'  // Define a altura da imagem
                        }}
                        src={processarIcone(tipo)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <span style={{ color: 'black', fontSize: '14px', fontWeight: 'bold' }}>{equipamento.nome_mapa} </span>
                    {(equipamento.modulo_pgm !== null &&
                        <span style={{ color: 'black', fontSize: '14px', fontWeight: 'bold' }}>{equipamento.modulo_pgm.nome_mapa} </span>
                    )}
                </Grid>




            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                align="flex-start"
                item xs={flag === 0 ? 1 : 8}
            >
                {flag === 1 && (
                    <div style={{
                        color: 'black',
                        display: 'flex', alignItems: 'center', gap: '8px', marginTop: '10px'
                    }}>

                        <div style={{
                            border: '1px solid black',
                            borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                        }}>
                            <IconButton color="error" onClick={() => { handleClickCentral(27) }}  >
                                <img src={icone_armar_stay} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                <span style={{ fontSize: 12, color: 'black' }}>Armar em Stay</span>
                            </IconButton>
                        </div>


                        <div style={{
                            border: '1px solid black',
                            borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                        }}>
                            <IconButton color="error" onClick={() => { handleClickCentral(28) }}  >
                                <img src={icone_armar_away} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                <span style={{ fontSize: 12, color: 'black' }}>Armar em Away</span>
                            </IconButton>
                        </div>

                        <div style={{
                            border: '1px solid black',
                            borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                        }}>
                            <IconButton color="error" onClick={() => { handleClickCentral(29) }}  >
                                <img src={icone_desarmar} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                <span style={{ fontSize: 12, color: 'black' }}>Desarmar</span>
                            </IconButton>
                        </div>

                        <div style={{
                            border: '1px solid black',
                            borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                        }}>
                            <IconButton color="error" onClick={() => { handleClickCentral(223) }}  >
                                <img src={icone_reiniciar} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                <span style={{ fontSize: 12, color: 'black' }}>Reiniciar</span>
                            </IconButton>
                        </div>

                    </div>
                )}
            </Grid>


            
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    style: {
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        marginTop: '60px', // Ajuste a altura conforme necessário
                        fontSize: '16px',
                        fontWeight: 'bold',
                        border: '1px solid black',
                        borderRadius: '4px',
                        padding: '10px',
                        zIndex: 555,
                        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',

                    }
                }}
            />


            {/* Modal */}
            {openLogTable && (
                <div
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro com opacidade
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "199999",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            width: "80%",
                            height: "80%",
                            borderRadius: "8px",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Sombra para destaque
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                            position: "relative",
                           
                        }}
                    >
                        {/* Botão de fechar */}
                        <button
                            onClick={() => setOpenLogTable(false)}
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                backgroundColor: "#FF4500",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                padding: "8px",
                                cursor: "pointer",
                                fontWeight: "bold",
                            }}
                        >
                            Fechar
                        </button>

                        {/* Conteúdo do Modal */}
                        <h6 style={{ marginTop: 0 }}>Historico de Eventos</h6>

                        {/* Espaço reservado para a tabela */}
                        <div
                            style={{
                                flex: 1,
                                overflowY: "auto", // Adiciona rolagem se a tabela for grande
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                padding: "10px",
                            }}
                        >

                            <div>
                                <div style={{ backgroundColor: 'white', margin: 5 }}>
                                    {logsPgm && (
                                        <MaterialReactTable
                                            columns={colunasLogsPgm}
                                            data={logsPgm}
                                            enableBottomToolbar={true}
                                            enableGlobalFilterModes
                                            enablePagination={true}
                                            enableRowActions={false}
                                            paginationDisplayMode={'pages'}
                                            getRowId={(row) => row.id_tarefa}
                                            enableGrouping
                                            enableExpandAll={false} //hide expand all double arrow in column header
                                            enableExpanding={true}
                                            filterFromLeafRows={true} //apply filtering to all rows instead of just parent rows
                                            paginateExpandedRows={false}
                                            initialState={{
                                                pagination: { pageSize: 200, pageIndex: 0 }, // Correção da sintaxe aqui
                                                showGlobalFilter: true,
                                            }}
                                            muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                            muiPaginationProps={{
                                                rowsPerPageOptions: [200, 300, 500],
                                                variant: 'outlined',
                                            }}
                                            enableFullScreenToggle={true}


                                        />

                                    )}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}

        </Grid>
    );


}

