import React, { useState, useEffect, useCallback, useRef } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};


export default function CadastrarPeD() {


   
       const [value, setValue] = React.useState(0);
   
       const handleChangeTab = (event, newValue) => {
           setValue(newValue);
   
       }
   
   
       const handleChangeIndex = (index) => {
           setValue(index);
       };


    const [botaoClicado, setBotaoClicado] = useState(false);




    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };



    const history = useHistory();

    const [descricaoProjeto, setDescricaoProjeto] = useState('');
    const [nomeProjeto, setNomeProjeto] = useState('');


    const [descricaoImagem1, setDescricaoImagem1] = useState('');
    const [descricaoImagem2, setDescricaoImagem2] = useState('');
    const [descricaoImagem3, setDescricaoImagem3] = useState('');
    const [descricaoImagem4, setDescricaoImagem4] = useState('');


    const [descricaoVideo1, setDescricaoVideo1] = useState('');
    const [descricaoVideo2, setDescricaoVideo2] = useState('');
    const [descricaoVideo3, setDescricaoVideo3] = useState('');


    const [descricaoArquivo1, setDescricaoArquivo1] = useState('');
    const [descricaoArquivo2, setDescricaoArquivo2] = useState('');
    const [descricaoArquivo3, setDescricaoArquivo3] = useState('');

    const [area, setArea] = useState(0);


    const [instalacao, setInstalacao] = useState([]);
    const [instalacoes, setInstalacoes] = useState([]);


    const handleChange = (event) => {
        setArea(event.target.value);
    };


    const [imagemSelecionada1, setImagemSelecionada1] = useState(null);
    const [imagemSelecionada2, setImagemSelecionada2] = useState(null);
    const [imagemSelecionada3, setImagemSelecionada3] = useState(null);
    const [imagemSelecionada4, setImagemSelecionada4] = useState(null);


    const onDrop1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada1(acceptedFiles[0]);
    }, []);

    const onDrop2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada2(acceptedFiles[0]);
    }, []);

    const onDrop3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada3(acceptedFiles[0]);
    }, []);

    const onDrop4 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada4(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: onDrop1,

        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: onDrop2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        onDrop: onDrop3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
        onDrop: onDrop4,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });



    const [videoSelecionado1, setVideoSelecionado1] = useState(null);
    const [videoSelecionado2, setVideoSelecionado2] = useState(null);
    const [videoSelecionado3, setVideoSelecionado3] = useState(null);


    const onDropVideo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropVideo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropVideo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado3(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootVideoProps1, getInputProps: getInputVideoProps1 } = useDropzone({
        onDrop: onDropVideo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps2, getInputProps: getInputVideoProps2 } = useDropzone({
        onDrop: onDropVideo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps3, getInputProps: getInputVideoProps3 } = useDropzone({
        onDrop: onDropVideo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });



    const [arquivoSelecionado1, setArquivoSelecionado1] = useState(null);
    const [arquivoSelecionado2, setArquivoSelecionado2] = useState(null);
    const [arquivoSelecionado3, setArquivoSelecionado3] = useState(null);


    const onDropArquivo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropArquivo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropArquivo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado3(acceptedFiles[0]);
    }, []);



    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps2, getInputProps: getInputArquivoProps2 } = useDropzone({
        onDrop: onDropArquivo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps3, getInputProps: getInputArquivoProps3 } = useDropzone({
        onDrop: onDropArquivo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });





    async function funcaoSalvar() {


        try {

            console.log("cadastrar chamado");

            const formData = new FormData();

            // Adicionar os dados do procedimento

            formData.append('area', area);

            formData.append('descricao_projeto', descricaoProjeto);
            formData.append('nome_projeto', nomeProjeto);

            

            formData.append('usuario_criador.id_usuario', Cookies.get('id_usuario'));



            formData.append('descricao_imagem1', descricaoImagem1);
            formData.append('descricao_imagem2', descricaoImagem2);
            formData.append('descricao_imagem3', descricaoImagem3);
            formData.append('descricao_imagem4', descricaoImagem4);


            formData.append('descricao_video1', descricaoVideo1);
            formData.append('descricao_video2', descricaoVideo2);
            formData.append('descricao_video3', descricaoVideo3);

            formData.append('descricao_arquivo1', descricaoArquivo1);
            formData.append('descricao_arquivo2', descricaoArquivo2);
            formData.append('descricao_arquivo3', descricaoArquivo3);



            // Adicionar as imagens ao FormData
            formData.append('imagens', imagemSelecionada1);
            formData.append('imagens', imagemSelecionada2);
            formData.append('imagens', imagemSelecionada3);
            formData.append('imagens', imagemSelecionada4);

            formData.append('videos', videoSelecionado1);
            formData.append('videos', videoSelecionado2);
            formData.append('videos', videoSelecionado3);

            formData.append('arquivos', arquivoSelecionado1);
            formData.append('arquivos', arquivoSelecionado2);
            formData.append('arquivos', arquivoSelecionado3);

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/ped/cadastrar', formData,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Projeto Cadastrado");
                    history.push({
                        pathname: "/gestao/administracao/ped/listar",
                    })
                



            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }





    const [loading, setLoading] = useState(true);

    





    async function listarInformacoes() {

        setLoading(true);

        try {
           
             setLoading(false);
                 

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Clientes: " + _err)
        }
    }

    useEffect(() => {

        listarInformacoes();


    }, []);

    
    
        // Função para lidar com o "paste"
        const handlePaste = useCallback(
            (event) => {
                const clipboardItems = event.clipboardData.items;
    
                for (const item of clipboardItems) {
                    if (item.type.startsWith("image/")) {
                        const file = item.getAsFile();
                        if (file) {
                            // Converte para PNG usando Canvas (caso necessário)
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                const img = new Image();
                                img.src = e.target.result;
                                img.onload = () => {
                                    const canvas = document.createElement("canvas");
                                    const ctx = canvas.getContext("2d");
                                    canvas.width = img.width;
                                    canvas.height = img.height;
    
                                    // Desenha a imagem no Canvas
                                    ctx.drawImage(img, 0, 0);
    
                                    // Converte o Canvas para PNG
                                    canvas.toBlob(
                                        (blob) => {
                                            const convertedFile = new File(
                                                [blob],
                                                `imagem-${Date.now()}.png`,
                                                { type: "image/png" }
                                            );
    
                                            // Define a próxima imagem disponível
                                            if (imagemSelecionada1 === null) {
                                                setImagemSelecionada1(convertedFile);
                                            } else if (imagemSelecionada2 === null) {
                                                setImagemSelecionada2(convertedFile);
                                            } else if (imagemSelecionada3 === null) {
                                                setImagemSelecionada3(convertedFile);
                                            } else if (imagemSelecionada4 === null) {
                                                setImagemSelecionada4(convertedFile);
                                            } else {
                                                alert("Todas as imagens já foram preenchidas!");
                                            }
                                        },
                                        "image/png",
                                        1
                                    );
                                };
                            };
                            reader.readAsDataURL(file);
                        }
                    }
                }
            },
            [imagemSelecionada1, imagemSelecionada2, imagemSelecionada3, imagemSelecionada4] // Adicionar dependências
        );

       // Listener para evento "paste"
        useEffect(() => {
            window.addEventListener("paste", handlePaste);
            return () => {
                window.removeEventListener("paste", handlePaste);
            };
        }, [handlePaste]);
    
        const [isRecording, setIsRecording] = useState(false);
        const mediaRecorderRef = useRef(null);
        const recordedChunksRef = useRef([]);
    
        const startRecording = async () => {
            try {
                // Captura a tela
                const screenStream = await navigator.mediaDevices.getDisplayMedia({
                    video: true,
                    audio: true, // Inclui o áudio da tela, se disponível
                });
    
                // Captura o microfone
                const audioStream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });
    
                // Combina os streams de tela e microfone
                const combinedStream = new MediaStream([
                    ...screenStream.getTracks(),
                    ...audioStream.getTracks(),
                ]);
    
                // Configura o MediaRecorder
                const mediaRecorder = new MediaRecorder(combinedStream, {
                    mimeType: "video/webm",
                });
    
                // Configura os eventos de gravação
                mediaRecorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        recordedChunksRef.current.push(event.data);
                    }
                };
    
                mediaRecorder.onstop = () => {
                    // Cria um blob com os dados gravados
                    const blob = new Blob(recordedChunksRef.current, {
                        type: "video/webm",
                    });
                    recordedChunksRef.current = [];
    
                    // Cria um arquivo a partir do blob
                    const file = new File([blob], `video-${Date.now()}.webm`, {
                        type: "video/webm",
                    });
    
                    // Salva o arquivo em videoSelecionado1
                    if (!videoSelecionado1)
                        setVideoSelecionado1(file);
                    else if (!videoSelecionado2) {
                        setVideoSelecionado2(file);
                    } else if(!videoSelecionado3){
                        setVideoSelecionado3(file);
                    }
    
                    // Limpa os streams
                    screenStream.getTracks().forEach((track) => track.stop());
                    audioStream.getTracks().forEach((track) => track.stop());
                };
    
                // Inicia a gravação
                mediaRecorder.start();
                mediaRecorderRef.current = mediaRecorder;
                setIsRecording(true);
            } catch (error) {
                console.error("Erro ao iniciar a gravação:", error);
            }
        };
    
        const stopRecording = () => {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
                setIsRecording(false);
            }
        };
    

   
    return (
        <div>
            <NavegadorGestao
                BaseDados={true}
                Projetos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} />


            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container >

                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >


                                        <Tabs
                                             value={value}
                                                                          onChange={handleChangeTab}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            style={{ width: '100%', margin: 0, padding: 0 }}
                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Imagens" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Vídeos" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Documentos" style={{ backgroundColor: '#1C1C1C' }} />


                                        </Tabs>

                                    </AppBar>

                                </Box>
                            </Grid>





                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={10}
                                >
                                    <Button style={{ margin: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={salvar}
                                        disabled={botaoClicado}
                                    > Salvar  </Button>
                                </Grid>

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >
                                        <Grid item xs={12}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="area-selector-label">Área</InputLabel>
                                                    <Select
                                                        labelId="area-selector-label"
                                                        id="area-selector"
                                                        value={area}
                                                        onChange={handleChange}
                                                        label="Área"
                                                    >
                                                        <MenuItem value={0}>Segurança</MenuItem>
                                                        <MenuItem value={1}>Refrigeração</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>




                                        <Grid item xs={4}>
                                            <TextField
                                                variant="standard"
                                                name="nomeProjeto"
                                                label="Nome Projeto"
                                                required
                                                id="nomeProjeto"
                                                value={nomeProjeto}
                                                onChange={(e) => setNomeProjeto(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>


                                        <Grid item xs={12} style={{ padding: 40 }}>
                                            <TextField
                                                variant="standard"
                                                name="descricaoProjeto"
                                                label="Descrição Projeto"
                                                required
                                                id="descricaoProjeto"
                                                value={descricaoProjeto}
                                                onChange={(e) => setDescricaoProjeto(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={2}
                                            />
                                        </Grid>




                                    </Grid>

                                    

                                     <Grid
                                                             value={value} index={1}
                                                             style={{ paddingTop: 5 }}
                                                             container
                                                             direction="row"
                                                             item xs={12}
                                                             alignItems="center"
                                                             justifyContent="center"
                                                             spacing={2}
                                                         >
                                 
                                 
                                 
                                 
                                                             <Grid item xs={12} style={{ padding: 40 }}
                                                                 container
                                                                 alignItems="center"
                                                                 justifyContent="center"
                                                             >
                                                                 <Grid item xs={3}>
                                                                     <Card>
                                                                         <CardContent>
                                                                             {imagemSelecionada1 ? (
                                                                                 <div style={{ textAlign: 'center' }}>
                                                                                     <img
                                                                                         src={URL.createObjectURL(imagemSelecionada1)}
                                                                                         alt="Imagem Selecionada 1"
                                                                                         style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                                                     />
                                                                                     <button
                                                                                         style={{
                                                                                             backgroundColor: '#ff4d4f',
                                                                                             color: '#fff',
                                                                                             border: 'none',
                                                                                             padding: '10px 15px',
                                                                                             borderRadius: '5px',
                                                                                             cursor: 'pointer',
                                                                                             marginTop: '10px',
                                                                                         }}
                                                                                         onClick={() => setImagemSelecionada1(null)}
                                                                                     >
                                                                                         Remover Imagem
                                                                                     </button>
                                                                                 </div>
                                                                             ) : (
                                                                                 <div {...getRootProps1()} style={dropzoneStyles}>
                                                                                     <input {...getInputProps1()} />
                                                                                     <Typography align="center" variant="h5">
                                                                                         Arraste e solte uma imagem aqui ou clique para adicionar
                                                                                     </Typography>
                                                                                 </div>
                                                                             )}
                                                                             <Grid item xs={12} style={{ padding: 40 }}>
                                                                                 <TextField
                                                                                     variant="standard"
                                                                                     name="descricaoImagem1"
                                                                                     label="Descrição"
                                                                                     required
                                                                                     id="descricaoImagem1"
                                                                                     value={descricaoImagem1}
                                                                                     onChange={(e) => setDescricaoImagem1(e.target.value)}
                                                                                     fullWidth
                                                                                     multiline
                                                                                     rows={4}
                                                                                 />
                                                                             </Grid>
                                                                         </CardContent>
                                                                     </Card>
                                                                 </Grid>
                                 
                                                                 <Grid item xs={3}>
                                                                     <Card>
                                                                         <CardContent>
                                                                             {imagemSelecionada2 ? (
                                                                                 <div style={{ textAlign: 'center' }}>
                                                                                     <img
                                                                                         src={URL.createObjectURL(imagemSelecionada2)}
                                                                                         alt="Imagem Selecionada 2"
                                                                                         style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                                                     />
                                                                                     <button
                                                                                         style={{
                                                                                             backgroundColor: '#ff4d4f',
                                                                                             color: '#fff',
                                                                                             border: 'none',
                                                                                             padding: '10px 15px',
                                                                                             borderRadius: '5px',
                                                                                             cursor: 'pointer',
                                                                                             marginTop: '10px',
                                                                                         }}
                                                                                         onClick={() => setImagemSelecionada2(null)}
                                                                                     >
                                                                                         Remover Imagem
                                                                                     </button>
                                                                                 </div>
                                                                             ) : (
                                                                                 <div {...getRootProps2()} style={dropzoneStyles}>
                                                                                     <input {...getInputProps2()} />
                                                                                     <Typography align="center" variant="h5">
                                                                                         Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                                                     </Typography>
                                                                                 </div>
                                                                             )}
                                                                             <Grid item xs={12} style={{ padding: 40 }}>
                                                                                 <TextField
                                                                                     variant="standard"
                                                                                     name="descricaoImagem2"
                                                                                     label="Descrição"
                                                                                     required
                                                                                     id="descricaoImagem2"
                                                                                     value={descricaoImagem2}
                                                                                     onChange={(e) => setDescricaoImagem2(e.target.value)}
                                                                                     fullWidth
                                                                                     multiline
                                                                                     rows={4}
                                                                                 />
                                                                             </Grid>
                                                                         </CardContent>
                                                                     </Card>
                                                                 </Grid>
                                 
                                                                 <Grid item xs={3}>
                                                                     <Card>
                                                                         <CardContent>
                                                                             {imagemSelecionada3 ? (
                                                                                 <div style={{ textAlign: 'center' }}>
                                                                                     <img
                                                                                         src={URL.createObjectURL(imagemSelecionada3)}
                                                                                         alt="Imagem Selecionada 3"
                                                                                         style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                                                     />
                                                                                     <button
                                                                                         style={{
                                                                                             backgroundColor: '#ff4d4f',
                                                                                             color: '#fff',
                                                                                             border: 'none',
                                                                                             padding: '10px 15px',
                                                                                             borderRadius: '5px',
                                                                                             cursor: 'pointer',
                                                                                             marginTop: '10px',
                                                                                         }}
                                                                                         onClick={() => setImagemSelecionada3(null)}
                                                                                     >
                                                                                         Remover Imagem
                                                                                     </button>
                                                                                 </div>
                                                                             ) : (
                                                                                 <div {...getRootProps3()} style={dropzoneStyles}>
                                                                                     <input {...getInputProps3()} />
                                                                                     <Typography align="center" variant="h5">
                                                                                         Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                                                     </Typography>
                                                                                 </div>
                                                                             )}
                                                                             <Grid item xs={12} style={{ padding: 40 }}>
                                                                                 <TextField
                                                                                     variant="standard"
                                                                                     name="descricaoImagem3"
                                                                                     label="Descrição"
                                                                                     required
                                                                                     id="descricaoImagem3"
                                                                                     value={descricaoImagem3}
                                                                                     onChange={(e) => setDescricaoImagem3(e.target.value)}
                                                                                     fullWidth
                                                                                     multiline
                                                                                     rows={4}
                                                                                 />
                                                                             </Grid>
                                                                         </CardContent>
                                                                     </Card>
                                                                 </Grid>
                                 
                                 
                                                                 <Grid item xs={3}>
                                                                     <Card>
                                                                         <CardContent>
                                                                             {imagemSelecionada4 ? (
                                                                                 <div style={{ textAlign: 'center' }}>
                                                                                     <img
                                                                                         src={URL.createObjectURL(imagemSelecionada4)}
                                                                                         alt="Imagem Selecionada 4"
                                                                                         style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                                                     />
                                                                                     <button
                                                                                         style={{
                                                                                             backgroundColor: '#ff4d4f',
                                                                                             color: '#fff',
                                                                                             border: 'none',
                                                                                             padding: '10px 15px',
                                                                                             borderRadius: '5px',
                                                                                             cursor: 'pointer',
                                                                                             marginTop: '10px',
                                                                                         }}
                                                                                         onClick={() => setImagemSelecionada4(null)}
                                                                                     >
                                                                                         Remover Imagem
                                                                                     </button>
                                                                                 </div>
                                                                             ) : (
                                                                                 <div {...getRootProps4()} style={dropzoneStyles}>
                                                                                     <input {...getInputProps4()} />
                                                                                     <Typography align="center" variant="h5">
                                                                                         Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                                                     </Typography>
                                                                                 </div>
                                                                             )}
                                                                             <Grid item xs={12} style={{ padding: 40 }}>
                                                                                 <TextField
                                                                                     variant="standard"
                                                                                     name="descricaoImagem4"
                                                                                     label="Descrição"
                                                                                     required
                                                                                     id="descricaoImagem4"
                                                                                     value={descricaoImagem4}
                                                                                     onChange={(e) => setDescricaoImagem4(e.target.value)}
                                                                                     fullWidth
                                                                                     multiline
                                                                                     rows={4}
                                                                                 />
                                                                             </Grid>
                                                                         </CardContent>
                                                                     </Card>
                                                                 </Grid>
                                 
                                                             </Grid>
                                 
                                                         </Grid>
                                 
                                                         <Grid
                                                             value={value} index={2}
                                                             style={{ paddingTop: 5 }}
                                                             container
                                                             direction="row"
                                                             item xs={12}
                                                             alignItems="center"
                                                             justifyContent="center"
                                                             spacing={2}
                                                         >
                                 
                                 
                                 
                                                             <Grid item xs={12} style={{ padding: 40 }}
                                                                 container
                                                                 alignItems="center"
                                                                 justifyContent="center"
                                                             >
                                 
                                                                 <Grid item xs={8}>
                                                                     <Card>
                                                                         <CardContent>
                                                                             {videoSelecionado1 ? (
                                                                                 <div style={{ textAlign: 'center' }}>
                                                                                     <video
                                                                                         controls
                                                                                         src={URL.createObjectURL(videoSelecionado1)}
                                                                                         alt="Vídeo Selecionado 1"
                                                                                         style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                                                     />
                                                                                     <button
                                                                                         style={{
                                                                                             backgroundColor: '#ff4d4f',
                                                                                             color: '#fff',
                                                                                             border: 'none',
                                                                                             padding: '10px 15px',
                                                                                             borderRadius: '5px',
                                                                                             cursor: 'pointer',
                                                                                             marginTop: '10px',
                                                                                         }}
                                                                                         onClick={() => setVideoSelecionado1(null)}
                                                                                     >
                                                                                         Remover
                                                                                     </button>
                                                                                 </div>
                                                                             ) : (
                                                                                 <div>
                                                                                     <div {...getRootVideoProps1()} style={dropzoneStyles}>
                                                                                         <input {...getInputVideoProps1()} />
                                                                                         <Typography align="center" variant="h5">
                                                                                             Arraste e solte um vídeo aqui ou clique para adicionar
                                                                                         </Typography>
                                 
                                                                                     </div>
                                                                                     <div> {isRecording ? (
                                                                                         <button onClick={stopRecording}>Parar Gravação</button>
                                                                                     ) : (
                                                                                         <button onClick={startRecording}>Iniciar Gravação</button>
                                                                                     )}</div>
                                                                                 </div>
                                 
                                 
                                                                             )}
                                                                             <Grid item xs={12} style={{ padding: 40 }}>
                                                                                 <TextField
                                                                                     variant="standard"
                                                                                     name="descricaoVideo1"
                                                                                     label="Descrição"
                                                                                     required
                                                                                     id="descricaoVideo1"
                                                                                     value={descricaoVideo1}
                                                                                     onChange={(e) => setDescricaoVideo1(e.target.value)}
                                                                                     fullWidth
                                                                                     multiline
                                                                                     rows={4}
                                                                                 />
                                                                             </Grid>
                                                                         </CardContent>
                                                                     </Card>
                                                                 </Grid>
                                 
                                                                 <Grid item xs={8}>
                                                                     <Card>
                                                                         <CardContent>
                                                                             {videoSelecionado2 ? (
                                                                                 <div style={{ textAlign: 'center' }}>
                                                                                     <video
                                                                                         controls
                                                                                         src={URL.createObjectURL(videoSelecionado2)}
                                                                                         alt="Vídeo Selecionado 2"
                                                                                         style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                                                     />
                                                                                     <button
                                                                                         style={{
                                                                                             backgroundColor: '#ff4d4f',
                                                                                             color: '#fff',
                                                                                             border: 'none',
                                                                                             padding: '10px 15px',
                                                                                             borderRadius: '5px',
                                                                                             cursor: 'pointer',
                                                                                             marginTop: '10px',
                                                                                         }}
                                                                                         onClick={() => setVideoSelecionado2(null)}
                                                                                     >
                                                                                         Remover
                                                                                     </button>
                                                                                 </div>
                                                                             ) : (
                                                                                 <div {...getRootVideoProps2()} style={dropzoneStyles}>
                                                                                     <input {...getInputVideoProps2()} />
                                                                                     <Typography align="center" variant="h5">
                                                                                         Arraste e solte um vídeo aqui ou clique para adicionar
                                                                                     </Typography>
                                                                                     {isRecording ? (
                                                                                         <button onClick={stopRecording}>Parar Gravação</button>
                                                                                     ) : (
                                                                                         <button onClick={startRecording}>Iniciar Gravação</button>
                                                                                     )}
                                                                                 </div>
                                                                             )}
                                                                             <Grid item xs={12} style={{ padding: 40 }}>
                                                                                 <TextField
                                                                                     variant="standard"
                                                                                     name="descricaoVideo2"
                                                                                     label="Descrição"
                                                                                     required
                                                                                     id="descricaoVideo2"
                                                                                     value={descricaoVideo2}
                                                                                     onChange={(e) => setDescricaoVideo2(e.target.value)}
                                                                                     fullWidth
                                                                                     multiline
                                                                                     rows={4}
                                                                                 />
                                                                             </Grid>
                                                                         </CardContent>
                                                                     </Card>
                                                                 </Grid>
                                 
                                                                 <Grid item xs={8}>
                                                                     <Card>
                                                                         <CardContent>
                                                                             {videoSelecionado3 ? (
                                                                                 <div style={{ textAlign: 'center' }}>
                                                                                     <video
                                                                                         controls
                                                                                         src={URL.createObjectURL(videoSelecionado3)}
                                                                                         alt="Vídeo Selecionado 3"
                                                                                         style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                                                     />
                                                                                     <button
                                                                                         style={{
                                                                                             backgroundColor: '#ff4d4f',
                                                                                             color: '#fff',
                                                                                             border: 'none',
                                                                                             padding: '10px 15px',
                                                                                             borderRadius: '5px',
                                                                                             cursor: 'pointer',
                                                                                             marginTop: '10px',
                                                                                         }}
                                                                                         onClick={() => setVideoSelecionado3(null)}
                                                                                     >
                                                                                         Remover
                                                                                     </button>
                                                                                 </div>
                                                                             ) : (
                                                                                 <div {...getRootVideoProps3()} style={dropzoneStyles}>
                                                                                     <input {...getInputVideoProps3()} />
                                                                                     <Typography align="center" variant="h5">
                                                                                         Arraste e solte um vídeo aqui ou clique para adicionar
                                                                                     </Typography>
                                                                                     {isRecording ? (
                                                                                         <button onClick={stopRecording}>Parar Gravação</button>
                                                                                     ) : (
                                                                                         <button onClick={startRecording}>Iniciar Gravação</button>
                                                                                     )}
                                                                                 </div>
                                                                             )}
                                                                             <Grid item xs={12} style={{ padding: 40 }}>
                                                                                 <TextField
                                                                                     variant="standard"
                                                                                     name="descricaoVideo3"
                                                                                     label="Descrição"
                                                                                     required
                                                                                     id="descricaoVideo3"
                                                                                     value={descricaoVideo3}
                                                                                     onChange={(e) => setDescricaoVideo3(e.target.value)}
                                                                                     fullWidth
                                                                                     multiline
                                                                                     rows={4}
                                                                                 />
                                                                             </Grid>
                                                                         </CardContent>
                                                                     </Card>
                                                                 </Grid>
                                                             </Grid>
                                                         </Grid>
                                 
                                                         <Grid
                                                             value={value} index={3}
                                                             style={{ paddingTop: 5 }}
                                                             container
                                                             direction="row"
                                                             item xs={12}
                                                             alignItems="center"
                                                             justifyContent="center"
                                                             spacing={2}
                                                         >
                                 
                                 
                                 
                                 
                                                             <Grid item xs={12} style={{ padding: 5 }}
                                                                 container
                                                                 alignItems="center"
                                                                 justifyContent="center"
                                                             >
                                                                 <Grid item xs={8}>
                                                                     <Card>
                                                                         <CardContent>
                                                                             {arquivoSelecionado1 ? (
                                 
                                                                                 <div style={{ textAlign: 'center' }}>
                                                                                     <div
                                                                                         href={URL.createObjectURL(arquivoSelecionado1)}
                                                                                         onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                                                                         onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado1))}
                                                                                         style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}
                                                                                     >
                                                                                         <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                                                     </div>
                                                                                     <div style={{ textAlign: 'center' }}>
                                                                                         <button
                                                                                             style={{
                                                                                                 backgroundColor: '#ff4d4f',
                                                                                                 color: '#fff',
                                                                                                 border: 'none',
                                                                                                 padding: '10px 15px',
                                                                                                 borderRadius: '5px',
                                                                                                 cursor: 'pointer',
                                                                                                 marginTop: '10px',
                                                                                             }}
                                                                                             onClick={() => setArquivoSelecionado1(null)}
                                                                                         >
                                                                                             Remover
                                                                                         </button>
                                                                                     </div>
                                                                                 </div>
                                 
                                 
                                                                             ) : (
                                                                                 <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                                                                     <input {...getInputArquivoProps1()} />
                                                                                     <Typography align="center" variant="h5">
                                                                                         Arraste e solte um arquivo aqui ou clique para adicionar
                                                                                     </Typography>
                                                                                 </div>
                                                                             )}
                                                                             <Grid item xs={12} style={{ padding: 40 }}>
                                                                                 <TextField
                                                                                     variant="standard"
                                                                                     name="descricaoArquivo1"
                                                                                     label="Descrição"
                                                                                     id="descricaoArquivo1"
                                                                                     value={descricaoArquivo1}
                                                                                     onChange={(e) => setDescricaoArquivo1(e.target.value)}
                                                                                     fullWidth
                                                                                     multiline
                                                                                     minRows={2}
                                                                                 />
                                                                             </Grid>
                                                                         </CardContent>
                                                                     </Card>
                                                                 </Grid>
                                 
                                                                 <Grid item xs={8}>
                                                                     <Card>
                                                                         <CardContent>
                                                                             {arquivoSelecionado2 ? (
                                                                                 <div style={{ textAlign: 'center' }}>
                                                                                     <div
                                                                                         href={URL.createObjectURL(arquivoSelecionado2)}
                                                                                         onClick={() => window.open(URL.createObjectURL(arquivoSelecionado2), '_blank')}
                                                                                         onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado2))}
                                                                                         style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}
                                                                                     >
                                                                                         <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                                                     </div>
                                                                                     <div style={{ textAlign: 'center' }}>
                                                                                         <button
                                                                                             style={{
                                                                                                 backgroundColor: '#ff4d4f',
                                                                                                 color: '#fff',
                                                                                                 border: 'none',
                                                                                                 padding: '10px 15px',
                                                                                                 borderRadius: '5px',
                                                                                                 cursor: 'pointer',
                                                                                                 marginTop: '10px',
                                                                                             }}
                                                                                             onClick={() => setArquivoSelecionado2(null)}
                                                                                         >
                                                                                             Remover
                                                                                         </button>
                                                                                     </div>
                                                                                 </div>
                                                                             ) : (
                                                                                 <div {...getRootArquivoProps2()} style={dropzoneStyles}>
                                                                                     <input {...getInputArquivoProps2()} />
                                                                                     <Typography align="center" variant="h5">
                                                                                         Arraste e solte um arquivo aqui ou clique para adicionar
                                                                                     </Typography>
                                                                                 </div>
                                                                             )}
                                                                             <Grid item xs={12} style={{ padding: 40 }}>
                                                                                 <TextField
                                                                                     variant="standard"
                                                                                     name="descricaoArquivo2"
                                                                                     label="Descrição"
                                                                                     id="descricaoArquivo2"
                                                                                     value={descricaoArquivo2}
                                                                                     onChange={(e) => setDescricaoArquivo2(e.target.value)}
                                                                                     fullWidth
                                                                                     multiline
                                                                                     minRows={2}
                                                                                 />
                                                                             </Grid>
                                                                         </CardContent>
                                                                     </Card>
                                                                 </Grid>
                                 
                                                                 <Grid item xs={8}>
                                                                     <Card>
                                                                         <CardContent>
                                                                             {arquivoSelecionado3 ? (
                                                                                 <div style={{ textAlign: 'center' }}>
                                                                                     <div
                                                                                         href={URL.createObjectURL(arquivoSelecionado3)}
                                                                                         onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                                                                         onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado3))}
                                                                                         style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}
                                                                                     >
                                                                                         <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                                                     </div>
                                                                                     <div style={{ textAlign: 'center' }}>
                                                                                         <button
                                                                                             style={{
                                                                                                 backgroundColor: '#ff4d4f',
                                                                                                 color: '#fff',
                                                                                                 border: 'none',
                                                                                                 padding: '10px 15px',
                                                                                                 borderRadius: '5px',
                                                                                                 cursor: 'pointer',
                                                                                                 marginTop: '10px',
                                                                                             }}
                                                                                             onClick={() => setArquivoSelecionado3(null)}
                                                                                         >
                                                                                             Remover
                                                                                         </button>
                                                                                     </div>
                                                                                 </div>
                                                                             ) : (
                                                                                 <div {...getRootArquivoProps3()} style={dropzoneStyles}>
                                                                                     <input {...getInputArquivoProps3()} />
                                                                                     <Typography align="center" variant="h5">
                                                                                         Arraste e solte um arquivo aqui ou clique para adicionar
                                                                                     </Typography>
                                                                                 </div>
                                                                             )}
                                                                             <Grid item xs={12} style={{ padding: 40 }}>
                                                                                 <TextField
                                                                                     variant="standard"
                                                                                     name="descricaoArquivo3"
                                                                                     label="Descrição"
                                                                                     id="descricaoArquivo3"
                                                                                     value={descricaoArquivo3}
                                                                                     onChange={(e) => setDescricaoArquivo3(e.target.value)}
                                                                                     fullWidth
                                                                                     multiline
                                                                                     minRows={2}
                                                                                 />
                                                                             </Grid>
                                                                         </CardContent>
                                                                     </Card>
                                                                 </Grid>
                                 
                                                             </Grid>
                                                         </Grid>

                                 
                                </SwipeableViews>







                            </Grid>





                        </Grid>


                    </React.Fragment>


                </Container>
            }

        </div >
    );
}


