import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@mui/material';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Ícone para "finalizar"
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import TextField from '@material-ui/core/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import Cookies from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { styled } from '@material-ui/styles';
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckIcon from '@mui/icons-material/Check';
import { useDropzone } from 'react-dropzone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';

import api from '../../../../../services/api';
import SNARonda from '../../../../../pages/painelAdmin/AreaGestao/ModuloOperacional/RondaVirtual/snaRonda';

import CadastrarProcedimentoAvulso from './cadastrarProcedimentoAvulso.js';
import CabecalhoInformacoes from './CabecalhoInformacoes.js';
import CabecalhoInformacoes2 from './CabecalhoInformacoes2.js';


import MenuRecursosAdministrativos from '../components/menu';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { AddToQueueOutlined, LaptopWindows } from '@material-ui/icons';

import GerenciarTratamentoEmCampo from './gerenciarTratamentoEmCampo';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'; // Ícone apropriado
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';



const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'whit',
        borderColor: 'red',
    },
}));


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },

    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'whit',
        borderColor: 'blue',
    },
}));




const dropzoneStyles = {
    border: '4px dashed #000000',
    borderRadius: '4px',
    padding: '2px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '2px 0',
};



export default function EditorOrdemServico({ idOrdemServico: idOrdemServicoProp, enterEstoque: enterEstoqueProp }) {


    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [ordemServico, setOrdemServico] = useState();
    // const { idOrdemServico } = useParams();
    const { idOrdemServico: idOrdemServicoParams } = useParams(); // Se estiver utilizando URL params

    const [limiar, setLimiar] = useState(900);

    const [categoria, setCategoria] = useState(0);
    const [projetoSelecionado, setProjetoSelecionado] = useState(null);
    const [propostaSelecionada, setPropostaSelecionada] = useState(null);


    const [rondaVirtual, setRondaVirtual] = useState(null);
    const [openSnaRondaVirtual, setOpenSnaRondaVirtual] = useState(false);

    const [larguraOriginal, setLarguraOriginal] = useState(0);

    function checkDimenssoes() {
        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        setLarguraOriginal(largura);


    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });



    useEffect(() => {

        //alert("Regras: " + regra);

        checkDimenssoes();

    }, []);


    const handleCloseSnaRondaVirtual = () => {

        setOpenSnaRondaVirtual(false);
    };


    const onDropArquivo1 = useCallback(async (acceptedFiles) => {
        // Valida o tipo de arquivo
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            alert('Apenas arquivos Excel são permitidos.');
            return;
        }

        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            const formData = new FormData();
            formData.append('arquivos', acceptedFiles[0]);
            formData.append('extensao', acceptedFiles[0].name.split('.').pop());


            await api.post("/v1/protected/ordemservico/listarequerimentos/upload/" + idOrdemServico, formData, {
                headers: headers
            }).then(function (response) {

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {


                    setOrdemServico(response.data.ordem);
                    const sortedItensPreRequerimento = response.data.ordem.itens_pre_requerimento.sort((a, b) => a.id_item_na_lista - b.id_item_na_lista);

                    // Atualiza o estado com a ordem de serviço e os itens ordenados
                    setOrdemServico({ ...response.data.ordem, itens_pre_requerimento: sortedItensPreRequerimento });


                    api.get("v1/protected/almoxarifado/estoque/listarporinsumo", {
                        headers: headers
                    }).then(function (response) {
                        setInsumos(response.data)
                    });

                    alert(cadastro_salvo.mensagem);

                } else {
                    // Lógica a ser executada caso ocorra algum erro durante o salvamento
                    alert("Erro ao processar, insira os itens manualmente!");
                }


            });






        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
        }
    }, []);


    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        //accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });

    const idOrdemServico = idOrdemServicoProp || idOrdemServicoParams;

    const { idEntradaEstoque } = useParams();


    const [entradaEstoque, setEntradaEstoque] = useState(null);

    const [solicitante, setSolicitante] = useState(null);
    const [solicitantesSelecionados, setSolicitantesSelecionados] = useState([]);

    const [analista, setAnalista] = useState(null);

    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);
    const [descricao, setDescricao] = useState('');
    const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState(null);


    const [observacoesItem, setObservacoesItem] = useState('');
    const [descricaoNovoInsumo, setDescricaoNovoInsumo] = useState('');


    const [usuarioSelecionadoTratarRemoto, setUsuarioSelecionadoTratarRemoto] = useState(null);
    const [usuarioSelecionadoTratarEmCampo, setUsuarioSelecionadoTratarEmCampo] = useState(null);

    function returnDescricaoInsumo(insumo_) {

        let insumo = insumo_.insumo;
        let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao;

        if (insumo.variacoes && insumo.variacoes.length > 0) {
            textoComQuebraDeLinha += insumo.variacoes

                .map((variacao, index) => (
                    `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                )).join('');
        }

        var texto =

            //"<br />Marca/Modelo: " + insumo.marca + "/" + insumo.modelo +
            // "<br />Código Fornecedor: " + insumo.codigo_fornecedor +
            //"<br />Tipo: " + insumo.tipo +
            //"<br />Categoria: " + insumo.categoria +
            textoComQuebraDeLinha + "<br />Estoque: " + (Array.isArray(insumo_.itens_estoque) ? insumo_.itens_estoque.length : 0) + " unidades";
        return texto;
    }





    function returnDescricaoInsumoSimplificada(insumo) {


        var texto =


            "Descrição: " + insumo.descricao;
        return texto;
    }



    const [insumos, setInsumos] = useState([]);
    const [insumoSelecionado, setInsumoSelecionado] = useState(null);
    const [quantidade, setQuantidade] = useState(0);

    function handleSetInsumo(insumo) {
        setInsumoSelecionado(insumo);
    }


    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/ordemservico/listarporid/" + idOrdemServico, {
                headers: headers
            }).then(function (response) {
                setOrdemServico(response.data);

                try {
                    const sortedItensPreRequerimento = response.data.itens_pre_requerimento.sort((a, b) => a.id_item_na_lista - b.id_item_na_lista);

                    // Atualiza o estado com a ordem de serviço e os itens ordenados
                    setOrdemServico({ ...response.data, itens_pre_requerimento: sortedItensPreRequerimento });
                } catch (_err) {

                }


                setDescricao(response.data.descricao);
                setSolicitante(response.data.usuario_solicitante);
                setSolicitacaoSelecionada(response.data.solicitacao);

                setCategoria(response.data.categoria_ordem);
                console.log("Categoria: " + response.data.categoria_ordem);
                setPropostaSelecionada(response.data.proposta);
                setProjetoSelecionado(response.data.projeto);

                setAnalista(response.data.analista);

                setSolicitantesSelecionados(response.data.solicitantes);
                setInstalacaoSelecionada(response.data.instalacao);

                if (parseInt(response.data.id_ronda_virtual, 10) > 0)
                    api.get("/v1/protected/rondavirtual/listarporid/" + response.data.id_ronda_virtual, {
                        headers: headers
                    }).then(function (response) {
                        setRondaVirtual(response.data);
                    });


                /*
                                api.get("v1/protected/almoxarifado/insumos/listar", {
                                    headers: headers
                                }).then(function (response) {
                                    setInsumos(response.data)
                
                                });
                   */

                api.get("v1/protected/almoxarifado/estoque/listarporinsumo", {
                    headers: headers
                }).then(function (response) {
                    setInsumos(response.data)

                    console.log(" Objetos: " + response);
                    setLoading(false);


                });




                if (response.data.solicitacao.id_solicitacao === 5) {
                    //solicitacao de entrada de estoque, checklist
                    api.get("v1/protected/almoxarifado/entradaestoque/listarporos/" + response.data.id_ordem_servico, {
                        headers: headers
                    }).then(function (response) {

                        setEntradaEstoque(response.data)

                        console.log(" Objetos: " + response);
                        setLoading(false);


                    });
                } else {
                    setLoading(false);

                }


            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        handleChangeIndex(parseInt(Cookies.get("abaPaginaGerenciarOs"), 10));
        listarInformacoes();


    }, []);



    const [etapaProcedimentoACadastrar, setEtapaProcedimentoACadastrar] = useState(0);
    const [openCadastrarProcedimento, setOpenCadastrarProcedimento] = useState(false);

    function novoProcedimento(etapaProcedimento) {
       // window.location.href = "/gestao/administracao/ordensdeservicos/cadastrarprocedimentoavulso/" + idOrdemServico + "/" + etapaProcedimento + "/0";
    
       setEtapaProcedimentoACadastrar(etapaProcedimento);
       setOpenCadastrarProcedimento(true);

    }

    function handleCloseCadastrarProcedimento(){
        setEtapaProcedimentoACadastrar(0);
        setOpenCadastrarProcedimento(false);

        //atualizar lista de procedimentos 
        listarInformacoes();
 
    }



    const handleClickAtualizarProcedimento = (proc) => {

        if (proc.usuario_executor) {
            if (parseInt(proc.usuario_executor.id_usuario, 10) !== parseInt(Cookies.get("id_usuario"), 10)) {
                alert("Um usuário foi designado para este procedimento, apenas ele pode executar esta ação!");
                return;
            } else {
                window.location.href = "/gestao/administracao/ordensdeservicos/atualizarprocedimentoexecucao/" + proc.id_procedimento_execucao + "/ordemservico/" + ordemServico.id_ordem_servico + "/2/" + idEntradaEstoque;

            }
        } else {
            //nao tem um usuario executor, verificar entao se o usuario é o usuario da O.S
            if (parseInt(ordemServico.usuario_executor_atual.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {
                window.location.href = "/gestao/administracao/ordensdeservicos/atualizarprocedimentoexecucao/" + proc.id_procedimento_execucao + "/ordemservico/" + ordemServico.id_ordem_servico + "/2/" + idEntradaEstoque;


            } else {
                alert("Somente o Analista ou o Usuário designado pode executar esta ação!");
                return;
            }
        }



    };


    const handleClickVizualizarProcedimento = (id) => {
        window.open(`/gestao/administracao/ordensdeservicos/vizualizarprocedimentoexecucao/${id}/ordemservico/${ordemServico.id_ordem_servico}`, '_blank');
    };


    const handleClickVizualizarProcedimentoTratamentoRemoto = (id) => {
        window.open(`/gestao/administracao/ordensdeservicos/vizualizarprocedimentotratamentoremoto/${id}/ordemservico/${ordemServico.id_ordem_servico}`, '_blank');
    };



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        Cookies.set("abaPaginaGerenciarOs", newValue);
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        Cookies.set("abaPaginaGerenciarOs", index);
        setValue(index);
    };



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



  
   



    function handleSolucaoTratamentoRemoto(event) {
        setSolucaoTratamentoRemoto(event.target.value);
    }




    const [open, setOpen] = useState(false);
    const [openTratamentoEmCampo, setOpenTratamentoEmCampo] = useState(false);

    const [descricaoAnalise, setDescricaoAnalise] = useState("");

    const [openTratamentoRemoto, setOpenTratamentoRemoto] = useState(false);
    const [descricaoTratamentoRemoto, setDescricaoTratamentoRemoto] = useState("");
    const [solucaoTratamentoRemoto, setSolucaoTratamentoRemoto] = useState(0);

    const [descricaoTratamentoEmCampo, setDescricaoTratamentoEmCampo] = useState("");

    const handleOpen = (event, cellValues) => {

        setOpen(true);

    }


    const handleClose = () => {
        setOpen(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };



    const verificarObrigatorioRemotoStatus = (ordemServico) => {
        return ordemServico.procedimentos_tratamento_remoto.some((procedimento_tratamento_remoto) => {
            return procedimento_tratamento_remoto.procedimento.obrigatorio === 1 && procedimento_tratamento_remoto.status === 0;
        });
    };

    async function handleOpenTratamentoRemoto() {
        if (parseInt(ordemServico.usuario_executor_atual.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {


            const result = verificarObrigatorioRemotoStatus(ordemServico);
            if (result) {
                alert("Existem Procedimentos Obrigatórios Pendentes, atualize-os!")
            } else {

                try {

                    const token = Cookies.get('token');

                    const headers = {
                        'Authorization': 'Bearer ' + token
                    }

                    await api.get("/v1/protected/user/listartodos", {
                        headers: headers
                    }).then(function (response) {
                        setUsuarios(response.data)
                        setOpenTratamentoRemoto(true);

                    });
                } catch (_err) {
                    // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                    console.log("Erro ao listar Usuarios: " + _err)

                }
            }

        } else {
            alert("Somente o Analista ou o Usuário designado pode executar esta ação!");
            return;
        }


    }


    const handleCloseTratamentoRemoto = () => {
        setOpenTratamentoRemoto(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };




    const handleCloseTratamentoEmCampo = () => {
        setOpenTratamentoEmCampo(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };

    const verificarObrigatorioStatus = (ordemServico) => {
        return ordemServico.procedimentos_analise.some((procedimento_analise) => {
            return procedimento_analise.procedimento.obrigatorio === 1 && procedimento_analise.status === 0;
        });
    };

    const handleGerenciarTratamentoEmCampo = () => {
        window.location.href = `/gestao/administracao/ordensdeservicos/gerenciartratamentoemcampo/${ordemServico.id_ordem_servico}`;
    };





    async function handleFinalizar() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {
                descricao_analise: descricaoAnalise,
                analista_tratamento_remoto: { id_usuario: parseInt(Cookies.get("id_usuario")) },
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/ordemservico/finalizarAnalise/' + idOrdemServico + "/" + parseInt(Cookies.get("id_usuario")), cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("A Ordem de Serviço foi atualizada!");
                setOpen(false);
                window.location.reload(true);

            }



        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }




    const resultadoTratamentoRemoto = () => {
        const status_ordem = ordemServico.status_ordem;
        const solucionado = ordemServico.solucionado_tratamento_remoto;

        let texto_status = "";
        let cor_fundo = 'white';

        if (status_ordem >= 2) {
            //tratamento remoto avançado
            if (solucionado === 1) {
                texto_status = "Status: ✅ Tratamento Remoto Finalizado Solucionando a Solicitação";
                cor_fundo = '#d4f8d4';
            } else {
                texto_status = "Status: ❌ Tratamento Remoto Finalizado | Necessário Tratamento Em Campo";
                cor_fundo = '#F08080';

            }
        } else if (status_ordem === 1) {
            texto_status = "Status: ⏳ Tratamento Remoto em andamento";
            cor_fundo = '#ADD8E6';

        }



        return (
            <Typography variant="h7"
                style={{
                    border: '1px solid #a2d5a2', // Verde um pouco mais escuro que o fundo
                    backgroundColor: cor_fundo, // Fundo verde claro
                    borderRadius: 5, // Bordas arredondadas (opcional)
                }}
            >
                {texto_status}
                {status_ordem >= 2 && ` às ${moment(ordemServico.data_hora_finalizacao_tratamento_remoto, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}
            </Typography>
        )
    }



    const resultadoAnalisePrevia = () => {
        const status_ordem = ordemServico.status_ordem;

        let texto_status = "";
        let cor_fundo = 'white';

        if (status_ordem == 2) {
            texto_status = "Status: ⏳ Análise Prévia em andamento";
            cor_fundo = '#d4f8d4';

        }



        return (
            <Typography variant="h7"
                style={{
                    border: '1px solid #a2d5a2', // Verde um pouco mais escuro que o fundo
                    backgroundColor: cor_fundo, // Fundo verde claro
                    borderRadius: 5, // Bordas arredondadas (opcional)
                }}
            >
                {texto_status}
                {status_ordem > 2 && ` às ${moment(ordemServico.data_hora_finalizacao_analise, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}
            </Typography>
        )
    }


    const resultadoTratamentoEmCampo = () => {
        const status_ordem = ordemServico.status_ordem;
        const solucionado = ordemServico.solucionado_tratamento_remoto;

        let texto_status = "";

        if (status_ordem >= 3 && status_ordem !== 9) {
            texto_status = "Status: Em Tratamento de Campo";
        } else if (status_ordem > 3) {
            //tratamento remoto avançado
            if (solucionado === 1) {
                texto_status = "Status: Tratamento Remoto Finalizado Solucionando a Solicitação";

            } else {
                texto_status = "Status: Tratamento Remoto Finalizado | Necessário Tratamento Em Campo";

            }
        }



        return (

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item xs={12}
                style={{ padding: 5 }}
            >

                <Typography variant="h7">
                    {texto_status} <br />
                    {ordemServico.data_hora_finalizacao_tratamento_em_campo !== null ? `às ${moment(ordemServico.data_hora_finalizacao_tratamento_em_campo, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}` : null}
                </Typography>

            </Grid>
        )
    }


    async function handleFinalizarTratamentoRemoto() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {
                descricao_tratamento_remoto: descricaoTratamentoRemoto,
                solucionado_tratamento_remoto: solucaoTratamentoRemoto,
                analista_tratamento_em_campo: { id_usuario: parseInt(Cookies.get("id_usuario")) },
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/ordemservico/finalizartratamentoremotoirparaanaliseprevia/' + idOrdemServico + "/" + parseInt(Cookies.get("id_usuario")), cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("A Ordem de Serviço foi atualizada!");
                setOpen(false);
                window.location.reload(true);

            }



        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }







    async function adicionarItem() {

        if (!insumoSelecionado) {
            if (descricaoNovoInsumo.length <= 0) {
                alert("Selecione o Insumo ou Insira a descrição para um novo Insumo!");
                return;
            } else {

            }

        }

        if (!quantidade || parseInt(quantidade) <= 0) {
            alert("Informe a quantidade!");
            return;
        }

        try {

            console.log("cadastrar chamado");
            const cadastro = {
                observacoes: observacoesItem,
                quantidade: parseInt(quantidade),
                insumo: insumoSelecionado !== null ? { id_insumo: insumoSelecionado.insumo.id_insumo } : null,
                descricao: descricaoNovoInsumo,

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.put("/v1/protected/ordemservico/editarrequerimento/" + idOrdemServico + "/" + id_usuario_logado + "/inseriritem", cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Adicionado!");
                listarInformacoes();

                setInsumoSelecionado(null);
                setQuantidade(0);
                setObservacoesItem('');
                setDescricao('');

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }




    }






    async function finalizarRequerimento() {


        const confirmacao = window.confirm("Prosseguir com finalização do Requerimento de Itens?");


        if (confirmacao) {
            try {

                console.log("cadastrar chamado");

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


                const response = await api.put("/v1/protected/ordemservico/finalizarrequerimento/" + idOrdemServico + "/" + id_usuario_logado,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    alert("Requerimento de Itens Finalizado!");
                    window.location.reload();


                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }

        }


    }


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };


    const [usuarios, setUsuarios] = useState([]);

    async function finalizarAnalise() {

        if (parseInt(ordemServico.usuario_executor_atual.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {



            const result = verificarObrigatorioStatus(ordemServico);
            if (result) {
                alert("Existem Procedimentos Obrigatórios Pendentes, atualize-os!")
            } else {

                try {

                    const token = Cookies.get('token');

                    const headers = {
                        'Authorization': 'Bearer ' + token
                    }

                    await api.get("/v1/protected/user/listartodos", {
                        headers: headers
                    }).then(function (response) {
                        setUsuarios(response.data)
                        handleOpen();

                    });
                } catch (_err) {
                    // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                    console.log("Erro ao listar Usuarios: " + _err)

                }
            }

        } else {
            alert("Somente o Analista ou o Usuário designado pode executar esta ação!");
            return;
        }

    }



    const [mensagemCancelamento, setMensagemCancelamento] = useState("");
    const [openCancelarProcedimento, setOpenCancelarProcedimento] = useState(false);
    const [idProcedimentoCancelar, setIdProcedimentoCancelar] = useState(false);

    const handleCloseCancelarProcedimento = () => {
        setMensagemCancelamento("");
        setIdProcedimentoCancelar(0);
        setOpenCancelarProcedimento(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };


    function handleClickOpenCancelarProcedimento(proc) {

        if (proc.usuario_executor) {
            if (parseInt(proc.usuario_executor.id_usuario, 10) !== parseInt(Cookies.get("id_usuario"), 10)) {
                alert("Um usuário foi designado para este procedimento, apenas ele pode executar esta ação!");
                return;
            } else {
                //sou o usuario executor do procedimento, 
                setMensagemCancelamento("");
                setIdProcedimentoCancelar(proc.id_procedimento_execucao);
                setOpenCancelarProcedimento(true);
            }
        } else {
            //nao tem um usuario executor, verificar entao se o usuario é o usuario da O.S
            if (parseInt(ordemServico.usuario_executor_atual.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {
                setMensagemCancelamento("");
                setIdProcedimentoCancelar(proc.id_procedimento_execucao);
                setOpenCancelarProcedimento(true);

            } else {
                alert("Somente o Analista ou o Usuário designado pode executar esta ação!");
                return;
            }
        }




    };


    async function handleClickCancelarProcedimento() {
        if (parseInt(ordemServico.usuario_executor_atual.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {
            const resposta = window.confirm("Confirmar cancelamento do procedimento?");

            if (resposta) {
                console.log(`Procedimento com ID ${idProcedimentoCancelar} cancelado.`);
                try {

                    const cadastro = {
                        mensagem_cancelamento: mensagemCancelamento,
                    }
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }
                    const response = await api.put('/v1/protected/ordemservico/cancelarprocedimento/' + idProcedimentoCancelar, cadastro,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Procedimento foi cancelado!");
                        handleCloseCancelarProcedimento();
                        listarInformacoes();
                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }


                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }

            } else {
                return;
            }

        } else {
            alert("Somente o Analista ou o Usuário designado pode executar esta ação!");
            return;
        }
    };

    async function handleClickIniciarProcedimento(proc) {

        let process = false;
        if (proc.usuario_executor) {
            if (parseInt(proc.usuario_executor.id_usuario, 10) !== parseInt(Cookies.get("id_usuario"), 10)) {
                alert("Um usuário foi designado para este procedimento, apenas ele pode executar esta ação!");
                return;
            } else {
                process = true;
            }
        } else {
            if (parseInt(ordemServico.usuario_executor_atual.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {

                process = true;
            } else {
                alert("Somente o Analista ou o Usuário designado pode executar esta ação!");
                return;
            }
        }

        if (process) {
            const resposta = window.confirm("Sinalizar inicio da execução do procedimento?");

            if (resposta) {
                try {

                    const cadastro = {
                        usuario_criador: { id_usuario: Cookies.get("id_usuario") },
                    }
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }
                    const response = await api.put('/v1/protected/ordemservico/iniciarprocedimento/' + proc.id_procedimento_execucao, cadastro,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Procedimento foi sinalizado como iniciado!");
                        listarInformacoes();
                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }


                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }

            } else {
                return;
            }
        }

    };





    function returnDescricaoUsuario(usuario) {

        try {
            var texto = "ID_Usuario: " + usuario.id_usuario + "\nNome: " + usuario.nome_amigavel + "\nRegiões de Atuação: " + usuario.regiao_cobertura;

            var dados_colaborador = "";

            if (usuario.tipo_associacao === 0) {
                //colaborador
                dados_colaborador += ("\nDepartamentos: " + usuario.colaborador.departamentos.map(departamento => departamento.nome).join(", "));
                dados_colaborador += ("\nCargos: " + usuario.colaborador.cargos.map(cargo => cargo.nome).join(", "));
                dados_colaborador += ("\nFunções: " + usuario.colaborador.funcoes.map(funcao => funcao.nome).join(", "));


            }

            texto += ("\n" + dados_colaborador);


            return texto;
        } catch (_err) {
            console.log("erro returnDescricaoUsuario " + _err);
            return "Dado invalido";
        }


    }



    async function handleClickRemocao(event, cellValues) {



        try {

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.delete("/v1/protected/ordemservico/editarrequerimento/" + idOrdemServico + "/" + id_usuario_logado + "/removeritem/" + cellValues.row.id_item_requerimento,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Removido!");
                listarInformacoes();


            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


    };



    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {

                return (
                    <ColorButtonRed variant="contained" color="primary"
                        onClick={(event) => handleClickRemocao(event, cellValues)}

                    >
                        Remover
                    </ColorButtonRed>
                )
            },
        },
        {
            headerName: 'Item',
            field: 'id_item_na_lista',
            id: 2,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Insumo',
            field: 'insumo',
            id: 3,
            width: 700,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const insumo = params.row.insumo;
                let descricao = returnDescricaoInsumoSimplificada(insumo);
                descricao = descricao.replace(/<br\s*\/?>/gi, '\n');
                return <div style={{ whiteSpace: 'pre-line' }}>{descricao}</div>;
            },
        },


        {
            headerName: 'Quantidade',
            field: 'quantidade',
            id: 4,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Observações',
            field: 'observacoes',
            id: 5,
            width: 600,
            headerClassName: 'cabecalho_azul',
        },

    ];



    return (
        <div>
            <div style={{ paddingTop: 5 }} >
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <React.Fragment >

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                        >


                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >

                                <a href="/gestao/administracao/ordensdeservicos/listar" style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"< Ordens de Serviços - "}</a>
                                <a href={"/gestao/administracao/ordensdeservicos/editar/" + idOrdemServico} style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"Ordem de Servico Nº " + idOrdemServico}</a>

                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        {
                                            ordemServico.solicitacao.id_solicitacao === 7 ?
                                                <Tabs
                                                    value={value}
                                                    onChange={handleChange}
                                                    indicatorColor="secondary"
                                                    textColor="inherit"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="scrollable auto tabs example"
                                                    style={{ width: '100%', margin: 0, padding: 0 }}
                                                >

                                                    {larguraOriginal > limiar ?
                                                        <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                                        :
                                                        <Tab
                                                            icon={<InfoOutlinedIcon />}
                                                            style={{ backgroundColor: '#1C1C1C', color: 'white' }}
                                                        />
                                                    }

                                                    {larguraOriginal > limiar ?
                                                        <Tab label="Requerimento de Insumos" style={{ backgroundColor: '#1C1C1C' }} />
                                                        :
                                                        <Tab
                                                            icon={<AssignmentOutlinedIcon />}
                                                            style={{ backgroundColor: '#1C1C1C', color: 'white' }}
                                                        />
                                                    }




                                                </Tabs>
                                                :
                                                enterEstoqueProp ?
                                                    <Tabs
                                                        value={value}
                                                        onChange={handleChange}
                                                        indicatorColor="secondary"
                                                        textColor="inherit"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs example"


                                                    >
                                                        {larguraOriginal > limiar ?
                                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                                            :
                                                            <Tab
                                                                icon={<InfoOutlinedIcon />}
                                                                style={{ backgroundColor: '#1C1C1C', color: 'white' }}
                                                            />
                                                        }

                                                        {larguraOriginal > limiar ?
                                                            <Tab label="Execução de CheckList" style={{ backgroundColor: '#1C1C1C' }} />
                                                            :
                                                            <Tab
                                                                icon={<ChecklistOutlinedIcon />}
                                                                style={{ backgroundColor: '#1C1C1C', color: 'white' }}
                                                            />
                                                        }


                                                    </Tabs>
                                                    :
                                                    <Tabs
                                                        value={value}
                                                        onChange={handleChange}
                                                        indicatorColor="secondary"
                                                        textColor="inherit"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs example"


                                                    >
                                                        {larguraOriginal > limiar ?
                                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                                            :
                                                            <Tab
                                                                icon={<InfoOutlinedIcon />}
                                                                style={{ backgroundColor: '#1C1C1C', color: 'white' }}
                                                            />
                                                        }

                                                        {larguraOriginal > limiar ?
                                                            <Tab label="Tratar Solicitação Remotamente" style={{ backgroundColor: '#1C1C1C' }} />
                                                            :
                                                            <Tab
                                                                icon={<ComputerOutlinedIcon />}
                                                                style={{ backgroundColor: '#1C1C1C', color: 'white' }}
                                                            />
                                                        }

                                                        {larguraOriginal > limiar ?
                                                            <Tab label="Análise Prévia" style={{ backgroundColor: '#1C1C1C' }} />
                                                            :
                                                            <Tab
                                                                icon={<SearchOutlinedIcon  />}
                                                                style={{ backgroundColor: '#1C1C1C', color: 'white' }}
                                                            />
                                                        }

                                                        {larguraOriginal > limiar ?
                                                            <Tab label="Tratar Solicitação com Equipe de Campo" style={{ backgroundColor: '#1C1C1C' }} />
                                                            :
                                                            <Tab
                                                                icon={<EngineeringOutlinedIcon   />}
                                                                style={{ backgroundColor: '#1C1C1C', color: 'white' }}
                                                            />
                                                        }




                                                    </Tabs>


                                        }

                                    </AppBar>

                                </Box>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                {
                                    ordemServico.solicitacao.id_solicitacao === 7
                                        ?
                                        <SwipeableViews
                                            index={value}
                                            onChangeIndex={handleChangeIndex}
                                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                        >
                                            <Grid value={value} index={0}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            >

                                               <CabecalhoInformacoes
                                                ordemServico={ordemServico} 
                                                instalacaoSelecionada={instalacaoSelecionada} 
                                                projetoSelecionado={projetoSelecionado}
                                                propostaSelecionada={propostaSelecionada}
                                                solicitacaoSelecionada={solicitacaoSelecionada}
                                                solicitante={solicitante}
                                                analista={analista}
                                                rondaVirtual={rondaVirtual}
                                                />

                                            </Grid>


                                            <Grid value={value} index={1}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            >




                                                {ordemServico.finalizar_requerimento_itens === 0 &&
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        item xs={6}
                                                    >
                                                        <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                                            <input {...getInputArquivoProps1()} />
                                                            <Typography align="center" variant="h7">
                                                                Anexo
                                                            </Typography>
                                                        </div>


                                                    </Grid>
                                                }


                                                {ordemServico.finalizar_requerimento_itens === 0 &&
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-end"
                                                        alignItems="flex-end"
                                                        item xs={6}
                                                    >


                                                        <Button
                                                            variant='outlined'
                                                            style={{ paddingTop: 2 }}
                                                            color="primary"
                                                            onClick={(ev) => finalizarRequerimento()}
                                                            endIcon={<CheckIcon />}
                                                        >
                                                            Finalizar
                                                        </Button>
                                                    </Grid>
                                                }


                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={12}
                                                    style={{ padding: 20 }}
                                                >
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={8}
                                                    >

                                                        <Autocomplete
                                                            id="select-insumo"
                                                            options={insumos}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(insumo) => returnDescricaoInsumo(insumo).replace(/<br \/>/g, '\n')}
                                                            value={insumoSelecionado}
                                                            getOptionSelected={(option, value) => true}
                                                            onChange={(e, v) => setInsumoSelecionado(v)}
                                                            filterOptions={(options, { inputValue }) => {
                                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                return options.filter((insumo) => {
                                                                    const insumoText = returnDescricaoInsumo(insumo).toLowerCase();
                                                                    return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                                });
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label=" Insumo " multiline minRows={2} />
                                                            )}
                                                            renderOption={(option) => (
                                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoInsumo(option) }} />
                                                            )}
                                                        />
                                                    </Grid>


                                                    <Grid
                                                        container
                                                        item xs={2}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            name="quantidade"
                                                            label="Quantidade"
                                                            id="quantidade"
                                                            value={quantidade}
                                                            onChange={(e) => setQuantidade(e.target.value)}
                                                            fullWidth
                                                        />
                                                    </Grid>


                                                    <Grid
                                                        container
                                                        item xs={12}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        style={{ paddingTop: 10 }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            name="observacoesItem"
                                                            label="Observações"
                                                            id="observacoesItem"
                                                            value={observacoesItem}
                                                            onChange={(e) => setObservacoesItem(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            minRows={2}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        item xs={12}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        style={{ paddingTop: 10 }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoNovoInsumo"
                                                            label="Descrição para Novo Insumo"
                                                            id="descricaoNovoInsumo"
                                                            value={descricaoNovoInsumo}
                                                            onChange={(e) => setDescricaoNovoInsumo(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            minRows={2}
                                                        />
                                                    </Grid>

                                                    {ordemServico.finalizar_requerimento_itens === 0 &&

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            item xs={2}
                                                        >
                                                            <Button style={{ marginTop: 5 }}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={(ev) => adicionarItem()}
                                                            > Adicionar Item  </Button>
                                                        </Grid>
                                                    }




                                                </Grid>







                                                <Grid
                                                    style={{ paddingTop: 5 }}
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    {
                                                        ordemServico.itens_pre_requerimento &&
                                                        <>
                                                            <DataGrid
                                                                pagination
                                                                checkboxSelection
                                                                rows={ordemServico.itens_pre_requerimento}
                                                                getRowId={(row) => row.id_item_requerimento}
                                                                columns={columnsDataGrid}
                                                                disableColumnResize={false}
                                                                rowHeight={40}
                                                                onCellClick={handleCellClick}
                                                                onRowClick={handleRowClick}
                                                                components={{
                                                                    Toolbar: CustomToolbar,
                                                                }}
                                                            />

                                                        </>
                                                    }



                                                </Grid>


                                            </Grid>



                                        </SwipeableViews>
                                        :
                                        <SwipeableViews
                                            index={value}
                                            onChangeIndex={handleChangeIndex}
                                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                        >
                                            <Grid value={value} index={0}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            >

                                              <CabecalhoInformacoes2 
                                               ordemServico={ordemServico} 
                                               instalacaoSelecionada={instalacaoSelecionada} 
                                               projetoSelecionado={projetoSelecionado}
                                               propostaSelecionada={propostaSelecionada}
                                               solicitacaoSelecionada={solicitacaoSelecionada}
                                               solicitante={solicitante}
                                               analista={analista}
                                               rondaVirtual={rondaVirtual}
                                             />

                                            </Grid>


                                            <Grid value={value} index={1}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            >



                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{ paddingTop: 20, backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                                    spacing={2}
                                                >

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        item xs={12} sm={12} md={6} lg={6} xl={6}
                                                        style={{
                                                            padding: 20,
                                                        }}
                                                    >
                                                        {resultadoTratamentoRemoto()}

                                                    </Grid>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        item xs={12} sm={12} md={6} lg={6} xl={6}
                                                        style={{
                                                            padding: 20
                                                        }}
                                                    > {ordemServico.status_ordem >= 2 &&
                                                        <TextField
                                                            label="Comentário"
                                                            variant="outlined"
                                                            fullWidth
                                                            multiline
                                                            minRows={1}
                                                            value={ordemServico.usuario_analista_finalizou_tratamento_remoto.nome_amigavel + ": " + ordemServico.descricao_tratamento_remoto}


                                                        />
                                                        }
                                                    </Grid>
                                                </Grid>


                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    item xs={12}
                                                    style={{ padding: 6 }}
                                                >
                                                    {ordemServico.status_ordem > 2 &&
                                                        <Typography variant="h7">
                                                            {"Analista para Tratamento em Campo: " + (ordemServico.analista_tratamento_em_campo ? ordemServico.analista_tratamento_em_campo.nome_amigavel : "Não especificado")}
                                                        </Typography>
                                                    }
                                                </Grid>


                                                {
                                                    ordemServico.status_ordem <= 0 ?
                                                        <div style={{ padding: 5 }}>
                                                            <Typography variant='h7'> Design o Analista para execução da O.S </Typography>
                                                        </div>
                                                        :
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-end"
                                                            alignItems="center"
                                                            spacing={2}
                                                            item xs={12}
                                                        >




                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                item xs={12}
                                                                style={{ margin: 20, borderRadius: '10px', border: '1px solid #000000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}
                                                            >


                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center"
                                                                    item xs={12} sm={12} md={12} lg={12} xl={12}                                                                    style={{ padding: 5 }}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        item xs={12} sm={12} md={6} lg={6} xl={6}
                                                                        style={{ padding: 5 }}
                                                                    >

                                                                        <Typography variant="h5">Procedimentos de Tratamento Remoto</Typography>
                                                                    </Grid>

                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="flex-end"
                                                                        alignItems="center"
                                                                        item xs={12} sm={12} md={6} lg={6} xl={6}
                                                                    >
                                                                        {ordemServico.status_ordem === 1 &&
                                                                            <Button

                                                                                variant="contained"
                                                                                style={{
                                                                                    margin: 5,
                                                                                    backgroundColor: '#4caf50', // Cor de fundo personalizada (verde)
                                                                                    color: '#fff',              // Cor do texto personalizada (branco)
                                                                                }}
                                                                                startIcon={<CheckCircleIcon />} // Ícone de finalizar
                                                                                onClick={handleOpenTratamentoRemoto}
                                                                            > Finalizar Tratamento Remoto  </Button>
                                                                        }

                                                                    </Grid>


                                                                </Grid>







                                                                {ordemServico.status_ordem === 1 ?
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        item xs={12}
                                                                    >

                                                                        <Tooltip title="Adicione novos procedimentos que irá executar ou destinar a outros usuários">
                                                                            <Button
                                                                                style={{ margin: 5 }}
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={(e) => novoProcedimento(1)} //1 ´´e tratamento remoto
                                                                                startIcon={<AddIcon />} // Ícone de "+" (você também pode usar um emoji, como "➕")
                                                                            >
                                                                                Procedimento
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    :
                                                                    <div></div>

                                                                }

                                                                <Grid container spacing={2}
                                                                    item xs={12}
                                                                    style={{ padding: 5 }}
                                                                    rowSpacing={2}>
                                                                    {ordemServico.procedimentos_tratamento_remoto.map((procedimento_analise, index) => (
                                                                        <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                                                                            {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}
                                                                            <Card style={{ borderRadius: '10px', border: '1px solid #000000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}>
                                                                                <CardContent>
                                                                                    <Link
                                                                                        target="_blank"
                                                                                        to={`/gestao/operacional/procedimentos/vizualizar/${procedimento_analise.procedimento.id_procedimento}`}
                                                                                    >
                                                                                        <Typography variant="h6">{procedimento_analise.procedimento.nome}</Typography>
                                                                                    </Link>
                                                                                    <br />
                                                                                    <Typography variant="h8">{"Status: " + (procedimento_analise.status === 0 ? "Não Executado" : "Concluído")}</Typography>

                                                                                    {
                                                                                        procedimento_analise.usuario_criador &&
                                                                                        (
                                                                                            <br />)
                                                                                    }
                                                                                    {
                                                                                        procedimento_analise.usuario_criador &&
                                                                                        (
                                                                                            <Typography variant="h8">{"Criador: " + procedimento_analise.usuario_criador.nome_amigavel}</Typography>
                                                                                        )
                                                                                    }

                                                                                    {
                                                                                        procedimento_analise.usuario_executor &&
                                                                                        (
                                                                                            <br />)
                                                                                    }
                                                                                    {
                                                                                        procedimento_analise.usuario_executor &&
                                                                                        (
                                                                                            <Typography variant="h8">{"Executor: " + procedimento_analise.usuario_executor.nome_amigavel}</Typography>
                                                                                        )
                                                                                    }

                                                                                    <br />
                                                                                    {
                                                                                        procedimento_analise.status === 1 ?
                                                                                            <Typography variant="h8">{(procedimento_analise.resultado === 0 ? "Resultado: Falha" : "Resultado: Sucesso")}</Typography>
                                                                                            :
                                                                                            <Typography variant="h8"></Typography>

                                                                                    }
                                                                                    <br />
                                                                                    {
                                                                                        procedimento_analise.status === 1 ?
                                                                                            <Typography variant="h8">
                                                                                                {`Executado por ${procedimento_analise.usuario_executor.nome_amigavel}`} <br />
                                                                                                {`às ${moment(procedimento_analise.data_hora_execucao, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}
                                                                                            </Typography> :
                                                                                            <Typography variant="h8"></Typography>

                                                                                    }
                                                                                    <br />
                                                                                    {
                                                                                        procedimento_analise.status === 1 ?
                                                                                            <Typography variant="h8">
                                                                                                {"Resumo: " + procedimento_analise.observacoes} <br />
                                                                                            </Typography> :
                                                                                            <Typography variant="h8"></Typography>

                                                                                    }
                                                                                    <br />
                                                                                    <Typography variant="h8">{(procedimento_analise.procedimento.obrigatorio === 1 ? "Procedimento Obrigatório" : "Procedimento Opcional")}</Typography>
                                                                                    {
                                                                                        (procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao === null) ?


                                                                                            <Grid container
                                                                                                item xs={12}
                                                                                            >
                                                                                                <Grid container
                                                                                                    item xs={6}
                                                                                                    justifyContent="flex-start"
                                                                                                    alignItems="center"
                                                                                                >
                                                                                                    <ColorButtonGreen variant="contained" color="primary"
                                                                                                        onClick={(event) => handleClickIniciarProcedimento(procedimento_analise)}
                                                                                                    >
                                                                                                        Iniciar
                                                                                                    </ColorButtonGreen>
                                                                                                </Grid>
                                                                                                <Grid container
                                                                                                    justifyContent="flex-end"
                                                                                                    alignItems="center"
                                                                                                    item xs={6}
                                                                                                >
                                                                                                    <Tooltip title="Cancelar procedimento">
                                                                                                        <IconButton
                                                                                                            color="error"
                                                                                                            onClick={(event) => handleClickOpenCancelarProcedimento(procedimento_analise)}
                                                                                                        >
                                                                                                            <CancelIcon />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>


                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            :
                                                                                            (procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao !== null) ?
                                                                                                <Button style={{ fontSize: 12, border: '2px solid #4caf50' }}
                                                                                                    variant="outlined"
                                                                                                    startIcon={<RefreshIcon />}
                                                                                                    onClick={(event) => handleClickAtualizarProcedimento(procedimento_analise)}
                                                                                                >  Atualizar
                                                                                                </Button>
                                                                                                :
                                                                                                (procedimento_analise.status === 9) ?
                                                                                                    <span>Procedimento Cancelado</span>
                                                                                                    : <div></div>
                                                                                    }
                                                                                    {
                                                                                        procedimento_analise.status === 1 &&
                                                                                        <ColorButtonGreen variant="contained" color="primary"
                                                                                            onClick={(event) => handleClickVizualizarProcedimento(procedimento_analise.id_procedimento_execucao)}
                                                                                        >
                                                                                            Vizualizar
                                                                                        </ColorButtonGreen>

                                                                                    }

                                                                                </CardContent>
                                                                            </Card>

                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                }


                                            </Grid>


                                            <Grid value={value} index={2}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            >



                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{ paddingTop: 20, backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                                    spacing={2}
                                                >

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                       
                                                        item xs={12} sm={12} md={6} lg={6} xl={6}
                                                        style={{
                                                            padding: 20,
                                                        }}
                                                    >
                                                        {resultadoAnalisePrevia()}

                                                    </Grid>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                       
                                                        item xs={12} sm={12} md={6} lg={6} xl={6}
                                                        style={{
                                                            padding: 20
                                                        }}
                                                    > {ordemServico.status_ordem > 2 && parseInt(ordemServico.solucionado_tratamento_remoto,10) === 1 &&
                                                        <TextField
                                                            label="Comentário"
                                                            variant="outlined"
                                                            fullWidth
                                                            multiline
                                                            minRows={1}
                                                            value={ordemServico.usuario_analista_finalizou_tratamento_remoto.nome_amigavel + ": " + ordemServico.descricao_analise}
       />
                                                        }
                                                    </Grid>
                                                </Grid>


                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    item xs={12}
                                                    style={{ padding: 6 }}
                                                >
                                                    {ordemServico.status_ordem > 2 &&
                                                        <Typography variant="h7">
                                                            {"Analista para Tratamento em Campo: " + (ordemServico.analista_tratamento_em_campo ? ordemServico.analista_tratamento_em_campo.nome_amigavel : "Não especificado")}
                                                        </Typography>
                                                    }
                                                </Grid>


                                                {
                                                    ordemServico.status_ordem <= 0 ?
                                                        <div style={{ padding: 5 }}>
                                                            <Typography variant='h7'> Design o Analista para execução da O.S </Typography>
                                                        </div>
                                                        :
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-end"
                                                            alignItems="center"
                                                            spacing={2}
                                                            item xs={12}
                                                        >




                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                item xs={12}
                                                                style={{ margin: 20, borderRadius: '10px', border: '1px solid #000000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}
                                                            >


                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center"
                                                                    item xs={12}
                                                                    style={{ padding: 5 }}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        item xs={12} sm={12} md={6} lg={6} xl={6}
                                                                        style={{ padding: 5 }}
                                                                    >

                                                                        <Typography variant="h5">Procedimentos de Análise Prévia</Typography>
                                                                    </Grid>

                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="flex-end"
                                                                        alignItems="center"
                                                                        item xs={12} sm={12} md={6} lg={6} xl={6}
                                                                    >
                                                                        {ordemServico.status_ordem === 2 &&
                                                                            <Button

                                                                                variant="contained"
                                                                                style={{
                                                                                    margin: 5,
                                                                                    backgroundColor: '#4caf50', // Cor de fundo personalizada (verde)
                                                                                    color: '#fff',              // Cor do texto personalizada (branco)
                                                                                }}
                                                                                startIcon={<CheckCircleIcon />} // Ícone de finalizar
                                                                                onClick={finalizarAnalise}
                                                                            >
                                                                                {larguraOriginal > limiar ? "Finalizar Análise Prévia " : ""}
                                                                                 
                                                                             </Button>
                                                                        }

                                                                    </Grid>


                                                                </Grid>







                                                                {ordemServico.status_ordem === 2 ?
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        item xs={12}
                                                                    >

                                                                        <Tooltip title="Adicione novos procedimentos que irá executar ou destinar a outros usuários">
                                                                            <Button
                                                                                style={{
                                                                                    margin: 5,
                                                                                    minWidth: 0, // Para evitar espaçamento extra quando o texto está oculto
                                                                                    padding: '6px 12px',
                                                                                  }}
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={(e) => novoProcedimento(2)} //2 analise previa
                                                                                startIcon={<AddIcon />} // Ícone de "+" (você também pode usar um emoji, como "➕")
                                                                                onMouseEnter={(e) => (e.currentTarget.style.minWidth = 'auto')}
                                                                                onMouseLeave={(e) => (e.currentTarget.style.minWidth = '0')}
                                                                            >
                                                                                  {larguraOriginal > limiar && "Procedimento de Análise"}

                                                                             </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    :
                                                                    <div></div>

                                                                }

                                                                <Grid container spacing={2}
                                                                    item xs={12}
                                                                    style={{ padding: 5 }}
                                                                    rowSpacing={2}>
                                                                    {ordemServico.procedimentos_analise.map((procedimento_analise, index) => (
                                                                        <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                                                                            {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}
                                                                            <Card style={{ borderRadius: '10px', border: '1px solid #000000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}>
                                                                                <CardContent>
                                                                                    <Link
                                                                                        target="_blank"
                                                                                        to={`/gestao/operacional/procedimentos/vizualizar/${procedimento_analise.procedimento.id_procedimento}`}
                                                                                    >
                                                                                        <Typography variant="h6">{procedimento_analise.procedimento.nome}</Typography>
                                                                                    </Link>
                                                                                    <br />
                                                                                    <Typography variant="h8">{"Status: " + (procedimento_analise.status === 0 ? "Não Executado" : "Concluído")}</Typography>

                                                                                    {
                                                                                        procedimento_analise.usuario_criador &&
                                                                                        (
                                                                                            <br />)
                                                                                    }
                                                                                    {
                                                                                        procedimento_analise.usuario_criador &&
                                                                                        (
                                                                                            <Typography variant="h8">{"Criador: " + procedimento_analise.usuario_criador.nome_amigavel}</Typography>
                                                                                        )
                                                                                    }

                                                                                    {
                                                                                        procedimento_analise.usuario_executor &&
                                                                                        (
                                                                                            <br />)
                                                                                    }
                                                                                    {
                                                                                        procedimento_analise.usuario_executor &&
                                                                                        (
                                                                                            <Typography variant="h8">{"Executor: " + procedimento_analise.usuario_executor.nome_amigavel}</Typography>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        procedimento_analise.usuario_executor &&
                                                                                        (
                                                                                            <br />)
                                                                                    }
                                                                                    <br />
                                                                                    {
                                                                                        procedimento_analise.status === 1 ?
                                                                                            <Typography variant="h8">{(procedimento_analise.resultado === 0 ? "Resultado: Falha" : "Resultado: Sucesso")}</Typography>
                                                                                            :
                                                                                            <Typography variant="h8"></Typography>

                                                                                    }
                                                                                    <br />
                                                                                    {
                                                                                        procedimento_analise.status === 1 ?
                                                                                            <Typography variant="h8">
                                                                                                {`Executado por ${procedimento_analise.usuario_executor.nome_amigavel}`} <br />
                                                                                                {`às ${moment(procedimento_analise.data_hora_execucao, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}
                                                                                            </Typography> :
                                                                                            <Typography variant="h8"></Typography>

                                                                                    }
                                                                                    <br />
                                                                                    {
                                                                                        procedimento_analise.status === 1 ?
                                                                                            <Typography variant="h8">
                                                                                                {"Resumo: " + procedimento_analise.observacoes} <br />
                                                                                            </Typography> :
                                                                                            <Typography variant="h8"></Typography>

                                                                                    }
                                                                                    <br />
                                                                                    <Typography variant="h8">{(procedimento_analise.procedimento.obrigatorio === 1 ? "Procedimento Obrigatório" : "Procedimento Opcional")}</Typography>
                                                                                    {
                                                                                        (procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao === null) ?


                                                                                            <Grid container
                                                                                                item xs={12}
                                                                                            >
                                                                                                <Grid container
                                                                                                    item xs={6}
                                                                                                    justifyContent="flex-start"
                                                                                                    alignItems="center"
                                                                                                >
                                                                                                    <ColorButtonGreen variant="contained" color="primary"
                                                                                                        onClick={(event) => handleClickIniciarProcedimento(procedimento_analise)}
                                                                                                    >
                                                                                                        Iniciar
                                                                                                    </ColorButtonGreen>
                                                                                                </Grid>
                                                                                                <Grid container
                                                                                                    justifyContent="flex-end"
                                                                                                    alignItems="center"
                                                                                                    item xs={6}
                                                                                                >
                                                                                                    <Tooltip title="Cancelar procedimento">
                                                                                                        <IconButton
                                                                                                            color="error"
                                                                                                            onClick={(event) => handleClickOpenCancelarProcedimento(procedimento_analise)}
                                                                                                        >
                                                                                                            <CancelIcon />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>


                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            :
                                                                                            (procedimento_analise.status === 0 && procedimento_analise.data_hora_inicio_execucao !== null) ?
                                                                                                <Button style={{ fontSize: 12, border: '2px solid #4caf50' }}
                                                                                                    variant="outlined"
                                                                                                    startIcon={<RefreshIcon />}
                                                                                                    onClick={(event) => handleClickAtualizarProcedimento(procedimento_analise)}
                                                                                                >  Atualizar
                                                                                                </Button>
                                                                                                :
                                                                                                (procedimento_analise.status === 9) ?
                                                                                                    <span>Procedimento Cancelado</span>
                                                                                                    : <div></div>
                                                                                    }
                                                                                    {
                                                                                        procedimento_analise.status === 1 &&
                                                                                        <ColorButtonGreen variant="contained" color="primary"
                                                                                            onClick={(event) => handleClickVizualizarProcedimento(procedimento_analise)}
                                                                                        >
                                                                                            Vizualizar
                                                                                        </ColorButtonGreen>

                                                                                    }

                                                                                </CardContent>
                                                                            </Card>

                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                }


                                            </Grid>

                                            <Grid value={value} index={3}

                                                container
                                                direction="row"
                                                item xs={12}
                                                style={{ backgroundColor: 'white' }}
                                            >

                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    style={{ padding: 1 }}
                                                >

                                                    {resultadoTratamentoEmCampo()}

                                                </Grid>

                                                {
                                                    ordemServico.status_ordem <= 2 ?
                                                        <div style={{ padding: 5 }}>
                                                            <Typography variant='h7'> Finalize a Ánalise Prévia para então Iniciar o Tratamento em Campo </Typography>
                                                        </div>
                                                        :


                                                        ordemServico.solucionado_tratamento_remoto !== 1 ?
                                                            <GerenciarTratamentoEmCampo
                                                                ordemServico={ordemServico}
                                                            /> : null

                                                }



                                            </Grid>


                                        </SwipeableViews>
                                }
                            </Grid>
                        </Grid>





                    </React.Fragment>
                }


            </div>


            <Dialog open={open} onClose={handleClose} PaperProps={{ style: { maxWidth: '600px', minWidth: '600px', minHeight: '600px' } }}>
                <DialogTitle>Finalizar Ánalise Prévia</DialogTitle>
                <DialogContent>
                    <Grid
                        direction={"row"}
                        item xs={12}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={3}
                    >

                        <Grid
                            item xs={12}
                            style={{ margin: 5, padding: 5 }}
                        >
                            <TextField
                                label="Descrição"
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={3}
                                value={descricaoAnalise}
                                onChange={(e) => setDescricaoAnalise(e.target.value)}
                            />
                        </Grid>


                        <Grid
                            item xs={12}
                        >


                            <Button style={{ margin: 5, padding: 5 }} variant="contained" color="primary" onClick={handleFinalizar}>
                                Finalizar
                            </Button>
                        </Grid>



                    </Grid>

                </DialogContent>
            </Dialog>



            <Dialog open={openTratamentoRemoto} onClose={handleCloseTratamentoRemoto} PaperProps={{ style: { maxWidth: '600px', minWidth: '300px' } }}>
                <DialogTitle>Finalizar Tratamento Remoto</DialogTitle>
                <DialogContent>
                    <Grid
                        direction={"row"}
                        item xs={12}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={3}
                    >

                        <Grid
                            item xs={12}
                            style={{ margin: 20, padding: 20 }}
                        >
                            <TextField
                                label="Descrição"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={descricaoTratamentoRemoto}
                                onChange={(e) => setDescricaoTratamentoRemoto(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="solucao-tratamento-selector-label">O Tratamento Remoto Solucionou a Solicitação?</InputLabel>
                                    <Select
                                        labelId="solucao-tratamento-remoto-label"
                                        id="solucao-tratamento-remoto"
                                        value={solucaoTratamentoRemoto}
                                        onChange={handleSolucaoTratamentoRemoto}
                                        label="O Tratamento Remoto Solucionou a Solicitação?"
                                    >
                                        <MenuItem value={0}>NÃO - Ir para Tratamento em Campo</MenuItem>
                                        <MenuItem value={1}>SIM - Concluir Solicitação</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>


                        <Grid
                            item xs={12}
                        >


                            <Button style={{ margin: 20, padding: 20 }} variant="contained" color="primary" onClick={handleFinalizarTratamentoRemoto}>
                                Finalizar
                            </Button>
                        </Grid>



                    </Grid>

                </DialogContent>
            </Dialog>

        


            <Dialog open={openCancelarProcedimento} onClose={handleCloseCancelarProcedimento} PaperProps={{ style: { maxWidth: '600px', minWidth: '300px' } }}>
                <DialogTitle>Cancelar Procedimento</DialogTitle>
                <DialogContent>
                    <Grid
                        direction={"row"}
                        item xs={12}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={3}
                    >

                        <Grid
                            item xs={12}
                            style={{ margin: 20, padding: 20 }}
                        >

                        </Grid>

                        <Grid
                            item xs={12}
                        >
                            <TextField
                                variant="standard"
                                name="justificativa"
                                label="Justificativa"
                                required
                                id="justificativa"
                                value={mensagemCancelamento}
                                onChange={(e) => setMensagemCancelamento(e.target.value)}
                                fullWidth
                                multiline
                                minRows={2}
                            />

                            <Button style={{ margin: 20, padding: 20 }} variant="contained" color="primary" onClick={(e) => handleClickCancelarProcedimento()}>
                                Finalizar
                            </Button>
                        </Grid>



                    </Grid>

                </DialogContent>
            </Dialog>


            <Dialog open={openCadastrarProcedimento} onClose={handleCloseCadastrarProcedimento} >
                <DialogTitle>Cadastrar Procedimento</DialogTitle>
                <DialogContent>
                    <Grid
                        direction={"row"}
                        item xs={12}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={1}
                    >

                          <CadastrarProcedimentoAvulso
                           idOrdemServico={idOrdemServico}
                            tipoProcedimento={etapaProcedimentoACadastrar}
                            onClose={handleCloseCadastrarProcedimento} 
                             />   
                    </Grid>

                </DialogContent>
            </Dialog>



        </div >
    );
}


