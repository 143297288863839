import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';


import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import { Button } from "@material-ui/core";


import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';




function novoProcedimento() {
    window.location.href = "/gestao/operacional/procedimentos/cadastrar"

}

export default function Procedimentos() {



    const [loading, setLoading] = useState(true);
    const [procedimentos, setProcedimentos] = useState([]);






    async function listarProcedimentos() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/procedimentos/listartodos", {
                headers: headers
            }).then(function (response) {
                setProcedimentos(response.data)

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Procedimentos: " + _err)

        }

    }


    useEffect(() => {




        listarProcedimentos();


    }, []);




    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleClickVizualizar = (event, cellValues) => {


        window.location.href = "/gestao/operacional/procedimentos/vizualizar/" + cellValues.row.id_procedimento;


    };



    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {


                return (

                    <IconButton
                        color="primary"
                        onClick={(event) => handleClickVizualizar(event, cellValues)}
                    >
                        <VisibilityIcon />
                    </IconButton>

                );

            },
        },
        {
            headerName: 'ID',
            field: 'id_procedimento',
            id: 1,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Área',
            field: 'area',
            id: 2,
            width: 150,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const area = params.row.area;

                let text = "";
                switch (area) {
                    case 0:
                        text = 'ORDEM DE SERVIÇO';
                        break;
                    case 1:
                        text = 'ORDEM DE COMPRAS';
                        break;
                    case 20:
                        text = 'RECURSOS HUMANOS';
                        break;
                    case 30:
                        text = 'RECURSOS FINANCEIROS';
                        break;
                        case 40:
                            text = 'RONDAS VIRTUAIS';
                            break;
                    default:
                        text = '';
                }

                return (
                    <Typography
                        variant="h8"
                    >
                        {text}
                    </Typography>
                );
            },
        },
        {
            headerName: 'Tipo',
            field: 'tipo',
            id: 3,
            width: 100,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const tipo = params.row.tipo;

                let text = "";
                switch (tipo) {
                    case 0:
                        text = 'Ánalise';
                        break;
                    default:
                        text = '';
                }

                return (
                    <Typography
                        variant="h8"
                    >
                        {text}
                    </Typography>
                );
            },
        },

        {
            headerName: 'Etapa',
            field: 'etapa',
            id: 4,
            width: 150,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const etapa = params.row.etapa;

                let text = "";
                switch (etapa) {
                    case 1:
                        text = 'Tratamento Remoto';
                        break;
                    case 2:
                        text = 'Ánalise Prévia';
                        break;
                    case 3:
                        text = 'Tratamento em Campo';
                        break;
                    default:
                        text = '';
                }

                return (
                    <Typography
                        variant="h8"
                    >
                        {text}
                    </Typography>
                );
            },
        },

        {
            headerName: 'Nome',
            field: 'nome',
            id: 5,
            width: 250,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Descrição',
            field: 'descricao_geral',
            id: 6,
            width: 350,
            headerClassName: 'cabecalho_azul',
        },
        {
            field: 'usuario_criador',
            id: 7,
            headerName: 'Criador',
            minWidth: '350',
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                var usuario = params.value;
                var rawDate = params.row.data_hora_criacao;
                const formattedDate = moment(rawDate).format('DD/MM/YYYY HH:mm');
                var texto = "Criado por " + (usuario.nome_amigavel) + " em " + formattedDate;

                return (
                    <Typography
                        variant="h8"
                    >
                        {texto}
                    </Typography>
                );
            },

        },
        {
            headerName: 'Exigir Comprovação',
            field: 'exigir_comprovacao',
            id: 8,
            width: 100,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const exigir_comprovacao = params.row.exigir_comprovacao;

                let text = "";
                switch (exigir_comprovacao) {
                    case 0:
                        text = 'NÃO';
                        break;
                    case 1:
                        text = 'SIM';
                        break;
                    default:
                        text = '';
                }

                return (
                    <Typography
                        variant="h8"
                    >
                        {text}
                    </Typography>
                );
            },
        },

    ];




    return (
        <div>

            <NavegadorGestao
                Operacional={true}
                Procedimentos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novoProcedimento}
                    >
                        Novo Procedimento
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div style={{ height: '100%', width: '100%' }}>
                        <>
                            <DataGrid
                                pagination
                                rows={procedimentos}
                                pageSize={20}
                                rowHeight={40}
                                getRowId={(row) => row.id_procedimento}
                                columns={columnsDataGrid}
                                disableColumnResize={false}
                                onCellClick={handleCellClick}
                                onRowClick={handleRowClick}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />

                        </>
                    </div>
                }


            </div>


        </div>
    );
}



