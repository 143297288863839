// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Bloqueia o clique direito */
img {
    pointer-events: none;  /* Impede qualquer interação com a imagem */
  }
  
  /* Alternativa para bloquear o menu de contexto */
  img {
    -webkit-user-drag: none;  /* Para impedir que a imagem seja arrastada */
    -webkit-user-select: none;
            user-select: none;        /* Impede a seleção de texto */
    pointer-events: none;     /* Impede interações com a imagem */
  } `, "",{"version":3,"sources":["webpack://./src/pages/painelAdmin/AreaTecnica/Monitoramento/components/CamerasComponent/App.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,oBAAoB,GAAG,2CAA2C;EACpE;;EAEA,iDAAiD;EACjD;IACE,uBAAuB,GAAG,6CAA6C;IACvE,yBAAiB;YAAjB,iBAAiB,SAAS,8BAA8B;IACxD,oBAAoB,MAAM,mCAAmC;EAC/D","sourcesContent":["/* Bloqueia o clique direito */\r\nimg {\r\n    pointer-events: none;  /* Impede qualquer interação com a imagem */\r\n  }\r\n  \r\n  /* Alternativa para bloquear o menu de contexto */\r\n  img {\r\n    -webkit-user-drag: none;  /* Para impedir que a imagem seja arrastada */\r\n    user-select: none;        /* Impede a seleção de texto */\r\n    pointer-events: none;     /* Impede interações com a imagem */\r\n  } "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
