import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown'
import './styles.css';
import logo from '../../assets/imgs/logo_kapplan_energy.png';


import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';

import icone_subestacao from '../../assets/imgs/icone_subestacao.png';
import icone_casa from '../../assets/imgs/icone_casa.png';
import icone_loja from '../../assets/imgs/icone_loja.png';

 

import Grid from '@material-ui/core/Grid';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import mini_icone_informacao from '../../assets/imgs/mini_icone_informacao.png';
import Cookies from 'js-cookie';
import { Box } from "@mui/material";
import TextField from '@material-ui/core/TextField';
import { useLocation } from 'react-router-dom';
import Draggable from "react-draggable";





import api from '../../services/api';
import Typography from '@material-ui/core/Typography';
import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from "react-router-dom";
import {
  DialogActions,
  DialogContentText,
} from "@mui/material";
import {

  Link

} from "react-router-dom";


const NavegadorGestao = (props) => {

  const [limiar, setLimiar] = useState(900);


  const [openRondaVirtualEmAndamento, setOpenRondaVirtualEmAndamento] = useState(false);
  const [rondaVirtualEmAndamento, setRondaVirtualEmAndamento] = useState(null);

  const location = useLocation();

  const [navBar, setNavbar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tarefas, setTarefas] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [width, setWidth] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [tarefaEmVista, setTarefaEmvista] = useState();
  const regra = Cookies.get('regra');

  const history = useHistory();

  const [justificativa, setJustificativa] = useState(false);
  const [mensagemJustificativa, setMensagemJustificativa] = useState(null);

  const handleJustificativa = () => {
    setMensagemJustificativa(null);
    setJustificativa(true);
  }

  const [openBalao, setOpenBalao] = useState(false);
  const handleOpenBalao = () => {

    setOpenBalao(true);
  };

  const handleCloseBalao = () => {
    setMensagemJustificativa(null);
    setJustificativa(false);
    setOpenBalao(false);
  };

  const handleIrParaGestaoRondaVirtual = () => {
    history.push({
      pathname: "/gestao/operacional/rondavirtual/gerenciar/" + rondaVirtualEmAndamento.id_ronda_virtual,
    })
  };





  async function handleBalaoOk() {
    //verificar se a ronda virtual ainda esta pendente
    try {


      console.log("Vizualizar tarefa chamado");
      const id_usuario = Cookies.get('id_usuario');


      var url = "v1/protected/rondavirtual/verificarpendente/" + parseInt(rondaVirtualPendente.id_ronda_virtual, 10);
      //console.log("Url: " + url);
      await api.get(url).then(function (response) {
        // alert(response.data);
        if (response.data === "ronda_nula") {
          setRondaVirtualPendente(null);
          handleCloseBalao();
          alert("Ronda não mais listada no sistema!");
        } else if (response.data === "disponivel") {
          //assumir
          const token = Cookies.get('token');
          const headers = {
            'Authorization': 'Bearer ' + token
          }
          const cadastro = {
            usuario_executor: { id_usuario: Cookies.get("id_usuario") },
          }
          api.put("/v1/protected/rondavirtual/assumirrondavirtual/" + rondaVirtualPendente.id_ronda_virtual + "/" + Cookies.get("id_usuario"), cadastro, {
            headers: headers
          }).then(function (response) {
            if (parseInt(response.data.usuario_executor.id_usuario) === parseInt(Cookies.get("id_usuario"), 10)) {
              setRondaVirtualPendente(null);
              handleCloseBalao();
              alert("Ronda Virtual assumida por você");
              history.push({
                pathname: "/gestao/operacional/rondavirtual/gerenciar/" + response.data.id_ronda_virtual,
              })
            } else {
              setRondaVirtualPendente(null);
              handleCloseBalao();
              alert("Erro, consulte o administrador");
            }
          });

        } else {
          setRondaVirtualPendente(null);
          handleCloseBalao();
          alert("Usuário " + response.data + " já assumiu a execução dessa ronda virtual");
        }
      });


    } catch (_err) {
      console.log("Erro ao vizualizar acao, err: " + _err);

    }
  };


  async function handleFinalizarJustificativa() {
    //verificar se a ronda virtual ainda esta pendente
    try {
      if (mensagemJustificativa.length < 10) {
        alert("Detalhe sua justificativa!");
        return;
      }

      const id_usuario = Cookies.get('id_usuario');


      var url = "v1/protected/rondavirtual/verificarpendente/" + parseInt(rondaVirtualPendente.id_ronda_virtual, 10);
      //console.log("Url: " + url);
      await api.get(url).then(function (response) {
        // alert(response.data);
        if (response.data === "ronda_nula") {
          setRondaVirtualPendente(null);
          handleCloseBalao();
          alert("Ronda não mais listada no sistema!");
        } else if (response.data === "disponivel") {
          //cancelar
          const token = Cookies.get('token');
          const headers = {
            'Authorization': 'Bearer ' + token
          }
          const cadastro = {
            id_usuario: Cookies.get("id_usuario"),
            id_ronda_virtual: rondaVirtualPendente.id_ronda_virtual,
            mensagem: mensagemJustificativa,
          }
          api.put("/v1/protected/rondavirtual/cancelarrondavirtual", cadastro, {
            headers: headers
          }).then(function (response) {

            if (response.data) {
              setRondaVirtualPendente(null);
              handleCloseBalao();
              alert("Justificativa Registrada!");
            } else {
              alert("Erro inesperado, consulte o administrador!");
            }

          });

        } else {
          setRondaVirtualPendente(null);
          handleCloseBalao();
          alert("Usuário " + response.data + " já assumiu a execução dessa ronda virtual");
        }
      });


    } catch (_err) {
      console.log("Erro ao vizualizar acao, err: " + _err);

    }
  };


  const [larguraOriginal, setLarguraOriginal] = useState(0);

  function checkDimenssoes() {
    var largura = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    setWidth(largura);
    setLarguraOriginal(largura);


  }

  window.addEventListener('resize', function (event) {
    checkDimenssoes();
  });

  window.addEventListener('scroll', function (event) {
    changeBackground();
  });


  const changeBackground = () => {
    //console.log(window.scrollY)
    if (window.scrollY >= 200) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {

    //alert("Regras: " + regra);

    checkDimenssoes();

  }, []);






  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const handleOpenAlert = (tarefa) => {
    console.log("handle open alert chamado!");
    setTarefaEmvista(tarefa);
    setAlertOpen(true);
    vizualizarTarefa(tarefa);
  };



  async function vizualizarTarefa(tarefa) {
    try {


      console.log("Vizualizar tarefa chamado");
      const id_usuario = Cookies.get('id_usuario');


      var url = "v1/protected/tarefasgestao/vizualizar/" + parseInt(tarefa.id_tarefa);
      //console.log("Url: " + url);
      await api.put(url).then(function (response) {

        if (response) {
          setTarefas(tarefas.filter(tarefa => tarefa.id_tarefa !== tarefa.id_tarefa));

        }

      });


    } catch (_err) {
      console.log("Erro ao vizualizar acao, err: " + _err);

    }
  }



  async function fecharTarefa(tarefa) {
    try {


      console.log("fechar tarefa chamado");
      const id_usuario = Cookies.get('id_usuario');


      var url = "v1/protected/tarefasgestao/fechar/" + parseInt(tarefa.id_tarefa);
      //console.log("Url: " + url);
      await api.put(url).then(function (response) {

        if (response) {
          setTarefas(tarefas.filter(tarefa => tarefa.id_tarefa !== tarefa.id_tarefa));

        }

      });

    } catch (_err) {
      console.log("Erro ao fechar acao, err: " + _err);

    }
  }


  async function criarOCporOs() {

    try {


      if (tarefaEmVista.ordem_servico.finalizar_requerimento_itens === 0) {
        alert("Aguarda até que o criador da O.S finalize o Requerimento de itens!");
        return;
      }

      const headers = {
        'Authorization': 'Bearer ' + Cookies.get("token")
      }

      const response = await api.post('/v1/protected/ordensdecompras/ordemdecompra/cadastrarporos', tarefaEmVista.ordem_servico,
        { headers: headers });

      const cadastro_salvo = response.data;
      if (cadastro_salvo) {
        alert("Ordem de Compra Cadastrada!");
        history.push({
          pathname: "/gestao/administracao/ordensdecompras/editar/" + cadastro_salvo.id_ordem_compra,
        })
      } else {
        alert("Erro de Conexão, tente novamente mais tarde");

      }


    } catch (_err) {
      console.log("erro ao cadastrar: " + _err);
      alert("Erro de Conexão, tente novamente mais tarde");

    }
  }





  const handleCloseAlert = () => {

    setAlertOpen(false);
    setTarefaEmvista(null);

  }



  async function handleDescartarNotificacao() {

    if (tarefaEmVista.especificidade === 0 && tarefaEmVista.ordem_compra) {
      //para ordes de compra
      return "/gestao/administracao/ordensdecompras/editar/" + tarefaEmVista.ordem_compra.id_ordem_compra;
    }
    else if (tarefaEmVista.especificidade === 0) {
      return "/gestao/administracao/ordensdecompras/editar/" + tarefaEmVista.id_ordem_compra;

    }
    else if (tarefaEmVista.especificidade === 1) {
      //verificar o status da ordem de servico e se o usuario atual é o usuario logado
      try {

        const token = Cookies.get('token');

        const headers = {
          'Authorization': 'Bearer ' + token
        }

        await api.get("/v1/protected/ordemservico/listarporid/" + tarefaEmVista.id_ordem_servico, {
          headers: headers
        }).then(function (response) {

          if (parseInt(response.data.usuario_executor_atual.id_usuario, 10) === parseInt(Cookies.get("id_usuario"), 10)) {
            if (parseInt(response.data.status_ordem, 10) !== 9) {
              //ordem esta aberta e o usuario executando, nao permite fechar
              alert("A Ordem de Serviço está com status 'Em Andamento', como você é o usuário executor atual, a notificação não pode ser fechada");
            } else {
              //alert("Ordem de Serviço já finalizada!, pode fechar");
              fecharTarefa(tarefaEmVista);

            }
          } else {
            //pode fechar a notificacao
            //alert("usuario nao é mais executor dessa O.S, pode fechar");
            fecharTarefa(tarefaEmVista);

          }

        });
      } catch (_err) {
        // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
        console.log("Erro ao listar informações: " + _err)

      }

    } else if (tarefaEmVista.especificidade === 2) {
      return "/gestao/financas/lancamento/gerenciar/" + tarefaEmVista.id_lancamento;

    }



  }

  function processarLink() {


    if (tarefaEmVista.especificidade === 0 && tarefaEmVista.ordem_compra) {
      //para ordes de compra
      return "/gestao/administracao/ordensdecompras/editar/" + tarefaEmVista.ordem_compra.id_ordem_compra;
    }
    else if (tarefaEmVista.especificidade === 0) {
      return "/gestao/administracao/ordensdecompras/editar/" + tarefaEmVista.id_ordem_compra;

    }
    else if (tarefaEmVista.especificidade === 1) {
      return "/gestao/administracao/ordensdeservicos/editar/" + tarefaEmVista.id_ordem_servico;

    } else if (tarefaEmVista.especificidade === 2) {
      return "/gestao/financas/lancamento/gerenciar/" + tarefaEmVista.id_lancamento;

    }



  }




  async function pesquisarBackGround() {


    try {


      const id_usuario_logado = Cookies.get('id_usuario');

      let url = "v1/protected/tarefasgestao/listarPorUsuario/" + id_usuario_logado;

      //console.log("Url: " + url);
      await api.get(url).then(function (response) {

        setTarefas(response.data);
        //console.log(JSON.stringify(response.data));
        setLoading(false);

      });


    } catch (_err) {
      console.log("erro: " + _err);

    }


  }

  const [rondaVirtualPendente, setRondaVirtualPendente] = useState(null);


  async function pesquisarBackGroundRondas() {


    try {

      const id_usuario_logado = Cookies.get('id_usuario');

      let url = "v1/protected/rondavirtual/buscarpendente/" + id_usuario_logado;

      await api.get(url).then(function (response) {

        if (response.data) {
          if (response.data.usuario_executor) {

            if (parseInt(response.data.usuario_executor.id_usuario, 10) === parseInt(Cookies.get('id_usuario'), 10)) {

              setRondaVirtualEmAndamento(response.data);
              setOpenRondaVirtualEmAndamento(true);
            } else {
              setRondaVirtualPendente(null);
              handleCloseBalao();
            }
          } else {
            setRondaVirtualPendente(response.data);
            handleOpenBalao();
          }
        }
        else {
          setRondaVirtualPendente(null);
          handleCloseBalao();
        }


      });


    } catch (_err) {
      console.log("erro: " + _err);

    }


  }


  useEffect(() => {

    checkDimenssoes();

    pesquisarBackGround();
    pesquisarBackGroundRondas();

  }, []);



  useEffect(() => {



    const interval = setInterval(() => {


      pesquisarBackGroundRondas();


    }, 30000);

    return () => clearInterval(interval);

  }, [rondaVirtualPendente]);

  useEffect(() => {



    const interval = setInterval(() => {


      pesquisarBackGround();


    }, 1000);

    return () => clearInterval(interval);

  }, [tarefas]);

  const toggleDrawer = (option) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (option) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }

  };



  function onConfirmSair() {
    Cookies.set('token', undefined);
    window.location.href = "/login"


  }

  const [searchTerm, setSearchTerm] = useState("");



  function returnDescricaoInstalacaoResumida(instalacao) {
    var texto = instalacao.identificador_integrador + "-" +
      (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

    return texto;
  }

  const getConditionalStyle = (condition, isMobile = false) => {
    // Se precisar de estilos diferentes para mobile, basta ajustar aqui:
    const styleDesktop = {
      textDecoration: condition ? 'underline' : '',
      backgroundColor: condition ? 'orange' : 'white'
    };

    const styleMobile = {
      textDecoration: condition ? '' : '',
      // Por exemplo, um tom diferente de laranja para mobile:
      backgroundColor: condition ? 'white' : 'white',

    };

    return isMobile ? styleMobile : styleDesktop;
  };


  const [instalacoesEncontradas, setInstalacoesEncontradas] = useState([]);

  async function handleSearch(evento) {
    setSearchTerm(evento);

    if (evento.length < 2) {
      setInstalacoesEncontradas([]); // Opcional: limpar resultados
      return;
    }

 
    try {
      const token = Cookies.get('token');

      const headers = {
        'Authorization': 'Bearer ' + token
      }

      await api.get("/v1/protected/cliente/instalacoes/buscar/" + evento, {
        headers: headers
      }).then(function (response) {

  
        setInstalacoesEncontradas(response.data)

      });

    } catch (_err) {
      // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
      console.log("Erro ao listar informações: " + _err)

    }
  }



  const getTitleConditionalStyle = (condition, isMobile = false) => {
    // Estilos para desktop
    const styleDesktop = {
      backgroundColor: condition ? '#FFA500' : '',
      fontSize: '14px',
      padding: '5px 10px',
      color: 'black',
      textDecoration: condition ? 'underline' : 'none',

    };

    // Estilos para mobile (mantendo os mesmos atributos)
    const styleMobile = {
      textDecoration: condition ? 'none' : 'none',
      backgroundColor: condition ? '#FFA500' : '',
      fontSize: '16px',
      color: 'black',
      border: '2px solid #FFA500',
      borderRadius: '8px',
      padding: '5px 10px',
      transition: 'background-color 0.3s, border-color 0.3s',
      textDecoration: 'none'
    };

    return isMobile ? styleMobile : styleDesktop;
  };



  function processarIconeInstalacao(tipo) {
    if (tipo === 0) {
      return icone_casa;
    } else if (tipo === 1) {
      return icone_loja;
    } else if (tipo == 2) {
      return icone_subestacao;
    }
  }



  const LocalComponent = ({ id_instalacao, lat, lng, onClick, tipo_icone, nome_mapa, status, instalacao }) => {
    const [showMenu, setShowMenu] = useState(false);



    const handleClickGerenciar = () => {
      const url = `/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/${id_instalacao}/0`;
      window.open(url, '_blank');
      setShowMenu(!showMenu);
    };





    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        align="center"
        item xs={12}
        style={{ padding: 10 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          align="flex-start"
          item xs={12}
          style={{}}
        >
          <img
            alt={"icone"}
            style={{
              textAlign: 'center',
              width: '40px',
              height: '40px'
            }}
            src={processarIconeInstalacao(tipo_icone)}

          />

          <spam style={{ textDecoration: "underline" }} > {nome_mapa + " - " + instalacao.identificador_integrador}</spam>
          <IconButton color="primary" onClick={handleClickGerenciar}>
            <SettingsIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };


  return (
    <div
      style={{ zIndex: 5, backgroundColor: navBar === true ? 'white' : props.corFundo, position: props.travado ? 'sticky' : null, top: props.travado ? 0 : null, borderBottom: '1px solid black' }}

    >
      <Grid
        container
        direction="row"
        item xs={12} sm={12} md={12} lg={12} xl={12}

      >
        {larguraOriginal > limiar && (
          <Grid
            container
            direction="row"
            item xs={12} sm={4} md={4} lg={4} xl={3}
            alignItems="center"
            justifyContent="center"
            style={{ padding: 10 }}
          >
            <div style={{}}>
              <Link className="a"

                to={{
                  pathname: "/",

                }}
              >
                <img alt="logomarca" width={200}
                  src={logo}
                />
              </Link>
            </div>
          </Grid>
        )}

        <Grid
          container
          direction="row"
          item xs={12} sm={8} md={8} lg={8} xl={9}
          alignItems="center"
          justifyContent={larguraOriginal < limiar ? "flex-start" : "center"}

        >
          <Navbar expand="xl" className={"color-nav"}>
            <Container  >
              <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ marginBottom: 10 }} />
              {/* Campo de Pesquisa dentro da mesma linha */}
              {larguraOriginal < limiar && (
                <input
                  type="text"
                  placeholder="Pesquisar..."
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  style={{
                    padding: "5px",
                    flexGrow: 1, // Faz o input crescer dentro do espaço disponível
                    maxWidth: "300px", // Limita o tamanho do input
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    marginLeft: "10px",
                  }}
                />
              )}
              <Navbar.Collapse
                style={{
                  backgroundColor: width >= 1200 ? "white" : "white",
                  padding: larguraOriginal < limiar ? '1px' : null
                }} >


                <Nav style={{ border: larguraOriginal < limiar ? '1px solid black' : null, padding: larguraOriginal < limiar ? 10 : null, borderRadius: larguraOriginal < limiar ? '5px' : null, backgroundColor: 'white' }}>

                  {regra.includes("ADMIN")
                    && (
                      <NavDropdown
                        title={
                          <span
                            style={getTitleConditionalStyle(props.AreaTecnica, larguraOriginal < limiar)}
                          >
                            Área Técnica
                          </span>
                        }
                        // id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                        style={getConditionalStyle(props.AreaTecnica, larguraOriginal < limiar)}
                        drop={larguraOriginal < limiar ? "down" : "down"}
                        className={larguraOriginal < limiar ? 'dropdown-mainmenu' : ''}
                      >
                        <NavDropdown
                          title={

                            <span
                              style={getTitleConditionalStyle(props.Monitoramento, larguraOriginal < limiar)}
                            >
                              Monitoramento
                            </span>
                          }
                          id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                          style={getConditionalStyle(props.Monitoramento, larguraOriginal < limiar)}
                          className={larguraOriginal < limiar ? 'dropdown-submenu' : ''}
                          drop={larguraOriginal < limiar ? "start" : "down"}
                        >
                          <NavDropdown.Item
                            eventKey="1.0"
                            style={getTitleConditionalStyle(props.MonitoramentoMosaico, larguraOriginal < limiar)}
                            href="/tecnica/monitoramento/mosaico"
                            target={larguraOriginal > limiar ? "_blank" : ''}
                            rel="noopener noreferrer" // Segurança ao abrir links externos
                          >
                            Mosaico
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            eventKey="1.0"
                            style={getTitleConditionalStyle(props.MonitoramentoDashboard, larguraOriginal < limiar)}
                            href="/tecnica/monitoramento/dashboard">Dashboard
                          </NavDropdown.Item>


                          <NavDropdown.Item
                            eventKey="1.0"
                            style={getTitleConditionalStyle(props.MonitoramentoMapa, larguraOriginal < limiar)}
                            href="/tecnica/monitoramento/mapa">Mapa
                          </NavDropdown.Item>


                        </NavDropdown>


                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Equipamentos, larguraOriginal < limiar)}
                          href="/tecnica/equipamentos">
                          Equipamentos
                        </NavDropdown.Item>


                      </NavDropdown>
                    )
                  }



                  {regra.includes("ADMIN")
                    && (
                      <NavDropdown
                        title={
                          <span
                            style={getTitleConditionalStyle(props.BaseDados, larguraOriginal < limiar)}
                          >
                            Base de Dados
                          </span>
                        }

                        style={getConditionalStyle(props.BaseDados, larguraOriginal < limiar)}
                        align={larguraOriginal < limiar ? "end" : ''}
                        className={larguraOriginal < limiar ? 'dropdown-mainmenu' : ''}

                      >
                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Unidades, larguraOriginal < limiar)}
                           href="/gestao/administracao/unidades/listar"
                        >Unidades
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Clientes, larguraOriginal < limiar)}
                          href="/gestao/administracao/clientes/listar"
                        >Clientes
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Projetos, larguraOriginal < limiar)}
                          href="/gestao/administracao/projetos/listar"
                        >Projetos
                        </NavDropdown.Item>


                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.PeD, larguraOriginal < limiar)}
                          href="/gestao/administracao/ped/listar"
                        >P & D
                        </NavDropdown.Item>

                        <NavDropdown
                          title={
                            <span
                              className={larguraOriginal < limiar ? 'mobile' : ''}
                            >
                              Tabelas Auxiliares
                            </span>
                          }
                          id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                          style={getConditionalStyle(props.TabelasAuxiliaresClientes, larguraOriginal < limiar)}
                        >
                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.GruposClientes ? 'underline' : 'white', backgroundColor: props.GruposClientes ? 'orange' : 'white' }} id="nav-link" href="/gestao/administracao/clientes/gruposclientes/listar">Grupos de Clientes
                          </NavDropdown.Item>

                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.GruposControle ? 'underline' : 'white', backgroundColor: props.GruposControle ? 'orange' : 'white' }} id="nav-link" href="/gestao/administracao/clientes/gruposcontrole/listar">Grupos de Controle
                          </NavDropdown.Item>


                        </NavDropdown>


                      </NavDropdown>
                    )
                  }



                  <NavDropdown
                    title={
                      <span
                        style={getTitleConditionalStyle(props.Operacional, larguraOriginal < limiar)}
                      >
                        Operacional
                      </span>
                    }

                    style={getConditionalStyle(props.Operacional, larguraOriginal < limiar)}
                    align={larguraOriginal < limiar ? "end" : ''}
                    className={larguraOriginal < limiar ? 'dropdown-mainmenu' : ''}

                  >
                    <NavDropdown.Item
                      eventKey="1.0"
                      style={getTitleConditionalStyle(props.Agendamentos, larguraOriginal < limiar)}
                      id="nav-link"
                      href="/gestao/operacional/agendamentos/listar"
                    >
                      Agendamentos
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      eventKey="1.0"
                      style={getTitleConditionalStyle(props.Equipes, larguraOriginal < limiar)}
                      id="nav-link"
                      href="/gestao/operacional/equipes/listar"
                    >
                      Equipes
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      eventKey="1.0"
                      style={getTitleConditionalStyle(props.Solicitacoes, larguraOriginal < limiar)}
                      id="nav-link"
                      href="/gestao/operacional/solicitacoes/listar"
                    >
                      Solicitações
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      eventKey="1.0"
                      style={getTitleConditionalStyle(props.Procedimentos, larguraOriginal < limiar)}
                      id="nav-link"
                      href="/gestao/operacional/procedimentos/listar"
                    >
                      Procedimentos
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      eventKey="1.0"
                      style={getTitleConditionalStyle(props.Veiculos, larguraOriginal < limiar)}
                      id="nav-link"
                      href="/gestao/operacional/frota/veiculos/listar"
                    >
                      Veículos
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      eventKey="1.0"
                      style={getTitleConditionalStyle(props.Rotas, larguraOriginal < limiar)}
                      id="nav-link"
                      href="/gestao/operacional/frota/rotas/listar"
                    >
                      Rotas
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      eventKey="1.0"
                      style={getTitleConditionalStyle(props.RondaVirtual, larguraOriginal < limiar)}
                      id="nav-link"
                      href="/gestao/operacional/rondavirtual/listar"
                    >
                      Ronda Virtual
                    </NavDropdown.Item>


                    <NavDropdown
                      title={
                        <span
                          style={getTitleConditionalStyle(props.Notificacoes, larguraOriginal < limiar)}
                        >
                          Notificações
                        </span>
                      }

                      style={getConditionalStyle(props.Notificacoes, larguraOriginal < limiar)}
                      align={larguraOriginal < limiar ? "end" : ''}
                      className={larguraOriginal < limiar ? 'dropdown-mainmenu' : ''}

                    >

                      <NavDropdown.Item
                        eventKey="1.0"
                        style={getTitleConditionalStyle(props.Notificacoes, larguraOriginal < limiar)}
                        id="nav-link"
                        href="/gestao/operacional/notificacoes/listar"
                      >
                        Notificações
                      </NavDropdown.Item>




                      <NavDropdown
                        title={
                          <span
                            className={larguraOriginal < limiar ? 'mobile' : ''}
                          >
                            Tabelas Auxiliares
                          </span>
                        }
                        id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                        style={getConditionalStyle(props.TabelasAuxiliares, larguraOriginal < limiar)}
                      >
                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.TipoOcorrencias ? 'underline' : 'white', backgroundColor: props.TipoOcorrencias ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/notificacoes/tiposocorrencias/listar">Tipos de Ocorrências
                        </NavDropdown.Item>


                      </NavDropdown>

                    </NavDropdown>

                  </NavDropdown>


                  {regra.includes("ADMIN")
                    && (
                      <NavDropdown
                        title={
                          <span style={getTitleConditionalStyle(props.Almoxarifado, larguraOriginal < limiar)}>
                            Almoxarifado
                          </span>
                        }
                        id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                        style={getConditionalStyle(props.Almoxarifado, larguraOriginal < limiar)}
                        align={larguraOriginal < limiar ? "end" : ''}
                        className={larguraOriginal < limiar ? 'dropdown-mainmenu' : ''}
                      >
                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Insumos, larguraOriginal < limiar)}
                          id="nav-link"
                          href="/gestao/operacional/almoxarifado/insumos/listar"
                        >
                          Insumos
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Armazenamento, larguraOriginal < limiar)}
                          id="nav-link"
                          href="/gestao/operacional/almoxarifado/armazenamento/listar"
                        >
                          Armazenamento
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.OpEntrada, larguraOriginal < limiar)}
                          id="nav-link"
                          href="/gestao/operacional/almoxarifado/entradaestoque/listar"
                        >
                          Operações de Entrada
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Estoque, larguraOriginal < limiar)}
                          id="nav-link"
                          href="/gestao/operacional/almoxarifado/estoque/listar"
                        >
                          Estoque
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Fornecedores, larguraOriginal < limiar)}
                          id="nav-link"
                          href="/gestao/operacional/almoxarifado/fornecedores/listar/1"
                        >
                          Fornecedores
                        </NavDropdown.Item>
                      </NavDropdown>

                    )
                  }

                  {(regra.includes("ADMIN") || regra.includes("TEC_OS"))
                    && (
                      <NavDropdown
                        title={
                          <span style={getTitleConditionalStyle(props.Gestao, larguraOriginal < limiar)}>
                            Gestão
                          </span>
                        }
                        id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                        style={getConditionalStyle(props.Gestao, larguraOriginal < limiar)}
                        align={larguraOriginal < limiar ? "end" : ''}
                        className={larguraOriginal < limiar ? 'dropdown-mainmenu' : ''}
                      >
                        {(regra.includes("SUPER_ADMIN") || regra.includes("ADMIN_OC")) && (
                          <NavDropdown.Item
                            eventKey="1.0"
                            style={getTitleConditionalStyle(props.OrdensCompras, larguraOriginal < limiar)}
                            id="nav-link"
                            href="/gestao/administracao/ordensdecompras/listar"
                          >
                            Ordens de Compras
                          </NavDropdown.Item>
                        )}

                        {(regra.includes("SUPER_ADMIN") || regra.includes("ADMIN_OS") || regra.includes("TEC_OS")) && (
                          <NavDropdown.Item
                            eventKey="2.0"
                            style={getTitleConditionalStyle(props.OrdensServicos, larguraOriginal < limiar)}
                            id="nav-link"
                            href="/gestao/administracao/ordensdeservicos/listar"
                          >
                            Ordens de Serviços
                          </NavDropdown.Item>
                        )}
                      </NavDropdown>

                    )
                  }


                  {(regra.includes("SUPER_ADMIN") || regra.includes("ADMIN_RH"))
                    && (
                      <NavDropdown
                        title={
                          <span style={getTitleConditionalStyle(props.RH, larguraOriginal < limiar)}>
                            RH
                          </span>
                        }
                        id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                        style={getConditionalStyle(props.RH, larguraOriginal < limiar)}
                        align={larguraOriginal < limiar ? "end" : ''}
                        className={larguraOriginal < limiar ? 'dropdown-mainmenu' : ''}
                      >
                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Colaboradores, larguraOriginal < limiar)}
                          id="nav-link"
                          href="/gestao/rh/colaboradores/listar"
                        >
                          Colaboradores
                        </NavDropdown.Item>

                        <NavDropdown
                          title={
                            <span style={getTitleConditionalStyle(props.TabelasAuxiliaresRh, larguraOriginal < limiar)}>
                              Tabelas Auxiliares
                            </span>
                          }
                          id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                          style={getConditionalStyle(props.TabelasAuxiliaresRh, larguraOriginal < limiar)}
                          align={larguraOriginal < limiar ? "end" : ''}
                          className={larguraOriginal < limiar ? 'dropdown-submenu' : ''}
                        >
                          <NavDropdown.Item
                            eventKey="1.1"
                            style={getTitleConditionalStyle(props.Funcoes, larguraOriginal < limiar)}
                            id="nav-link"
                            href="/gestao/rh/funcoes/listar"
                          >
                            Funções
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            eventKey="1.2"
                            style={getTitleConditionalStyle(props.Departamentos, larguraOriginal < limiar)}
                            id="nav-link"
                            href="/gestao/rh/departamentos/listar"
                          >
                            Departamentos
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            eventKey="1.3"
                            style={getTitleConditionalStyle(props.Cargos, larguraOriginal < limiar)}
                            id="nav-link"
                            href="/gestao/rh/cargos/listar"
                          >
                            Cargos
                          </NavDropdown.Item>
                        </NavDropdown>
                      </NavDropdown>

                    )
                  }

                  {(regra.includes("SUPER_ADMIN") || regra.includes("ADMIN_FINAN"))
                    && (
                      <NavDropdown
                        title={
                          <span style={getTitleConditionalStyle(props.Financas, larguraOriginal < limiar)}>
                            Finanças
                          </span>
                        }
                        id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                        style={getConditionalStyle(props.Financas, larguraOriginal < limiar)}
                        align={larguraOriginal < limiar ? "end" : ''}
                        className={larguraOriginal < limiar ? 'dropdown-mainmenu' : ''}
                      >
                        <NavDropdown
                          title={
                            <span style={getTitleConditionalStyle(props.TabelasAuxiliaresFinancas, larguraOriginal < limiar)}>
                              Tabelas Auxiliares
                            </span>
                          }
                          id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                          style={getConditionalStyle(props.TabelasAuxiliaresFinancas, larguraOriginal < limiar)}
                          align={larguraOriginal < limiar ? "end" : ''}
                          className={larguraOriginal < limiar ? 'dropdown-submenu' : ''}
                        >
                          <NavDropdown.Item
                            eventKey="1.0"
                            style={getTitleConditionalStyle(props.MetodosPagamento, larguraOriginal < limiar)}
                            id="nav-link"
                            href="/gestao/financas/metodospagamento/listar"
                          >
                            Métodos de Pagamento
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            eventKey="1.1"
                            style={getTitleConditionalStyle(props.CondicoesPagamento, larguraOriginal < limiar)}
                            id="nav-link"
                            href="/gestao/financas/condicoespagamento/listar"
                          >
                            Condições de Pagamento
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            eventKey="1.2"
                            style={getTitleConditionalStyle(props.GrupoContas, larguraOriginal < limiar)}
                            id="nav-link"
                            href="/gestao/financas/grupocontas/listar"
                          >
                            Grupo de Contas
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            eventKey="1.3"
                            style={getTitleConditionalStyle(props.Contas, larguraOriginal < limiar)}
                            id="nav-link"
                            href="/gestao/financas/contas/listar"
                          >
                            Contas
                          </NavDropdown.Item>

                          <NavDropdown.Item
                            eventKey="1.4"
                            style={getTitleConditionalStyle(props.InstituicaoBancaria, larguraOriginal < limiar)}
                            id="nav-link"
                            href="/gestao/financas/instituicaobancaria/listar"
                          >
                            Instituições Bancárias
                          </NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown.Item
                          eventKey="2.0"
                          style={getTitleConditionalStyle(props.Lancamento, larguraOriginal < limiar)}
                          id="nav-link"
                          href="/gestao/financas/lancamento/listar"
                        >
                          Lançamentos
                        </NavDropdown.Item>
                      </NavDropdown>


                    )
                  }

                  {
                    larguraOriginal < limiar && (
                      <div style={{ backgroundColor: 'black', width: '100%', height: '2px' }}>

                      </div>
                    )
                  }

                  {regra.includes("SUPER_ADMIN")
                    && (
                      <NavDropdown
                        title={
                          <div
                            className={larguraOriginal < limiar ? 'mobile' : ''}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}

                          >
                            <SettingsOutlinedIcon sx={{ fontSize: 24, color: 'black' }} />
                            {larguraOriginal < limiar && (
                              <span style={{ marginLeft: '5px' }}>Configurações</span>
                            )}
                          </div>
                        }
                        id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                        style={getConditionalStyle(props.TabelasAuxiliares, larguraOriginal < limiar)}
                        align={larguraOriginal < limiar ? "end" : ''}
                        className={larguraOriginal < limiar ? 'dropdown-submenu' : ''}
                      >

                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Configuracoes, larguraOriginal < limiar)}
                          id="nav-link"
                          href="/administracao/configuracoes"
                        >Configurações
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          eventKey="1.0"
                          style={getTitleConditionalStyle(props.Usuarios, larguraOriginal < limiar)}
                          id="nav-link"
                          href="/administracao/usuarios"
                        >Usuários
                        </NavDropdown.Item>

                      </NavDropdown>

                    )
                  }



                  <NavDropdown
                    title={
                      <div
                        className={larguraOriginal < limiar ? 'mobile' : ''}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <AccountCircleIcon sx={{ fontSize: 24, color: 'black' }} />
                        {larguraOriginal < limiar && (
                          <span style={{ marginLeft: '5px' }}>Minha Conta</span>
                        )}
                      </div>
                    }
                    id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}
                    style={getConditionalStyle(props.TabelasAuxiliares, larguraOriginal < limiar)}
                    className={larguraOriginal < limiar ? 'dropdown-submenu' : ''}

                  >
                    <NavDropdown.Item
                      eventKey="1.0"
                      style={getConditionalStyle(props.MinhaConta, larguraOriginal < limiar)}
                      id="nav-link"
                      href="/minhaconta"
                    >Minha Conta
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      eventKey="1.0"
                      style={getConditionalStyle(props.Sair, larguraOriginal < limiar)}
                      onClick={onConfirmSair}
                      id="nav-link"
                    >
                      Sair
                    </NavDropdown.Item>






                  </NavDropdown>



                  {larguraOriginal > limiar && (
                    <NavDropdown
                      id={larguraOriginal > limiar ? 'nav-dropdown' : 'nav-dropdownmobile'}

                      drop='start'

                      title={
                        <NotificationsIcon
                          className={tarefas.length > 0 ? "shake-bell" : ""}
                          style={{ color: "green" }}
                          sx={{ fontSize: 24, color: 'green' }} />
                      }


                    >
                      <div > {/* Defina a largura desejada */}

                        {
                          loading && tarefas ?
                            null
                            :
                            tarefas.map((tarefa) => {
                              return (
                                <NavDropdown.Item
                                  onClick={(ev) => handleOpenAlert(tarefa)}
                                  style={{ backgroundColor: 'white', color: 'black', paddingRight: 40 }}
                                  key={tarefa.id_tarefa} eventKey={tarefa.id_tarefa}
                                >
                                  <Grid item xs={12} container spacing={2} alignItems="center" style={{ marginRight: 30, marginLeft: 30 }}>
                                    <Grid item xs={6}>
                                      <Typography variant="h7">
                                        {tarefa.nome}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6} container justifyContent="flex-end">
                                      <img style={{ height: '30px', marginInline: 5 }} alt={'mini_icone_informacao'} src={mini_icone_informacao} />
                                    </Grid>
                                  </Grid>
                                </NavDropdown.Item>
                              )
                            })
                        }
                      </div>
                    </NavDropdown>
                  )}




                </Nav>


              </Navbar.Collapse>

            </Container>

          </Navbar>



        </Grid >
      </Grid >


      <div>

        {tarefaEmVista &&
          <Dialog
            open={alertOpen}
            onClose={handleCloseAlert}
            aria-labelledby="confirm-dialog"
          >
            <DialogTitle id="confirm-dialog">{ }</DialogTitle>
            <DialogContent >
              <div >

                <Grid container spacing={2}
                  style={{ padding: 20 }}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  item xs={12} >
                  <Grid

                    item xs={6}

                  >
                    <Typography
                      variant="h5"
                    >
                      {tarefaEmVista.nome}
                    </Typography>
                  </Grid>

                  <Grid

                    item xs={6}

                  >
                    <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                      <img alt={'mini_icone_informacao'} style={{ textAlign: 'center' }} src={mini_icone_informacao}></img>
                    </div>
                  </Grid>



                  <Grid

                    item xs={12}

                  >
                    <Typography
                      variant="h6"
                      component="div"
                      style={{ color: 'black' }}
                    >
                      {tarefaEmVista.descricao.split('\n').map((line, index) => {
                        const parts = line.split('\n'); // Dividindo a linha pela quebra de linha
                        return (
                          <React.Fragment key={index}>
                            <React.Fragment>
                              <a
                                href={processarLink()} // Adicione o link aqui
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'underline', color: 'blue' }}
                              >
                                {parts[0]} {/* Somente a primeira parte é o link */}
                              </a>
                              {parts[1]} {/* Mostra o restante do texto */}
                            </React.Fragment>
                            {/* Adiciona uma quebra de linha, se não for a última linha */}
                            {index !== tarefaEmVista.descricao.split('\n').length - 1 && <br />}
                          </React.Fragment>
                        );
                      })}
                    </Typography>
                  </Grid>
                  {tarefaEmVista.ordem_servico?.solicitacao.id_solicitacao === 7 &&
                    tarefaEmVista.ordem_servico?.id_ordem_compra === 0
                    &&
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      item xs={6}
                    >
                      <Button style={{ marginTop: 20 }}
                        variant="contained"
                        color="primary"
                        onClick={criarOCporOs}

                      >
                        Criar a Ordem de Compra
                      </Button>
                    </Grid>
                  }
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={6}
                  >
                    <Button style={{ marginTop: 20 }}
                      variant="contained"
                      color="primary"
                      onClick={handleCloseAlert}

                    >
                      OK
                    </Button>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={6}
                  >
                    <Button style={{ marginTop: 20 }}
                      variant="contained"
                      color="secondary"
                      onClick={handleDescartarNotificacao}

                    >
                      Descartar Notificação
                    </Button>
                  </Grid>

                </Grid>
              </div>
            </DialogContent>

          </Dialog >
        }

      </div>


      {openBalao && (
        <Draggable>
          <Box
            sx={{
              position: "fixed",
              top: "20px",
              left: "20px",
              backgroundColor: "#fff",
              border: "2px solid #000", // Borda preta mais espessa
              borderRadius: "8px",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)", // Aumenta a sombra
              padding: "16px",
              zIndex: 9999, // Garante que fique por cima de outros elementos
            }}


          >
            <Typography variant="body1" gutterBottom>
              {"Nova Ronda Virtual nº " + rondaVirtualPendente.id_ronda_virtual + " pendente"}
              <br />
              {"Localidade: " + returnDescricaoInstalacaoResumida(rondaVirtualPendente.instalacao)}
              <br />
              {"Grupo: " + rondaVirtualPendente.instalacao.grupo_cliente.nome}
            </Typography>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "8px",
              }}
              item
              container
              xs={12}
            >
              {!justificativa && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={justificativa}
                  onClick={handleBalaoOk}
                  sx={{ marginRight: "8px" }}
                >
                  Assumir
                </Button>
              )}
              {!justificativa && (
                <Button variant="outlined" color="secondary" onClick={handleJustificativa}>
                  Agora não
                </Button>
              )}
              {justificativa && (
                <Grid
                  item
                  container
                  xs={12}
                  style={{ paddingTop: 2 }}
                >
                  <TextField
                    variant="standard"
                    name="justificativa"
                    label="Justificativa"
                    required
                    id="justificativa"
                    value={mensagemJustificativa}
                    onChange={(e) => setMensagemJustificativa(e.target.value)}
                    fullWidth
                    multiline
                    minRows={2}
                  />
                </Grid>
              )}
              {justificativa && (
                <Button variant="outlined" color="secondary" onClick={(e) => handleFinalizarJustificativa()}>
                  Finalizar
                </Button>
              )}

            </Grid>
          </Box>
        </Draggable>
      )}


      {openRondaVirtualEmAndamento && !location.pathname.includes('rondavirtual') && (
        <Draggable>
          <Box
            sx={{
              position: "fixed",
              top: "20px",
              left: "20px",
              backgroundColor: "#fff",
              border: "2px solid #000", // Borda preta mais espessa
              borderRadius: "8px",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)", // Aumenta a sombra
              padding: "16px",
              zIndex: 9999, // Garante que fique por cima de outros elementos
            }}


          >
            <Typography variant="body1" gutterBottom>
              {"Você tem uma Ronda Virtual está em andamento!"}
              <br />
              {"Ronda Virtual nº " + rondaVirtualEmAndamento.id_ronda_virtual}
              <br />
              {"Localidade: " + returnDescricaoInstalacaoResumida(rondaVirtualEmAndamento.instalacao)}
              <br />
              {"Grupo: " + rondaVirtualEmAndamento.instalacao.grupo_cliente.nome}
            </Typography>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "8px",
              }}
              item
              container
              xs={12}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => handleIrParaGestaoRondaVirtual()}
                sx={{ marginRight: "8px" }}
              >
                Ir para gestão da Ronda Virtual
              </Button>
            </Grid>
          </Box>
        </Draggable>
      )}


      {/* Exibe a tela de resultados abaixo do Navbar se houver mais de 1 instalação encontrada */}
      {instalacoesEncontradas.length > 0 && (
        <div className={larguraOriginal < limiar ? "resultadoMobile" : "resultado"}>
          <button className="fecharBotao" onClick={() => setInstalacoesEncontradas([])}>
            ✖
          </button>

          {instalacoesEncontradas.map((instalacao) => (
            <Grid
              key={instalacao.id_instalacao} // Adiciona uma key para cada item no loop
              container
              direction="row"
              justifyContent="center"
              align="center"
              item xs={12}
              style={{ border: "1px solid black", padding: 10, margin: 5 }}
              //onClick={() => handleInstalacaoSelecioanada(instalacao)} // Atualiza o estado ao clicar
            >
 
              <LocalComponent
                id_instalacao={instalacao.id_instalacao}
                tipo_icone={instalacao.icone_mapa}
                nome_mapa={instalacao.nome_mapa}
                instalacao={instalacao}
              />
            </Grid>
          ))}
        </div>
      )}

    </div >
  )
}
export default NavegadorGestao;