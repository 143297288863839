
import React, { useState, useEffect } from 'react';
import Rodape from '../../components/Rodape';
import Navegador from '../../components/NavBar';
import Cookies from 'js-cookie';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import './styles.scss';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import logo from '../../assets/imgs/logo_kapplan_energy.png';



const useStyles = makeStyles((theme) => ({
    root: {
        height: '50vh',
    },

    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    papermobile: {
        margin: theme.spacing(1, 1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    avatarmobile: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    entrada: {
        fontSize: 22
    },
}));

export default function PageNotFound() {

    const [width, setWidth] = useState(0);

    const [larguraOriginal, setLarguraOriginal] = useState(0);

    const [limiar, setLimiar] = useState(900);



    function checkDimenssoes() {
        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
        console.log("altura: " + altura);
        console.log("largura: " + largura);

        setLarguraOriginal(largura);
        setWidth(largura * 0.7);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });


    useEffect(() => {



        checkDimenssoes();



    }, []);


    const history = useHistory();

  

 

    return (
        <div>
        <Grid
            item xs={12} container
            direction='row'
            alignContent={larguraOriginal < limiar ? 'center' : 'flex-start'}
            justifyContent={larguraOriginal < limiar ? 'center' : 'flex-start'}
            component={Paper} elevation={larguraOriginal < limiar ? 0 : 10} square
            style={{ margin: larguraOriginal < limiar ? 1 : 50, marginTop: larguraOriginal < limiar ? 20 : null }}
        >
            <Grid
                item xs={12} container
                direction="column" alignItems="center" justifyContent="center"
                style={{ padding: 20 }}
            >
                <Typography variant="h4" color="textPrimary" align="center">
                    404 - Página Não Encontrada
                </Typography>
                <Typography variant="h6" color="textSecondary" align="center" style={{ marginTop: 20 }}>
                    A página que você está procurando não foi encontrada.
                </Typography>
                <Typography variant="body1" color="textSecondary" align="center" style={{ marginTop: 10 }}>
                    Verifique o endereço ou volte para a página inicial.
                </Typography>
            </Grid>
        </Grid>
    </div>
    );
}
