import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@material-ui/core/IconButton';
import {
    MaterialReactTable,

} from 'material-react-table';


import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';



import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import {
    Box,
    Tooltip
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import { Button } from "@material-ui/core";
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { Timeline } from 'rsuite';
import CreditCardIcon from '@rsuite/icons/legacy/CreditCard';
import PlaneIcon from '@rsuite/icons/legacy/Plane';
import TruckIcon from '@rsuite/icons/legacy/Truck';
import UserIcon from '@rsuite/icons/legacy/User';
import CheckIcon from '@rsuite/icons/legacy/Check';
import './Timeline.css';

const drawerWidth = 240;




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));

const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));



export default function OrdensDeServicos() {

    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [height, setHeight] = useState(0);

    const [objetos, setObjetos] = useState(null);

    function checkDimenssoes() {

        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;


        setHeight(altura * 0.75);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });




    async function listar() {

        setLoading(true);

        try {
            const token = Cookies.get('token');
            const id_usuario = Cookies.get('id_usuario');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const regra = Cookies.get('regra');
            if (regra.includes("ADMIN")) {
                await api.get("v1/protected/ordemservico/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setObjetos(response.data)

                    //console.log(" Objetos: " + JSON.stringify(response.data));
                    setLoading(false);


                });
            } else if (regra.includes("TEC_OS")) {
                await api.get("v1/protected/ordemservico/listarporusuario/" + id_usuario, {
                    headers: headers
                }).then(function (response) {
                    setObjetos(response.data)
                    // console.log(" Objetos: " + response);
                    setLoading(false);
                });
            }

        } catch (_err) {

            console.log("Erro ao listar objetos: " + _err)
            setLoading(false);

        }

    }


    useEffect(() => {


        checkDimenssoes();


        listar();


    }, []);

    function novo() {
        window.location.href = "/gestao/administracao/ordensdeservicos/cadastrarordemservico"

    }




    const handleClickEdicao = (cellValues) => {


        window.location.href = "/gestao/administracao/ordensdeservicos/editar/" + cellValues.id_ordem_servico;


    };


    function returnDescricaoInstalacao(instalacao) {
        var texto = "" + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia) +
            " | SIGMA: " + instalacao.identificador_integrador;

        return texto;
    }



    function returnDescricaoSolicitacao(solicitacao) {
        var texto = "" + solicitacao.solicitacao;
        return texto;
    }






    const columns = useMemo(
        () => [

            {
                accessorKey: 'id_ordem_servico', //normal accessorKey
                header: 'ID',
                size: 30,
                maxWidth: 30,
                id: 'id_ordem',


            },

            {
                header: 'Solicitação',
                id: 'solicitacao',
                size: 200,
                accessorFn: row => {
                    return {
                        solicitacao: row.solicitacao,

                    };

                },
                Cell: ({ renderedCellValue }) => {

                    if (renderedCellValue.solicitacao) {
                        return (
                            <span style={{ fontSize: 14, color: 'black', fontSize: 'bold' }}>
                                {returnDescricaoSolicitacao(renderedCellValue.solicitacao)}
                            </span>
                        );
                    } else {
                        return (
                            <span style={{ fontSize: 14, color: 'black', fontSize: 'bold' }}>
                            </span>
                        );

                    }


                }
            },

            {
                header: 'Instalação',
                size: 100,
                accessorFn: row => {
                    return {
                        instalacao: row.instalacao,

                    };

                },
                Cell: ({ renderedCellValue }) => {

                    if (renderedCellValue.instalacao) {
                        return (
                            <span style={{ fontSize: 14, color: 'black', fontSize: 'bold' }}>
                                {returnDescricaoInstalacao(renderedCellValue.instalacao)}
                            </span>
                        );
                    } else {
                        return (
                            <span style={{ fontSize: 14, color: 'black', fontSize: 'bold' }}>
                            </span>
                        );

                    }


                }
            },

            {
                id: 'timeline',
                header: 'Linha do Tempo',
                size: 400,
                maxWidth: 400,
                accessorFn: row => {
                    return {
                        data_hora_criacao: row.data_hora_criacao,
                        status_ordem: row.status_ordem,
                        data_hora_finalizacao_tratamento_remoto: row.data_hora_finalizacao_tratamento_remoto,
                    };

                },
                Cell: ({ renderedCellValue }) => {

                    const dataHoraCriacaoFormatada = moment(renderedCellValue.data_hora_criacao, 'YYYY-MM-DD HH:mm:ss').format('HH:mm DD/MM');
                    const status = renderedCellValue.status_ordem;
                    const dataHoraFinalizouTratamentoRemoto = moment(renderedCellValue.data_hora_finalizacao_tratamento_remoto, 'YYYY-MM-DD HH:mm:ss').format('HH:mm DD/MM');

                    
                    return (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                            {/* Bloco 1 */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    margin: '0 1rem'
                                }}
                            >
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    {dataHoraCriacaoFormatada}
                                </Typography>

                                <Box sx={{ height: '2px' }} />

                                <Box
                                    sx={{
                                        width: 32,
                                        height: 32,
                                        border: '2px solid green',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <EditIcon sx={{ color: 'green', fontSize: 16 }} />
                                </Box>
                            </Box>

                            {/* Traço entre Bloco 1 e Bloco 2 */}
                            {status > 0 && (
                                <Box
                                    sx={{
                                        width: 50, // comprimento do traço
                                        height: 3, // espessura
                                        alignSelf: 'center',
                                        marginTop: '1.25rem',
                                        background:
                                            status > 0
                                                ? 'green'
                                                : 'linear-gradient(90deg, transparent, #ffcdd2, transparent)',
                                        animation:
                                            status < 1
                                                ? 'pulseLine 2s infinite ease-in-out'
                                                : 'none',
                                        '@keyframes pulseLine': {
                                            '0%': {
                                                background: 'linear-gradient(90deg, transparent, #ffcdd2, transparent)',
                                            },
                                            '50%': {
                                                background: 'linear-gradient(90deg, transparent, #e57373, transparent)',
                                            },
                                            '100%': {
                                                background: 'linear-gradient(90deg, transparent, #ffcdd2, transparent)',
                                            },
                                        },
                                    }}
                                />
                            )}

                            {status > 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        margin: '0 1rem',
                                    }}
                                >
                                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                        {status === 1  ? "Em curso" : status === 9 ?  dataHoraFinalizouTratamentoRemoto : ""}
                                    </Typography>

                                    <Box sx={{ height: '2px' }} />

                                    <Box
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            border: `2px solid ${status === 1 ? '#e57373' : 'green'}`,
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: status === 1 ? '#fce4ec' : 'transparent',
                                            animation: status === 1 ? 'pulseZoom 2s infinite ease-in-out' : 'none',
                                            '@keyframes pulseZoom': {
                                                '0%': {
                                                    backgroundColor: 'transparent',
                                                    transform: 'scale(1)',
                                                },
                                                '50%': {
                                                    backgroundColor: '#ffcdd2',
                                                    transform: 'scale(1.3)', // aumenta o tamanho
                                                },
                                                '100%': {
                                                    backgroundColor: 'transparent',
                                                    transform: 'scale(1)',
                                                },
                                            },
                                        }}
                                    >
                                        <ComputerOutlinedIcon
                                            sx={{
                                                color: status === 1 ? '#e57373' : 'green',
                                                fontSize: 18,
                                                animation: status === 1 ? 'iconZoom 2s infinite ease-in-out' : 'none',
                                                '@keyframes iconZoom': {
                                                    '0%': {
                                                        transform: 'scale(1)',
                                                    },
                                                    '50%': {
                                                        transform: 'scale(1.3)',
                                                    },
                                                    '100%': {
                                                        transform: 'scale(1)',
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                            )}


                            {status >= 1 && (
                                <Box
                                    sx={{
                                        width: 50, // comprimento do traço
                                        height: 3, // espessura
                                        alignSelf: 'center',
                                        marginTop: '1.25rem',
                                        background:
                                            status === 1
                                                ? 'linear-gradient(90deg, transparent, #ffcdd2, transparent)'
                                                : 'green',
                                        animation:
                                            status === 1
                                                ? 'pulseLine 2s infinite ease-in-out'
                                                : 'none',
                                        '@keyframes pulseLine': {
                                            '0%': {
                                                background: 'linear-gradient(90deg, transparent, #ffcdd2, transparent)',
                                            },
                                            '50%': {
                                                background: 'linear-gradient(90deg, transparent, #e57373, transparent)',
                                            },
                                            '100%': {
                                                background: 'linear-gradient(90deg, transparent, #ffcdd2, transparent)',
                                            },
                                        },
                                    }}
                                />
                            )}

                        </Box >


                    );
                },
            },


            {
                id: 'analista',
                accessorKey: 'analista.nome_amigavel',
                header: 'Analista',
                size: 25,
                maxWidth: 25,
                Cell: ({ cell }) => (
                    <span style={{ fontSize: 14, color: 'black', fontSize: 'bold' }}>
                        {cell.getValue()}
                    </span>
                ),
            },

            {
                id: 'usuario_criador',
                accessorKey: 'usuario_criador.nome_amigavel',
                header: 'Usuário Criador',
                size: 25,
                maxWidth: 25,
                Cell: ({ cell }) => (
                    <span style={{ fontSize: 14, color: 'black', fontSize: 'bold' }}>
                        {cell.getValue()}
                    </span>
                ),
            },

            {
                id: 'data_hora_criacao',
                accessorKey: 'data_hora_criacao',
                header: 'Data Criação',
                size: 25,
                maxWidth: 25,
                Cell: ({ cell }) => {

                    if (cell.getValue()) {
                        const formattedDate = moment(cell.getValue(), 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY');
                        return (
                            <span style={{ fontSize: 14, color: 'black', fontWeight: 'bold' }}>
                                {formattedDate}
                            </span>
                        );
                    }

                    return (
                        <span style={{ fontSize: 14, color: 'black', fontSize: 'bold' }}>

                        </span>
                    );


                },
            },



            {
                id: 'usuario_solicitante',
                accessorKey: 'usuario_solicitante.nome_amigavel',
                header: 'Usuário Solicitante',
                size: 25,
                maxWidth: 25,
                Cell: ({ cell }) => (
                    <span style={{ fontSize: 14, color: 'black', fontSize: 'bold' }}>
                        {cell.getValue()}
                    </span>
                ),
            },

        ],
        [],
    );





    const [open, setOpen] = useState(false);

    const [motivo, setMotivo] = useState('');

    const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
    const [usuarios, setUsuarios] = useState(null);
    const [idOrdemDesignar, setIdOrdemDesiginar] = useState('');



    async function handleDesignar() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {
                motivo: motivo,
                usuario_designador: { id_usuario: Cookies.get("id_usuario") },
                analista: { id_usuario: usuarioSelecionado.id_usuario },
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/ordemservico/designar/' + idOrdemDesignar, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("A Ordem de Serviço foi atualizada!");
                setOpen(false);
                setUsuarioSelecionado(null);
                setMotivo('');
                setIdOrdemDesiginar('');
                window.location.reload(true);

            }



        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }




    const handleOpen = (cellValues) => {

        setIdOrdemDesiginar(cellValues.id_ordem_servico);
        listarUsuarios();
    }





    async function listarUsuarios() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/user/listartodos", {
                headers: headers
            }).then(function (response) {
                setUsuarios(response.data)
                setOpen(true);

            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Usuarios: " + _err)

        }

    }

    function returnDescricaoUsuario(usuario) {
        var texto = "ID_Usuario: " + usuario.id_usuario + "\nNome: " + usuario.nome_amigavel + "\nRegiões de Atuação: " + usuario.regiao_cobertura;

        var dados_colaborador = "";

        if (usuario.tipo_associacao === 0) {
            //colaborador
            dados_colaborador += ("\nDepartamentos: " + usuario.colaborador.departamentos.map(departamento => departamento.nome).join(", "));
            dados_colaborador += ("\nCargos: " + usuario.colaborador.cargos.map(cargo => cargo.nome).join(", "));
            dados_colaborador += ("\nFunções: " + usuario.colaborador.funcoes.map(funcao => funcao.nome).join(", "));


        }

        texto += ("\n" + dados_colaborador);


        return texto;
    }


    const handleClose = () => {
        setOpen(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };

    return (
        <div>


            <NavegadorGestao
                Gestao={true}
                OrdensServicos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 10, width: '100%', height: '100%' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novo}
                    >
                        Nova Ordem de Serviço
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <div style={{ backgroundColor: 'white', margin: 5 }}>
                            {objetos && (
                                <MaterialReactTable
                                    columns={columns}
                                    data={objetos}
                                    enableBottomToolbar={true}
                                    enableGlobalFilterModes
                                    enablePagination={true}
                                    enableRowActions
                                    paginationDisplayMode={'pages'}
                                    getRowId={(row) => row.id_ordem_servico}
                                    enableGrouping
                                    initialState={{
                                        pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe 
                                        showGlobalFilter: true,
                                    }}
                                    muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                    muiPaginationProps={{
                                        rowsPerPageOptions: [50, 100, 200],
                                        variant: 'outlined',
                                    }}
                                    enableFullScreenToggle={true}
                                    renderDetailPanel={({ row }) => (
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                left: '30px',
                                                maxWidth: '1000px',
                                                position: 'sticky',
                                                width: '100%',
                                            }}
                                        >
                                        </Box>
                                    )}
                                    renderRowActions={({ row }) => (
                                        <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>
                                            {
                                                parseInt(row.original.status_ordem) > 0 &&
                                                <Tooltip title="Gerenciar">
                                                    <IconButton
                                                        onClick={() => handleClickEdicao(row.original)}
                                                    >
                                                        <EditIcon style={{ color: 'green' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                parseInt(row.original.status_ordem) === 0 &&
                                                <Tooltip title="Designar Analista">
                                                    <IconButton color="error" onClick={() => handleOpen(row.original)}>
                                                        <SettingsIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }


                                        </Box>
                                    )}

                                />
                            )}
                        </div>
                    </div>

                }


            </div>


            <Dialog open={open} onClose={handleClose} PaperProps={{ style: { maxWidth: '600px', minWidth: '300px' } }}>
                <DialogTitle>Designar Analista</DialogTitle>
                <DialogContent>
                    <Grid
                        direction={"row"}
                        item xs={12}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={3}
                    >

                        <Grid
                            item xs={12}
                            style={{ margin: 20, padding: 20 }}
                        >
                            <TextField
                                label="Motivo"
                                variant="outlined"
                                fullWidth
                                value={motivo}
                                onChange={(e) => setMotivo(e.target.value)}
                            />
                        </Grid>
                        <Grid
                            item xs={12}
                        >
                            <Autocomplete
                                id="select-analista"
                                options={usuarios}
                                getOptionLabel={(usuario) => returnDescricaoUsuario(usuario).replace(/<br \/>/g, '\n')}
                                value={usuarioSelecionado}
                                onChange={(e, v) => setUsuarioSelecionado(v)}
                                filterOptions={(options, { inputValue }) => {
                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                    return options.filter((cotacao) => {
                                        const cotacaoText = returnDescricaoUsuario(cotacao).toLowerCase();
                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label=" Usuário " multiline rows={3} />
                                )}

                                renderOption={(option) => (
                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoUsuario(option).replace(/\n/g, '<br>') }} />
                                )}
                            />

                            <Button style={{ margin: 20, padding: 20 }} variant="contained" color="primary" onClick={handleDesignar}>
                                Designar
                            </Button>
                        </Grid>



                    </Grid>

                </DialogContent>
            </Dialog>

        </div>
    );
}



